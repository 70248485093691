<template>
<div class='inputWrapper' :style="'width:'+width+'% !important;'">
   <div v-if='showOpener && entity' class='input80' >	
         <span >
			
	         <vSelect :multiple='true' :options="options" v-model="internalValue" :style='getStyle()' :selectable=isSelectable
	            :appendToBody="true" :disabled="isDisabled()" @input="setSelected" placeholder='' :clearable="true"> 
	        </vSelect>
        </span>

    </div>	
    <div v-else class='input100' >	
         <span >
			
	         <vSelect :multiple='true' :options="options" v-model="internalValue" :style='getStyle()' :selectable=isSelectable
	            :appendToBody="true" :disabled="isDisabled()" @input="setSelected" placeholder='' :clearable="true"> 
	        </vSelect>
        </span>

    </div>	
    <div v-if='showOpener && entity' class='input20' >	
        <span v-tooltip='tooltip()'>
		<button :disabled='(! internalValue.id)' class='button btn' @click="$refs.editor.open( entity, value);">
		<svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
			  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
			</svg>
		</button>
		</span>
	 </div>	
	 <GFWEOpenEditor ref='editor'></GFWEOpenEditor>
</div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import {HTTP, fwAPI, accessPointAPI, APIURL} from '@/variables.js';

export default {
  name: "GSelectSimple",
  props: {
    disabled: { type: Boolean, default: false },
    showOpener: { type: Boolean, default: true },
    value: Array,
    width:  { type: Number, default: 100 },
    entity: String,
    options: Array,
    fontSize: { type: Number, default: 12 },
  },
  components : { vSelect},
  data() {
  	return {
         
  	}
  },
  methods: {
    openDetail() {
    	this.$emit("open", this.selectedValue.id, this.linkData.name)
    },
    setSelected() {
    	this.$emit("changed", this.value);
    },
    tooltip() { return ( this.entity) ? "Open " + this.entity: "" },
    getStyle() { 
      	if ( this.mark )
      	{
      		return "border: .5px solid #ddd;background-color: #fdd; font-size: " +this.fontSize+"px";
      	}
      	return "border: .5px solid #ddd;font-size: " +this.fontSize+"px";
      },
    isSelectable(option) { return option.visible; },
    isDisabled() { return this.disabled  },
  },
  computed: {
  	internalValue: {
      get() {
        if ( this.options.length === 1 )
        {
            this.$emit("input", [this.options[0].id])
        	return [ this.options[0] ];
        }
        if ( this.value && Array.isArray( this.value) )
        {
        	return this.options.filter( p => this.value.includes( p.id))
        }
        
        return [];
      },
      set(v) 
      {
       
        if ( v === null || v == [])
        {
        	this.$emit("input", []);
        	this.$emit("change", []);
        	this.$emit("clear", []) 
        }
        let x = v.map(p => p.id)
        this.value = x;
        this.$emit("input", this.value)
        this.$emit("change", this.value)

      }
    }
  },
  watch: {

  },
  updated() {

  },
  created() {

  }
 }
</script>
<style  scoped>
.inputWrapper {
	width: 50%;
	display: inline-block;
	padding: 0;
	margin:0;
}
.input80 {
	width: 90% !important;
	display: inline-block;
	padding: 0;
	margin:0;
}
.input100 {
	width: 100% !important;
	display: inline-block;
	padding: 0;
	margin:0;
}
.input20 {
	width: 10% !important;
	display: inline-block;
	text-align: right;
	vertical-align: top;
	padding: 0;
	margin:0;
}
.btn {
	height: 2.2em !important;
	padding-left: 0.5em;
	padding-right: 0.5em;
	
}
label, span{
        display: block;
    }
</style>