<template>
<div>   
	  <XModal :name="name" :resizable="true" :width="width+'%'" :height="height+'%'" :draggable="false" >
	  <div :style="getAppStyle()+'height: 100%;border: 2pt inset grey;'">
	  <div class='appHead'>
             <div class="dontWrap" style='color: #000; width: 80%; overflow-x: clip; font-size: 10pt; display: inline-flex; float: left; font-weight: bold; padding-left: 5pt; padding-top: 2pt; margin-right: 15pt;'>
              <div v-if="copy && copy.name" style='padding-top: 4pt; '>XXXXXXXX</div><div v-else style='padding-top: 4pt; '>{{tr('Placement')}}</div>
             </div>
            
              <button id='closePopup2' type="button" class="iButton" @click='close' data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16">
				  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
				  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
				</svg>
			  </span>
              </button>
            </div> <!-- appHead -->
       <div class="modal-header">
          <slot name="header">
            <div class='appHeadLine'>
            	
            </div> <!-- appHeadLine -->
            
           </slot>
        </div> <!-- modal-header -->  	  
        <div class="modal-body" style='height: 100% !important;' id='main-body'>
	  
	      <div  style=''>
          <slot name="body">
           
            <div class='mainContent' v-if="data" >
             
				<table width="100%">
					<tr>
						<td width="15%">Name</td><td width="84%"><InputText v-model="placementName"/></td>
					</tr>
					<tr>
						<td width="15%">Campaign</td><td width="84%"><InputText :disabled="true" v-model="campaignName"/></td>
					</tr>
					<tr>
						<td width="15%">Free text</td><td width="84%"><InputText v-model="placementFreeText"/></td>
					</tr>
					<tr>
						<td>Date From/To</td><td><div style="display: inline-flex;width:30%"><InputDatePick2 tabIndex="7" v-model="from"/></div>-
							                <div style="display: inline-flex;width:30%"><InputDatePick2 tabIndex="7" v-model="to"/></div></td>
					</tr>
					<tr>
						<td>Advertiser</td><td>
							<InputTextWS search='true' entity="Client" subType="Advertiser"
					            searchImmed='99' tabIndex="1" 
						        :fontSize='12' :disabled='false' 
							    :clipboard='false' ref='ifldEdit' v-model="advertiserName" 
								@id="id=>advertiserId=id"
						        @enter='closeEdit(col, line)' />
						</td>
					</tr>
					<tr>
						<td>Agency</td><td>
							<InputTextWS :disabled="!advertiserId" search='true' entity="Client" subType="Agency"
					            searchImmed='99' tabIndex="2" 
						        :fontSize='12' 
							    :clipboard='false' ref='ifldEdit' v-model="agencyName" 
								@id="id=>id?agencyId=id:agencyId=agencyId"
						        @enter='closeEdit(col, line)' />
						</td>
					</tr>
					<tr>
						<td>Type</td><td><GSelectSimple :options="data.placementTypesOpt" v-model="typeId"/></td>
					</tr>
					<tr>
						<td>Media</td><td><GSelectSimple :options="data.mediasOpt" v-model="mediaId"/></td>
					</tr>
					<tr>
						<td>Tax</td><td><GSelectSimple :options="data.taxSettingsOpt" v-model="taxId"/></td>
					</tr>
					<tr>
						<td>Billing Param</td><td><GSelectSimple :options="data.billingParametersOpt" v-model="billParId"/></td>
					</tr>
					<tr>
						<td>Business type</td><td><GSelectSimple :options="data.businessTypesOpt" v-model="busTypeId"/></td>
					</tr>
					
				</table>	
               
				
			   
			</div>
			
	      </slot>
	      </div>
	      </div>
		  <div style='position: sticky; width: 100%; bottom: 20pt !important; height: 30pt; padding-right: 15pt;'>
               <Button style='float:right;' class='button btn-save' @click="savePlacement()"><mdicon :size='18' name='content-save'/></button>
			   
               <Button title="close popup" style='float:right;' class='button' @click="close"><mdicon :size='18' name='close'/></button>
         
		</div>
	  </div>		
	  </XModal>
	  
	<GFWEOpenEditor ref='editor' @opened="hideMe" @closed="openMeAgain"></GFWEOpenEditor>
	
	
</div>
</template>


<script>
import {HTTP, fwAPI, copyWorkflowAPI, placementServiceAPI, invAPI, setReload, showError} from '@/variables.js';
import { getAppStyle, setDarkMode, initAppMode, getFGColor } from '@/AppStyle.js';
import { getData, defer } from '@/utils.js';
import { tr } from '@/translate.js';
import { format } from '@/Numbers.js';
import { parseTime } from '@/basicTimeFN.js';
import InputDatePick2 from '@/components/inputElements/InputDatePick3';
import InputDateTime from '@/components/inputElements/InputDateTime';
import InputMoney from '@/components/inputElements/InputMoneyNC';
import InputDouble from '@/components/inputElements/InputDouble';
import InputTextWS from '@/components/inputElements/InputTextWithSearch';
import Switch from '@/components/Switch';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import InputText from '@/components/inputElements/InputText';
import GSelectSimple from '@/components/GSelectSimple';
import GSelectMSimple from '@/components/GSelectMSimple';
import Checked from '@/components/Ops/Checked';
import CopyCreateWizard from '@/components/misc/CopyCreateWizard';
import ClickOutside from 'vue-click-outside'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import JQuery from "jquery";
let $ = JQuery;
var momentTZ = require('moment-timezone');
export default {
  name: 'GOPS_CopyEntryWizzard',
  components : {
      //InputText, InputDatePick2, GSelectSimple, CopyCreateWizard,GSelectMSimple 
	  //, InputDatePick2,InputDouble, 'app-switch': Switch
	  //,InputMoney
	  InputTextWS,  GSelectSimple, InputDatePick2,InputText
  },
  props: {
    name: {
      type: String,
      default: 'CopyWizzard'
    },
    height: {
      type: Number,
      default: 62
    }
  },
  data () {
    return {
		width: 60,
		adjust: true,
		data: null,
		placementName: "",
		placementFreeText: "",
		advertiserName: "",
		advertiserId: 0,
		agencyId: 0,
		agencyName: "",
		campaignId: 0,
		campaignName: "",
		
		types: [],
		typeId: 0,
		mediaId: 0,
		taxId: 0,
		billParId: 0,
		busTypeId: 0,
		
		from: new Date(),
		to: new Date(),
		
		visible: false,
		popupData: {},
		getAppStyle, format, tr
    }
  },
  directives:{
    ClickOutside,
  },
  
  methods: {
     close() {  this.$modal.hide(this.name) },
     getWidth() { return this.width+"%";},
     
     title( name )
     {
    	return name;
     },
	 
	
	 openEdit( entity, id )
	 {
		this.$refs.editor.open( entity, id);
	 },
	 openMeAgain()
	 {
		this.visible = true;
	 },
	 hideMe()
	 {
	 	this.visible = false;
	 },
	 doReload( p )
	 {
		//alert( JSON.stringify(p))
		if ( p )
		{
			this.copy=this.asCopy(p); 
			this.$emit('reload');
		}
	 },
	 asPlacement()
	 {
		let p = {};
		p.name = this.placementName;
		p.freeText = this.placementFreeText;
		p.media = [ this.mediaId];
		p.typeId = this.typeId;
		p.billingParameterId = this.billParId;
		p.taxId = this.taxId;
		p.clientId = this.advertiserId;
		p.agencyId = this.agencyId;
		p.businessTypeIds = [ this.busTypeId];
		p.campaignId = this.campaignId;
		p.fromDate = new Date(this.from).toISOString().split('T')[0];
		p.untilDate = new Date(this.to).toISOString().split('T')[0];
		return p;
	 },
	 savePlacement()
	 {
		let that = this;
		let p = this.asPlacement();
		
		HTTP.post( placementServiceAPI+"/createPlacement/"+sessionStorage.tenantId
					+"/"+sessionStorage.accessPointId
					+"/"+sessionStorage.unitId
					+"/"+sessionStorage.userId,
					p
					)

				 .then( response => 
		         {
					that.$toast.success( tr('Placement')+' '+ response.data.name+' created', 'Ok', { timeout: 1500, position: "topRight" });
					that.$forceUpdate();
				}).catch(e => {
					console.log(e)
		    	    showError( that.$toast, "reload", e);
		        });
	 },
	 getName()
 	 {
 		let that = this;
 		let p = this.asPlacement();
 		//alert( JSON.stringify( p))
 		HTTP.post( placementServiceAPI+"/getPlacementName/"+sessionStorage.tenantId
 					+"/"+sessionStorage.accessPointId
 					+"/"+sessionStorage.unitId
 					+"/"+sessionStorage.userId,
 					p
 					)

 				 .then( response => 
 		         {
 					
 					that.placementName = response.data;
 					that.$forceUpdate();
 				}).catch(e => {
 					console.log(e)
 		    	    showError( that.$toast, "reload", e);
 		        });
 	 },
	 searchCampaign()
  	 {
  		let that = this;
  		let p = this.asPlacement();
		that.campaignName = "no campaign found (will be created)"
  		HTTP.post( placementServiceAPI+"/findCampaign/"+sessionStorage.tenantId
  					+"/"+sessionStorage.accessPointId
  					+"/"+sessionStorage.unitId
  					+"/"+sessionStorage.userId
					+"/"+p.clientId
					+"/"+p.agencyId
					+"/"+p.fromDate
					+"/"+p.untilDate
  					)

  				 .then( response => 
  		         {
  					if ( response.data && response.data.name )
  					that.campaignName = response.data.name;
  				}).catch(e => {
  					console.log(e)
  		    	    showError( that.$toast, "searchCampaign", e);
  		        });
  	 },
	 open(  ) {
		this.placementName = "";
		this.placementFreeText = "";
		this.advertiserId = 0;
		this.advertiserName = "";
		this.agencyId = 0;
		this.agencyName = "";
		this.typeId = 0;
		this.loadData();
	 },
	 loadData()
	 {
		let that = this;

		HTTP.post( placementServiceAPI+"/getAllPlacementData/"+sessionStorage.tenantId
			+"/"+sessionStorage.accessPointId
			+"/"+sessionStorage.unitId
			+"/"+sessionStorage.userId
			)

		 .then( response => 
         {
			that.data = response.data;
			
			that.data.placementTypesOpt = that.data.placementTypes.map( p=> { return {id: p.id, label: p.name, visible: true}});
			that.data.taxSettingsOpt = that.data.taxSettings.map( p=> { return {id: p.id, label: p.name, visible: true}});
			that.data.billingParametersOpt = that.data.billingParameters.map( p=> { return {id: p.id, label: p.name, visible: true}});
			that.data.businessTypesOpt = that.data.businessTypes.map( p=> { return {id: p.id, label: p.name, visible: true}});
			that.visible = true;
			that.$modal.show(this.name);
			that.$forceUpdate();
		}).catch(e => {
    	    showError( that.$toast, "reload", e);
        });
	 },
     getTableStyle() {
        
     	//return "overflow-y: scroll; height: calc(60vH - 104px)";
     	return "overflow-y: auto; height: calc("+this.height+"vH - 180pt) !important;";
     },
	 
	 save( )
 	 {
		let that = this;
		this.copy.duration = parseTime( this.copy.duration);
		
		this.getLogName().then( p=> {
			let copy = {...this.copy}
			//alert( JSON.stringify( copy))
			copy.received = momentTZ(copy.received).utcOffset(0, true).format().split('T')[0];
			
			HTTP.post( copyWorkflowAPI+"/saveCopyEntry/"+sessionStorage.tenantId
				+"/"+sessionStorage.accessPointId
				+"/"+sessionStorage.unitId
				+"/"+sessionStorage.userId
				,
				copy
				)
	
			 .then( response => 
	         {
				that.copy = that.asCopy( response.data);
				that.$emit("reload");
		   		
			}).catch(e => {
	    	    showError( that.$toast, "update discount scale", e);
	        });
		});
 	 },
	 
  },
  watch: {
	advertiserId: function() {
		if ( this.advertiserId )
		{
			HTTP.post( placementServiceAPI+"/findAgency/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.advertiserId)	
			   		.then( response => {
						if ( response.data)
						{
							//alert( JSON.stringify( response.data))
							this.agencyId = response.data.id;
							
							//alert( JSON.stringify( this.agencyId))
							this.agencyName = response.data.name;
							if ( response.data.sameCountry)
							{
								this.data.taxSettingsOpt = this.data.taxSettings.filter(p=>p.taxRate && p.adTaxRate).map( p=> { return {id: p.id, label: p.name, visible: true}});
							}
							else
							{
								this.data.taxSettingsOpt = this.data.taxSettings.filter(p=>!p.taxRate && p.adTaxRate).map( p=> { return {id: p.id, label: p.name, visible: true}});
							}
							this.$forceUpdate();
						}
					});
		}
	},
	agencyId: function() {
		this.campaignName = "no campaign found (will be created)"
		if ( this.agencyId )
		{
			HTTP.post( placementServiceAPI+"/checkAgency/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.agencyId)	
			   		.then( response => {
						if ( response.data)
						{
							//alert( JSON.stringify( response.data))
							this.agencyId = response.data.id;
							this.searchCampaign()
							//alert( JSON.stringify( this.agencyId))
							this.agencyName = response.data.name;
							if ( response.data.sameCountry)
							{
								this.data.taxSettingsOpt = this.data.taxSettings.filter(p=>p.taxRate && p.adTaxRate).map( p=> { return {id: p.id, label: p.name, visible: true}});
							}
							else
							{
								this.data.taxSettingsOpt = this.data.taxSettings.filter(p=>!p.taxRate && p.adTaxRate).map( p=> { return {id: p.id, label: p.name, visible: true}});
							}
							this.$forceUpdate();
						}
					});
		}
	},
	typeId: function() {
		if ( this.typeId )
		{
			let type = this.data.placementTypes.find( p=>p.id==this.typeId);
			this.data.mediasOpt = this.data.medias.filter(p=>!type.mediaTypeId || p.mediaTypeId==type.mediaTypeId ).map( p=> { return {id: p.id, label: p.name, visible: true}});
			this.busTypeId = type.defaultBusinessTypeId;
			
			
		}
	},
	mediaId: function() {
		if ( this.typeId && this.mediaId)
		{
			this.getName();
		}	
	}
  },
  created() {
	
  }
}
</script>
<style scoped >

 .transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }
.hTable {
  table-layout: fixed;
  width: 100%;;
}

.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  overflow-x: hidden;
  overflow-y: hidden;
}
.tdSmall {
  overflow: hidden;
  width: 5%;
}
.tdLarge {
  width: 25%;
  overflow: hidden;
}
.tdXLarge {
  width: 40%;
  overflow: hidden;
}
.appLeft {
	display:  inline-table; 
	text-align: right;
	width: 100%; 
} 
.appRight {
	display: inline-table; 
	text-align: right;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
	width: 50%; 
}
.appHead { 
    width: 100%;  
    text-align: right;
    background-color: #eef;
    padding: 0pt 0pt 0pt;
 }
.history {
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 400 !important;
}
.iButton {
    background-color: transparent;
    border-color: #dbdbdb;
    border-width: 0px;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding-bottom: 2pt;;
    padding-left: 2pt;
    padding-right: 2pt;
    padding-top: 4pt;
    height: 20pt;
    text-align: center;
    white-space: nowrap;
}  
.mainContent {
  position: relative;
  height: 80% !important;
  
  float: top;
} 
.selectable {
	cursor: pointer; 
	transition: all 0.4s ease;
}
.bold {
	font-weight: bold;
}
.indent1 {
	margin-left: 10pt;
}
.indent2 {
	margin-left: 20pt;
}
.border {
	border: 1px solid #ccc;
}
.selectable:hover {
	background-color: #ccc !important;
	color: #000 !important;
}
.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: table;
}
.btn-small {
	height: 14pt;
}
.btn-save {
	background-color: #080 !important;
    color: #fff !important;
}
.button {
    background-color: white;
    border-color: #dbdbdb;
    border-width: 1px;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding-bottom: calc(0.7em - 1px);
    padding-left: .7em;
    padding-right: .7em;
    padding-top: calc(0.7em - 1px);
    text-align: center;
    white-space: nowrap;
    font-size: 10pt;
}
.smallBN {
	//width: 18%;
    outline: none;
    border-radius: 3px;
    height: 20pt;
    width: 22pt;
    font-size: 9pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
	margin-left: 5pt;
	padding-top: 0pt;
	margin-top: 0pt;
} 
.smallBN:hover {
    background-color: #aaf;
    border: 1px outset #aaf;
} 
.smallBN2 {
	//width: 18%;
    outline: none;
    border-radius: 3px;
    height: 20pt;
    width: 20pt;
    font-size: 9pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
	margin-left: 5pt;
	padding-top: 2pt;
	margin-top: 2pt;
} 
.smallStep {
	
    outline: none;
    border-radius: 3px;
    height: 14pt;
    width: 18pt;
    font-size: 9pt;
    background-color: #cfc;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
	margin-left: 5pt;
	padding-top: 0pt;
	margin-top: 2pt;
}  
.button:hover {
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
    border: 1pt outset grey;
    background-color: #e8f0e8;
}
.modal-wrapper {

  vertical-align: middle;
}
td { 
    padding-right: 8px;
}
.modal-container {

  margin: 0px auto;
  padding-left: 0pt;
  padding-bottom: 20pt;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.4s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}
.steps {
	margin-left: 5pt;
}
.modal-body {
  margin: 20px 0;
  padding: 5pt;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.scInp {
	display: inline-flex;
	width: 12% !important;	
}
</style>
