<template>
 <div style='width: 100%; height: 100%; position: relative;'>
   
     <div class='transition' :style='getControlStyle()'>
    	<!-- CONTROLS -->
    	    
		    <div style='float: top; width: 100%; vertical-align: middle;'>
		        <div :style="'vertical-align: middle; color: '+getFGColor('#000','#c66')+'; text-align: center; font-size: 14pt; font-weight: bold;width: 100%;'">Bill-Board 1.0 (monthly)</div>

		        
		        <br/>
		        <div style='display: inline-flex; width: 45%;'>
			    <InputDatePick :auto="false" 
                            @change="dateChanged" v-model="fromDate"/>  
                            </div>
                            -
                <div style='display: inline-flex; width: 45%;'>
                <InputDatePick :disabled="!true" :auto="false"
                            v-model="untilDate"/>     
                 </div>   
                 <div class='SVcontrolLabel' style='display: inline-flex; width: 45%;'>        
                 Invoice date 
                 </div>
                 &nbsp; 
                 <div class='SVcontrolLabel' style='display: inline-flex; width: 45%;'>               
                 Posting date
                 </div>        
                 <div style='display: inline-flex; width: 45%;'>        
                 <InputDatePick  :auto="false"  
                            @change="dateChanged" v-model="invoiceDate"/>  
                   </div> 
                   &nbsp; 
                   <div style='display: inline-flex; width: 45%;'>               
                  <InputDatePick  :auto="false"  
                            @change="dateChanged" v-model="postingDate"/>  
                  </div>            
                
                <span class='SVcontrolLabel'>{{tr('SalesUnit')}}:</span>
			    <GSelectMSimple fontSize="9" width="70"
			         :multiple="true" :options="branches" v-model="selectedBranch" >
			    </GSelectMSimple>
			    
			    <span class='SVcontrolLabel'>Channel:</span>
			    <GSelectMSimple fontSize="9" width="70"
			         :multiple="true" :options="stations" v-model="selectedStation" >
			    </GSelectMSimple>
			    
			    <span class='SVcontrolLabel'>Networks:</span>
			    <GSelectMSimple fontSize="9" width="70"
			         :multiple="true" :options="networks" v-model="selectedNetwork" >
			    </GSelectMSimple>
			    
			    <span class='SVcontrolLabel'>Agencies:</span>
			    <GSelectMSimple fontSize="9" width="70"
			         :multiple="true" :options="agencies" v-model="selectedAgencies" >
			    </GSelectMSimple>
			    
			    <span class='SVcontrolLabel'>Bill To:</span>
			    <GSelectMSimple fontSize="9" width="70"
			         :multiple="true" :options="billTos" v-model="selectedBillTos" >
			    </GSelectMSimple>
			    
			    <span class='SVcontrolLabel'>Clients:</span>
			    <GSelectMSimple fontSize="9" width="70"
			         :multiple="true" :options="clients" v-model="selectedClients" >
			    </GSelectMSimple>
			    <span class='SVcontrolLabel'>{{tr('Placement')}}:</span>
			    <InputText  width="70" @enter="getInvoices" v-model='placementRef'/>
			    <span class='SVcontrolLabel'>Inv.-Number:</span>
			    <InputText :disabled="!invoices || !invoices.length" width="70" @enter="getInvoices" v-model='invoiceNumber'/>
			    <div class='transition' :style="(invoices && invoices.length && invoiceNumber)?'color: #000;':'color: #eee;'">
			    <span  class='SVcontrolLabel'>To</span>
			    <InputText :disabled="(!invoices || !invoices.length || !invoiceNumber)" width="70" @enter="getInvoices" v-model='invoiceNumberTo'/>
			    </div>
			    

		    	<span class='SVcontrolLabel'>Filter</span>
		    	<button :style="getAppStyle()" :titlep="'Reload data for filtering'" class='button myButton' @click="dateChanged">Reload filter</button>
		    	<button :style="getAppStyle()" :title="'Clear all filter selections'" class='button myButton' @click="clear">Clear filter</button>
		    	<hr/>
		    	<span class='SVcontrolLabel'>View</span>
		    	<button :style="getAppStyle()" :title="'View existing invoices'" class='button myButton' @click="getInvoices">Invoice-Viewer</button>
		    	<button :disabled="!invoices || !invoices.length" :style='getAppStyle()+"height:28px; width: 28px;"' title='reload all existing invoices' class='button myButton' @click="getInvoices"><mdicon size="16" name='reload'/></button>
		    	<!-- 
		    
		    	-->
		    	<br/>
		    	<span class='SVcontrolLabel'>Create</span>
		    	<button :style="getAppStyle()" :title="'Detect items to invoice (virtual invoice)'" class='button myButton' @click="doInvoice(false)">Simulate</button>
		    	<button :style="getAppStyle()" :title="'Invoice all selected invoices'" :disabled='!simDone' class='button myButton' @click="doInvoice(true)">Invoice</button>
		    	<br/><span class='SVcontrolLabel'>Watermark</span>
		    	<div style='width: 50%; display: inline-flex; padding-left: 5pt; padding-top: 3pt;'>
		    	<InputText :title="'enter label for watermark (for invoice simulation)'" width="100" v-model='watermark'/>
		    	</div>
		    	
		    	<br/>
		    
		    	<hr/>
				<span class='SVcontrolLabel'>Show Controls: </span>
		    	&nbsp;
		    	<app-switch v-model="controlsRight" :checked="controlsRight"/>

		    	
		    </div> <!-- CONTROLS -->
    </div>
   
    <!-- MAIN -->
	<div class='transition' style='float: left; width: 78%;'> 
	    <!-- HEADER -->
	    <div style='float: top; width: 100%; height: 34pt;'>
	    <table border=0 width=100%>
	    <tr><td><b>
	    <div v-if="invoices && invoices.length">
	    	{{filter(invoices).length + " invoice(s) found"}}
	    </div>
	    <div v-else>
	        <div v-if="simulation && simulation.length">
	    		{{filter(simulation).length + " line(s) for invoicing found"}}
	    	</div>
	    	<div v-else style='font-weight: normal;'>
	    	    {{infoText}}
	    	</div>
	    </div> 
	    </b></td>
	        <td rowspan=2 style='text-align: right;'>
	    	<div v-if="invoices && invoices.length">
	    	    <span class='SVcontrolLabel' style='width: 33pt;'>Show</span>
	    	    <GSelectMSimple fontSize="9" width="20"
			         :multiple="true" :options="allInvKinds" v-model="selectedKinds" >
			    </GSelectMSimple>
	    	
	    	    <span class='SVcontrolLabel' style='padding-left: 5pt; width: 75pt;'>Hide cancelled</span><app-switch v-model="hideCXL" :checked="hideCXL"/>
	    	  
	    	    <button :style="getAppStyle()" v-for="(bn,i) in buttons" :key="'bn_'+i" class='button myButton' @click="runButton($event, bn)">{{bn.name}}</button>

		    	<button :style="getAppStyle()" :disabled="!invoices || !invoices.length" class='button myButton' @click="exportInvoices(invoices)">Export</button>
		    	
		    	<button :style="getAppStyle()" :disabled="!invoices || !invoices.length" class='button myButton' @click="exportOverview(invoices)">Report</button>
		    	
		    	 
		    	<span class='SVcontrolLabel' style='width: 40pt;'>Checks</span>
		    	<button :style="getAppStyle()" :title="'check full document'" key="bi1" class='button myButton red' @click="doCheck()"><mdicon size=18 name="text-box-check-outline"/></button>
		    	<button :style="getAppStyle()" :title="'check invoiced amount vs current amount'" key="bi2" class='button myButton red' @click="doCheckM()"><mdicon size=18 name="cash-multiple"/></button>
	    	</div>
	    	<div v-else-if="simulation && simulation.length">

		    	<button :title="'create (virtual) invoice export'" id="bs1" :disabled="!simulation || !simulation.length" class='button myButton' @click="exportInvoices(simulation)">Export</button>
		    	<button :title="'create invoice overview report'" id="bs2" :disabled="!simulation || !simulation.length" class='button myButton' @click="exportOverview(simulation)">Report</button>
		    	
		   
	    	</div>
	    </td></tr>
	    <tr>
	       <td><div style='display: inline-flex; font-size: 9pt;' v-if="marked.length">{{marked.length}} lines selected</div></td>

	    </tr>
	    </table>

		
	  	</div>
	    <!-- HEADER -->
	    <!-- TABLE -->
	    <div style='width: 100%; height: calc(100vh - 155px); '>
	      
	    	<div v-if="simulation && simulation.length" style='float: top; width: 100%;height: 100%;'>
	    	   
	    	    <div style='float: top; width: 100%; height: 28pt; overflow-y: scroll;'>
				    <div style='float: top;'>
					    <div v-for="(col,idx) in selectedColumns" :key="'ColSp_'+idx" class='floatLeft' :style='getColumnStyle(col)'
					        >
					      <div class='header' :style='getIColumnStyle(col)'>
					       		<div > {{ tr(col.name) }}<sub></sub></div>
					       	</div>
					    </div>
					   
				    </div>  <!-- float: top -->   
				</div> <!-- HEADER -->
				
	    	    <div style='width: 100%; height: calc(75vH); overflow-y: scroll;'>
	    	    <div v-for="(i,idx) in simulation" :key="'I'+idx">
			    	
			    	<div v-if="i" class='item' :id="'I_'+idx" @contextmenu="contextmenu($event,i)" 
			    	      v-on:click.exact='mark($event, i, idx)'
			    	      v-on:click.shift='markUntil($event, simulation, idx)'
			    	      v-on:click.shift.prevent
			    	      @mouseenter="hoverLine('I_'+idx)"
			              @mouseleave="leaveLine('I_'+idx)">
	    	    		<div v-for="(col,idx2) in selectedColumns" :key="'CS_'+idx+'_'+idx2" class='floatLeft' :style='getColumnStyle(col,i)'>
						       <div class='spotDetail dontWrap' :style='getIColumnStyle(col)' >
						          <div :style="getAppStyle()" v-if="col.type==='pdf' && i.net !== null">
						       		<button  class='button' @click="downloadPDFs($event, 2, i)"><mdicon :width="16" name="download" /></button>
						          </div>
						          <div v-else :title='print(i, col)' :class="addClass(col,i)">
						       		{{ print(i, col) }}
						          </div>
						       	</div>
						    </div> 
	    				</div>
	    		 </div> 
	    	   </div> 

	    	</div>
    		<div v-else-if="invoices && invoices.length">
    		
    		    <div style='float: top; width: 100%; height: 28pt; overflow-y: scroll;'>
				    <div style='float: top;'>
					    <div v-for="(col,idx) in selectedColumns" :key="'ColSp_'+idx" class='floatLeft' :style='getColumnStyle(col)'
					        >
					      <div class='header' :style='getIColumnStyle(col)'>
					       		<div > {{ col.name }}<sub></sub></div>
					       	</div>
					    </div>
					   
				    </div>  <!-- float: top -->   
				</div> <!-- HEADER -->
	    	    <div style='width: 100%; height: calc(75vH); overflow-y: scroll;'>
	    	    <div v-for="(i,idx) in filter(invoices)" :key="'I'+idx">
			    	
			    	<div class='item' :id="'I_'+idx" @contextmenu="contextmenu($event,i)" 
			    	      v-on:click.exact='mark($event, i, idx)'
			    	      v-on:click.shift='markUntil($event, invoices, idx)'
						  v-on:click.shift.prevent
			    	      @mouseenter="hoverLine('I_'+idx)"
			              @mouseleave="leaveLine('I_'+idx)">
	    	    		<div v-for="(col,idx2) in selectedColumns" :key="'CS_'+idx+'_'+idx2" class='floatLeft' :style='getColumnStyle(col,i)'>
						       <div class='spotDetail dontWrap' :style='getIColumnStyle(col)' >
						          <div v-if="col.type==='pdf'">
						       		<button class='button' @click="downloadPDFs($event, 2, i)"><mdicon :width="16" name="download" /></button>
						          </div>
						          <div v-if="col.special==='issue'" :class="addClass(col,i)" @click="showIssues($event, i)">
						       		{{ print(i, col) }} 
						          </div>
						          <div v-else :title='print(i, col)' :class="addClass(col,i)" >
						       		{{ print(i, col) }} 
						          </div>
						       	</div>
						    </div> 
	    				</div>
	    		 </div> 
	    	   </div> 
	    	  
	        </div>
	    	
    	</div>  <!-- TABLE -->
    </div>  <!-- MAIN -->
    
    <ContextMenu ref="menu" :offsetY="-100" :placement='auto'>
      <template v-if="contextData"  slot-scope="{ contextData }">
        <ContextMenuLabel>
        <div v-if="! contextData.id">
        {{contextData.placementRef}}
        </div>
        <div v-else>
        {{contextData.invoiceNumber}}
        </div>
        </ContextMenuLabel>
        <ContextMenuSep/>
        <ContextMenuItem @clicked="$refs.menu.close(); $refs.editor.open('Placement', contextData.placementId)">
          Open {{tr("Placement")}}
        </ContextMenuItem>
        <ContextMenuItem v-if="! contextData.id" @clicked="$refs.menu.close(); $refs.editor.open('BillingParameter', contextData.paramId)">
          Open {{tr("BillingParameter")}} 
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.id" @clicked="$refs.menu.close(); $refs.editor.open('BillingItem', contextData.id)">
          Open {{tr("BillingItem")}} 
        </ContextMenuItem>
        <ContextMenuItem v-if="! contextData.id" @clicked="$refs.menu.close(); $refs.editor.open('SalesUnit', contextData.salesUnitId)">
          Open {{tr("SalesUnit")}} 
        </ContextMenuItem>
         <ContextMenuItem v-if="true" @clicked="closeCMN(); openBookMan(contextData)">
         Open Traffic Manager 
        </ContextMenuItem>
        <ContextMenuSep />
        
        <div v-for="(mn,mi) in menue" :key="'mn_'+mi">
	        <ContextMenuItem v-if="(!mn.check) || (Array.isArray(contextData[mn.check])?contextData[mn.check].length: contextData[mn.check])"
	                          @clicked="$refs.menu.close(); runButton($event, mn, contextData)">
	          {{mn.name}}
	        </ContextMenuItem>
	    </div>
	    
        <ContextMenuSep/>
        <div v-for="(mn,mi) in menue2" :key="'mn2_'+mi">
	        <ContextMenuItem v-if="(!mn.check) || (Array.isArray(contextData[mn.check])?contextData[mn.check].length: contextData[mn.check])"
	                          @clicked="$refs.menu.close(); runButton($event, mn, contextData)">
	          <span style='color: #6a6;'>{{mn.name}} </span>
	        </ContextMenuItem>
	    </div>
        
       <ContextMenuSep/>
       
       <ContextMenuItem v-if="contextData.id && !marked.length" @clicked="$refs.menu.close(); removeExport(contextData)">
          <span style='color: blue;'>Reset export status {{contextData.invoiceNumber}}</span>
        </ContextMenuItem>
        <ContextMenuItem v-else-if="contextData.id && marked.length" @clicked="$refs.menu.close(); removeExport()">
          <span style='color: blue;'>Reset export status for {{marked.length}} invoices</span>
        </ContextMenuItem>
         <ContextMenuItem v-if="contextData.id && !marked.length" @clicked="$refs.menu.close(); cancelInvoice(contextData)">
          <span style='color: blue;'>Cancel invoice {{contextData.invoiceNumber}}</span>
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.id && marked.length" @clicked="$refs.menu.close(); cancelInvoice(contextData)">
          <span style='color: blue;'>Cancel {{marked.length}} marked invoice(s)</span>
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.id" @clicked="$refs.menu.close(); deleteInvoice(contextData)">
          <span style='color: red;'>Delete invoice {{contextData.invoiceNumber}}</span>
        </ContextMenuItem>
        <ContextMenuItem  v-if="! contextData.id && marked.length" @clicked="$refs.menu.close(); doInvoice(true, contextData)">
          Invoice {{marked.length}} line(s) 
        </ContextMenuItem>
        <ContextMenuItem v-if="! contextData.id && !marked.length" @clicked="$refs.menu.close(); doInvoice(true, contextData)">
          Invoice this line 
        </ContextMenuItem>
        <ContextMenuSep v-if="contextData.id && !contextData.billingItemId && !contextData.correctionForId"/>
        
        <ContextMenuItem v-if="contextData.id && !contextData.billingItemId && !contextData.correctionForId" @clicked="$refs.menu.close(); doReInvoice(true, contextData)">
          Create correction 
        </ContextMenuItem>
        <!--
        <ContextMenuSep v-if="contextData.id && !contextData.creditNoteId && !contextData.billingItemId && !contextData.creditNoteForId"/>
         <ContextMenuItem v-if="contextData.id && !contextData.creditNoteId && !contextData.billingItemId && !contextData.creditNoteForId" @clicked="$refs.menu.close(); doCNInvoice(false, contextData)">
          Simulate Credit Note 
        </ContextMenuItem>
         <ContextMenuItem v-if="contextData.id && !contextData.creditNoteId && !contextData.billingItemId && !contextData.creditNoteForId" @clicked="$refs.menu.close(); doCNInvoice(true, contextData)">
          Create Credit Note 
        </ContextMenuItem>
        -->
       
       </template>
    </ContextMenu>
    <GConfirm ref='confirm'/>
    <PDFViewer name='pdfView' ref='pdfView' />
    <GFWEOpenEditor ref='editor'></GFWEOpenEditor>
	<ProgressBar v-if="showProgressBar" :generalInfo=pbTitle :action=pbAction @action=action></ProgressBar>
	<ShowBillIssues ref='showBillIssues'>
	</ShowBillIssues>
 </div>
</template>

<script>
import {HTTP, fwAPI, invAPI, reportAPI, opsAPI, invoiceAPI, invExp, setReload, myLocale, showError, formatNumber, rebuildAPI} from '@/variables.js';
import { setGoBack } from '@/breadCrumb.js';
import {store, restore} from '@/invoiceScreen.js';
import { getAppStyle, setDarkMode, initAppMode, getFGColor, getBGStyle2, isDarkMode } from '@/AppStyle.js';
import InputTime from '@/components/inputElements/InputTime2';
import InputText from '@/components/inputElements/InputText';
import Switch from '@/components/Switch';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';
import ContextMenuLabel from '@/components/ContextMenuLabel';
import InputTimezone from '@/components/inputElements/InputTimezone';
import GridBreakView from '@/components/GridBreakView';
import InputDatePick from '@/components/inputElements/InputDatePick3'; 
import GSelectMSimple from '@/components/GSelectMSimple'; 
import ProgressBar from '@/components/ProgressBar';
import ShowBillIssues from '@/components/misc/ShowBillIssues';
import PDFViewer from '@/components/PDFViewer';
import GConfirm from '@/components/GConfirm';
import { tr } from '@/translate.js';

var momentTZ = require('moment-timezone');
var timers = [];
var lastMarked = "-";
import JQuery from "jquery";
let $ = JQuery;
export default {
  name: 'NEXT_INVOICE_View',
  components : {
   'app-switch': Switch, InputDatePick, GSelectMSimple, ProgressBar, ShowBillIssues,
   ContextMenu, ContextMenuItem, ContextMenuSep, ContextMenuLabel, PDFViewer, GConfirm, InputText
  },
  data () {
    return {
      allStations: {},
      selectedStation: [],
      selectedAgencies: [],
      selectedClients: [],
      selectedBillTos: [],
      selectedNetwork: [],
      networks: [],
      branches: [],
      currencies: [],
      selectedBranch: [],
      currency: {},
      stations: [],
      allAndFullStations: [],
      clients: [],
      billTos: [],
      placements: [],
      agencies: [],
      simulation: [],
      invoices: [],
      valueMap: {},
      invoiceNumber: "",
      invoiceNumberTo: "",
      placementRef: "",
      errors: [],
      fromDate: {},
      untilDate: {},
      invoiceDate: {},
      postingDate: {},
      title: "",
      infoText: "Press Simulate to see what can be invoiced - or Invoice Viewer to display existing invoices",
      fontSize: 11,
      loadingActive: false,
      hideCXL: false,
      controlsRight: false,
      simDone: false,
	  showProgressBar: false,
	  marked: [],
	  watermark: "",
	  pBRealInvoice: false,
	  pBBill: null,
	  pbType: 0,
      pbAction: "",
      pbTitle: "",
      tr,getAppStyle,getFGColor,
      widthCalculated: 0,     
      selectedKinds: [],
      allInvKinds: [],
      buttons: [],	  
      menue: [],	
      menue2: [],	
      availableColumns: [],
      selectedInv: [],
      selectedColumns: []
      
    }
  },
  methods: {
    myLocale() { return myLocale();},
    getControlStyle() {
    	if ( this.controlsRight )
    	{
    		return "float: right; width: 21%; height: calc(100vh - 120px); padding: 2ex;"
    	}
    	return "float: left; width: 21%; height: calc(100vh - 120px); padding: 2ex;"
    },
    contextmenu(event, item){
        event.preventDefault();
       
        this.$refs.menu.open(event, item, null, null, 'auto' )
	},
	closeCMN() { try{ this.$refs.menu.close() } catch(e) { /* */ }},
	setSelectedColumns( sc ) { this.selectedColumns = sc; this.calcWidths( ) },
	getIColumnStyle(col)
    {
       if ( this.loadingActive )
       {
       	return "color: #fff; font-size:"+this.fontSize+"px";
       }
       let sty = "font-size:"+this.fontSize+"px;"
       if ( col.align == 'R' )
       {
       	sty += "justify-content: right;";
       }
       else if ( col.align == 'L' )
       {
       	sty += "justify-content: left;";
       }
       else
       {
       	sty += "justify-content: center;";
       }
       if ( col.special === 'actual' )
       {
    	   if ( isDarkMode())
    	   {
    		   sty += "color: #00d;"   
    	   }
    	   else
    	   {
    	   	sty += "color: #00d;"
       	   }
       }
       return sty;
    },
	calcWidths()
	{
		let wTot = 0;
		var columns = "";
		for ( var i in this.selectedColumns )
     	{
     		wTot += this.selectedColumns[ i].w;
     		if ( columns )
     		{
     			columns += "," + this.selectedColumns[ i].name;
     		}
     		else
     		{
     			columns = this.selectedColumns[ i].name;
     		}
     	}
     	this.widthCalculated = wTot;
	},
	filter(i)
	{
		let tmp = i;
		if ( this.hideCXL)
		{
			tmp = tmp.filter(p=> !p.billingItemId && !i.find(ii=>ii.billingItemId===p.id))
		}
		if ( this.selectedKinds && this.selectedKinds.length )
		{
			tmp = tmp.filter(p=>this.selectedKinds.includes(p.kind))
		}
		return tmp;
	},
	arrangeColumns(selected)
	{
	    this.selectedColumns = new Array();
		for ( var i in selected )
		{
			let colName = selected[i];
			for ( var j in this.availableColumns )
			{
				let column = this.availableColumns[j];
				if ( column.name == colName )
				{
					this.selectedColumns.push( column );
					break;
				}
			}
	    }
	    this.calcWidths();
	},

	print(i, col)
    {
       //return spot[ col.show ];
    	let val = i[ col.show ];
    	if ( col.show==='kindValue')
    	{
    		return val.substring(0,3);
    	}
    	if ( col.show==='categoryValue')
    	{
    		return val.substring(0,3);
    	}
    	if ( col.type==='boolean' )
    	{
    		if ( val )
    		{
    			return "X";
    		}
    		return "";
    	}
    	if ( col.type==='month')
    	{
    		return new Date(i.untilDate).toLocaleString("en-us", { month: "short" });
    		//return this.allStations.find(p=>p.id==i.mediaId).label
    	}
    	
    	if ( col.show==='mediaId')
    	{
    		return this.valueMap['media'][i.mediaId];
    		//return this.allStations.find(p=>p.id==i.mediaId).label
    	}
    	
    	if ( col.show==='salesUnitId')
    	{
    		return this.valueMap['branch'][i.salesUnitId];
    		//let branch = this.branches.find(p=>p.id==i.salesUnitId);
    		//return branch?branch.shorty:"?"
    	}
    	if ( col.show==='clientId')
    	{
    		return this.valueMap['client'][ i.clientId];
    		//return this.clients.find(p=>p.id==i.clientId).label
    	}
    	
    	if ( col.show==='agencyId')
    	{
    		return this.valueMap['client'][ i.agencyId];
    		//return this.agencies.find(p=>p.id==i.agencyId).label
    	}
    	if ( col.show==='billToId')
    	{
    		return this.valueMap['client'][ i.billToId];
    		//return this.billTos.find(p=>p.id==i.billToId).label
    	}
    	
    	if ( col.special==='attach')
    	{
    		return i.reqAttachedPDFs && i.reqAttachedPDFs.length ? i.reqAttachedPDFs.length : "";
    	}
    	if ( col.show==='placementId')
    	{
    		return this.valueMap['placement'][ i.placementId];
    	}
    	if ( col.show==='net')
    	{
    		if ( val === null )
    		{
    			return "- " + this.valueMap['currency'][i.currencyId]
    		}
    		return formatNumber( val ) +" " + this.valueMap['currency'][i.currencyId]
    	}
    	if ( col.special==='actual')
    	{
    	    return formatNumber(this.errors[i.invoiceNumber]);	
    	}
    	if ( col.special==='issue')
    	{
    		if ( i.id )
    		{
    			let diffs = this.countDiffs( this.errors[i.invoiceNumber]);
    			if ( diffs > 0 )
    			{
    				return diffs + " differences";
    			}
    			return "";
    		}
    	    return this.errors[i.invoiceNumber];	
    	}
    	if ( val === null || val === '')
    	{
    		return "-";
    	}
    	
    	if ( val !== null && col.type==='num' )
    	{
    		return formatNumber( val );
    	}
    	return val;
    },
    countDiffs( errors )
    {
    	let found = 0;
    	for ( let key in errors )
    	{
    		let item = errors[ key];
    		if ( Object.keys(item).length )
    		{
    			for ( let key2 in item )
    	    	{
    				let item2 = item[ key];
    				found++;
    	    	}
    		}
    	}
    	return found;
    },
    dateChanged()
    {
    	this.getData();
    },
    forceFileDownload(base64, name) 
	{  
      const url = window.URL.createObjectURL(new Blob([this.base64ToArrayBuffer(base64)]));
      const link = document.createElement('a');
      link.href = url
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
    },
    base64ToArrayBuffer(base64) {
	    var binary_string = atob(base64);
	    var len = binary_string.length;
	    var bytes = new Uint8Array(len);
	    for (var i = 0; i < len; i++) {
	        bytes[i] = binary_string.charCodeAt(i);
	    }
	    return bytes.buffer;
	},
	getColumnStyle(col, inv)
    {
       let sty = "width: " + (parseInt(col.w)/this.widthCalculated*100) +"%;";
          
      
       if ( col.special=='actual' && inv && inv.invoiceNumber && (this.errors[inv.invoiceNumber] !== null) && (Math.abs(inv.gross5 - this.errors[inv.invoiceNumber]) > 0))
       {
    	   sty += "background-color: #fcc;";
       }
       else
       {
    	   sty += ((inv) ? this.getMarked(inv):"")
       }
    
       return sty;
    }, 
    addClass(col, inv)
    {
       let cls = "allTd ";
       if ( inv )
       {
    	   if ( inv.id && inv.categoryValue==='CREDIT_NOTE' && (col.show==='invoiceNumber'))
	       {
	    	   cls += "minusN";
	       }
    	   else if ( inv.id && inv.categoryValue==='CREDIT_NOTE' && col.type==='num')
	       {
	    	   cls += "minusN";
	       }
    	   else if ( this.errors && this.errors[inv.invoiceNumber] && (col.special==='issue'))
	       {
	 		   if ( JSON.stringify(this.errors[inv.invoiceNumber]).includes("netWithTax"))
    	       {
    			   cls += "redAlert";
    	       }
    		   else if ( this.countDiffs(this.errors[inv.invoiceNumber]) )
    		   {
	    	   		cls += "alert";
    		   }
	       }
    	   else if ( inv && inv.billingItemId && (col.show==='invoiceNumber' || col.type==='num'))
	       {
	    	   cls += "cxl";
	       }
    	   else if ( inv && !inv.id && (col.show==='invoiceNumber'))
	       {
	    	   cls += "virt";
	       }
    	   if ( col.special==='all' && inv.allItems-inv.invalidItems-inv.items > 0)
    	   {
    		   cls += "alert num";
    	   }
       }
       return cls;
    }, 
    getMarked( inv)
    {
    	if (inv && this.marked.includes(inv.invoiceNumber)) 
    	{
    		return "background-color: #ddd; color: #000;"
    	} 
    	 
    	return "background-color: "+ sessionStorage.bgColor;
    }, 
	mark(evt, inv, idx)
    {
       // evt.target.parentNode.parentNode.style='font-weight: bold;'
       var add = true;
       lastMarked = idx;
    	for ( var s in this.marked )
    	{
    		if (this.marked[s] === inv.invoiceNumber)
    		{
    			this.marked.splice( s, 1);
    			add = false;
    			break;
    		}
    	}
    	if ( add )
    	{
    		this.marked.push( inv.invoiceNumber);
    	}
    },
    markUntil(evt, dataBase, idx)
    {
       if ( lastMarked < idx )
       {
	        for ( var i = lastMarked+1; i <= idx; i++)
	        {
	        	this.mark(evt, dataBase[i], i);
	        }
	    }
	    else if ( lastMarked > idx )
	    {
	    	for (  i = lastMarked-1; i >= idx; i--)
	        {
	    		this.mark(evt, dataBase[i], i);
	        }
	    }
	    lastMarked = idx;
    },
    noZero( arr)
    {
		return arr.filter(Number);
	},
	getFilters( toObj)
	{
    	toObj.clientIds = this.selectedClients; 
    	toObj.agencyIds = this.selectedAgencies; 
    	toObj.billToIds = this.selectedBillTos; 
    	toObj.channelIds = this.selectedStation; 
    	toObj.salesUnitId = this.selectedBranch;
    	toObj.networkIds = this.selectedNetwork;
    	if ( !Array.isArray(this.selectedNetwork))
    	{
    		toObj.networkIds = [];
    	}
    	toObj.networkIds = this.noZero( toObj.networkIds);
    	toObj.placementRef = this.placementRef;
    	toObj.invoiceNumberFrom = this.invoiceNumber;
    	toObj.invoiceNumberTo = this.invoiceNumberTo;
	},
	createRequest()
	{
		let req = {	
			    billingDateISO:  momentTZ(this.invoiceDate).utcOffset(0, true).format().split('T')[0], 
    	        postingDateISO:  momentTZ(this.postingDate).utcOffset(0, true).format().split('T')[0], 
				fromDateISO: momentTZ(this.fromDate).utcOffset(0, true).format().split('T')[0], 
				untilDateISO: momentTZ(this.untilDate).utcOffset(0, true).format().split('T')[0] 
	        };
		this.getFilters( req);
		return req;
	},
	runButton( event, bn, bill)
	{
		this[ bn.function]( event, bn, bill )
	},
	exportInvoices(dataBase)
	{
		if ( !this.marked.length)
        {
			this.pBBill = dataBase;
        }
        else
        {
        	this.pBBill = dataBase.filter( p=>this.marked.includes( p.invoiceNumber));
        }
		this.pbAction = "exportInvoicesInternal";
        this.pbTitle = "Exporting invoice data";
 	    this.showProgressBar = true;
	},
	
	exportInvoicesInternal(myId, api) 
	{
		let that = this;
		
		this.startLoader();
		let req = this.createRequest();
		req.bill = this.pBBill;
		let onInvoices = false;
		if ( req.bill.length )
		{
			onInvoices = req.bill[0].id;
		}
		
		let dataLoad = new Promise(function (resolve, reject) {
            console.log( invExp+"/exportInvoice/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId);
            
       		api.post( invExp+"/exportInvoice/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+myId, req)

        		 .then( response => 
                 {
                	let downloadResponse = response.data;
                	that.showProgressBar = false;
                	if ( onInvoices)
                	{
                		that.getInvoices();	
                	}
        		    that.forceFileDownload( downloadResponse.file, downloadResponse.filename);
        		    that.stopLoader();
                    resolve (true);     
                    
                 }).catch(e => {
                	    showError( that.$toast, "export INVOICE", e);
                	    that.showProgressBar = false;
                	    that.stopLoader();
                        reject ();
                    });
	      });
	},
	removeExport(bill)
	{
	
		if ( !this.marked.length)
        {
			if ( bill )
			{
				this.pBBill = [ bill];
			}
			else
			{
				this.pBBill = this.invoices;
			}
        }
        else
        {
        	this.pBBill = this.invoices.filter( p=>this.marked.includes( p.invoiceNumber));
        }
		
		this.pbAction = "removeExportInternal";
        this.pbTitle = "Reset export status";
 	    this.showProgressBar = true;
	},
	
	removeExportInternal(myId, api) 
	{
		let that = this;
		
		this.startLoader();
		let req = this.createRequest();
		req.bill = this.pBBill
		let dataLoad = new Promise(function (resolve, reject) {
            console.log( invExp+"/removeExportStatus/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId);
            
       		api.post( invExp+"/removeExportStatus/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+myId, req)

        		 .then( response => 
                 {
					that.showProgressBar = false;
                	that.getInvoices();
        		    that.stopLoader();
                    resolve (true);     
                    
                 }).catch(e => {
                	    showError( that.$toast, "export INVOICE", e);
                	    that.showProgressBar = false;
                	    that.stopLoader();
                        reject ();
                    });
	      });
	},
	exportOverview( dataBase) 
	{
		let that = this;
		this.startLoader();
		let req = this.createRequest();
		if ( !this.marked.length)
        {
			req.bill = dataBase;
        }
        else
        {
        	req.bill = dataBase.filter( p=>this.marked.includes( p.invoiceNumber));
        }

		let dataLoad = new Promise(function (resolve, reject) {
            HTTP.post( invExp+"/exportExcel/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, req)

        		 .then( response => 
                 {
                	let downloadResponse = response.data;
        		    that.forceFileDownload( downloadResponse.file, downloadResponse.filename);

                    resolve (true);     
                    that.stopLoader();
                 }).catch(e => {
                	    showError( that.$toast, "export INVOICE", e);

                	    that.stopLoader();
                        reject ();
                    });
	      });
	},
	zipInvoices(event, bn)
	{
		this.pbType = bn;
		this.pbAction = "zipInvoicesInternal";
        this.pbTitle = "Creating zip archive";
 	    this.showProgressBar = true;
 	    
	},
	zipInvoicesInternal(myId, api) 
	{
		let that = this;
		let type = 2;
		let call = "zipInvoice";
		if ( this.pbType.option )
		{
			type = this.pbType.option;
			call = this.pbType.service;
		}
		else
		{
			type = this.pbType;
		}
		this.startLoader();
		let req = this.createRequest(); 
		if ( this.marked.length)
        {
        	req.bill = this.invoices.filter( p=>this.marked.includes( p.invoiceNumber));
        }

		let dataLoad = new Promise(function (resolve, reject) {
			api.post( invoiceAPI+"/"+call+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+type+"/"+myId, req)
			 .then( response => 
	        {
	       
	       	    let downloadResponse = response.data;
			    that.forceFileDownload( downloadResponse.file, downloadResponse.filename);
	        	that.simDone = false;
	        	that.showProgressBar = false; 
	            that.stopLoader();
	        }).catch(e => {
	        	console.log(e)
	       	    showError( that.$toast, "create Zip for INVOICE", e);
	       	    that.showProgressBar = false;
	       	    that.stopLoader();
	           });
	      });
		
	},
	cancelInvoice(bill)
	{
		let that = this;
		let bills = []
		if ( !this.marked.length)
        {
        	if ( bill )
        	{
        		bills = [ bill ];
        	}
        	else
        	{
        		showError( that.$toast, "Cancel INVOICE", "no invoice selected for cancellation");
        		return;
        	}
        }
        else
        {
        	bills = this.invoices.filter( p=>this.marked.includes( p.invoiceNumber));
        }
		
		this.$refs.confirm.confirm( {text: 'Cancel '+bills.length+' invoice(s)', title: "Cancellation", button1: "Cancel", button2: "Ok"}).then(x => {
			this.pbAction = "cancelInvoiceInternal";
	        this.pbTitle = "Cancellation";
	       
	        this.pBBill = bills;
	 	    this.showProgressBar = true;
		});
	},
	cancelInvoiceInternal(myId, api) 
	{
		let that = this;
		let req = this.createRequest();
		req.bill = this.pBBill;
		//let bills = this.pBBill.map(p=>p.id);
		
		let dataLoad = new Promise(function (resolve, reject) {
            //alert(api + "/"+invoiceAPI+"/cancelInvoice/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+myId + JSON.stringify(req)) 
       		api.post( invoiceAPI+"/cancelInvoice/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/true/"+myId, req)
        		 .then( response => 
                 {
                	that.showProgressBar = false;
                 	that.getInvoices();
                 	resolve (true);     
                 }).catch(e => {
                	    showError( that.$toast, "Cancel INVOICE", e);
                	    that.showProgressBar = false;
                        reject ();
                    });
	      });
	},
	deleteInvoice(bill)
	{
		let that = this;
		this.$refs.confirm.confirm( {text: 'Delete invoive '+bill.invoiceNumber, title: "DELETE", button1: "Cancel", button2: "Ok"}).then(x => {
			let dataLoad = new Promise(function (resolve, reject) {
	             
	       		HTTP.delete( invoiceAPI+"/deleteInvoice/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+bill.id)
	        		 .then( response => 
	                 {
	                 	that.getInvoices();
	                 	resolve (true);     
	                 }).catch(e => {
	                	    showError( that.$toast, "delete INVOICE", e);
	                	    that.stopLoader();
	                        reject ();
	                    });
		      });
		});
	},
	getInvoices()
	{
		let that = this;
		this.startLoader();
		let req = this.createRequest();

		//alert( JSON.stringify( req))
    	let dataLoad = new Promise(function (resolve, reject) {
            HTTP.post( invoiceAPI+"/getInvoices/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, req)

        		 .then( response => 
                 {
                	// alert( JSON.stringify( response.data.data))
                 	that.invoices = response.data.data;
                 	that.valueMap = response.data.values.map;
                 	//alert( JSON.stringify(that.invoices.map(p=>p.invoiceNumber)))
                 	that.simulation = [];
                 	if ( that.invoices && that.invoices.length )
                 	{
                 		that.invoices.sort((a,b)=>(a.id>b.id)?1:-1)
                    }
                 	that.buttons = response.data.buttons.filter(p=>p.type=='BUTTON' || p.type=='BUTTON_AND_MENU');
                 	that.menue = response.data.buttons.filter(p=>(p.type=='MENU' || p.type=='BUTTON_AND_MENU') && p.section == 1);
                 	that.menue2 = response.data.buttons.filter(p=>(p.type=='MENU' || p.type=='BUTTON_AND_MENU') && p.section == 2);
                 	that.availableColumns = response.data.columns;
                 	that.arrangeColumns( response.data.selected);
                 	that.marked = [];
                 	that.errors = [];
                 	that.simDone = false;
                 	
                 	that.stopLoader();
                    resolve (true);     
                    
                 }).catch(e => {
                	    showError( that.$toast, "read INVOICE", e);
                	    that.stopLoader();
                        reject ();
                    });
	      });
	},

    showIssues( event, bill )
    {
    	if (event) event.stopPropagation();
    	this.$refs.showBillIssues.open( bill, this.errors[ bill.invoiceNumber] );
    },
	downloadPDFs( event, pbType, bill )
    {
    	if (event)  event.stopPropagation()
    	let that = this;
    	let type = 0;
		let call = "printInvoice";
		if ( pbType.option )
		{
			type = pbType.option;
			call = pbType.service;
		}
		else
		{
			type = pbType;
		}
		
    	bill.watermark = this.watermark;
    	//alert( invoiceAPI+"/"+call+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+type)
    	HTTP.post( invoiceAPI+"/"+call+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+type, bill)
        	.then( response => {
				
               let downloadResponse = response.data;
               //alert(downloadResponse.filename);
           	   //that.forceFileDownload( downloadResponse.file, downloadResponse.filename);
               that.$refs.pdfView.open(downloadResponse.file, downloadResponse.filename)
           		
        	}).catch(e => (showError( that.$toast, "file download error", e)))

    },
    getStations() {
        let that = this;
		this.startLoader()

        let time = new Date().getTime();
    	let stationLoad = new Promise(function (resolve, reject) {
            console.log(fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/Media/asOptions/true/0");
       		HTTP.get( fwAPI+"/getAsOptions/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/Media/true/0")
        		 .then( response => 
                 {
                 	let dur = (new Date().getTime()-time);
               		console.log("LOADED STATIONS in " + dur +"ms");  
                    var tmpAllStations = response.data.data;
                    that.allStations = [];
                    for ( var channel in tmpAllStations )
                    {
                    	if ( tmpAllStations[channel].visible )
                    	{
                    		that.allStations.push( tmpAllStations[channel]);
                    	}
                    	
                    }   
                    
                    resolve (true);                    
                 }).catch(e => {
                	    showError( that.$toast, "loading stations", e);
                        reject ();
                    });
	      });
    },
    clear() {
    	this.selectedClients = [];
    	this.selectedAgencies = []
    	this.selectedStation = []
    	this.selectedBranch = []
    	this.selectedBillTos = []
    	this.selectedNetwork = []
    	this.networkIds = []
    	this.invoiceNumber = "";
    	this.placementRef = "";
    },
    getData(dontUpdateDates) {
        let that = this;
		this.startLoader();

		let req = {	billingDateISO:  momentTZ(this.invoiceDate).utcOffset(0, true).format().split('T')[0], 
        	        postingDateISO:  momentTZ(this.postingDate).utcOffset(0, true).format().split('T')[0], 
					fromDateISO: momentTZ(this.fromDate).utcOffset(0, true).format().split('T')[0], 
					untilDateISO: momentTZ(this.untilDate).utcOffset(0, true).format().split('T')[0] 
		};
		
		let dataLoad = new Promise(function (resolve, reject) {
            
       		HTTP.post( invoiceAPI+"/getData4Invoice/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, req)

        		 .then( response => 
                 {
                 	let map = response.data;
                 	that.simDone = false;
                 	if ( !dontUpdateDates)
                 	{
                 		that.fromDate = new Date( map.fromDateISO);
                 		that.untilDate = new Date( map.untilDateISO);
                 		//alert(that.untilDate +"/" + map.untilDateISO)
                 	}
                 	try
                 	{
	                 	//alert(map.untilDateISO)
	                 	that.marked = [];
	                 	that.agencies = map.agencies.map(a => {return {id: a.id, label: a.name, visible: true}});
	                 	that.branches = map.salesUnits.map(a => {return {id: a.id, label: a.name, shorty: a.shortname, visible: true}});
	                 	that.clients = map.clients.map(a => {return {id: a.id, label: a.name, visible: true}});
	                 	that.allAndFullStations = map.media;
	                 	//alert( JSON.stringify(that.allAndFullStations))
	                 	that.stations = that.allStations.filter(p=>map.channelIds.includes(p.id));
	                 	that.networks = map.networks.map(a => {return {id: a.id, label: a.name, visible: true}});
	                 	that.billTos = map.billTos.map(a => {return {id: a.id, label: a.name, visible: true}});
	                 	that.placements = map.placements.map(a => {return {id: a.id, label: a.name, ref: a.reference, visible: true}});
	                 	that.currencies = map.currencies.map(a => {return {id: a.id, label: a.code, ref: a.reference, visible: true}});
	                 	that.networks.push( {id: 0, label: "<no group>", visible: true})
	                 	
                 	} catch (e)
                 	{
                 		//
                 	}
					that.clear();
                    resolve (true);   

                    that.stopLoader();
                 }).catch(e => {
                	    showError( that.$toast, "get INVOICE Input data", e);
                	    that.stopLoader();
                        reject ();
                    });
	      });
    },
    doInvoice(realInvoice, bill)
    {
    	let CALL = (realInvoice) ? "invoicing of selected items" : "invoice simulation";
    	if ( realInvoice )
    	{
	    	this.$refs.confirm.confirm( {text: 'Start '+CALL, title: "Billing", button1: "Cancel", button2: "Ok"}).then(x => {
		    	this.pbAction = "doInvoiceInternal";
		        this.pbTitle = "Invoicing";
		        this.pBRealInvoice = realInvoice;
		        if ( !this.marked.length)
		        {
		        	if ( bill )
		        	{
		        		this.pBBill = [ bill ];
		        	}
		        	else
		        	{
		        		this.pBBill = this.simulation;
		        	}
		        }
		        else
		        {
		        	this.pBBill = this.simulation.filter( p=>this.marked.includes( p.invoiceNumber));
		        }
		 	    this.showProgressBar = true;
	    	});
    	}
    	else
    	{
    		this.pbAction = "doInvoiceInternal";
	        this.pbTitle = "Invoicing (preview)";
	        this.pBRealInvoice = false;
	 	    this.showProgressBar = true;
	 	    this.pBBill = null;
    	}
    },
    doCNInvoice(realInvoice, bill)
    {
    	let CALL = (realInvoice) ? "create credit-notes for selected items" : "imvoice simulation";
    	this.$refs.confirm.confirm( {text: 'Start '+CALL, title: "Credit Notes", button1: "Cancel", button2: "Ok"}).then(x => {
	    	this.pbAction = "doCreditNoteInternal";
	        this.pbTitle = "Invoicing";
	        this.pBRealInvoice = realInvoice;
	        if ( !this.marked.length)
	        {
	        	if ( bill )
	        	{
	        		this.pBBill = [ bill ];
	        	}
	        	else
	        	{
	        		this.pBBill = this.invoices;
	        	}
	        }
	        else
	        {
	        	this.pBBill = this.invoices.filter( p=>this.marked.includes( p.invoiceNumber));
	        }
	 	    this.showProgressBar = true;
	 	    
    	});
    },
    doReInvoice(realInvoice, bill)
    {
    	let CALL = (realInvoice) ? "create correction for selected items" : "simulate correction";
    	this.$refs.confirm.confirm( {text: 'Start '+CALL, title: "Corrections", button1: "Cancel", button2: "Ok"}).then(x => {
	    	this.pbAction = "doReInvoiceInternal";
	        this.pbTitle = "Invoicing";
	        this.pBRealInvoice = realInvoice;
	        if ( !this.marked.length)
	        {
	        	if ( bill )
	        	{
	        		this.pBBill = [ bill ];
	        	}
	        	else
	        	{
	        		this.pBBill = this.invoices;
	        	}
	        }
	        else
	        {
	        	this.pBBill = this.invoices.filter( p=>this.marked.includes( p.invoiceNumber));
	        }
	 	    this.showProgressBar = true;
	 	    
    	});
    },
    doCheck( bill)
    {
    	let CALL = "Checking invoices";
    	this.pbAction = "checkInvoiceInternal";
        this.pbTitle = "checking";
 	    this.showProgressBar = true;
 	    this.pBBill = bill;
    },
    doCheckM( bill)
    {
    	let CALL = "Checking invoices";
    	this.pbAction = "checkMoneyInvoiceInternal";
        this.pbTitle = "checking";
 	    this.showProgressBar = true;
 	    this.pBBill = bill;
    },
    action( what, myId, api)
    {
   		this[what](myId, api)
    },
    doInvoiceInternal(myId, api) {
        let that = this;
        let realInvoice = this.pBRealInvoice;
		
		let CALL = (realInvoice) ? "createInvoice" : "virtualInvoice";
		let TITLE = (realInvoice) ? "INVOICING" : "Virtual invoice (items for invoicing)";
		let req = this.createRequest();
		if ( this.pBBill )
		{
			req.bill = this.pBBill;
		}
        let time = new Date().getTime();
		//alert( JSON.stringify( req))
    	let dataLoad = new Promise(function (resolve, reject) {
           
    		api.post( invoiceAPI+"/"+CALL+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+myId, req)

        		 .then( response => 
                 {
                 	let map = response.data.data;
                 	if ( map && !map.length)
                 	{
                 		that.infoText = "no data found for invoicing in given period";
                 	}
                 	
                 	that.valueMap = response.data.values.map;
                 	//alert( JSON.stringify(that.valueMap))
                 	//alert( JSON.stringify(that.valueMap['schedule'][42]))
                 	that.errors = response.data.errors;
                 	
                 	map.sort((a,b)=>(a.invoiceNumber>b.invoiceNumber)?1:-1)
                 	that.availableColumns = response.data.columns;
                 	
                 	that.buttons = response.data.buttons.filter(p=>p.type=='BUTTON' || p.type=='BUTTON_AND_MENU');
                 	that.menue = response.data.buttons.filter(p=>(p.type=='MENU' || p.type=='BUTTON_AND_MENU') && p.section == 1);
                 	that.menue2 = response.data.buttons.filter(p=>(p.type=='MENU' || p.type=='BUTTON_AND_MENU') && p.section == 2);
                 	
                 	if ( realInvoice )
                 	{
                 		that.invoices = map;
                 		that.simulation = [];
                 		that.arrangeColumns( response.data.selected);
                 	}
                 	else
                 	{
                 		that.simulation = map;
                 		that.invoices = [];
                 		
                 		let arr =  [...response.data.selected];
                 		if ( Object.keys(that.errors).length )
                     	{
                         	arr.push("Issue");
                       	}
                 		that.arrangeColumns( arr);
                 	}
                 	
                 	
                 	that.marked = [];
                 	that.simDone = true;
                 	that.showProgressBar = false;
                 	
                 	that.title = TITLE;
                    resolve (true);     
                 }).catch(e => {
                	    showError( that.$toast, "Create INVOICE", e);
                	    that.showProgressBar = false;
                	   
                        reject ();
                    });
	      });
    },
    doReInvoiceInternal(myId, api) {
        let that = this;
        let realInvoice = this.pBRealInvoice;
		this.startLoader();
		let CALL = (realInvoice) ? "reInvoice" : "reInvoice";
		let TITLE = (realInvoice) ? "Corrections" : "Virtual Corrections (items for invoicing)";
		let req = this.createRequest();
		if ( this.pBBill )
		{
			req.bill = this.pBBill;
		}
        let time = new Date().getTime();
        

    	let dataLoad = new Promise(function (resolve, reject) {
           
    		api.post( invoiceAPI+"/"+CALL+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+myId, req)

        		 .then( response => 
                 {
                	that.showProgressBar = false; 
                	if ( realInvoice )
                  	{
                		that.getInvoices();
                  	}
                  	else
                  	{
                  		that.availableColumns = response.data.columns;
                      	
                      	that.buttons = response.data.buttons.filter(p=>p.type=='BUTTON' || 'BUTTON_AND_MENU');
                      	that.menue = response.data.buttons.filter(p=>(p.type=='MENU' || p.type=='BUTTON_AND_MENU') && p.section == 1);
                      	that.menue2 = response.data.buttons.filter(p=>(p.type=='MENU' || p.type=='BUTTON_AND_MENU') && p.section == 2);
                      	
                  		let map = response.data.data;
                     	that.errors = response.data.errors;
                  		that.simulation = map;
                  		that.invoices = [];
                  		
                  		let arr =  [...response.data.selected];
                  		if ( Object.keys(that.errors).length )
                      	{
                          	arr.push("Issue");
                        }
                  		that.arrangeColumns( arr);
                  	} 
                	
                    resolve (true);     
                    that.stopLoader();
                 }).catch(e => {
                	    showError( that.$toast, "Create INVOICE", e);
                	    that.showProgressBar = false;
                	    that.stopLoader();
                        reject ();
                    });
	      });
    },
    doCreditNoteInternal(myId, api) {
        let that = this;
        let realInvoice = this.pBRealInvoice;
		this.startLoader();
		let CALL = (realInvoice) ? "createCreditNote" : "virtualCreditNote";
		let TITLE = (realInvoice) ? "CREDIT NOTES" : "Virtual credit-notes (items for invoicing)";
		let req = this.createRequest();
		if ( this.pBBill )
		{
			req.bill = this.pBBill;
		}
        let time = new Date().getTime();
        

    	let dataLoad = new Promise(function (resolve, reject) {
           
    		api.post( invoiceAPI+"/"+CALL+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+myId, req)

        		 .then( response => 
                 {
                	that.showProgressBar = false; 
                	if ( realInvoice )
                  	{
                		that.getInvoices();
                  	}
                  	else
                  	{
                  		that.availableColumns = response.data.columns;
                      	
                      	that.buttons = response.data.buttons.filter(p=>p.type=='BUTTON' || 'BUTTON_AND_MENU');
                      	that.menue = response.data.buttons.filter(p=>(p.type=='MENU' || p.type=='BUTTON_AND_MENU') && p.section == 1);
                      	that.menue2 = response.data.buttons.filter(p=>(p.type=='MENU' || p.type=='BUTTON_AND_MENU') && p.section == 2);
                      	
                  		let map = response.data.data;
                     	that.errors = response.data.errors;
                  		that.simulation = map;
                  		that.invoices = [];
                  		
                  		let arr =  [...response.data.selected];
                  		if ( Object.keys(that.errors).length )
                      	{
                          	arr.push("Issue");
                        }
                  		that.arrangeColumns( arr);
                  	} 
                	
                    resolve (true);     
                    that.stopLoader();
                 }).catch(e => {
                	    showError( that.$toast, "Create INVOICE", e);
                	    that.showProgressBar = false;
                	    that.stopLoader();
                        reject ();
                    });
	      });
    },
    checkInvoiceInternal(myId, api) {
        let that = this;
       
		this.startLoader();
		
		let TITLE = "INVOICE CHECK";
		let req = this.createRequest();
		if ( this.marked.length)
        {
        	req.bill = this.invoices.filter( p=>this.marked.includes( p.invoiceNumber));
        }
		else 
		{
			if ( this.pBBill )
			{
				req.bill = this.pBBill;
			}	
		}
        let time = new Date().getTime();
		
    	let dataLoad = new Promise(function (resolve, reject) {
            console.log( invoiceAPI+"/checkInvoice/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId);
            
       		api.post( invoiceAPI+"/checkInvoice/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/1/"+myId, req)

        		 .then( response => 
                 {
                	
                 	let map = response.data.data;
                 	
                 	map.sort((a,b)=>(a.invoiceNumber>b.invoiceNumber)?1:-1)
                 	that.invoices = that.filter(map);
                 	that.simulation = [];
                 	that.buttons = response.data.buttons.filter(p=>p.type=='BUTTON' || p.type=='BUTTON_AND_MENU');
                 	that.menue = response.data.buttons.filter(p=>(p.type=='MENU' || p.type=='BUTTON_AND_MENU') && p.section == 1);
                 	that.menue2 = response.data.buttons.filter(p=>(p.type=='MENU' || p.type=='BUTTON_AND_MENU') && p.section == 2);

                 	let arr =  [...response.data.selected];

                 	arr.push("Issue");
                 	that.arrangeColumns( arr);
                 	that.marked = [];
                 	that.simDone = false;
                 	that.showProgressBar = false;
                 	that.errors = response.data.errors;
                 	//alert( JSON.stringify(that.errors));
                 	//that.errors = [];
                 	that.title = TITLE;
                    resolve (true);     
                    that.stopLoader();
                 }).catch(e => {
                	    showError( that.$toast, "check INVOICE", e);
                	    that.showProgressBar = false;
                	    that.stopLoader();
                        reject ();
                    });
	      });
    },
    checkMoneyInvoiceInternal(myId, api) {
        let that = this;
       
		this.startLoader();
		
		let TITLE = "INVOICE CHECK MONEY";
		let req = this.createRequest();
		if ( this.marked.length)
        {
        	req.bill = this.invoices.filter( p=>this.marked.includes( p.invoiceNumber));
        }
		else 
		{
			if ( this.pBBill )
			{
				req.bill = this.pBBill;
			}	
		}
        let time = new Date().getTime();
		
    	let dataLoad = new Promise(function (resolve, reject) {
            console.log( invoiceAPI+"/checkInvoiceMoney/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId);
            
       		api.post( invoiceAPI+"/checkInvoiceNet/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/1/"+myId, req)

        		 .then( response => 
                 {
                	
                 	let map = response.data.data;
					that.availableColumns = response.data.columns;
                 	
                 	that.buttons = response.data.buttons.filter(p=>p.type=='BUTTON' || p.type=='BUTTON_AND_MENU');
                 	that.menue = response.data.buttons.filter(p=>(p.type=='MENU' || p.type=='BUTTON_AND_MENU') && p.section == 1);
                 	that.menue2 = response.data.buttons.filter(p=>(p.type=='MENU' || p.type=='BUTTON_AND_MENU') && p.section == 2);

                 	map.sort((a,b)=>(a.invoiceNumber>b.invoiceNumber)?1:-1)
                 	that.invoices = that.filter(map);
                 	that.simulation = [];
                 	let arr =  [...response.data.selected];
                 	//alert( JSON.stringify( arr))
                 	let printEl = arr.pop();
                 	let netEl = arr.pop();
                 	arr.push("Cur.Net");
                 	arr.push(netEl);
                 	arr.push(printEl);
                 	that.arrangeColumns( arr);
                 	that.marked = [];
                 	that.simDone = false;
                 	that.showProgressBar = false;
                 	that.errors = response.data.errors;
                 	
                 	//alert( JSON.stringify(that.errors));
                 	//that.errors = [];
                 	that.title = TITLE;
                    resolve (true);     
                    that.stopLoader();
                 }).catch(e => {
                	    showError( that.$toast, "check INVOICE", e);
                	    that.showProgressBar = false;
                	    that.stopLoader();
                        reject ();
                    });
	      });
    },
    
    openBookMan( billingRec ) {
        setGoBack( this.$router.currentRoute.path, this.$router.currentRoute.name);
        let channel = this.allAndFullStations.find(p=>p.id==billingRec.mediaId);
        let channelName = (channel) ? channel.shortname : null;
    	this.$router.replace('bookMan?toOpen=Placement&list=true&id='+ billingRec.placementId+'&channel='+channelName+'&from='+ momentTZ(this.fromDate).utcOffset(0, true).format().split('T')[0]+'&to='+momentTZ(this.untilDate).utcOffset(0, true).format().split('T')[0]);
    },  
    hoverLine(line)
    {
   		$('#'+line).children().addClass('reqLineBG');
    },
    leaveLine(line)
    {
   		$('#'+line).children().removeClass('reqLineBG');
    },
    startLoader()
    {
    	if ( !this.loadingActive)
      	{
	      	this.loadingActive = true;
	      	this.loader = this.$loading.show({
	                    // Optional parameters
	                    container: this.$refs.formContainer,
	                    canCancel: true,
	                    programmatic: false,
	                    onCancel: this.onCancel,
	                    color: '#000000',
					    loader: 'dots',
					    width: 64,
					    height: 64,
					    active: true,
					    backgroundColor: '#ffffff',
					    opacity: 0.5,
					    zIndex: 999,
	                });
	    }
    },
    stopLoader()
	{
		clearInterval( timers.pop());
		this.loadingActive = false;
		this.loader.hide();
	},
	storeSettings() 
	{
		try
		{
	    	let obj = {inv: this.invoices, sim: this.simulation, width: this.widthCalculated, cols: this.selectedColumns};
	    	obj.agencies = this.agencies;
	    	obj.branches = this.branches;
	    	obj.clients = this.clients;
	    	obj.stations = this.stations;
	    	obj.billTos = this.billTos;
	    	obj.placements = this.placements;
	    	obj.currencies = this.currencies;
	    	obj.networks = this.networks;
	    	obj.allStations = this.allStations;
	    	obj.allAndFullStations = this.allAndFullStations;
	    	obj.fromDate = this.fromDate;
	    	obj.untilDate = this.untilDate;
	    	obj.invoiceDate = this.invoiceDate;
	    	obj.postingDate = this.postingDate;
	    	obj.valueMap = this.valueMap;
	    	this.getFilters( obj);
	    	store( obj);
	    	//alert( JSON.stringify(obj.allStations))
		} catch(e) { showError( this.$toast, "storeSettings()", e);  }
	}
  },
  beforeDestroy() {
	  this.storeSettings();

  },
  created() 
  {
	rebuildAPI( true);
	this.allInvKinds = [{id: 0, label: "Inv", visible: true},
	  {id: 1, label: "CreditNotes", visible: true},
	  {id: 2, label: "Corr.", visible: true},
	  {id: 5, label: "Def.", visible: true}];
	this.selectedKinds = [0,2,5];
	let restoreView = restore();
	if ( this.$route.params && this.$route.params.clientId )
    {
    	
    	if ( this.$route.params.to )
	    {
	    	this.untilDate = new Date( this.$route.params.to);
	    }
	    if ( this.$route.params.from )
	    {
	    	this.fromDate = new Date( this.$route.params.from);
	    }
	    this.reference = ''
	    this.selectedClients = [ parseInt(this.$route.params.clientId)];
	    this.getStations();
	  	this.getData( true); 
	  	this.selectedClients = [ parseInt(this.$route.params.clientId)];
	  	this.$set( this, "selectedClients", [ parseInt(this.$route.params.clientId)])
	  	this.getInvoices();
    }
    else
	if ( this.$route.query.clientId )
    {
		if ( this.$route.query.to )
	    {
	    	this.untilDate = new Date( this.$route.query.to);
	    }
	    if ( this.$route.query.from )
	    {
	    	this.fromDate = new Date( this.$route.query.from);
	    }
	    this.selectedClients = [ parseInt(this.$route.query.clientId)];
	    this.getStations();
	  	this.getData( true); 
	  	this.selectedClients = [ parseInt(this.$route.query.clientId)];
	  	this.$set( this, "selectedClients", [ parseInt(this.$route.query.clientId)])
	    this.getInvoices();
    }
	else if ( this.$route.query.agencyId )
    {
		if ( this.$route.query.to )
	    {
	    	this.untilDate = new Date( this.$route.query.to);
	    }
	    if ( this.$route.query.from )
	    {
	    	this.fromDate = new Date( this.$route.query.from);
	    }
	    this.selectedAgencies = [ parseInt(this.$route.query.agencyId)];
	    this.getStations();
	  	this.getData( true); 
	  	this.selectedAgencies = [ parseInt(this.$route.query.agencyId)];
	  	this.$set( this, "selectedAgencies", [ parseInt(this.$route.query.agencyId)])
	    this.getInvoices();
    }
	else
	{
		// restoreView = {}
		if ( restoreView && restoreView.width)
		{
			this.fromDate = restoreView.fromDate;
			this.untilDate = restoreView.untilDate;
			this.invoiceDate = restoreView.invoiceDate;
		  	this.postingDate = restoreView.postingDate;
		    this.selectedColumns = restoreView.cols;
		    this.widthCalculated = restoreView.width;
		    this.allAndFullStations = restoreView.allAndFullStations;
		    this.allStations = restoreView.allStations;
		    this.agencies = restoreView.agencies;
		    this.branches = restoreView.branches;
		    this.clients = restoreView.clients;
	     	this.stations = restoreView.stations;
	     	this.networks = restoreView.networks;
	     	this.billTos = restoreView.billTos;
	     	this.placements = restoreView.placements;
	     	this.currencies = restoreView.currencies;
	     	this.valueMap = restoreView.valueMap;
	     	this.invoices = restoreView.inv;
			this.simulation = restoreView.sim;
	
	    	this.selectedClients = restoreView.clientIds;
	    	this.selectedAgencies = restoreView.agencyIds; 
	    	this.selectedBillTos = restoreView.billToIds; 
	    	this.selectedStation = restoreView.channelIds; 
	    	this.selectedBranch = restoreView.salesUnitId;
	    	this.selectedNetwork = restoreView.networkIds;
	    	this.placementRef = restoreView.placementRef;
	    	this.invoiceNumber = restoreView.invoiceNumberFrom;
	    	this.invoiceNumberTo = restoreView.invoiceNumberTo;
	
	
			if ( this.invoices && this.invoices.length )
		    {
				
				this.marked = [];
		     	this.errors = [];
		     	this.simDone = false;
		    }
			else
			{
				
				this.marked = [];
		     	this.errors = [];
		     	this.simDone = true;
			}
			//alert( JSON.stringify(this.allStations))
		}
		else
		{
			let d = new Date();  
		  	this.fromDate = new Date(d.getFullYear(), d.getMonth(), 1);
		  	this.untilDate = new Date(d.getFullYear(), d.getMonth()+1, 0);
		  	this.invoiceDate = new Date();
		  	this.postingDate = new Date();
		  	this.getStations();
		  	this.getData(); 
		}
		initAppMode();
	}

  },
  watch: {
  	fromDate: function() {  },
  	untilDate: function() {  },
  	invoiceNumber: function() { if (!this.invoiceNumber) {this.invoiceNumberTo="";}}
  },
  updated() {
  		//console.log("update...");
	  this.$nextTick(function () {
		    // Code that will run only after the
		    // entire view has been re-rendered
		    //console.log("stopping loader");
		    if (this.loadingActive)
		    {
		        timers.push( setTimeout( this.stopLoader, 100));
		    }
		  })
 	}
}
</script>
<style lang="scss" scoped>
 .transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }
  
.transitionFast {
 	transition: all .4s;
   -webkit-transition: all .4s;
 }
.header { 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 7pt; 
  font-weight: bold; 
  width: 100% !important; 
  height: 28pt; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
  background-color: #5fe; 
  color: #000;
  }


.allTd
{
  display:inline-block;
  padding: 0pt;
  margin: 0pt;
  white-space: nowrap; /* Don't forget this one */
  overflow: hidden; 
  text-overflow: ellipsis;
  height: 12pt;
}
.td0 {
  width: calc(14vH) !important; 
  font-weight: bold;
}
.tdcl {
  width: calc(12vH) !important;  
}
.tdcls {
  width: calc(8vH) !important;  
}
.tdm {
  width: calc(8vH) !important;  
}
.td6 {
  width: calc(6vH) !important;  
}
.td1 {
  width: calc(16vH) !important; 
}
.td2 {
  width: calc(8vH) !important;  
  text-align: right;
}

.td2s {
  width: calc(4vH) !important;  
  text-align: right;
}
.tdMny {
  width: calc(11vH) !important;  
  text-align: right;
}
.td3 {
  width: calc(16vH) !important;  
  padding-left: 5pt;
}
.cxl {
 font-weight: bold;
 color: #44f;
}
.cxlC {
 color: #f44;
}
.minusN {
 font-weight: bold;
 color: #f44;
}
.virt {
 font-weight: bold;
 color: #89A;
}
.red {
 font-weight: bold;
 color: #f44;
}
.redAlert {
 font-weight: bold;
 color: #fff;
 background-color: #f44;
}
.alert {
  background-color: #ffa;
  color: #000;
  width: 100%;
}
.num {
 text-align: right;
}
.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  overflow: hidden; 
  text-overflow: ellipsis;
}

.htd {
  display:inline-block;
  background-color: #aac;
  font-weight: bold;
  color: #fff;
  font-size: 9pt; 
  height: 14pt;
}


.gridMain { position: absolute; z-Index: 2; text-align: center; width: 99% ;border: 1pt solid #aaa; opacity:1;
    transition: all 1s;
   -webkit-transition: all 1s; }
   
.SVcontrolLabel {
    display:inline-flex;
	font-weight: bold;
	font-size: 9pt;
	padding-top: 7pt;
	width: 30%;
}
.myButton {
	//width: 18%;
    padding: 6px 8px;
    outline: none;
    border-radius: 3px;
    height: 22pt;
    font-size: 9pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 4pt;
    margin-right: 4pt;
    margin-bottom: 4pt;
}
.reqLineBG { 
  background-color: #888 !important; color: #fff; text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7);
}
.item { cursor: pointer; display: block; white-space: nowrap; }
.item:hover { background-color: #888 !important; color: #fff; text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7); }
.floatLeft {
    float: left;
}
.spotDetail { 
  display: flex;
  align-items: center;
  
  font-size: 7pt;  
  width: 100% !important; 
  height: 16pt; 
  border-left: 1px solid #eee; 
  border-bottom: 1pt solid #ccc;
  -webkit-user-select: none;
-khtml-user-select: none;
-moz-user-select: none;
-o-user-select: none;
user-select: none;
}

</style>
