<template>
 <div class="modal fade modal-mask"
    style='z-index: 9;'
    id="fwEditModal"
    tabindex="1"
    z-index="9"
    role="dialog"
    aria-labelledby="fwEditModal"
    aria-hidden="true"
  >

     <div ref="draggableContainer" id="draggable-container" class="modal-container" :style='getAppStyle()+getContentStyle()'>
         <div class='appHead' id="draggable-header">
             <div style='display: inline-flex; color: #888; font-weight: bold; padding-right: 20pt; font-size: 10pt;'>
             </div>
              <button id='closePopup2' type="button" class="iButton" @click='maximize()' aria-label="Maximize">
              <span aria-hidden="true">
              <svg v-show='width != 100' xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-fullscreen" viewBox="0 0 16 16">
				  <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z"/>
			  </svg>
              <svg v-show='width == 100' xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-fullscreen-exit" viewBox="0 0 16 16">
				  <path d="M5.5 0a.5.5 0 0 1 .5.5v4A1.5 1.5 0 0 1 4.5 6h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5zm5 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 10 4.5v-4a.5.5 0 0 1 .5-.5zM0 10.5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 6 11.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zm10 1a1.5 1.5 0 0 1 1.5-1.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4z"/>
			   </svg>
              </span>
              
              </button>
              <button id='closePopup2' type="button" class="iButton" @click='close' data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16">
				  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
				  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
				</svg>
			  </span>
              </button>
            </div> <!-- appHead -->
       <div class="modal-header">
          <slot name="header">
            <div class='appHeadLine'>
            
            	<div class='appLeft'>
            	Table/Column configurator
                </div>
                <div class='appRight'>
                </div>
            </div> <!-- appHeadLine -->
            
           </slot>
        </div> <!-- modal-header -->  	  
        <div class="modal-body" :style='getMainContentStyle()'>
          <slot name="body">

    		<!-- MAIN -->
            <div class='transition'  style='float: left; width: 100%;'> 
              <div class='avText'>Table-header preview</div>
			  <div style='float: top; width: 100%; height: 48pt;'>
				    <div style='float: top;'>
				       
					    <div v-for="(col,idx) in getActiveColumns()" :key="'col'+idx" class='floatLeft' :style='getColumnStyle(col)'
					        @dragstart="startDragColumn($event, col.show, col.id, idx)" draggable
					        @drop="onDropColumn($event, idx)"
					        @dragover.prevent
							@dragenter.prevent
							>
							
					       <div class='header higher' >
					       	<span class='ltd'>{{ col.name }}</span>
					       		
					       	</div>
					    </div>
					     
				    </div>  <!-- float: top -->   
	
				    
				    
				</div> <!-- HEADER -->
				
				<div class='avText'>available Columns <span style='font-size: 8pt; font-weight: normal;'>(move and arrange with drag & drop)</span></div>
			        <div style='float: top; width: 100%; background-color: #fff; vertical-align: middle;' class='smaller'>
						<div  style='float: left; border: 3pt groovy #ccc; width: 640pt;' class='transition'>		 
				    	<div v-for="(col,idx) in notSelectedColumns()" :key="'COL_'+idx" style='display: block; padding-left: 5%;' class='transition'>
				    		<div :class='getAvColumnClasses()'   draggable
				    	         @click="addColumn(col.show, -1)"
				    	         @drop="removeColumn($event)" 
								 @dragover.prevent
								 @dragenter.prevent
				    	         @dragstart="startDragColumn($event, col.show, col.id,-1)"
				    	         > 
						       		{{ col.name }}
						       	</div>
				    	</div>
				    </div>
				

			    	
            </div> <!-- float: left -->
			</div> <!-- mainContent -->
			
	      </slot>
	    </div> <!-- modal-body -->
	  </div> <!-- modal-container -->  	  

 </div>
</template>

<script>
import {HTTP, fwAPI, invAPI, bngAPI, setReload, myLocale, formatNumber } from '@/variables.js';
import { getAppStyle, setDarkMode, initAppMode, isDarkMode } from '@/AppStyle.js';
import InputTime from '@/components/inputElements/InputTime2';
import Switch from '@/components/Switch';

import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import InputTimezone from '@/components/inputElements/InputTimezone';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
export default {
  name: 'GFW ColumnSelector',
  components : {
   
  },
  props: {
    reference: String,
    time: Number,
    byId: { type: Boolean, default: false},
    display: Boolean,
    availableColumns: Array,
    selected: Array
    
  },
  data () {
    return {
        width: 60,
        height: 50,
        mainHeight: 75,
        
        selectedColumns: [],
        controlsRight: true,
        avColumnsVisible: true,
        getAppStyle,
        positions: {
	        clientX: undefined,
	        clientY: undefined,
	        movementX: 0,
	        movementY: 0
	      }
	      
    }
  },
  /////
 
  /////
  methods: {
     getContentStyle()  { return "width: "+ this.width+"% !important;height: "+ this.height+"% !important;"; },
     getAvColumnClasses()  { if(!this.avColumnsVisible) { return "invisible transition" } else { return "choice normal floatLeft button transition"}},
     myLocale() { return myLocale();},
     getControlStyle() {
    	if ( this.controlsRight )
    	{
    		return "float: right; width: 18%; height: calc(100% - 234px); padding: 1ex;"
    	}
    	return "float: left; width: 18%; height: calc(100% - 200px); padding: 1ex;"
    },
     dragMouseDown: function (event) {
      event.preventDefault()
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      document.onmousemove = this.elementDrag
      document.onmouseup = this.closeDragElement
    },
    elementDrag: function (event) {
      event.preventDefault()
      this.positions.movementX = this.positions.clientX - event.clientX
      this.positions.movementY = this.positions.clientY - event.clientY
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      // set the element's new position:
      this.$refs.draggableContainer.style.top = (this.$refs.draggableContainer.offsetTop - this.positions.movementY) + 'px'
      this.$refs.draggableContainer.style.left = (this.$refs.draggableContainer.offsetLeft - this.positions.movementX) + 'px'
    },
    closeDragElement () {
      document.onmouseup = null
      document.onmousemove = null
    },
    getColumnStyle(col, spot)
     {
        return "width: " + (parseInt(col.w)/this.widthCalculated*100) +"%"+";"
     }, 
    startDragColumn (evt, col, colId, idx) {
        evt.dataTransfer.dropEffect = 'move'
        evt.dataTransfer.effectAllowed = 'move'
        evt.dataTransfer.setData('column', col)
        evt.dataTransfer.setData('columnId', colId)
        evt.dataTransfer.setData('columnIndex', idx)
  	},
    notSelectedColumns()
     {
     	var cols = new Array();
     	for ( var i in this.availableColumns )
     	{
     		let col = this.availableColumns[ i].show;
     		var selected = false;
     		for ( var vis in this.selectedColumns )
     		{
     			let colSel = this.selectedColumns[ vis].show;
     			if ( colSel === col )
     			{
     				selected = true;
     				break;
     			}
     		}
     		if ( ! selected )
     		{
     			cols.push( this.availableColumns[ i]);
     		}
     	} 
     	return cols;
     },
     onDropColumn (evt, target) {
		const source = evt.dataTransfer.getData('column');
		const sourceId = parseInt(evt.dataTransfer.getData('columnId'));
		const columnIndex = evt.dataTransfer.getData('columnIndex');
		var colToInsert;
		for ( var i in this.availableColumns )
     	{
     		let col = this.availableColumns[ i];
     		if ( this.byId )
     		{
     			if ( col.id === sourceId )
     			{
     				colToInsert = col;
     				break;
     			}
     		}
     		else
     		{
     			if ( col.show == source )
     			{
     				colToInsert = col;
     				break;
     			}
     		}
     	}
     	if ( colToInsert )
     	{
	     	//alert( target + " vs " + columnIndex);
	     	if ( target <= columnIndex )
	     	{
				if ( columnIndex && columnIndex >= 0 ) 
				{
					this.selectedColumns.splice( columnIndex, 1);
				}
				this.selectedColumns.splice( target, 0, colToInsert);
			}
			else
			{
				this.selectedColumns.splice( target+1, 0, colToInsert); // insert at 6
				if ( columnIndex && columnIndex >= 0 ) 
				{
					this.selectedColumns.splice( columnIndex, 1);
				}
			}
			
			this.calcWidths( true );
		}
	},
	
	arrangeColumns()
	{
	    
	    this.selectedColumns = new Array();
	    if ( this.selected && this.availableColumns && this.availableColumns.length )
	    {
			for ( var i in this.selected )
			{
				let colName = this.selected[i];
				//console.log(colName);
				for ( var j in this.availableColumns )
				{
					let column = this.availableColumns[j];
					if ( this.byId)
					{
						if ( column.id === parseInt(colName) )
						{
							this.selectedColumns.push( column );
							//console.log(JSON.stringify(this.selectedColumns));
							break;
						}
					}
					else
					{
						if ( column.name === colName )
						{
							this.selectedColumns.push( column );
							//console.log(JSON.stringify(this.selectedColumns));
							break;
						}
					}
				}
		    }
		    this.calcWidths( false);

		    return this.selectedColumns;
		}
		return [];
	},
	calcWidths(withSaving)
	{
		let wTot = 0;
		var columns = "";
		for ( var i in this.selectedColumns )
     	{
     		wTot += this.selectedColumns[ i].w;
     		if ( columns )
     		{
     			columns += "," + this.selectedColumns[ i].name;
     		}
     		else
     		{
     			columns = this.selectedColumns[ i].name;
     		}
     	}
     	if ( withSaving )
     	{
	     	this.$emit("selectedColumns", this.selectedColumns);
	     	this.$emit("selectedColumnsShort", columns);
		}
     	this.widthCalculated = wTot;
	},
	removeColumn (evt )
	{
		const columnIndex = evt.dataTransfer.getData('columnIndex');
		if ( columnIndex && columnIndex >= 0 ) 
		{
			this.selectedColumns.splice( columnIndex, 1);
		}
		this.calcWidths( true );
	},
	addColumn (show) {
		if ( this.avColumnsVisible )
		{
			var colToInsert;
			for ( var i in this.availableColumns )
	     	{
	     		let col = this.availableColumns[ i];
	     		if ( col.show == show )
	     		{
	     			colToInsert = col;
	     			break;
	     		}
	     	}
	     	this.selectedColumns.splice( this.selectedColumns.length-1, 0, colToInsert); // insert at 6
	     	
			this.calcWidths( true);
		}
	},
	getActiveColumns() {
		if ( this.selectedColumns && this.selectedColumns.length )
		{
			return this.selectedColumns;
		}
		else
		{
			this.arrangeColumns();
			
			return this.selectedColumns;
		}
	},
     getMainContentStyle()  { return "height: "+ this.mainHeight+"% !important;"; },
     maximize() { if ( this.width == 100) { this.width = 70; this.mainHeight=80, this.height=90; } else { this.width=100; this.mainHeight=70, this.height=100; }},
     close() { this.breakReference = null; this.$emit("close"); },
     identity(x) { return x }
    
  },
  watch: {
	availableColumns: function() { this.arrangeColumns(); },
	selected: function() {  this.arrangeColumns(); }
  },
  created() {
	
  },
  
}
</script>
<style scoped>
.transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }
.header { 
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  
  width: 100% !important; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
  background-color: #eef; 
}
.avText {

	font-weight: bold;
	font-size: 9pt;
	padding-bottom: 8pt;
}
.BMappHead { 
    width: 100%;  
    text-align: right;
    padding: 0pt 0pt 0pt;
 }
.colored {
  background-color: #eef; 
 }
.normal {
  height: 16pt; 
}
.higher {
  height: 22pt; 
  font-weight: bold; 
  font-size:8pt;
}

.todrag {
 display: block;
 width: 100%; 
 height: 16pt;
 border-left: 1pt solid #ccc; 
 border-bottom: 1pt solid #ccc;
}
.floatLeft {
    float: left;
}
.appLeft {
	display:  inline-table; 
	text-align: left;
	width: 70%; 
} 
.appRight {
	display: inline-table; 
	text-align: right;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
	width: 30%; 
}
.appHead { 
    width: 100%;  
    text-align: right;
    background-color: #eef;
    padding: 0pt 0pt 0pt;
 }
.iButton {
    background-color: transparent;
    border-color: #dbdbdb;
    border-width: 0px;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding-bottom: 2pt;;
    padding-left: 2pt;
    padding-right: 2pt;
    padding-top: 4pt;
    height: 20pt;
    text-align: center;
    white-space: nowrap;
}  
 
.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  //display: table-cell;
  
}

.modal-container {
  background-color: #fff;
  padding-bottom: 20pt;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  //transition: all 0.4s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
  padding: 5pt;
  height: 100%;
}

.modal-default-button {
  float: right;
}

.choice { 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 7pt; 
  
  width: 100pt !important; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
}



/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}


.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
ul {
  list-style: square inside none;
}
.gBold { 

}
.ltd {
  padding-left: 1pt;
  padding-right: 1pt;
  overflow-x: hidden;
  font-size: 9px;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
}
.invisible {
   display: flex;
   height: 0pt;
   color: transparent;
   align-items: center;
   justify-content: center;
   background-color: transparent; 
   border-left: 0pt solid #fff; 
   border-bottom: 0pt solid #fff;
}
.colored {
  background-color: #eef; 
 }
.normal {
  height: 16pt; 
}
.small { font-size: 8pt }
.smaller { font-size: 10pt }
.tiny { font-size: 7pt }
#draggable-container {
  position: absolute;
  z-index: 9;
}
#draggable-header {
  z-index: 10;
}
</style>
