<template>
  <div class="main" >
       <div class='headLine'>
	       <div :style="'width: 85%; float: left; padding-top:4pt; padding-left: 10pt;'" id='inputQSearch2'>
	       	<input ref="entersearch" v-model="searchTerm" id='inputQSearch1'
			                @keypress.enter="enterPressed()" 
    						@focus="$event.target.select()"
    						placeholder='enter search...'
    						:style="getAppStyle()+'width: 75%; height: 22pt;'"/> 
							
    			<button title='Start module search' :style="getAppStyle()" :disabled="!searchTerm" class='button' style=' height: 22pt;' @click="enterPressed"><mdicon size="20" name="magnify"/></button>
				<button title='Show favorites' :style="getAppStyle()" class='button' style='padding:2pt; height: 22pt;' @click="getSuggestions"><mdicon size="20" style="color:red" name="heart-outline"/></button>
	       </div> 
	       
	       <div style='float: right;' @click='$emit("close")'>
	       		<WindowCloseBN @click='$emit("close")'/>
		   </div>
	  </div>
	  <br/>
	  <div class='white body'>
       <div class='white' style="padding-left: 10pt; width: 100%; height: 80pt;">
    			<div class='white text' style='width: 100%; height: 60pt; overflow-y: auto;'>
    			<div v-if="data && data.length">
	    			<div v-for="(menu,idx) in data" :key="'M'+idx" @contextmenu.prevent="openCM($event, menu)" :style="'font-size: '+fontSize+'pt;'">
					   <div v-if="menu.highlyRecommended" style="getAppStyle()+';color: red;'">
			    	   <A  :title="'Open '+menu.title" @click="open(menu)" :style="'color: red; font-size: '+(fontSize+2)+'pt; font-weight: bold;'">{{ menu.title}}</A> ({{menu.menu}}) <span style='font-size: 7pt;'>{{formatNumber(Math.min(100,Math.round(menu.prop*10)*.1))}}%</span>
					   </div>
					   <div v-else>
					   <A :title="'Open '+menu.title" @click="open(menu)" style="font-weight: bold;">{{ menu.title}}</A> ({{menu.menu}}) <span style='font-size: 7pt;'>{{formatNumber(Math.min(100,Math.round(menu.prop*10)*.1))}}%</span>
					   </div>
				   </div>
				</div>
				<div v-else>
				{{info}}
				
				</div>
	    	   </div>
       </div>
	 </div>
    <ContextMenu ref="menu" >
      <template v-if="contextData"  slot-scope="{ contextData }">
       
        <ContextMenuItem v-if='contextData'  @clicked="closeCMN(); open(contextData)">
          Open {{contextData.title}}
        </ContextMenuItem>
        <ContextMenuSep/>
        <ContextMenuItem v-if='contextData'  @clicked="closeCMN(); add(contextData)">
          Add {{contextData.title}}
        </ContextMenuItem>
        
      </template>
    </ContextMenu>
    <GFWEOpenEditor ref='editor' ></GFWEOpenEditor>
  </div>
</template>

<script>
var momentTZ = require('moment-timezone');
import { HTTP, HOME, fwAPI, searchAPI, userAPI, myTimer, receiveToken, formatNumber, myName, showError} from '@/variables.js';
import {store, restore} from '@/searchWidget.js';
import WindowCloseBN from '@/components/misc/WindowCloseBN';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';
import { getAppStyle, initAppMode } from '@/AppStyle.js';
export default {
  name: 'GFW_QSEARCH',
  components : {
	   ContextMenu, ContextMenuItem, ContextMenuSep, WindowCloseBN
	  },
  props: {
   	user: Object,
   	fontSize: {type: Number, defaultValue: 8}
   },
  data: function () {
    return {
      searchTerm: "",
      search: "",
      data: [],
      info: "",
      getAppStyle,
	  formatNumber,
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0
      }
    }
  },
  methods: {
	  enterPressed() {
		    if ( this.searchTerm )
		    {
	    		this.search = this.searchTerm;
	    		this.getData();
		    }
		    else
		    {
		    	this.data = new Array();
		    	this.info="";
		        //this.startLoader();
		        if ( !this.search )
		        {
		        	return;
		        }
		    	//this.$toast.warning('empty search', 'Warning', { position: "topRight" });
		    }
	  },
	  open(menu) {
		  this.$emit("open", menu)
	  },
	  add(menu) {
		  this.$emit("add", menu.entity, menu.title)
	  },
	  openCM(event, menu){
	        event.stopPropagation();
	        
	        this.$refs.menu.open(event, menu )
	  },
	  closeCMN() { try{ this.$refs.menu.close(); } catch(e) { /* */ }},
	  getData() {
	        let that = this;
	        this.data = new Array();
	        //this.startLoader();
	        if ( !that.search )
	        {
	        	return;
	        }
	        that.info = "searching...";
	    	let dataLoad = new Promise(function (resolve, reject) {
	            //console.log( searchAPI+"/quicksearch/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+that.search);
	            
	       		HTTP.get( searchAPI+"/quicksearch/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+that.search)

	        		 .then( response => 
	                 {
	                    that.data = response.data;
	                    that.info = that.data.length +" module(s) found for '"+that.search+"'";

	                    resolve (that.data);                    
	                 }).catch(e => {
	                        this.$toast.error("loading data for search: " + e.response.data, 'Error', { position: "topRight" });
	                        //this.stopLoader();
	                        that.info = "";
	                        reject ();
	                    });
		      });
	    },
		getSuggestions() {
	        let that = this;
	        this.data = new Array();
	        that.info = "searching...";
	    	let dataLoad = new Promise(function (resolve, reject) {
	            HTTP.post( searchAPI+"/suggest2/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/9/400/20")

	        		 .then( response => 
	                 {
	                    that.data = response.data;
	                    that.info = that.data.length +" module(s) found for '"+that.search+"'";
						
	                    resolve (that.data);                    
	                 }).catch(e => {
	                        this.$toast.error("loading data for search: " + e.response.data, 'Error', { position: "topRight" });
	                        //this.stopLoader();
	                        that.info = "";
	                        reject ();
	                    });
		      });
	    },
	    storeSettings() 
		{
			try
			{
		    	let obj = {searchTerm: this.searchTerm, data: this.data, info: this.info};
		    	store( obj);
			} catch(e) { showError( this.$toast, "storeSettings()", e);  }
		}
  },
  beforeDestroy() {
	  this.storeSettings();

  },
  updated() {
	  
	 
	
  },
  created()
  {
	let restoreView = restore();
	
	// restoreView = {}
	if ( restoreView && restoreView.searchTerm)
	{
		this.searchTerm = restoreView.searchTerm;
		this.data = restoreView.data;
		this.info = restoreView.info;
	}
	try
	{
		document.getElementById("inputQSearch1").style.backgroundColor=sessionStorage.bgColor;
		document.getElementById("inputQSearch1").style.color = sessionStorage.fgColor;
	} catch (e)
	{
		//
	}
	try
	{
		document.getElementById("inputQSearch2").style.backgroundColor=sessionStorage.bgColor;
		document.getElementById("inputQSearch2").style.color = sessionStorage.fgColor;
	} catch (e)
	{
		//
	}
	this.getSuggestions();
	this.$forceUpdate();
  },
  watch: {
        user: function() {
            
		 }
    }
}
</script>

<style scoped>
.main {
	display: block;
	width: 100% !important;
}
.body {
	display: block;
	float: top;	
}

.headLine {
	float: top;	
	width: 100%;
	white-space: nowrap;
}
.white {
   //background-color: white !important;
}
.text {
 	font-size: 9pt;
}

.iButton:hover {color: #e8f0e8 !important;}
.item { cursor: pointer; display: block; white-space: nowrap; }
.item:hover { background-color: #888 !important; color: #fff; text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7); }
</style>


