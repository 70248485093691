<template>
<div style='display: block;'>
<div class='inputWrapper'>
    <span class='span25'>
        <span v-if="disabled">
         	<div v-for="(col,idx) in getActiveColumns()" :key="'col'+idx" class='floatLeft' :style='getColumnStyle(col)'
				>
				
		       <div class='active higher disabled' >
		       		{{ col.show }}
		       	</div>
		    </div>
        </span>
        <div v-else-if="value && value.length" style='border: 1px solid #aaa; border-radius: 6px;overflow:hidden;'>
			
		    <span v-for="(col,idx) in availableColumns" :key="'col'+idx" class='floatLeft' :style='getColumnStyle(col)'
				v-tooltip="title(col.show)"	        
							>
							
			       <span v-if="selectedColumns.includes( col)" class='active higher limit' @click="removeColumn(col.show)">
			       		{{ col.show }}
			       	</span>
					<span v-else class='higher limit nonActive' @click="addColumn(col.show)">
			       		{{ col.show }}
			       	</span>
			    </span>
		</div>
		<span v-else>
			<span style='color: transparent;'>
				X
		    </span>
		</span>
    </span>

    
    
</div>
</div>
</template>
<script>
import clipboard from '@/components/Clipboard';
export default {
  props: {
    value: Array,
    recordId: Number,
    column: String,
    metadata: {},
    disabled: Boolean,
    definition: Object,
    entityMap: Object,
	explanation: Object,
    mark: { type: Boolean, default: false},
    border: { type: Boolean, default: false}
  },
  components : {
     
  },
  data() {
  	return {
  	   selectData: [],
  	   showColumnSelector: false,
  	   availableColumns: [],
  	   selected: [],
  	   selectedColumns: [],
  	}
  },
  directives:{
    
  },
  methods: {
	  title( val)
	  {
		if ( this.explanation )
		{
			return this.explanation[val];
		}
		return val;
	  },
      getData() {
		//alert(JSON.stringify(this.definition))
		if ( this.definition.values && this.definition.values.length)
		{
			let map = [];
			for ( let i in this.definition.values)
			{
				map.push({id: i, label: this.definition.values[i], additionalColumn: [0,this.definition.values[i]]});
			}
			map.sort((a,b)=> a.label.localeCompare(b.label))
			//alert(this.column + "/" + JSON.stringify(map))
			return map;
		}
      	//let col = this.definition.columnHeader;
      	//alert(this.column + "/" + JSON.stringify(this.definition))
      	let col = this.definition.columnHeader;
      	
      	//alert(this.column + "/" + JSON.stringify( this.entityMap[col].data.filter(p => p.additionalColumn[0] === this.recordId)))
      	return this.entityMap[col].data.filter(p => p.additionalColumn[0] === this.recordId);
      },
      getAvColumnClasses()  { return "choice normal floatLeft button transition" },
      setSelectedColumns(selectedColumns)
      {
        let cols = this.getData();
        this.value = []
      	for (var i in selectedColumns)
      	{
      		let name = selectedColumns[i].name;
      		let column = cols.find( p => p.label === name );
      		this.value.push(column.id);
      		//alert( name + "->"+column.id)
      	}
      	this.$emit("input", this.value);
      	//this.$emit("enter", this.value);
      },
      getColumnStyle(col, spot)
     {
        return "color: #000 !important; width: 16.66%"+";"
     }, 
      getActiveColumns() {
        
		if ( this.selectedColumns && this.selectedColumns.length )
		{
			return this.selectedColumns;
		}
		else
		{
		    this.getAvailableColumns();
			this.arrangeColumns();
			
			return this.selectedColumns;
		}
	  },
	  close() {
	  	this.showColumnSelector=false
	  },
	  switchPopup() {
	  	this.showColumnSelector=!this.showColumnSelector
	  },
	  arrangeColumns()
	  {
	    
	    this.selectedColumns = new Array();
	    if ( this.selected && this.availableColumns && this.availableColumns.length )
	    {
			for ( var i in this.selected )
			{
				let colName = this.selected[i];
				//console.log(colName);
				for ( var j in this.availableColumns )
				{
					let column = this.availableColumns[j];
					//console.log(column.name);
					if ( column.name === colName )
					{
						this.selectedColumns.push( column );
						//console.log(JSON.stringify(this.selectedColumns));
						break;
					}
				}
		    }
		    this.calcWidths( false);

		    return this.selectedColumns;
		}
		return [];
	},
	calcWidths(withSaving)
	{
		let wTot = 0;
		var columns = "";
		for ( var i in this.selectedColumns )
     	{
     		wTot += this.selectedColumns[ i].w;
     		if ( columns )
     		{
     			columns += "," + this.selectedColumns[ i].name;
     		}
     		else
     		{
     			columns = this.selectedColumns[ i].name;
     		}
     	}
     	if ( withSaving )
     	{
			this.setSelectedColumns(this.selectedColumns)
		}
     	this.widthCalculated = wTot;
	},
      getAvailableColumns()
      {
      	let cols = this.getData();
      	this.availableColumns = [];
      	this.selected = [];
      	for ( var i in cols )
      	{
      	   let column = cols[i];
      	   if ( column && column.label )
      	   {
	      	  this.availableColumns.push({ name: column.label, show: column.additionalColumn[1], w: 5});
      	   }
      	}
      	
      	if ( this.value )
      	{
	      	for ( i in this.value )
	      	{
	      	   let cId = this.value[i]
	      	   
	      	   this.selected.push( cols.find(p => p.id == cId).label);
	      	   
	      	}
	    }
	    this.arrangeColumns();
      	return this.availableColumns;
      },
      startDragColumn (evt, col, idx) {
        evt.dataTransfer.dropEffect = 'move'
        evt.dataTransfer.effectAllowed = 'move'
        evt.dataTransfer.setData('column', col)
        evt.dataTransfer.setData('columnIndex', idx)
  	},
	addColumn (show) {
	  
			let colToInsert;
			for ( let i in this.availableColumns )
	     	{
	     		let col = this.availableColumns[ i];
	     		if ( col.show == show )
	     		{
	     			colToInsert = col;
	     			break;
	     		}
	     	}
	     	this.selectedColumns.push( colToInsert);
			this.setSelectedColumns( this.selectedColumns);

	},
	removeColumn (show) {
		    
	    this.selectedColumns = this.selectedColumns.filter(p=>p.show !== show)
		this.setSelectedColumns( this.selectedColumns);
	
	},
     
      show()
      {
      	 this.getAvailableColumns();
      	 this.showColumnSelector = true;
      },
      enterPressed() { this.$emit("enter"); },
      changed() { this.$emit("changed", this.value); }
  },
  created() {
	  
	  this.getAvailableColumns();
	  
  },
  watch: {
	recordId: function() { this.getAvailableColumns(); },
	definition: function() { this.getAvailableColumns(); },
	value: function() {  this.getAvailableColumns(); }
  },
 }
</script>
<style lang="scss" scoped>
input { font-size: 12pt; border: none; width: 100%}
input:disabled { font-weight: bold; font-size: 12pt; border: none; width: 100%; color: #888; background-color: #fff;}
input:focus { outline: 1pt dotted grey;}

.inputText { text-align: left;}
.inputBorder { font-size: 12pt; padding: 2pt; text-align: left; border: 1pt solid #ccc; width: 80%;  }

.span25 { display: inline-block; width: 100%}
.dist5 { margin-left: 4pt; }
.span75 {  width: 15%; text-align: right; float: right; }

.avText {
	font-weight: bold;
	font-size: 9pt;
	padding: 4pt;
	color: #888;
}
.transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }
.disabled {
	color: #888;
}
.invisible {
   display: flex;
   height: 0pt;
   color: transparent;
   align-items: center;
   justify-content: center;
   background-color: transparent; 
   border-left: 0pt solid #fff; 
   border-bottom: 0pt solid #fff;
}
.choice { 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px; 
  font-weight: bold;
  width: 100pt !important; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
}
.normal {
  height: 16pt; 
}

.inputWrapper { 
    display: block;
    
    padding-left: 2pt;
    padding-top: 2pt;
    padding-bottom: 0pt;
    margin-bottom: 0pt;
    width: 100%;

	overflow: visible !important;
}
.fieldWrapper { 
    border: 1pt solid #888;
    background-color: #eef;
    border-radius: 6px;
    padding: 2pt;
    margin: 1pt;
}
.floatLeft {
    float: left;
}
.active { 
  display: flex;
  align-items: center;
  justify-content: left;
	
  padding-left: 1pt;
  
  width: 100% !important; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
  background-color: #eef; 
}
.nonActive { 
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 1pt;
  color: #888;
  width: 100% !important; 
  
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
  background-color: #fff; 
}

.higher {
  height: 16pt; 
}
.limit {
	overflow: hidden;text-align: left;white-space: nowrap; text-overflow: ellipsis;font-size: 7pt;
}
.inputButton { 
    border: 1pt solid grey;
}
.menuList { 
    position: absolute; 
    float: top; 
    display: block;
	overflow: visible !important;
    background-color: #fff; 
    //color: #dde;
    border: 1pt solid #ccc; 
    z-index: 9999;
    border-radius: 4px;
    padding-top: 1pt;
    padding-bottom: 1pt;
    box-shadow: 2px 2px 4px 0 #aaa;
}
</style>