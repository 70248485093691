import { render, staticRenderFns } from "./CopyView.vue?vue&type=template&id=3966dac5&scoped=true&"
import script from "./CopyView.vue?vue&type=script&lang=js&"
export * from "./CopyView.vue?vue&type=script&lang=js&"
import style0 from "./CopyView.vue?vue&type=style&index=0&id=3966dac5&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3966dac5",
  null
  
)

export default component.exports