<template>
 <div style='width: 100%; height: 100%; position: relative;'>
   
     <div class='transition' :style='getControlStyle()'>
    	<!-- CONTROLS -->
    	    
		    <div style='float: top; width: 100%; vertical-align: middle;'>
		        <div style='vertical-align: middle; text-align: center; font-size: 14pt; font-weight: bold;width: 100%;'>REPORTER  1.0</div>
		        
		        <br/>
		        <div style='display: inline-flex; width: 45%;'>
			    <InputDatePick :auto="false" 
                            @change="dateChanged" v-model="fromDate"/>  
                            </div>
                            -
                <div style='display: inline-flex; width: 45%;'>
                <InputDatePick :auto="false" @change="dateChanged" v
                            v-model="untilDate"/>     
                 </div>   
                 
                  
                <span class='SVcontrolLabel'>{{tr('SalesUnit')}}:</span>
			    <GSelectMSimple fontSize="9" width="70"
			         :multiple="true" :options="branches" v-model="selectedBranch" >
			    </GSelectMSimple>
			    
			    <span class='SVcontrolLabel'>Channel:</span>
			    <GSelectMSimple fontSize="9" width="70"
			         :multiple="true" :options="stations" v-model="selectedStation" >
			    </GSelectMSimple>
			    
			    <span class='SVcontrolLabel'>Networks:</span>
			    <GSelectMSimple fontSize="9" width="70"
			         :multiple="true" :options="networks" v-model="selectedNetwork" >
			    </GSelectMSimple>
			    
			    <span class='SVcontrolLabel'>Holdings:</span>
			    <GSelectMSimple fontSize="9" width="70"
			         :multiple="true" :options="holdings" v-model="selectedHoldings" >
			    </GSelectMSimple>
			    
			    <span class='SVcontrolLabel'>Agencies:</span>
			    <GSelectMSimple fontSize="9" width="70"
			         :multiple="true" :options="agencies" v-model="selectedAgencies" >
			    </GSelectMSimple>
			    
			    <span class='SVcontrolLabel'>Clients:</span>
			    <GSelectMSimple fontSize="9" width="70"
			         :multiple="true" :options="clients" v-model="selectedClients" >
			    </GSelectMSimple>
			   

			    <span class='SVcontrolLabel'>{{tr('Placement')}}:</span>
			    <InputText  width="70" @enter="getReportData" v-model='placementRef'/>
			    
			    <span class='SVcontrolLabel'>Reference:</span>
			    <InputText  width="70" @enter="getReportData" v-model='reference'/>
			    
			    

		    	<span class='SVcontrolLabel'>Filter</span>
		    	<button v-tooltip="'Reload data for filtering'" class='button myButton' @click="getData">Reload filter</button>
		    	<button v-tooltip="'Clear all filter selections'" class='button myButton' @click="clear">Clear filter</button>
		    	<hr/>
		    	<span class='SVcontrolLabel'>Operations</span>
		    	<button class='button myButton' @click="getReportData('Report')"><mdicon :size="15" name="reload" /></button>
				<button class='button myButton' @click="exportOverview('Report')">Excel</button>
				<br/>
				<span class='SVcontrolLabel'>Airing</span>
		    	<button class='button myButton' @click="getReportData('Vouching')"><mdicon :size="15" name="reload" /></button>
				<button class='button myButton' @click="exportOverview('Vouching')">Excel</button>

		    	
		    	<br/>
		    	
		    	<hr/>
				<span class='SVcontrolLabel'>Show Controls: </span>
		    	&nbsp;
		    	<app-switch v-model="controlsRight" :checked="controlsRight"/>

		    	
		    </div> <!-- CONTROLS -->
    </div>
    
    <!-- MAIN -->
	<div class='transition' style='float: left; width: 78%;'> 
	    <!-- HEADER -->
	    <div style='float: top; width: 100%; height: 28pt;'>
	          
			  <div v-if="data  && data.length" style='width: 100%; height: calc(100vh - 155px); '>
			      
			        <div style='float: top; width: 100%; height: 28pt; overflow-y: scroll;'>
						    <div style='float: top;'>
							    <div v-for="(col,idx) in selectedColumns" :key="'ColSp_'+idx" class='floatLeft tableHeader' 
							    	:style='getColumnStyle(col)'
							    	@click='setOrderBy(col)' >
							      <div class='header' :style='getIColumnStyle(col)'>
							       		<div > {{ col.name }}<sub>{{getOrderBy(col)}}</sub></div>
							       	</div>
							    </div>
							   
						    </div>  <!-- float: top -->   
						</div> <!-- HEADER -->
			 </div>
	  	</div>
	    <!-- HEADER -->
	    <!-- TABLE -->
	    <div v-if="data  && data.length" style='width: 100%; height: calc(100vh - 155px); '>

	    	    <div style='width: 100%; height: calc(75vH); overflow-y: scroll;'>
	    	    <div v-for="(i,idx) in data" :key="'I'+idx"
				    	  @contextmenu="contextmenu($event,i)" 
				    	  @mouseenter="hoverLine('I_'+idx)"
			              @mouseleave="leaveLine('I_'+idx)">
			    	
			    	<div v-if="i" class='item' :id="'I_'+idx" >
	    	    		<div v-for="(col,idx2) in selectedColumns" :key="'CS_'+idx+'_'+idx2" class='floatLeft' :style='getColumnStyle(col,i)'>
						       <div class='spotDetail dontWrap' :style='getIColumnStyle(col)' >
						         
						          <div :title='print(i, col)' >
						       		{{ print(i, col) }}
						          </div>
						       	</div>
						    </div> 
	    				</div>
	    		 </div> 
	    	   </div> 
	    	
    	</div>  <!-- TABLE -->
    </div>  <!-- MAIN -->
    
<ContextMenu ref="menu" :offsetY="-100" :placement='auto'>
      <template v-if="contextData"  slot-scope="{ contextData }">
        <ContextMenuLabel>
        <div v-if="contextData.schedule">
        {{contextData.schedule}}
        </div>
        <div v-else>
        {{contextData.date}} @ {{contextData.media}}
        </div>
        </ContextMenuLabel>
        <ContextMenuSep/>

        <div v-for="(mn,mi) in menue" :key="'mn_'+mi">
	        <ContextMenuItem v-if="(!mn.check) || (Array.isArray(contextData[mn.check])?contextData[mn.check].length: contextData[mn.check])"
	                          @clicked="$refs.menu.close(); runButton($event, mn, contextData)">
	          {{mn.name}}
	        </ContextMenuItem>
	    </div>
       
       </template>
    </ContextMenu>
    <GConfirm ref='confirm'/>
    <PDFViewer name='pdfView' ref='pdfView' />
    <GFWEOpenEditor ref='editor'></GFWEOpenEditor>
	<ProgressBar v-if="showProgressBar" :generalInfo=pbTitle :action=pbAction @action=action></ProgressBar>

 </div>
</template>

<script>
import {HTTP, fwAPI, invAPI, reportAPI, clientReportAPI, opsAPI, invoiceAPI, setReload, myLocale, showError, formatNumber} from '@/variables.js';
import { setGoBack } from '@/breadCrumb.js';
import {store, restore} from '@/reporterScreen.js';
import InputTime from '@/components/inputElements/InputTime2';
import InputText from '@/components/inputElements/InputText';
import Switch from '@/components/Switch';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';
import ContextMenuLabel from '@/components/ContextMenuLabel';
import InputTimezone from '@/components/inputElements/InputTimezone';
import GridBreakView from '@/components/GridBreakView';
import InputDatePick from '@/components/inputElements/InputDatePick3'; 
import GSelectMSimple from '@/components/GSelectMSimple'; 
import ProgressBar from '@/components/ProgressBar';
import PDFViewer from '@/components/PDFViewer';
import GConfirm from '@/components/GConfirm';
import { tr } from '@/translate.js';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
var momentTZ = require('moment-timezone');
var timers = [];
var lastMarked = "-";
import JQuery from "jquery";
let $ = JQuery;
export default {
  name: 'NEXT_REPORTER_View',
  components : {
   'app-switch': Switch, InputDatePick, GSelectMSimple, ProgressBar, 
   ContextMenu, ContextMenuItem, ContextMenuSep, ContextMenuLabel, 
   PDFViewer, GConfirm, InputText
  },
  data () {
    return {
      allStations: {},
      selectedStation: [],
      selectedAgencies: [],
      selectedClients: [],
      selectedBillTos: [],
      selectedNetwork: [],
      selectedHoldings: [],
      networks: [],
      holdings: [],
      branches: [],
      currencies: [],
      selectedBranch: [],
      currency: {},
      stations: [],
      clients: [],
      billTos: [],
      placements: [],
      agencies: [],
      placementRef: "",
      reference: "",
     
      fromDate: {},
      untilDate: {},
      data: {},
      title: "",
      fontSize: 11,
      loadingActive: false,
      
      controlsRight: false,
      
	  showProgressBar: false,
	  
	  orderBy: [],
	  menue: [],
	  pBRealInvoice: false,
	  pBBill: null,
	  pbType: 0,
      pbAction: "",
      pbTitle: "",
      tr,
      widthCalculated: 0,     

    	  
      availableColumns: [
      ],
      selected: [],
      
      selectedColumns: [
	        
      ]
      
    }
  },
  methods: {
    myLocale() { return myLocale();},
    getControlStyle() {
    	if ( this.controlsRight )
    	{
    		return "float: right; width: 21%; height: calc(100vh - 120px); padding: 2ex;"
    	}
    	return "float: left; width: 21%; height: calc(100vh - 120px); padding: 2ex;"
    },
    contextmenu(event, item){
        event.preventDefault();
       
        this.$refs.menu.open(event, item, null, null, 'auto' )
	},
	closeCMN() { try{ this.$refs.menu.close() } catch(e) { /* */ }},
	setSelectedColumns( sc ) { this.selectedColumns = sc; this.calcWidths( ) },
	getIColumnStyle(col)
    {
       if ( this.loadingActive )
       {
       	return "color: #fff; font-size:"+this.fontSize+"px";
       }
       let sty = "font-size:"+this.fontSize+"px;"
       if ( col.align == 'R' )
       {
       	sty += "justify-content: right;";
       }
       else if ( col.align == 'L' )
       {
       	sty += "justify-content: left;";
       }
       else
       {
       	sty += "justify-content: center;";
       }
       
       return sty;
    },
	calcWidths()
	{
		let wTot = 0;
		var columns = "";
		for ( var i in this.selectedColumns )
     	{
     		wTot += this.selectedColumns[ i].w;
     		if ( columns )
     		{
     			columns += "," + this.selectedColumns[ i].name;
     		}
     		else
     		{
     			columns = this.selectedColumns[ i].name;
     		}
     	}
     	this.widthCalculated = wTot;
	},
	filter(i)
	{
		let tmp = i;
		
		return tmp;
	},
	runButton( event, bn, data)
	{
		this[ bn.function]( event, bn, data )
	},
	arrangeColumns(selected)
	{
	    this.selectedColumns = new Array();
		for ( var i in selected )
		{
			let colName = selected[i];
			for ( var j in this.availableColumns )
			{
				let column = this.availableColumns[j];
				if ( column.name == colName )
				{
					this.selectedColumns.push( column );
					break;
				}
			}
	    }
	    this.calcWidths();
	},

	print(i, col)
    {
     
    	let val = i[ col.show ];
    	if ( i && col.show.endsWith('Color'))
        {
    		return i.dayStatus[0];
        }
    	if ( val !== null && col.num )
    	{
    		return formatNumber( val );
    	}
    	return val;
    },
    openBookMan( event, bn, rec ) {
        setGoBack( this.$router.currentRoute.path, this.$router.currentRoute.name);
        let channel = rec.media
       
    	this.$router.replace('bookMan?toOpen=Placement&list=true&id='+ rec.placementId+'&channel='+channel+'&from='+ momentTZ(rec.from).utcOffset(0, true).format().split('T')[0]+'&to='+momentTZ(rec.to).utcOffset(0, true).format().split('T')[0]);
    },
    openDetailP(event, bn, rec)
    {
    	setGoBack( this.$router.currentRoute.path, this.$router.currentRoute.name);
    	
    	this.$router.replace('detailPlanning?stationId='+rec.mediaId+'&day='+ rec.date);
    },
    openAsRun(event, bn, rec)
    {
    	setGoBack( this.$router.currentRoute.path, this.$router.currentRoute.name);
    	this.$router.replace('AsRun?stationId='+rec.mediaId+'&day='+ rec.date);
    },
    dateChanged()
    {
    	this.getData();
    },
    forceFileDownload(base64, name) 
	{  
      const url = window.URL.createObjectURL(new Blob([this.base64ToArrayBuffer(base64)]));
      const link = document.createElement('a');
      link.href = url
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
    },
    base64ToArrayBuffer(base64) {
	    var binary_string = atob(base64);
	    var len = binary_string.length;
	    var bytes = new Uint8Array(len);
	    for (var i = 0; i < len; i++) {
	        bytes[i] = binary_string.charCodeAt(i);
	    }
	    return bytes.buffer;
	},
	getColumnStyle(col, i)
    {
       let sty = "width: " + (parseInt(col.w)/this.widthCalculated*100) +"%;";
       if ( i && col.show.endsWith('Color'))
       {
    	   sty += "background-color: "+i[col.show]+"; font-weight: bold;";
       }
       if ( i && col.special == 'showIf' )
	   	{ 
	   		if ( i.placementLineNo && i.placementLineNo > 1 )
	   		{
	   			sty += "color: blue; background-color: #eee;";
	   		}
	   	}
       return sty;
    }, 
    
	
	getFilters( toObj)
	{
    	toObj.clientIds = this.selectedClients; 
    	toObj.agencyIds = this.selectedAgencies; 
    	toObj.billToIds = this.selectedBillTos; 
    	toObj.channelIds = this.selectedStation; 
    	toObj.salesUnitId = this.selectedBranch;
    	toObj.networkIds = this.selectedNetwork;
    	toObj.placementRef = this.placementRef;
    	toObj.reference = this.reference;
    	toObj.invoiceNumberFrom = this.invoiceNumber;
    	toObj.invoiceNumberTo = this.invoiceNumberTo;
	},
	createRequest()
	{
		let req = {	
				fromDateISO: momentTZ(this.fromDate).utcOffset(0, true).format().split('T')[0], 
				untilDateISO: momentTZ(this.untilDate).utcOffset(0, true).format().split('T')[0]
				
	        };
		this.getFilters( req);
		return req;
	},
    getStations() {
        let that = this;
		this.startLoader()

        let time = new Date().getTime();
    	let stationLoad = new Promise(function (resolve, reject) {
            console.log(fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/Media/asOptions/true/0");
       		HTTP.get( fwAPI+"/getAsOptions/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/Media/true/0")
        		 .then( response => 
                 {
                 	let dur = (new Date().getTime()-time);
               		console.log("LOADED STATIONS in " + dur +"ms");  
                    var tmpAllStations = response.data.data;
                    that.allStations = [];
                    for ( var channel in tmpAllStations )
                    {
                    	if ( tmpAllStations[channel].visible )
                    	{
                    		that.allStations.push( tmpAllStations[channel]);
                    	}
                    	
                    }   
                    
                    resolve (true);                    
                 }).catch(e => {
                	    showError( that.$toast, "loading stations", e);
                        reject ();
                    });
	      });
    },
    clear() {
    	this.selectedClients = [];
    	this.selectedAgencies = []
    	this.selectedStation = []
    	this.selectedBranch = []
    	this.selectedBillTos = []
    	this.selectedNetwork = []
    	this.selectedClients = []
    	this.networkIds = []
    	this.invoiceNumber = "";
    	this.placementRef = "";
    },
    
    getReportData(type)
	{
		let that = this;
		that.data = [];
		this.startLoader();
		let req = this.createRequest();
		
    	let dataLoad = new Promise(function (resolve, reject) {
            HTTP.post( clientReportAPI+"/get"+type+"Data/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, req)

        		 .then( response => 
                 {
                	let map = response.data.data;
					that.availableColumns = response.data.columns;
					that.buttons = [];
					that.menue = [];
					if ( response.data.buttons )
					{
						that.buttons = response.data.buttons.filter(p=>p.type=='BUTTON' || p.type=='BUTTON_AND_MENU');
	                 	that.menue = response.data.buttons.filter(p=>(p.type=='MENU' || p.type=='BUTTON_AND_MENU') && p.section == 1);
					}
                 	that.arrangeColumns( response.data.selected);
                 	//alert( JSON.stringify(that.selectedColumns))
                  	that.data = map;
                  	
                 	that.stopLoader();
                    resolve (true);     
                    
                 }).catch(e => {
                	    showError( that.$toast, "read INVOICE", e);
                	    that.stopLoader();
                        reject ();
                    });
	      });
	},
    exportOverview( type) 
	{
		let that = this;
		this.startLoader();
		let req = this.createRequest();
		
		let dataLoad = new Promise(function (resolve, reject) {
           
            HTTP.post( clientReportAPI+"/get"+type+"DataExcel/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, req)

        		 .then( response => 
                 {
                	
                	let downloadResponse = response.data;
        		    that.forceFileDownload( downloadResponse.file, downloadResponse.filename);

                    resolve (true);     
                    that.stopLoader();
                 }).catch(e => {
                	    showError( that.$toast, "export INVOICE", e);

                	    that.stopLoader();
                        reject ();
                    });
	      });
	},
    getData( ) {
        let that = this;
		this.startLoader();
		store( {});
		let req = {	 
					fromDateISO: momentTZ(this.fromDate).utcOffset(0, true).format().split('T')[0], 
					untilDateISO: momentTZ(this.untilDate).utcOffset(0, true).format().split('T')[0] 
		};
		// alert( JSON.stringify(req)+"/"+this.fromDate)
		let dataLoad = new Promise(function (resolve, reject) {
            
       		HTTP.post( clientReportAPI+"/getData4Report/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, req)

        		 .then( response => 
                 {
                 	let map = response.data;
                 
                 	//that.fromDate = new Date( map.fromDateISO);
                 	//that.untilDate = new Date( map.untilDateISO);
                 	//alert(map.untilDateISO)
                 	
                 	that.agencies = map.agencies.map(a => {return {id: a.id, label: a.name, visible: true}});
                 	that.branches = map.salesUnits.map(a => {return {id: a.id, label: a.name, shorty: a.shortname, visible: true}});
                 	that.clients = map.clients.map(a => {return {id: a.id, label: a.name, visible: true}});
                 	that.stations = that.allStations.filter(p=>map.channelIds.includes(p.id));
                 	that.networks = map.networks.map(a => {return {id: a.id, label: a.name, visible: true}});
                 	that.holdings = map.holdings.map(a => {return {id: a.id, label: a.name, visible: true}});
                 	that.billTos = map.billTos.map(a => {return {id: a.id, label: a.name, visible: true}});
                 	that.placements = map.placements.map(a => {return {id: a.id, label: a.name, ref: a.reference, visible: true}});
                 	that.currencies = map.currencies.map(a => {return {id: a.id, label: a.code, ref: a.reference, visible: true}});
                 	that.networks.push( {id: 0, label: "<no group>", visible: true})
                 	that.holdings.push( {id: 0, label: "<no group>", visible: true})
                    resolve (true);     
                    that.stopLoader();
                 }).catch(e => {
                	    showError( that.$toast, "get INVOICE Input data", e);
                	    that.stopLoader();
                        reject ();
                    });
	      });
    },
    
    action( what, myId, api)
    {
    	console.log(what+"/"+ myId+"/"+api)
   		this[what](myId, api)
    },
    setOrderBy( col )
    {
       var add = true;
    	for ( var i in this.orderBy)
    	{
    		let iCol = this.orderBy[i];
    		if ( iCol.show == col.show )
    		{
    		    if ( col.sorting === 1 )
    		    {
    		    	col.sorting = -1;
    		    }
    		    else
    		    {
    				this.orderBy.splice( i, 1);
    				col.sorting = 0;
    			}
    			add = false;
    			break;
    		}
    	}
    	if ( add )
    	{
    		this.orderBy.push( col );
    		col.sorting = 1;
    	}
    	this.data = this.doSort( this.data);
    },
    doSort( data)
    {
       let that = this;
       data.sort(function(a,b) {
	     	for ( var i in that.orderBy)
	     	{
	     		let iCol = that.orderBy[i];
	     		let v1 = a[ iCol.show ];
	     		let v2 = b[ iCol.show ];
	     		
	     		if ( v1 > v2 )
	     		{
	     		   
	     			return iCol.sorting;
	     		}
	     		if ( v1 < v2 )
	     		{
	     			return -iCol.sorting;
	     		}
	     	}
	     	return 0;
	     });
	     
    	return data;
    },
    getOrderBy( col )
    {
    	for ( var i in this.orderBy)
    	{
    		let iCol = this.orderBy[i];
    		if ( iCol.show === col.show )
    		{
    		    if ( iCol.sorting > 0 )
    		    {
    				return "A"+(parseInt(i)+1);
    			}
    			return "D"+(parseInt(i)+1);
    		}
    	}
    	return "";
    },
    hoverLine(line)
    {
   		$('#'+line).children().addClass('reqLineBG');
    },
    leaveLine(line)
    {
   		$('#'+line).children().removeClass('reqLineBG');
    },
    startLoader()
    {
    	if ( !this.loadingActive)
      	{
	      	this.loadingActive = true;
	      	this.loader = this.$loading.show({
	                    // Optional parameters
	                    container: this.$refs.formContainer,
	                    canCancel: true,
	                    programmatic: false,
	                    onCancel: this.onCancel,
	                    color: '#000000',
					    loader: 'dots',
					    width: 64,
					    height: 64,
					    active: true,
					    backgroundColor: '#ffffff',
					    opacity: 0.5,
					    zIndex: 999,
	                });
	    }
    },
    stopLoader()
	{
		clearInterval( timers.pop());
		this.loadingActive = false;
		this.loader.hide();
	},
	storeSettings() 
	{
		try
		{
	    	let obj = {};
	    	obj.fromDate = this.fromDate;
	    	obj.untilDate = this.untilDate;
	    	obj.placementRef = this.placementRef;
	    	obj.data = this.data;
	    	obj.menue = this.menue;
	    	obj.buttons = this.buttons;
	    	obj.selectedColumns = this.selectedColumns;
	    	obj.widthCalculated = this.widthCalculated;
	    	store( obj);
	    	//alert( JSON.stringify(obj.allStations))
		} catch(e) { showError( this.$toast, "storeSettings()", e);  }
	}
  },
  beforeDestroy() {
	  this.storeSettings();

  },
  created() {
	    if ( this.$route.params && this.$route.params.clientId )
	    {
	    	
	    	if ( this.$route.params.to )
		    {
		    	this.untilDate = new Date( this.$route.params.to);
		    }
		    if ( this.$route.params.from )
		    {
		    	this.fromDate = new Date( this.$route.params.from);
		    }
		    this.reference = ''
		    this.selectedClients = [ parseInt(this.$route.params.clientId)];
		    this.selectedAgencies = [ ];
		    this.getStations();
		  	this.getData( true); 
		  	this.selectedAgencies = [ ];
		  	this.selectedClients = [ parseInt(this.$route.params.clientId)];
		  	this.$set( this, "selectedClients", [ parseInt(this.$route.params.clientId)])
		    this.getReportData('Report')
	    }
	    else if ( this.$route.query.reference )
	    {
			if ( this.$route.query.to )
		    {
		    	this.untilDate = new Date( this.$route.query.to);
		    }
		    if ( this.$route.query.from )
		    {
		    	this.fromDate = new Date( this.$route.query.from);
		    }
		    this.reference = this.$route.query.reference;
		    this.selectedClients = [ parseInt(this.$route.query.clientId)];
		    this.selectedAgencies = [ parseInt(this.$route.query.agencyId)];
		    this.getStations();
		  	this.getData( true); 
		  	this.selectedAgencies = [ parseInt(this.$route.query.agencyId)];
		  	this.selectedClients = [ parseInt(this.$route.query.clientId)];
		  	this.$set( this, "selectedAgencies", [ parseInt(this.$route.query.agencyId)])
		  	this.$set( this, "selectedClients", [ parseInt(this.$route.query.clientId)])
		  	this.$set( this, "reference", this.$route.query.reference)
		    this.getReportData('Report')
	    }
		else if ( this.$route.query.clientId )
	    {
			if ( this.$route.query.to )
		    {
		    	this.untilDate = new Date( this.$route.query.to);
		    }
		    if ( this.$route.query.from )
		    {
		    	this.fromDate = new Date( this.$route.query.from);
		    }
		    this.selectedClients = [ parseInt(this.$route.query.clientId)];
		    this.getStations();
		  	this.getData( true); 
		  	this.selectedClients = [ parseInt(this.$route.query.clientId)];
		  	this.$set( this, "selectedClients", [ parseInt(this.$route.query.clientId)])
		    this.getReportData('Report')
	    }
		else if ( this.$route.query.agencyId )
	    {
			if ( this.$route.query.to )
		    {
		    	this.untilDate = new Date( this.$route.query.to);
		    }
		    if ( this.$route.query.from )
		    {
		    	this.fromDate = new Date( this.$route.query.from);
		    }
		    this.selectedAgencies = [ parseInt(this.$route.query.agencyId)];
		    this.getStations();
		  	this.getData( true); 
		  	this.selectedAgencies = [ parseInt(this.$route.query.agencyId)];
		  	this.$set( this, "selectedAgencies", [ parseInt(this.$route.query.agencyId)])
		    this.getReportData('Report')
	    }
		else 
		{
		  	let restoreView = restore();
			//restoreView = {}
			if ( restoreView && restoreView.widthCalculated)
			{
				this.fromDate = restoreView.fromDate;
				this.untilDate = restoreView.untilDate;
				this.data = restoreView.data;
				this.menue = restoreView.menue;
				this.buttons = restoreView.buttons;
				this.selectedColumns = restoreView.selectedColumns;
				this.widthCalculated = restoreView.widthCalculated;
				this.placementRef = restoreView.placementRef;
			}
			else
			{
				let d = new Date();  
			  	this.fromDate = new Date(d.getFullYear(), d.getMonth() - 1, 1);
			  	this.untilDate = new Date(d.getFullYear(), d.getMonth(), 0);
			  	this.invoiceDate = new Date();
			  	this.postingDate = new Date();
			  	
			}
			this.getStations();
		  	this.getData(); 
		}

  },
  watch: {
  	fromDate: function() {  },
  	untilDate: function() {  },
  	
  },
  updated() {
  		//console.log("update...");
	  this.$nextTick(function () {
		    // Code that will run only after the
		    // entire view has been re-rendered
		    //console.log("stopping loader");
		    if (this.loadingActive)
		    {
		        timers.push( setTimeout( this.stopLoader, 100));
		    }
		  })
 	}
}
</script>
<style lang="scss" scoped>
 .transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }
  
.transitionFast {
 	transition: all .4s;
   -webkit-transition: all .4s;
 }
.header { 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 7pt; 
  font-weight: bold; 
  width: 100% !important; 
  height: 28pt; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
  background-color: #ff9800; 
  }
.tableHeader{
 cursor: pointer;
}

.allTd
{
  display:inline-block;
  padding: 0pt;
  margin: 0pt;
  white-space: nowrap; /* Don't forget this one */
  overflow: hidden; 
  text-overflow: ellipsis;
  height: 12pt;
}
.td0 {
  width: calc(14vH) !important; 
  font-weight: bold;
}
.tdcl {
  width: calc(12vH) !important;  
}
.tdcls {
  width: calc(8vH) !important;  
}
.tdm {
  width: calc(8vH) !important;  
}
.td6 {
  width: calc(6vH) !important;  
}
.td1 {
  width: calc(16vH) !important; 
}
.td2 {
  width: calc(8vH) !important;  
  text-align: right;
}

.td2s {
  width: calc(4vH) !important;  
  text-align: right;
}
.tdMny {
  width: calc(11vH) !important;  
  text-align: right;
}
.td3 {
  width: calc(16vH) !important;  
  padding-left: 5pt;
}
.cxl {
 font-weight: bold;
 color: #44f;
}
.cxlC {
 color: #f44;
}
.minusN {
 font-weight: bold;
 color: #f44;
}
.virt {
 font-weight: bold;
 color: #89A;
}
.red {
 font-weight: bold;
 color: #f44;
}
.redAlert {
 font-weight: bold;
 color: #fff;
 background-color: #f44;
}
.alert {
  background-color: #ffa;
  width: 100%;
}
.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  overflow: hidden; 
  text-overflow: ellipsis;
}

.htd {
  display:inline-block;
  background-color: #aac;
  font-weight: bold;
  color: #fff;
  font-size: 9pt; 
  height: 14pt;
}


.gridMain { position: absolute; z-Index: 2; text-align: center; width: 99% ;border: 1pt solid #aaa; opacity:1;
    transition: all 1s;
   -webkit-transition: all 1s; }
   
.SVcontrolLabel {
    display:inline-flex;
	font-weight: bold;
	font-size: 9pt;
	padding-top: 7pt;
	width: 30%;
}
.myButton {
	//width: 18%;
    padding: 6px 8px;
    outline: none;
    border-radius: 3px;
    height: 22pt;
    font-size: 9pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 4pt;
    margin-right: 4pt;
    margin-bottom: 4pt;
}
.reqLineBG { 
  background-color: #888 !important; color: #fff; text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7);
}
.item { cursor: pointer; display: block; white-space: nowrap; }
.item:hover { background-color: #888 !important; color: #fff; text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7); }
.floatLeft {
    float: left;
}
.spotDetail { 
  display: flex;
  align-items: center;
  
  font-size: 7pt;  
  width: 100% !important; 
  height: 16pt; 
  border-left: 1px solid #eee; 
  border-bottom: 1pt solid #ccc;
  -webkit-user-select: none;
-khtml-user-select: none;
-moz-user-select: none;
-o-user-select: none;
user-select: none;
}

</style>
