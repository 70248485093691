<template>
<div class='inputWrapper' :style="getAppStyle()+''">
	<div style='display: inline-flex; color: #aaa !important;'><mdicon size="14" name="magnify" spin="true"/></div>
    <input ref="entersearch" v-model="searchterm" 
			                @keypress.enter="enterPressed()" 
			                @keyup="$emit('input', searchterm ); $emit('search')" 
    						@focus="$event.target.select()"
    						:placeholder="'enter search for '+topic+'...'"
    						:style="getAppStyle()+'font-size: 9pt; margin-left: 2pt;margin-top: 4px; border: none; width: 80%; height: 22pt; margin-right: 10pt;'"/> 
</div>
</template>
<script>
import { getAppStyle, getAppStyleImp, setDarkMode, initAppMode, getBGStyle2 } from '@/AppStyle.js';
import clipboard from '@/components/Clipboard';
import toggleViewIcon from '@/components/ToggleViewIcon';

export default {
  props: {
	  value: String,
	  topic: String
  },
  components : {
	    // clipboard
	    //, toggleViewIcon
	  },
  data() {
      return {
    	  searchterm: this.value,
          getAppStyle, getAppStyleImp
      }
  },
  methods: {
      enterPressed() { 
		
		this.$emit('input', this.searchterm ); 
		this.$emit('search'); 
		this.$emit('enter', this.searchterm );
	  }
  },
  updated() {
	  this.$emit('input', this.searchterm );
  },
  created() {
	  this.searchterm = this.value
  },
  watch:
  {
   value: function() { this.searchterm=this.value; this.$forceUpdate(); },
   imgData: function() { this.showImage = true; }
  },
  computed: {
    
    
 }
}
</script>
<style lang="scss" scoped>

.textInput { font-size: 12pt; border: 1pt solid #ccc; width: 100%}
.span25 { display: inline-block; width: 85%; background-color: transparent !important;}

.dist5 { margin-left: 4pt; }
.span75 { display: inline-block; width: 15%; text-align: right; flow: right; background-color: transparent !important;}
.inputWrapper { 
    border: 1pt solid #ccc;
    border-radius: 6px;
    width: 100%;
    background-color: transparent !important;
}
.inputButton { 
    border: 1pt solid grey;
}
input { font-size: 10pt; border: none; }
input:disabled { font-size: 10pt; border: none; color: #888;}
input, input:focus{
    outline: none;
}
</style>