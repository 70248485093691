<template>
<div class='inputWrapper' >
   <div v-if='showOpener && entity && create' class='input70' >	
         <span >
			
	         <vSelect :options="options" v-model="internalValue" :style='getStyle()' :selectable=isSelectable
	            :appendToBody="true" :disabled="isDisabled()" @input="setSelected" :placeholder='placeholder' :clearable="nulls"> 
				<template v-if="!multiple" #selected-option-container="{ option }">
			      <div  :style="getAppStyle()+'padding-left: 1pt; font-size:9pt; padding-top: 4pt; padding-right: 2pt;'" class='selectedOption'>{{ option.label }} </div>
			    </template>
	        </vSelect>
        </span>

    </div>	
    <div v-else-if='showOpener && entity' class='input80' >	
         <span >
			
	         <vSelect :options="options" v-model="internalValue" :style='getStyle()' :selectable=isSelectable
	            :appendToBody="true" :disabled="isDisabled()" @input="setSelected" :placeholder='placeholder' :clearable="nulls"> 
				<template v-if="!multiple" #selected-option-container="{ option }">
			      <div  :style="getAppStyle()+'padding-left: 1pt; font-size:9pt; padding-top: 4pt;  padding-right: 2pt;'" >{{ option.label }} </div>
			    </template>
	        </vSelect>
        </span>

    </div>	
    <div v-else class='input100' >	
         <span >
			
	         <vSelect :options="options" v-model="internalValue" :style='getStyle()' :selectable=isSelectable
	            :appendToBody="true" :disabled="isDisabled()" @input="setSelected" :placeholder='placeholder' :clearable="nulls"> 
				<template v-if="!multiple" #selected-option-container="{ option }">
			      <div :title="option.label" :class="clip?'dontWrap':''" :style="getAppStyle()+'padding-left: 1pt; font-size:9pt; padding-top: 4pt;  padding-right: 2pt;'" >{{ option.label }} </div>
			    </template>
				<template #option="{ id, label }">
	      			<div style='font-size: 9pt;'>{{label}}</div>
	    		</template>
				
	        </vSelect>
        </span>

    </div>	

    <div style="float: right">
    
    <div v-if='showOpener && entity' class='dontWrap input20' >	
        <span v-if="create" :title="'Create new '+entity" style='display: inline-flex;' :style='getAppStyle()'>
			
		 <button :disabled="!createIf || !createWith" class='button btn' :style='getAppStyle()' @click="createNew">
		 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-folder-plus" viewBox="0 0 16 16">
			  <path d="m.5 3 .04.87a1.99 1.99 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14H9v-1H2.826a1 1 0 0 1-.995-.91l-.637-7A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09L14.54 8h1.005l.256-2.819A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2zm5.672-1a1 1 0 0 1 .707.293L7.586 3H2.19c-.24 0-.47.042-.683.12L1.5 2.98a1 1 0 0 1 1-.98h3.672z"/>
			  <path d="M13.5 10a.5.5 0 0 1 .5.5V12h1.5a.5.5 0 1 1 0 1H14v1.5a.5.5 0 1 1-1 0V13h-1.5a.5.5 0 0 1 0-1H13v-1.5a.5.5 0 0 1 .5-.5z"/>
			</svg>
		  </button>
		</span>
        <span :title='tooltip()' style='display: inline-flex;' :style='getAppStyle()'>
		<button :disabled='(! internalValue.id)' class='button btn' :style='getAppStyle()' @click="$refs.editor.open( entity, value);">
		<svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
			  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
			</svg>
		</button>
		</span>
		
	 </div>	
    </div>
	 <GFWEOpenEditor @insert="passInsert" @update="passUpdate" ref='editor'></GFWEOpenEditor>
</div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import { getAppStyle, setDarkMode, initAppMode, getBGColor } from '@/AppStyle.js';
import {HTTP, fwAPI, accessPointAPI, APIURL} from '@/variables.js';
export default {
  name: "GSelectSimple",
  props: {
    disabled: { type: Boolean, default: false },
	createWith: Object, 
	placeholder:  { type: String, default: '' },
	createIf: { type: Number, default: 0 }, 
    create: { type: Boolean, default: false },
    border: { type: Boolean, default: true },
    showOpener: { type: Boolean, default: true },
    nulls: { type: Boolean, default: true },
    value: Number,
	clip: { type: Boolean, default: false },
    entity: String,
    options: Array,
    fontSize: { type: Number, default: 10 },
  },
  components : { vSelect},
  data() {
  	return {
  		getAppStyle
  	}
  },
  methods: {
    openDetail() {
        
    	this.$emit("open", this.selectedValue.id, this.linkData.name)
    },
    setSelected() {
    	this.$emit("changed", this.value);
    },
    tooltip() { return ( this.entity) ? "Open " + this.entity: "" },
    getStyle() { 
      	if ( this.mark )
      	{
      		return "border: .5px solid #ddd; background-color: #fdd; font-size: " +this.fontSize+"px";
      	}
      	if ( this.border )
      	{
      		return "border: .5px solid "+getBGColor('#eee','#444')+"; font-size: " +this.fontSize+"px";
      	}
      	return "font-size: " +this.fontSize+"px";
      },
    createNew()
	{
	  	this.$refs.editor.open( this.entity, 0, this.createNewRecord)     	
	},
	  
   	createNewRecord()
	{
		let record = {... this.createWith};

	  	return record;
	},
    passUpdate(entity, record) {
    	this.$emit( "update", entity, record);
    },
    passInsert(entity, record) {
    	this.$emit( "insert", entity, record);
    },
    isSelectable(option) { return option.visible; },
    isDisabled() { return this.disabled  },
  },
  computed: {
  	internalValue: {
      get() {
        if ( this.options.length === 1 )
        {
            this.$emit("input", this.options[0].id)
        	return this.options[0];
        }
        if ( this.value || this.value === 0)
        {
        	let ival = parseInt( this.value)
        	return this.options.find( p => p.id === ival)
        }
        
        return {};
      },
      set(v) 
      {
        if ( v === null )
        {
        	this.$emit("input", null)
        	this.$emit("change", null)
        	this.$emit("clear", null)
        }
        else
        {
        	this.$emit("input", v.id)
        	this.$emit("change", v.id)
      	}
      }
    }
  },
  watch: {

  },
  updated() {

  },
  created() {

  }
 }
</script>
<style  scoped>
.inputWrapper {
	width: 100% !important;
	display: inline-block;
	padding: 0;
	margin:0;
	
}

.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  overflow: hidden;
  width: 100pt;
  display: inline-block;
}
.selectedOption { 
   font-size: 12px; 
   height: 18px !important; 
   max-width: calc(25vH);
   width: calc(25vH);
   margin-top: 2pt;
  
   margin-right: 2pt;
   white-space: nowrap;
   overflow: hidden !important;
   text-overflow: ellipsis !important;
}
.input80 {
	width: 90% !important;
	display: inline-block;
	padding: 0;
	margin:0;
}
.input70 {
	width: 80% !important;
	display: inline-block;
	padding: 0;
	margin:0;
}
.input100 {
	width: 100% !important;
	display: inline-block;
	padding: 0;
	margin:0;
}
.input20 {
    width: 10% !important;
	display: inline;
	text-align: right;
	vertical-align: top;
	padding: 0;
	margin:0;
}
.btn {
	height: 2.2em !important;
	padding-left: 0.5em;
	padding-right: 0.5em;
	
}
label, span{
        display: block;
    }
</style>