 <template>
  <div   
  >
  <XModal :name="getInvEditorId()" :resizable="true" :width="'80%'" :height="'68%'" :draggable="false">
   <div :style="getAppStyle()+'height: 100%;'">
   <div class="modal-wrapper" >

     <div class="modal-container" :style="getAppStyle()+getContentStyle()+'position: absolute !important; border: 2pt inset #cca; top: 0px !important; left: 0px  !important; width: 100%  !important; height: 100%  !important;'">
         <div class='appHeadLeft'>
         Grid/Inventory filter editor
         </div>
         <div class='appHeadRight'>
             <div style='display: inline-flex; color: #888; font-weight: bold; padding-right: 20pt; font-size: 10pt;'> </div>
            

            <button id='closePopup2' type="button" class="iButton" @click='closeUntilPosition(-1)' data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16">
				  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
				  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
				</svg>
			  </span>
            </button>
            </div>
       <div class="modal-header">
          <slot name="header">
            <div class='appHeadLine' style='border-bottom: 1px solid grey;'>
             
            	<div class='appLeft' >
            	  
            	  <div style='float: left'>
            	  <div :class="(selectedTab==0?'breadcrumbItemCurrent':'breadcrumbItem')" 
            	       :style="'background-color: '+getBGColor('#eee','#111')+';color: '+getBGColor('#333','#ddd')" @click="selectedTab=0">
            	  Editor</div>
            	  <div :class="(selectedTab==1?'breadcrumbItemCurrent':'breadcrumbItem')" 
            	       :style="'background-color: '+getBGColor('#eee','#111')+';color: '+getBGColor('#333','#ddd')" @click="findInventory(data.id)">
            	  Search inventory...</div>
            	  <div :class="(selectedTab==2?'breadcrumbItemCurrent':'breadcrumbItem')" 
            	       :style="'background-color: '+getBGColor('#eee','#111')+';color: '+getBGColor('#333','#ddd')" @click="visualizeGraph(filter)">
            	  Visualize graph</div>
            	  
                </div>
            	</div>
                <div class='appRight' style='padding-top: 4pt;'>
                 
            	  
                </div>
                
                </div>
            
           </slot>
        </div>
        <div class="modal-body" :style='getMainContentStyle()'>
          <slot name="body">
            <div class='mainContent' >
            <div v-if="selectedTab==1">
              <div style='font-size: 9pt; padding-bottom: 5pt; display: block; width: 97%; white-space: nowrap; text-overflow: ellipsis; overflow-x: hidden;'> {{filter?filter.title:""}}</div>
              <div style='display: block; max-height: 40% !important; height: calc(36vH) !important; overflow-y: scroll'>
              <div v-if="!showAsText" style='margin-top: 30pt; margin-left: 30pt;'>
              	LOADING INVENTORY....
              </div>
              <table v-else width="90%">
              <tr>
               <th class='tHeader' :style="'position: sticky; top: 0; color: '+getBGColor('#008','#bbf')+';background-color: '+getBGColor('#ccc','#333')">
               Grid
               </th>
               <th class='tHeader' :style="'position: sticky; top: 0; color: '+getBGColor('#008','#bbf')+';background-color: '+getBGColor('#ccc','#333')">
               Category
               </th>
               <th class='tHeader' :style="'position: sticky; top: 0; color: '+getBGColor('#008','#bbf')+';background-color: '+getBGColor('#ccc','#333')">
               Genre
               </th>
                <th class='tHeader' :style="'position: sticky; top: 0; color: '+getBGColor('#008','#bbf')+';background-color: '+getBGColor('#ccc','#333')">
               Sub-Genre
               </th>
                <th class='tHeader' :style="'position: sticky; top: 0; color: '+getBGColor('#008','#bbf')+';background-color: '+getBGColor('#ccc','#333')">
               Channel/Media
               </th>
                <th class='tHeader' :style="'position: sticky; top: 0; color: '+getBGColor('#008','#bbf')+';background-color: '+getBGColor('#ccc','#333')">
               PG
               </th>
               <th class='tHeader' :style="'position: sticky; top: 0; color: '+getBGColor('#008','#bbf')+';background-color: '+getBGColor('#ccc','#333')">
               Starttime
               </th>
              </tr>
              <tr v-for="(data, dIdx) in showAsText" :key="'i_'+dIdx" >
              <td class='tDetail' @click="$refs.editor.open('GridDetail', data.detail.id )">     
              {{data.detail.name}}
              </td>
              <td class='tDetail' @click="data.category?$refs.editor.open('ContentCategory', data.category.id ):''">     
              {{data.category.name}}
              </td>
              <td class='tDetail' @click="data.genre?$refs.editor.open('Genre', data.genre.id ):''">          
              {{data.genre?data.genre.name:'-'}}
              </td>
              <td class='tDetail' @click="data.subGenre?$refs.editor.open('Genre', data.subGenre.id ):''">    
              {{data.subGenre?data.subGenre.name:'-'}}
              </td>
              <td class='tDetail' @click="data.media?$refs.editor.open('Media', data.media.id ):''">       
              {{data.media.shortname}}
              </td>
              <td @click="data.rateCard?$refs.editor.open('RateCard', data.rateCard.id ):''">     
              {{data.rateCard.name}}
              </td>
              <td class='tDetail' @click="$refs.editor.open('GridDetail', data.detail.id )">    
              {{printTime2(data.detail.starttime)}}
              </td>
             
               <!-- .detail.name}} / {{ data.media.shortname}} --> 
              </tr>
              </table>
              
              
              </div>
            </div>
            <div v-else-if="selectedTab==2" style=' height: 100%; border: none; '>
             
               <vue-tree v-if="graphData && graphData.value"
				      style="width: 100%; height: 84%;"
				      :dataset="graphData"
				      :config="treeConfig"
				      :direction="graphDirection"
				    >
				      <template v-slot:node="{ node, collapsed }">
				        <div :title="node.value"
				          :class="(node.joiner != null && node.joiner.length)?(node.joiner+'-node'+' ' +node.joiner+'-color' ):'rich-media-node'"
				          :style="{ border: collapsed ? '2px solid grey' : ''}"
				          @contextmenu.prevent=contextMenu
				          @click='openEditor(node)'
				        >
				          
				          <span  style="padding: 4px 0;" 
				            
				            >{{node.value }}
				            
				            </span
				          >
				        </div>
				      </template>
				    </vue-tree>
				    <div>
				    
				    <span style='float: left; font-size: 8pt; color: #aaa;'>move graph with mouse, click on a node to edit</span>
				    <button type='button' style='float: right; font-size: 8pt;' @click="changeGraphDirection">{{graphDirection}}</button>
				    				    
				    <div v-if="aFilter" class="transition" style='float: left; width: 100%;'>
				    <InputInventoryFilter  @closeEdit="closeEdit"
			              	  :counter=aFilterEvent
			              	  :index=1
			              	  :number=1
			              	  :forceOpen="true"
			              	  :keepOpen="forceOpenAlways"
			              	  :simple="true"
			                  :allOperators=allOperators
			                  :allTypes="invFilterArray"
			                  :operatorId=aFilter.operator.id
			                  :value=aFilter 
			              	  :title=aFilter.name 
			              	  >
			              	</InputInventoryFilter>
				    
				    </div>
				     </div>
            </div>
            <div v-else-if="selectedTab==0">
            <span class='elementLabel required' style='width:10%;'>Media kind</span>  
            <span class='editorElement' style='width:40%;'>
              <GSelectEntity @selected="mediaKindChanged" @open=openLink :multiple="true" 
	              	               entityTitle="MediaKind" entityName="MediaKind" 
	              	               :id=data.mediaKindId
	              	               :visible="true" :disabled="false">
	              	</GSelectEntity>
              
            </span>   
            <span class='elementLabel required' style='width:10%;'>Behaviour</span>  
            <span class='editorElement' style='display: inline-block; width:40%;'>
            <button @click='setBlacklistOnOff' :class='getOnOffStyle(filter)' style='height: 20pt;'>{{getOnOff(filter)}}</button>
            
            <button @click='forceOpenAlways = !forceOpenAlways' class='button keepOpenBn' :style="getAppStyle()+'height: 20pt;'">{{(forceOpenAlways?"Edit":"Text")+"-mode"}}</button>
            </span>   
            <br/>
			    <div style='float: top; width: 100%; height: 66%; padding-top: 2pt; margin-top: 2pt; overflow-y: scroll;overflow-x: hidden; ' >
				
             	<div style='float: left; height: calc(38vH); width: 81%;'>
			      <FilterTree :allTypes="invFilterArray" :allOperators="allOperators" 
			                  @copyLine="copyLine"
			                  @remove="removeFilter"
			                  @drop.exact="dropFilter($event, filter)"
			                  @addFilterToParent="addFilterToParent"
			                  @moveFilterToParent="moveFilterToParent"
			                  @dragover.prevent
							  @dragenter.prevent
			                  @openCTX="contextmenuFTree"
			                  @clickJoiner="clickJoiner"
			                  @openCTXJoiner="contextmenuJoiner"
							 
			                  :keepOpen="forceOpenAlways"
			                  :forceUpdate="forceUpdate"
			                  v-model="filter"/>
			    </div>
	            	<div style='position: sticky; top: 1px; float: right; height: calc(30vH); width: 19%;'>
	            	   <button draggable class='button typeButtonSmall active JOINER AND-color' 
	            	           @dragstart.exact="dragStartJoiner($event, 'AND')"
					           @dragend.exact="dragEndFilter($event, '')" 
					           @dragover.prevent
							   @dragenter.prevent 
	            	           @click="addAnd">AND</button>
	            	   <button draggable class='button typeButtonSmall active JOINER OR-color' style='float: right;'
	            	           @dragstart.exact="dragStartJoiner($event, 'OR')"
					           @dragend.exact="dragEndFilter($event, '')" 
					           @dragover.prevent
							   @dragenter.prevent 
	            	           @click="addOr">OR</button>
		               <div v-for='idx in invFilterArrayMatched' :key="idx.id" style='display: inline'>
		              	<span>
		              		<button draggable @click="addFilterToParent( idx, filter )"
		              		       @dragstart.exact="dragStartFilter($event, idx)"
					               @dragend.exact="dragEndFilter($event, idx)" 
					               @dragover.prevent
							       @dragenter.prevent 
		              		        class='button typeButton active'><b>{{idx.name}}</b></button>
		              	</span>
		              </div>    
		             </div>
                </div>
             </div>
            
              
              <table width='100%' >
              <tr>
              <td :class="msgClass">
                {{ message }} 
              </td>
              </tr>
              </table>
              </div>
              <div style='border-top: 1pt solid #bbbbff;'>
              <table width='100%'>
              <tr>
              <td colspan="2" class='lUpdateInfo' style="font-size:9pt;">
              <div style='font-size: 9pt; padding-bottom: 5pt; display: flex; width: calc(80vH) !important; white-space: nowrap; text-overflow: ellipsis; overflow-x: hidden;'> {{filter?filter.title:""}}</div>
              
              </td>
              </tr>
              <!-- 
              <tr>
              <td width="20%" class='lUpdateInfo'>Last Update</td>
              <td class='lUpdateInfo'><InputDateTime v-if="data.lUpdate" size="10pt" width="80%" :disabled=true v-model=data.lUpdate /></td>
              
              </tr>
              -->
              
              </table>
            </div>
              
               <div class="modal-footer" style='width: 100%; height: 80pt; float: right;'>
               <div style='display: inline-flex; float: right;'>
               
             
                <button :style="getAppStyle()" type="button" class="button btn-secondary" @click="close()" data-dismiss="modal">Close</button>
                <button :style="getAppStyle()" type="button" class="button btn-secondary" @click="reload(data.id)" data-dismiss="modal"><mdicon :size="20" name='reload'/></button>
                <button :style="getAppStyle()" type="button" class="button btn-secondary" @click="clearFilter" data-dismiss="modal"><mdicon :size="20" name='delete'/></button>
                <button :style="getAppStyle()" type="button" class="button btn-primary btn-save"   @click.prevent="update()">Apply</button>
                <button :style="getAppStyle()" type="button" class="button btn-primary btn-save"   @click.prevent="update(); close()">Apply and Close</button>
              </div>
               
               <div style='display: inline-flex; float: right; padding-top: 4pt; padding-right: 10pt;'>

               
               </div>
               <div :style="'display: inline-flex; float: right; padding-right: 20pt;'">
               
               <button :disabled="selectedTab==0" :style="getAppStyle()" type="button" class="button btn-secondary" @click="selectedTab=0" data-dismiss="modal">Editor</button>
               
               <button :style="getAppStyle()" type="button" class="button btn-secondary" @click="expandFilters()" data-dismiss="modal">Check/Simplify</button>
               
               <button :style="getAppStyle()" type="button" class="button btn-secondary" @click="findInventory(data.id)" data-dismiss="modal">Search Inventory</button>
               <button :style="getAppStyle()" type="button" class="button btn-secondary" @click="visualizeGraph(filter)" data-dismiss="modal">Visualize</button>
               </div>
             </div>
             
            
         </slot>
      </div>
    </div>
    
  </div>    
  </div>
  <GFWEOpenEditor ref='editor' @update='$emit("reload")'></GFWEOpenEditor>
  
  </XModal>
  <ContextMenu ref="xMenu" :offsetY="0" v-show="mnShow">
      <template slot-scope="{ contextData }">
        <ContextMenuLabel>
          MENU {{(contextData.scope=='joiner'?contextData.parent.joiner+" ("+contextData.parent.id+")":"")}}
        </ContextMenuLabel>
        <ContextMenuSep/>
        
        <ContextMenuItem v-if="contextData && contextData.scope=='definition'" @clicked="$refs.xMenu.close(); removeFilter( contextData.line)">
         Remove
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData && contextData.scope=='definition'" @clicked="$refs.xMenu.close(); copyLine( contextData.parent, contextData.filter )">
         Copy
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData && contextData.scope=='joiner'" @clicked="$refs.xMenu.close(); changeJoiner( contextData.parent)">
         Change {{contextData.parent.joiner}}
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData && contextData.scope=='joiner'" @clicked="$refs.xMenu.close(); replaceChildInTree( filter, contextData.parent, null)">
         Remove {{contextData.parent.joiner}} (and all filters)
        </ContextMenuItem>
		<ContextMenuSep/>
		<ContextMenuItem @clicked="mnShow = false; $refs.xMenu.close();">
         Close
        </ContextMenuItem>
        </template>
   </ContextMenu>
   <ProgressBar v-if="showProgressBar" :generalInfo=pbTitle :action=pbAction @action=action></ProgressBar>
  </div>
</template>

<script>
import GLink from '@/components/GLink';
import GSelect from '@/components/GSelect';
import GSelectEntity from '@/components/GSelectEntity';
import GSelectM from '@/components/GSelectM';
import ProgressBar from '@/components/ProgressBar';
import InputInventoryFilter from '@/components/inputElements/InputInventoryFilterNew';
import InputText from '@/components/inputElements/InputText';
import InputWeekdays from '@/components/inputElements/InputWeekdays';
import InputTime from '@/components/inputElements/InputTime2';
import GImage from '@/components/GImage';
import InputSecret from '@/components/inputElements/InputSecret';
import InputTimezone from '@/components/inputElements/InputTimezone';
import InputInt from '@/components/inputElements/InputInt';
import InputDouble from '@/components/inputElements/InputDouble';
import InputDateTime from '@/components/inputElements/InputDateTime';
import InputDatePick from '@/components/inputElements/InputDatePick';
import InventoryPanel from '@/components/GFWEditorInventoryPanel';
import Switch from '@/components/Switch';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';
import ContextMenuLabel from '@/components/ContextMenuLabel';
import {HTTP, fwAPI, invAPI, inventoryInlineAPI, showError } from '@/variables.js'
import { getAppStyle, setDarkMode, initAppMode, getBGColor } from '@/AppStyle.js';
import { printTime2 } from '@/basicTimeFN.js';
import { Tabs, TabPane } from 'vue-bulma-tabs'
var timers = [];
export default {
  name: "GFWEditorInventory",
   components : {
    //InputDateTime,
    InputInventoryFilter,ContextMenu,ContextMenuLabel,
    GSelectEntity, ContextMenuItem, ContextMenuSep,
    ProgressBar
    // ,InventoryPanel
  },
  props: {
  
    dataId: { type: Number, default: 0 },
    path: { type: Array, default: () => [] },
    
    display: Boolean
  },
  data(){
    return {
        success: false,
        dataRecord: {},
        origData: {},
        origRecord: {},
        origFilter: {},
        selectedTab: 0,
        invColumnName: "filter",
        filter: {},
        graphData: {},
        aFilter: null,
        aFilterEvent: 0,
        graphDirection: "vertical",
        treeConfig: { nodeWidth: 140, nodeHeight: 8, levelHeight: 70, levelWidth: 40 },
        treeConfigV: { nodeWidth: 140, nodeHeight: 8, levelHeight: 70, levelWidth: 40 },
        treeConfigH: { nodeWidth: 100, nodeHeight: 100, levelHeight: 70, levelWidth: 40 },
        index: this.selectedIndex,
        creatables: [],
        asText: "",
        mnShow: false,
        showAsText: [],
        infoTime: 0,
        showThisEditor: false,
        data: {...this.dataRecord},
        visualMapping: Array,
        visualIndex: 0,
        numberOfRows: 0,
        lastUser: {},
        width: 90,
        height: 80,
        mainHeight: 71,
        allDetailIDs: [],
        selectedDetail: {},
        metadataDetail: {},
        showEditorDetail: false,
        msgClass: "msgDefault",
        allOperators: [],
        invFilterArray: [],
        invFilterArrayMatched: [],
        invFilterArrayDisabled: [],
        activeFilter: [],
        forceUpdate: 0,
        forceOpenAlways: false,
        opArray: [],
        message: "",
        errorPU: {},
        errorTypePU: "",
        updateCount: 0,
		currentDataRecord: this.data,
        currentParentId: 0,
        currentParentMeta: {},
        loaded: false,
        callback: null,
        myId: Math.round(Math.random()*100000000),
         // progBar
        showProgressBar: false,
        pbAction: null, 
        pbTitle: null, 
        getAppStyle, getBGColor
     }
    },
  methods: {
	    getInvEditorId() {
	    	return 'INVEDIT'+this.myId; 
	    },
	    contextMenu(event, scope, parent) {
	    	event.preventDefault();
	    	this.mnShow = true;
	    	this.$refs.xMenu.open( event,{scope: scope, parent: parent})
	    },
	    contextmenuFTree(event, line, parent) {
	    	event.preventDefault();
	    	this.mnShow = true;
	    	this.$refs.xMenu.open( event, {scope: 'definition', parent: parent, line:line})
	    },
	    clickJoiner( event, tree)
	    {
	    	this.changeJoiner( tree)
	    },
	    contextmenuJoiner(event, parent) {
	    	event.preventDefault();
	    	this.mnShow = true;
	    	this.$refs.xMenu.open( event,{scope: 'joiner', parent: parent})
	    },
	    dragStartFilter(evt, filter)
	    {
	    	evt.dataTransfer.dropEffect = 'move'
	        evt.dataTransfer.effectAllowed = 'move'
	        evt.dataTransfer.setData('iRow', filter.id)
	    },
	    dragStartJoiner(evt, joiner)
	    {
	    	evt.dataTransfer.dropEffect = 'move'
	        evt.dataTransfer.effectAllowed = 'move'
	        evt.dataTransfer.setData('JOINER', joiner)
	    },
	    dragEndFilter(evt, filter, iRow)
	    {
	    },
	    addNewFilter( parent) 
	    {
	    	this.$refs.xMenu.close();
	    	let id = this.maxId( this.filter)+1;
	    	parent.atomicLines.push({ "operator": { }, "type": { }, "positive": true, "struct": { "value": null, "values": [], "labels": [], "from": null, "to": null }, "id": id, "value": ""})
	    },
	    dropFilter(evt, parent)
	    {
	    	let typeId = evt.dataTransfer.getData('iRow');
	    	
	    },
	    closeEdit( filter)
	    {
	    	if ( !filter)
	    	{
	    		this.aFilter = null; //this.visualizeGraph(this.filterEntities).then(p=>{})
	    		return;
	    	}
	    	else
	    	{
	    		
	    		this.filter = this.replaceInTree( this.filter, filter.id, filter)
	    		
				this.visualizeGraph({...this.filter})
		    	
	    	}
	    },
	    findLast( tree)
	    {
	    	let node = tree;
	    	if ( tree.children )
	    	{
	    		for ( let c in tree.children )
	    		{
	    			node = this.findLast( tree.children[c]);
	    		}
	    	}
	    	return node;
	    },
	    findInTree( tree, id)
	    {
	    	if ( tree.atomicLines && tree.atomicLines.length )
	    	{
	    		let found = tree.atomicLines.find(p=>p.id===id);
	    		return found;
	    	}
	    	else if ( tree.children )
	    	{
	    		for ( let c in tree.children )
	    		{
	    			let node = this.findInTree( tree.children[c], id);
	    			if ( node )
	    			{
	    				return node;
	    			}
	    		}
	    	}
	    	return null;
	    },
	    printAllIds( tree )
	    {
	    	let ids = [];
	    	if ( tree.atomicLines && tree.atomicLines.length )
	    	{
	    		let found = tree.atomicLines.map( p=>p.id);
	    		return found;
	    	}
	    	else if ( tree.children )
	    	{
	    		for ( let c in tree.children )
	    		{
	    			ids = ids.concat(this.printAllIds( tree.children[c]));
	    			
	    		}
	    	}
	    	return ids;
	    },
	    maxId( tree)
	    {
	    	if ( tree.atomicLines && tree.atomicLines.length )
	    	{
	    		let ids = tree.atomicLines.map(p=>p.id);
	    		return Math.max(...ids)
	    	}
	    	else if ( tree.children )
	    	{
	    		let max=0;
	    		for ( let c in tree.children )
	    		{
	    			let tmp = this.maxId( tree.children[c]);
	    			
	    			max = Math.max( tmp, max);
	    		}
	    		return max;
	    	}
	    	return 0;
	    },
	    maxTreeId( tree)
	    {
	    	if ( !tree )
	    	{
	    		return 0;
	    	}
	    	let max = tree.id;
			if ( tree.children )
	    	{
	    		for ( let c in tree.children )
	    		{
	    			let tmp = this.maxTreeId( tree.children[c]);
	    			
	    			max = Math.max( tmp, max);
	    		}
	    		return max;
	    	}
	    	return max;
	    },
	    replaceInTree( tree, id, replace)
	    {
	    	if ( tree.atomicLines && tree.atomicLines.length )
	    	{
	    		let found = tree.atomicLines.find(p=>p.id===id);
	    		
	    		if ( found && replace )
	    		{
	    			tree.atomicLines = tree.atomicLines.map(p =>((p.id===id) ? replace: p));
	    			return tree;
	    		}
	    		if ( found && !replace )
	    		{
	    			tree.atomicLines.splice(tree.atomicLines.indexOf( found), 1);
	    			return tree;
	    		}
	    		return null;
	    	}
	    	else if ( tree.children )
	    	{
	    		for ( let c in tree.children )
	    		{
	    			let node = this.replaceInTree( tree.children[c], id, replace);
	    			if ( node
	    			     && (!node.children || node.children.length===0) 
	    			     && (!node.atomicLines || node.atomicLines.length===0) )
	    			{
	    				tree.children.splice( c, 1);
	    			}
	    		}
	    	}
	    	return tree;
	    },
	    changeJoiner( parent)
	    {
	    	let node = this.findChildInTree( this.filter, parent);
	    	//alert( JSON.stringify( node))
	    	if ( node == this.filter )
	    	{
	    		this.filter.joiner = parent.joiner == 'AND' ? 'OR' : 'AND';
	    		
	    	}
	    	else
	    	{
	    		//alert( JSON.stringify( node))
	    		node.joiner = node.joiner == 'AND' ? 'OR' : 'AND';
	    		//alert( JSON.stringify( node))
	    	}
	    	
	    	this.$nextTick(function () {
	    		this.forceUpdate++;
	    		this.$forceUpdate();
	    	});
	    },
	    replaceChildInTree( tree, child, replace)
	    {
	    	
	    	if ( tree.id == child.id )
	    	{
	    		this.clearFilter();
	    		return;
	    	}
	    	if ( tree.children )
	    	{
	    		for ( let c in tree.children )
	    		{
	    			let node = null;
	    			if ( tree.children[c].id == child.id  )
	    			{
	    				node = tree.children[c];
	    				tree.children.splice( c, 1);
	    			}
	    			if ( !node )
	    			{
	    				node = this.replaceChildInTree( tree.children[c], child, replace);
	    			}
	    			
	    			return node;
	    		}
	    	}
	    	return tree;
	    },
	    findChildInTree( tree, child)
	    {
	    	if ( tree.id == child.id )
	    	{
	    		return tree;
	    	}
	    	if ( tree.children )
	    	{
	    		for ( let c in tree.children )
	    		{
	    			let node = this.findChildInTree( tree.children[c], child);
	    			if ( node )
	    			{
	    				return node;
	    			}
	    		}
	    	}
	    	return null;
	    },
	    addAnd()
	    {
	    	let copy = null;
	    	if ( this.filter.children || this.filter.atomicLines )
	    	{
	    		copy = {...this.filter} ;
	    	}
	    	if ( !this.filter.joiner )
	    	{
	    		this.filter.joiner = 'AND'
	    		this.filter.value='AND'
	    		this.filter.children=[];
	    		this.filter.atomicLines=[];
	    	}
	    	else
	    	{
	    		let id = this.maxTreeId()+1;
	    		if ( id <= 0)
	    		{
	    			id = 1;
	    		}
	    		this.filter = {joiner:'AND', value:'AND', id: id, children: [ copy ], atomicLines: []};
	    	}
	    	this.forceUpdate++;

	    },
	    addOr()
	    {
	    	let copy = null;
	    	if ( this.filter.children || this.filter.atomicLines )
	    	{
	    		copy = {...this.filter} ;
	    	}
	    	if ( !this.filter.joiner )
	    	{
	    		this.filter.joiner = 'OR'
	    		this.filter.value='OR'
	    		this.filter.children=[];
	    		this.filter.atomicLines=[];
	    	}
	    	else
	    	{
	    		let id = this.maxTreeId()+1;
	    		if ( ! id > 0)
	    		{
	    			id = 1;
	    		}
	    		this.filter = {joiner:'OR', value:'OR', id: id, children: [ copy ], atomicLines: []};
	    	}
	    	this.forceUpdate++;
	    },
	    openEditor(filter)
	    {
			//alert(JSON.stringify( filter))
	    	if ( this.aFilter )
	    	{
	    		this.aFilter = null;
	    		this.$nextTick(function () {
	    			
	    			let x = this.findInTree( this.filter, filter.id)
	    			
			    	this.aFilter = JSON.parse( JSON.stringify( x)); // deep copy
			    	this.aFilterEvent++;
		    		});
	    		//this.visualizeGraph(this.filterEntities).then(p=>{
	    			
	    		//})
		    	
	    		//
	    	}
	    	else
	    	{
	    		//alert( filter.id );
	    		//alert( JSON.stringify( this.printAllIds( this.filter)) );
		    	let x = this.findInTree( this.filter, filter.id)
				
		    	this.aFilter = JSON.parse( JSON.stringify( x)); // deep copy
		    	this.aFilterEvent++;
	    	}
	    },
	    changeGraphDirection() {
	    	let tmp = {...this.graphData}
	    	this.graphData = {}
	    	if ( this.graphDirection=='horizontal')
	    	{
	    		this.graphDirection='vertical';
	    		this.treeConfig = this.treeConfigV;
	    	}
	    	else
	    	{
	    		this.graphDirection='horizontal';
	    		this.treeConfig = this.treeConfigH;
	    	}
	    	
	    	
	    	this.$forceUpdate();
	    	this.$nextTick(function () {
	    		this.graphData = tmp;
	    		});
	    	
	    },
        name() { return "FWViewer"+this.zIndex; },
        printTime2( val) { return printTime2(val);},
        tooltipFilterAdd(txt) { return "add filter: " + txt; },
        tooltipFilterAdded(txt) { return "filter: " + txt + " already used"; },
        close( reset) 
        { 
        	this.selectedTab = 0; 
        	if ( reset )
        	{
        		this.$emit('inventory', this.origRecord);
        	}
        	this.$modal.hide(this.getInvEditorId()); 
        	
        	this.$emit('close')
        },
        getContentStyle()  { return "width: "+ this.width+"% !important;height: "+ this.height+"% !important;"; },
        getMainContentStyle()  { return "height: "+ this.mainHeight+"% !important;"; },
        incWidth() { if ( this.width < 90) { this.width *= 1.1; this.height*=1.1;} },
        decWidth() { if ( this.width > 20) { this.width *= 0.9; this.height*=0.9;} },
        maximize() { if ( this.width == 100) { this.width = 70; this.mainHeight=80, this.height=90; } else { this.width=100; this.mainHeight=70, this.height=100; }},
        isString(column) { return column.editor == 'STRING'; },
        isTz(column) { return column.editor == 'SELECT' && column.visualDetail == 'TZ' && !column.array; },
        isLink(column) { return column.editor == 'LINK'; },
        isSelect(column) { return column.editor == 'SELECT' && column.visualDetail != 'TZ' && !column.array; },
        isMSelect(column) { return column.editor == 'SELECT' && column.visualDetail != 'TZ' && column.array; },
        isInt(column) { return column.editor == 'INT'; },
        isDouble(column) { return column.editor == 'DECIMAL'; },
        isSecret(column) { return column.editor == 'SECRET'; },
        isBool(column) { return column.editor == 'BOOLEAN'; },
        isTime(column) { return column.editor == 'TIME'; },
        isWeekday(column) { return column.editor == 'WEEKDAYS'; },
        isDate(column) { return column.editor == 'DATE'; },
        isDatetime(column) { return column.editor == 'DATETIME'; },
        isImg(column) { return column.editor == 'IMAGE';},

        copyLine( parent, filter )
        {
        	//alert( JSON.stringify( parent))
        	let copy = JSON.parse( JSON.stringify(filter));
        	copy.id = this.maxId( this.filter)+1;
        	let addTo = parent;
        	if ( parent.children  )
       		{
        		let id = this.maxTreeId()+1;
	    		if ( ! id > 0)
	    		{
	    			id = 1;
	    		}
       			let newKid = { "joiner": null, "value": null, "title": null, id: id, "blacklist": false, "children": [], "atomicLines": [] }
       			parent.children.push( newKid );
       			addTo = newKid;
       		}
        	addTo.atomicLines.push( copy);
        },
        moveFilterToParent(filter, parent, from)
        {
        	let node = this.findInTree( this.filter, parseInt(filter));
        	
        	if ( from )
            {
        		this.removeFilter( node);
            }
        	let copy = JSON.parse( JSON.stringify(node));
        	copy.id = this.maxId( this.filter)+1;
        	let addTo = parent;
        	//console.log( JSON.stringify( this.node))
        	
        	if ( parent.children  )
       		{
        		let id = this.maxTreeId()+1;
	    		if ( id <= 0)
	    		{
	    			id = 1;
	    		}
       			let newKid = { "joiner": null, "value": null, "title": null, id: id, "blacklist": false, "children": [], "atomicLines": [] }
       			parent.children.push( newKid );
       			addTo = newKid;
       		}
        	addTo.atomicLines.push( copy);
        	//alert( JSON.stringify( addTo))
        },
        addFilterToParent(filter, parent)
        {
        	//alert( JSON.stringify( this.filter))
        	console.log(JSON.stringify( this.filter) )
        	console.log("===================================================" )
        	//alert( JSON.stringify( parent))
        	console.log(JSON.stringify( parent) )
        	if ( (typeof filter === 'string' || filter instanceof String))
        	{
        		if ( !parent.children)
        		{
        			parent.children = [];
        		}
        		let id = this.maxTreeId()+1;
	    		if ( ! id > 0)
	    		{
	    			id = 1;
	    		}
        		parent.children.push( {joiner:filter, value:filter, id: id, children: [], atomicLines: []})
        		
        		return;
        	}
        	
       		var opId = 0;
       		var operator = {};
       		if ( !filter.entityName )
       		{
       			filter = this.invFilterArray.find( p=>p.id == filter.id)
       		}
       		let filterType = { name: filter.name, path: filter.path, type: filter.typeValue, entity: filter.entityName, id: filter.id};
       		
       		if ( filter.operators.length == 1 )
       		{
       		    opId = parseInt(filter.operators[0]);
       			operator = this.allOperators.find( op => op.id === opId);
       		}
       		else
       		{
       			operator = this.allOperators.find( op => filter.operators.includes(op.id));
       			opId = operator.id;
       		}
       		let id = this.maxId( this.filter)+1;
       		if (id <= 0)
       		{
       			id = 1;
       		}
       		operator = {id: operator.id, name: operator.name, type: operator.typeValue, operands: operator.operandsValue}
       		let filterNEW = { id: id, 
       				          type: filterType, operator: operator, positive: true, 
       				          struct: { values: [], labels: []} } 
       		if ( filter.typeValue=='Date')
       		{
       			
       			filterNEW.struct = { from: new Date().toLocaleDateString('en-CA'), to: new Date().toLocaleDateString('en-CA')}
       		}
       		let addTo = parent;
       		//alert( JSON.stringify( parent.children))
       		//alert( JSON.stringify( filterNEW))
       		if ( parent.children )
       		{
       			let treeId = this.maxTreeId()+1;
	    		if ( ! treeId > 0)
	    		{
	    			treeId = 1;
	    		}
       			let newKid = { "joiner": null, "value": null, "title": null, id: treeId, "blacklist": false, "children": [], "atomicLines": [] }
       			parent.children.push( newKid );
       			addTo = newKid;
       		}
       		
       		if ( !addTo.atomicLines )
       		{
       			addTo.atomicLines=[];
       		}
       		addTo.atomicLines.push( filterNEW );
       		//this.filter = {...this.filter}
       		this.forceUpdate++;
        },
       
        clearFilter()
        {
        	this.filter = { "joiner": null, "value": null, "title": "That no restriction.", "blacklist": false, "children": null, "atomicLines": null, "id": null };
        },
        removeFilter( line)
        {
        	this.$refs.xMenu.close(); 
            this.replaceInTree( this.filter, line.id, null);
        	
        },
        containsAll( filterKind, inventoryKind) 
        {
     	    // converting into Set
		    const filterSet = new Set(filterKind);
		    const inventorySet = new Set(inventoryKind);

		    for (let i of inventorySet) {
		    
		        if (! filterSet.has(i)) {
		            return false
		        }
		        
			}
    
    		return true;
    	},
        checkFilterArray( invFilterArray, mediaKind) 
        {
            var invFilterArrayMatched = [];
            if ( !mediaKind)
            {
            	invFilterArrayMatched = [...invFilterArray]
            	invFilterArrayMatched.sort( function(a,b) {
            		if (a.name > b.name ) return 1;
            		if (a.name < b.name ) return -1;
            		return 0;
            	});
            	//alert(invFilterArrayMatched.length)
            	return invFilterArrayMatched;
            }
            
        	for ( var idx in invFilterArray )
        	{
        		let filter = invFilterArray[ idx];
        		
        		//alert( JSON.stringify(filter.mediaKindId ) +"/"+mediaKindId+"/"+this.containsAll(filter.mediaKindId, mediaKind));
        		if ( this.containsAll(filter.mediaKindId, mediaKind) )
        		{
        			invFilterArrayMatched.push( filter);
        		}
        	}
        	
        	invFilterArrayMatched.sort( function(a,b) {
        		if (a.name > b.name ) return 1;
        		if (a.name < b.name ) return -1;
        		return 0;
        	});
        	return invFilterArrayMatched;
        },
        mediaKindChanged( mediaKindArr )
        { 
        	if ( mediaKindArr )
        	{
	        	let mediaKind = mediaKindArr.map(p=>p.id);
	        	if ( this.data.mediaKindId && !mediaKind.every((val, idx) => val === this.data.mediaKindId[idx]))
	        	{
		        	this.data.mediaKindId = mediaKind;
		            
		        	if ( mediaKind  )
		        	{
		        		this.invFilterArrayMatched = this.checkFilterArray( this.invFilterArray, mediaKind);
		        	}
	        	}
        	}
        },
        error( txt, err)
	    {
	      if ( err && err.response && err.response.data )
	      {
	      	this.printMsg( err.response.status, txt+ ": " +err.response.data);	
	      }
	      else 
	      {	
	      	this.errorTypePU=txt;
	      	if ( ! err )
	      	{
	        	this.errorPU = {'message': ""};
	      	}
	      	else
	      	{
	      		this.errorPU = err;
	      	}
	      }
	    },
        getDisplay(col) 
        { 
            if (this.visible(col)) 
            {
                let column = this.findColumn(col);
            	return column.display; 
            }
            
            return "";
        },
        visible(column) { return column && column != null && column != 'null';},

        incZIndex() { return parseInt(this.zIndex)+1; },
        clearMsg()
        {
        	this.message = "";
        },
        printMsg(status, msg)
        {
        	if ( parseInt(status) != 200 )
        	{
        		this.msgClass = "msgError";
        	}
        	else
        	{
        		this.msgClass = "msgDefault";
        	}
        	timers.push( setTimeout(this.hideSuccess, 4000));
        	this.message = msg;
        },
        findColumn(column) { 
           //console.log( column );
           var colObject=this.metadata.columns.find(p => p.column === column); 
           //console.log( colObject );
           if ( colObject ) { 
              return colObject; 
           } 
           else { 
              colObject=this.metadata.links.find(p => p.name === column); 
              if ( colObject ) { 
              	return colObject; 
              } 
              else {
                return {};
              }
           }
        },
        addPath()
        {
        	var p = JSON.parse(JSON.stringify(this.path));
        	p.push({name: this.data.visualDisplay, entity: this.metadata.name, position: p.length});
        	return p;
        },
        action( what, myId, api)
        {
        	console.log(what+"/"+ myId+"/"+api)
       		this[what](myId, api)
        },
        findInventory()
        {
        	this.pbAction = "findInventoryInternal";
        	this.pbTitle = "find inventory";
        	this.showProgressBar = true;
        },
        findInventoryInternal(myId, api)
        {	
        	
            let now = new Date().toISOString().split('T')[0];
       	    let data = this.filter;
       	    this.selectedTab = 1;
       	    this.showAsText = null;
       	    api.post( inventoryInlineAPI+"/findInventory/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+now+"/"+now+"/"+sessionStorage.timezoneId+"/"+myId, data)
        		 .then( response => 
                 {  
                	this.showProgressBar = false;
                    this.showAsText = [];
                  
                    this.filter.title = response.data.first.title;
                    for ( var i = 0; i < response.data.second.length; i++ )  
                    { 
                    	this.showAsText.push(response.data.second[i]);
                    }
                    
                    //this.showAsText = JSON.stringify(response.data);
                    //this.infoTime = new Date().getTime();
                    this.asText = this.data.name;
                    this.selectedTab = 1;    
                 }).catch(e => {
                	 this.showProgressBar = false;
                	 showError( this.$toast, "findInventory", e)
                       
                    });
        }, 
        expandFilters()
        {	
        	
       	    let data = this.filter;
       	    HTTP.post( inventoryInlineAPI+"/expand/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, data)
        		 .then( response => 
                 {  
                	 this.filter = response.data;
                     
                 	 this.data.mediaKindId = this.data.mediaKindId ? this.data.mediaKindId : [];
                    
                     this.getInventoryFilters( true);
                        
                 }).catch(e => {
                       showError( this.$toast, "expanding failed", e)
                       
                    });
       	    
        }, 
       
        openLink(id, link, allIds)
        {
        	//console.log( fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+this.metadata.name+"/"+id+"/"+link );
        	this.allDetailIDs = [];
        	if ( allIds )
        	{
				for ( var x in allIds )
				{
					this.allDetailIDs.push({row: x, dataId: allIds[x], rId: allIds[x], visible: true });
				}
			}
			else
			{
				this.allDetailIDs.push({row: 0, dataId:id, rId: id, visible: true });
			}
			this.currentParentId = this.visualMapping[ this.visualIndex ].dataId;
			this.currentParentMeta = this.metadata;
			HTTP.post( fwAPI+"/getLinkedDetail/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+this.metadata.name+"/"+id+"/"+link)
                    .then( response => {
                    this.selectedDetail = response.data.data;
                    this.metadataDetail =  response.data.metadata;
                    this.detailEntityMap = response.data.entity2DataMap;
                    //console.log( "OPEN-"+link+ " " +JSON.stringify( this.detailEntityMap));
					this.showEditorDetail = true;
					//alert( JSON.stringify(this.selectedDetail));
                    //
                }).catch(e => {
                	showError( this.$toast, "get details", e)
                });
        	
        },
        closeUntilPosition(position)
        {
        	//alert( position +"/" + this.path.length);
        	if ( position < this.path.length )
        	{
        		this.close(true);
        	}
        	this.$emit("closeUntilPosition", position)
        },
        closeEditorDetail()
	    {
	        this.showEditorDetail = false;
	    },
        getImgData(val) { return 'data:image/png;base64,' + val; },
        hideSuccess() { this.success = false; this.clearMsg(); clearInterval( timers.pop()); },
        
        folderColumnsIndex(tabColumns) {
            var intArr = [];
            for ( var i=0; i < tabColumns.length; i++ )
            {
                intArr.push(i);
            }
            return intArr;
        },
        // style='display: inline-flex; width: 25%;'
        toolTip( tab, colIdx)
        {
        	 let columnName = tab.columns[colIdx];
             let column = this.findColumn(columnName)
        	 if ( column.tooltip )
        	 {
        	 	return column.tooltip;
        	 }
        	return {};
        },
        getStyle( tab, colIdx) 
        { 
            let column = tab.columns[colIdx];
            let columnNext = colIdx + 1 < tab.columns.length ? tab.columns[colIdx+1]: column;
            if (this.visible(column)) {
                if ( this.visible(columnNext) ) { 
                    return "width: " + Math.round(100.0/tab.cells)+"%;";
                }
                else 
                {
                    //console.log("column " + column+ " " + "width: " + Math.round(200.0/tab.cells)+"%;");
                    return "width: " + Math.round(200.0/tab.cells)+"%;";
                }
            } else {
                return "display: none;";
            } 
        },  
        getLabelStyle(tab, colIdx) 
        { 
            let columnName = tab.columns[colIdx];
            let column = this.findColumn(columnName)
            let columnNext = colIdx + 1 < tab.columns.length ? tab.columns[colIdx+1]: columnName;
            let width = 25.0;
            let unit = "%;";
            
            let fontStyle = (column.tooltip)? "text-decoration: underline;": "";
            if (this.visible(column.column)) {
                if ( this.visible(columnNext) ) 
                { 
                    return fontStyle+"width: " + (width)+unit;
                }
                else 
                {
                    return fontStyle+"width: " + (width/tab.cells)+unit;
                }
            } else {
                return fontStyle+"width: " + (width)+unit;
            } 
        },  
        passUpdate(entity, record) {
            this.updateCount++;
        	this.$emit( "update", entity, record);
        },
        passInsert(entity, record) {
            this.updateCount++;
        	this.$emit( "insert", entity, record);
        },

        check( )
        {
        	//let cat = this.valueStruct.find( p=>p.type.name==='Category');
        	//alert( "CAT> "+ JSON.stringify(cat))
        },
       
        open( filter, data, columnName, forceOpen, callback)
        {
        	//alert("GFII"+JSON.stringify(data)+columnName)
        	this.invColumnName = columnName;
        	this.origData = data;
        	this.dataRecord = {...data};
        	this.callback = callback;
        	this.filter = filter;
        	
        	if ( !this.filter )
        	{
        		this.filter = { };
        		this.dataRecord[ this.invColumnName] = this.filter;
        	}
        	
        	//alert( this.invColumnName + JSON.stringify(this.filter))
        	let that = this;
        	this.readDataRecord().then(p=>{
        		that.selectedTab = 0;
        		if ( forceOpen )
    	       	{
        			//alert( that.getInvEditorId()+" "+that.dataRecord.visualDisplay)
        			that.$set( that, "dataRecord", that.dataRecord )
    	       		that.$modal.show(that.getInvEditorId())
    	       	}
        	})

        },
        getInventoryFilters( dontreload)
        {
        	let that = this;
        	let dataLoad = new Promise(function (resolve, reject) {
	        	//alert("getInventoryFilters>"+dontreload+this.data)
	        	if ( that.data )
	        	{
	        	    //alert("load " + this.data.id);
	        	    //alert( JSON.stringify( this.data))
	        	    let f = that.data[that.invColumnName];
	        	    if ( !f )
	        	    {
	        	    	f = {}
	        	    }
	        	    //alert("colName> "+this.invColumnName+"/"+JSON.stringify(f))
	        	    for ( let fi in f.filter )
	        	    {
	        	    	f.filter[fi].type.name = (f.filter[fi].type.name)?f.filter[fi].type.name:"";
	        	    	f.filter[fi].operator.operands = (f.filter[fi].operator.operands)?f.filter[fi].operator.operands:"one";
	        	    	
	        	    }
	        	    if ( dontreload )
	        	    {
	        	    	let filters = f.filter;
	        	    	//console.log( JSON.stringify(filters))
	                    that.invFilterArrayDisabled = [];
	        	    	
	        	    	that.opArray = []; 
	                  
	        	    	if ( that.data.mediaKindId && that.data.mediaKindId.length )
	                    {
	                    	that.invFilterArrayMatched = that.checkFilterArray( that.invFilterArray, that.data.mediaKindId);
	                    }
	                    else
	                    {
	                    	that.invFilterArrayMatched = that.invFilterArray;
	                    }
	                    
	                   /*
	        			for ( let i in filters )
	        			{
	        				//console.log( i + ">" + JSON.stringify(filters[i]))
	        				filters[i].operator = that.allOperators.find( o=>o.id===filters[i].operator.id)
	        				filters[i].type = that.invFilterArray.find( t=>t.id===filters[i].type.id)
	        				filters[i].typeId = filters[i].type.id;
	        				filters[i].operatorId = filters[i].operator.id;
	        				filters[i].values = filters[i].struct;
							
	        				that.putFilter( filters[i] );
	        			}
	                   */
	        			resolve(that.data)
	        	    }	
	        	    else
	        	    {
	        	    	//that.origRecord = {...that.data}
	        	    	//alert(JSON.stringify(f))
	        	    	
	        	    	delete f.empty;
	        	    	delete f.leaf;
	        	    	//alert( ">"+JSON.stringify( f));
	        	    	//alert("load " + invAPI+"/getInventoryFilters/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+this.data.id);
        		
		        		HTTP.post( inventoryInlineAPI+"/getInventoryFilters/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, f)
		        		 .then( response => 
		                 {     
		                    //alert( ">"+JSON.stringify(response.data.allFilters));
		                    let filters = response.data.resultList;
		                    that.invFilterArrayDisabled = [];
		                    
		                    that.opArray = []; 
		                    let invFilterArray = response.data.allFilters;
		                    let invFilterArrayMatched = that.checkFilterArray( that.invFilterArray, that.data.mediaKindId);
		                    that.allOperators = response.data.allOperators;
		                    
		                    
		        			
		        			that.$set( that, "invFilterArrayMatched", [...invFilterArrayMatched])
		        			that.$set( that, "invFilterArray", [...invFilterArray])
		        			let id = 1;
		        			/*
		        			for ( let i in filters )
		        			{
		        				//console.log( "ORIG>"+JSON.stringify(filters[i]));
		        				filters[i].operator = that.allOperators.find( o=>o.id===filters[i].operatorId)
		        				filters[i].type = that.invFilterArray.find( t=>t.id===filters[i].typeId)
		        				//alert(JSON.stringify(filters[i]))
		        				filters[i].id = id++;
		        			    that.putFilter( filters[i] );
		        			}
		        			*/
		        			that.$forceUpdate();
		        			resolve(that.data)
		        			
		                 }).catch(e => {
		                	    showError( that.$toast, "getInventoryFilters() failed", e)
		                        console.log(e)
		                        reject();
		                    });
	        	    }
	        	}
	        	else
	        	{
	        		that.data.mediaKindId = [];
	        		resolve(that.data)
	        	}
        	});
        	return dataLoad;
        },
        getStruct( struct)
        {
        	//alert( "getStruct> "+ JSON.stringify( struct))
        	return struct.values;
        	
        },
        
        
        clearChildren( children )
        {
        	if ( children)
        	{
	        	let newKids = [];
	        	for ( let x in children)
	        	{
	        		let node = children[x];
	        		let childNode = { value: node.joiner?node.joiner:node.title?node.title:node.value, 
	        				          id: node.id, joiner: node.joiner, 
	        				          children: this.clearChildren( node.children)}
	        		newKids.push( childNode);
	        	}
	        	return newKids;
        	}
        	return children;
        },
        visualizeGraph( filters)
        {
        	let that = this;
        	
        	let dataLoad = new Promise(function (resolve, reject) {
	        	let data = filters;
	        	
	        	HTTP.post( inventoryInlineAPI+"/graph/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, filters )
	            .then( response => 
	            {
	            	 //console.log(">"+JSON.stringify( response.data))
	            	 let graph = response.data.first;
	            	 that.filter = response.data.second;
					 that.forceUpdate++;
					 //alert( JSON.stringify(that.printAllIds( that.filter )))
	            	 graph.children = that.clearChildren( graph.children);
	            	 graph.value = graph.joiner?graph.joiner:graph.name;
	            	 //console.log( JSON.stringify(graph))
	            	 that.graphData = graph;
	            	 that.aFilter = null;	       
	            		 
	            	 that.selectedTab=2;
	            	 resolve( graph);
	                
	            }).catch(e => {
	            	reject();
	                showError(that.$toast, "failed", e)
	            });
        	});
        	return dataLoad;
        	
        },
        update() { 

            let data = this.filter;
           
			//alert( JSON.stringify(data)) 
        	HTTP.post( inventoryInlineAPI+"/read/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, data )
            .then( response => 
            {
            	 //alert( JSON.stringify(response.data)) 
                 this.data[this.invColumnName] = response.data;
            	 this.filter = response.data;
            	 this.forceUpdate++;
                 //alert( JSON.stringify( this.printAllIds( response.data)));
                 //console.log( JSON.stringify( response.data))
                 this.$emit("inventory", this.data, this.invColumnName)
                 if ( this.callback )
                 {
                	 this.callback( this.data, this.origData, this.invColumnName);
                 }
                 //this.getInventoryFilters();
                 this.$toast.success("check: ok, please don't forgat to save the record", 'OK', { timeout: 1500, position: "topRight" });
                 
                
            }).catch(e => {
                showError(this.$toast, "failed", e)
            });
        
            
        },
        getUser(id)
        {
            this.lastUser = {};
            HTTP.get( fwAPI+"/findUser/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+id)
                    .then( response => 
                    {
                         this.lastUser = response.data;
                        
                    }).catch(e => {
                    	showError( this.$toast, "get user", e)
                    });
        },
        selectTab(tab)
        {
        	var firstTab = (tab === this.metadata.folder[0]);
        	//console.log("selectTab() --> " + firstTab+ " :"+  JSON.stringify(tab));
        	return firstTab;
        },
        tabChange(index)
        {
        	this.selectedTab = index;
        	//console.log("tabChange() --> " +index);
        },
        reload(id)
        {
        	this.data = {...this.origRecord}
        	this.dataRecord = {...this.origRecord}
        	this.filter = JSON.parse( JSON.stringify( this.origFilter))
        	this.readDataRecord();
        },
        readDataRecord()
        {
        	//this.dataRecord.filter.title = this.dataRecord.filter.title?this.dataRecord.filter.title:"XXX";
        	//this.data = {...this.dataRecord}
        	let that = this;
        	let dataLoad = new Promise(function (resolve, reject) {
        		that.data.mediaKindId = that.data.mediaKindId ? that.data.mediaKindId : [];
        		that.$set( that, "data", {...that.dataRecord})
	        	that.$set( that, "origRecord", JSON.parse( JSON.stringify( that.dataRecord)))
	        	that.origFilter = JSON.parse( JSON.stringify( that.filter))
	        	console.log( JSON.stringify( that.filter))
	        	
	        	that.invFilterArrayDisabled = [];
        		that.activeFilter = [];
	    		//alert( JSON.stringify( this.data))
	            that.getInventoryFilters(false).then(p=>{
	            	resolve(that.data);
	            	
	            })
        	});
        	return dataLoad;
        	
      },
      getOnOff(data) {
      	if ( !data.blacklist )
      	{
      		return "FILTER";
      	}
      	else
      	{
      		return "BLACKLIST"
      	}
      },
      getOnOffStyle(data) {
      	if ( !data.blacklist)
      	{
      		return "button on"
      	}
      	else
      	{
      		return "button off"
      	}
      },
      setBlacklistOnOff() {
        //this.value.positive = !this.value.positive;
        //alert( JSON.stringify( this.filter))
        this.filter.blacklist = !this.filter.blacklist;
      }
  },
   updated() {
     if ( !this.loaded )
     {
     	this.loaded = true;
     	this.reload(this.dataId);
     }
   
  },
  computed: {
	msgDisplayClass: function () { return this.msgClass; }
  },
  
  created() {
	  this.mnShow = false;
	 
  },
   watch:
   {
   	    
   }
}
</script>
<style  scoped>
.editorElement {
     display: inline-flex; 
     padding-bottom: 8pt;
     padding-top: 2pt;
     padding-right: 8pt;
 }
.elementLabel {
    display: inline-flex;
 }
.required:after {
    content:" *";
    color: red;
 }
.elementField {
    display: inline-flex;
     //border: 1pt solid yellow;
     width: 100%;
 }
.lUpdateInfo {
    font-size: 11pt;
    color: #888;
}
.lUpdateInfoSuccess {
    vertical-align: middle;
    
}
.mainContent {
    height: 100%;
    transition: all 0.4s ease;
    //border: 1pt solid blue;
}
.appHeadLine { 
    width: 100%; 
    border-bottom: 1px solid #fff; 
    text-align: right;
    margin-bottom: 2pt; 
    font-weight: bold;
    //padding: 10pt 10pt 1pt;}
    
.appCounter {
	display: inline-grid;
	font-size: 11pt;
	color: #888;
}    
.appLeft {
	display:  inline-block; 
	text-align: left;
	vertical-align: top;
	width: 70%; 
	//border: 1px solid grey;
} 
.appRight {
	display:  inline-block; 
	text-align: right;
	vertical-align: top;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
	width: 30%; 
	//border: 1px solid grey;
}
.appHead { 
    width: 100%;  
    text-align: right;
    background-color: #cca;
    padding: 0pt 0pt 0pt;}
   
.appHeadLeft { 
    width: 50%;  
    text-align: left;
    float: left;
    height: 22pt;
    color: #444;
    font-weight: bold;
    background-color: #cca;
    padding: 4pt 2pt 0pt;}
.appHeadRight { 
    width: 50%;  
    text-align: right;
    float: right;
    height: 22pt;
    background-color: #cca;
    padding: 0pt 0pt 0pt;}
    
.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  //height: 60%;
  width: calc(100vH) !important;
  height: calc(100vH) !important;
  margin: 0px auto;
  padding-left: 0pt;
  padding-bottom: 20pt;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.4s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 0px 0;
  padding: 1pt;
  //border: 1pt solid blue;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.btn-nav {
    font-size: 9pt;
    vertical-align: middle;
    valign: middle;
}
.btn-save {
	background-color: #ded !important;
	color: #000 !important;
	font-weight: bold;
}
.iButton {
    background-color: transparent;
    border-color: #dbdbdb;
    border-width: 0px;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding-bottom: 2pt;;
    padding-left: 2pt;
    padding-right: 2pt;
    padding-top: 4pt;
    height: 20pt;
    text-align: center;
    white-space: nowrap;
}
.msgDefault {
	font-size: 9pt;
}
.msgError {
	font-size: 9pt;
	font-weight: bold;
	color: #bb0000;
}
.on { background-color: #ded; font-size: 7pt; width: 8em;}
.off { background-color: #000; color: #fcc; font-weight: bold; font-size: 7pt; width: 8em;}
.button {font-size: 10pt;}
.button:hover { background-color: #888; color: #fff; }

.popup {
  z-index: 999999;
  border: 3pt inset #cca;
  box-shadow: 50pt 50pt 800pt #000;
  width: 80% !important;
  height: 64% !important;
  padding: 0;
  margin: 0;
  position: absolute !important;
  top: calc(15vH) !important;
  left: calc(30vH) !important;
  z-index: 10;
}
.tHeader {
	font-weight: normal;
	margin-bottom: -0pt !important;
	//display: inline-flex;
    justify-content: center;
    //border-radius: 6px;
    align-items: center;
    font-size: 10pt;
    //background-color: #f0714a;
    color: rgb(0, 0, 0);
    //box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.33);
}
.tDetail {
	font-weight: normal;
	margin-bottom: -0pt !important;
	//display: inline-flex;
    justify-content: center;
    //border-radius: 6px;
    align-items: center;
    font-size: 10pt;
    cursor: pointer;
    //color: rgb(0, 0, 0);
    //box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.33);
}
.dt {
  display: block;
 
  color: #fff;
  font: bold 14px/18px Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 2px 0 0 12px;
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: top;
}

.rich-media-node {
  width: 120px;
  padding: 2px;
  display: flex;
  font-size: 8pt;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #000;
  background-color: #f7f6a6;
  border-radius: 4px;
}
.AND-node {
  width: 60px;
  padding: 2px;
  display: flex;
  font-size: 10pt;
  font-weight: bold !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  
  border-radius: 10px;
}
.breadcrumbItem {
    float: left;
    cursor: pointer;
    //position: relative;
	border: 1px solid #ccc;
	border-radius: 7px 7px 0 0;
	margin-top: 3pt;
	margin-right: 0pt;
	padding: 12px;
	padding-top: 3px;
	background-color: #111;
	font-weight: normal;
	color: #fff;
	height: 21pt;
	font-size: 12px;
	box-shadow: 0px 0px 0px 0 #aaa;

}
.breadcrumbItemCurrent {
    float: left;
    //position: relative;
	//padding-bottom: 5pt;
	border: 1px solid #ccc;
	border-radius: 7px 7px 0 0;
	margin-top: 3pt;
	margin-right: 0pt;
	padding: 12px;
	padding-top: 3px;
	background-color: #aaa !important;
	font-weight: normal;
	color: #fff !important;
	height: 21pt;
	font-size: 12px;
	box-shadow: 4px 0px 5px 0 #aaa;

}
.breadcrumbItem:hover {
    background-color: #ada !important;
    color: #000 !important;
}
.OR-node {
  width: 60px;
  padding: 2px;
  display: flex;
  font-size: 10pt;
  font-weight: bold !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #000;
  
  border-radius: 10px;
}
.OR-color {	
  background-color: #16c616 !important;
  color: #000 !important;
}
.AND-color {
  background-color: #ff1616 !important;
  color: #000 !important;  
}
.JOINER {
  border-radius: 10px;
  margin-bottom: 8pt;
}
.typeButton {
	font-size: 8pt;
	width:50%;
	padding-top:1pt; 
	padding-botton: 1pt;
}
.typeButtonSmall {
    margin-left: 10%;
    margin-right: 10%;
	font-size: 8pt;
	width:30%;
	padding-top:1pt; 
	padding-botton: 1pt;
}
.typeButton:hover {
	background-color: #ccc;
	color: #00;
}
.keepOpenBn {
	font-size: 9pt;
	float: right;
	margin-right: 5pt;
}
.active {
	background-color: #fff;
}
</style>