 <template>
  <div 
  class="modal fade modal-mask"
    :style=getModalStyle()
    id="fwEditModal"
    tabindex="1"
    :z-index="zIndex"
    role="dialog"
    aria-labelledby="fwEditModal"
    aria-hidden="true"
  >
   <div class="modal-wrapper">
     <div class="modal-container" :style="getAppStyle()+getContentStyle()+'border: 2pt inset grey;'" @contextmenu.prevent="none()">
         <div class='appHead'>
             <div style='display: inline-flex; color: #888; font-weight: bold; padding-right: 20pt; font-size: 10pt;'> </div>
             
              <button id='closePopup2' type="button" class="iButton" @click='maximize()' aria-label="Maximize">
              <span aria-hidden="true">
              <svg v-show='width != 100' xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-fullscreen" viewBox="0 0 16 16">
				  <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z"/>
			  </svg>
              <svg v-show='width == 100' xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-fullscreen-exit" viewBox="0 0 16 16">
				  <path d="M5.5 0a.5.5 0 0 1 .5.5v4A1.5 1.5 0 0 1 4.5 6h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5zm5 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 10 4.5v-4a.5.5 0 0 1 .5-.5zM0 10.5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 6 11.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zm10 1a1.5 1.5 0 0 1 1.5-1.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4z"/>
			   </svg>
              </span>
              
            </button>
            <button id='closePopup2' type="button" class="iButton" @click='closeUntilPosition(-1)' data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16">
				  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
				  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
				</svg>
			  </span>
            </button>
            </div>
       <div class="modal-header">
          <slot name="header">
            <div class='appHeadLine'>
             
            	<div class='appLeft'>
            	  
            	  <div style='float: left' >
                  <a v-for="(p,idx) in path" :key="idx" @click=closeUntilPosition(idx) class='breadcrumbItem'>{{p.name}}</a>
            	  <div class='breadcrumbItemCurrent' :style="getAppStyle()" v-if="dataRecord && dataRecord.id">
            	  Editor for Copy-Rotation {{dataRecord.name}}
            	  </div>
            	  <div class='breadcrumbItemCurrent' :style="getAppStyle()" v-else-if="parentRecord">
            	  Create Copy-Rotation for {{parentRecord.name}}
            	  </div>
            	  
                </div>
            	</div>
                <div class='appRight'>
                  {{(metadata && metadata.menuTitle)?metadata.menuTitle:metadata.name}}
            	  <span class='appCounter'>{{visualIndex+1}}/{{numberOfRows}}</span>
                </div>
                
                </div>
            
           </slot>
        </div>
        <div class="modal-body" :style="getMainContentStyle()">
          <slot name="body">
            <div class='mainContent' >
                <table style='margin-bottom: 10pt;' border=0 width="95%">
                <tr>
                <td class='header' :style="getDefaultColorStyle()">Name</td><td :colspan="2"> <InputText v-model="dataRecord.name"/></td>
                <td class='header' :style="getDefaultColorStyle()">Duration</td><td :colspan="1"> <InputInt :disabled="dataRecord.ignoreDuration" v-model="duration"/></td>
                <td class='header' title='copy can be assigned, even if spot duration is different' 
                                   :style="getDefaultColorStyle()">Ignore Duration 
                </td>
                <td class='header' :style="getDefaultColorStyle()">
                <app-switch v-model="dataRecord.ignoreDuration" @changed="duration=0; $forceUpdate()" 
                     :checked="dataRecord.ignoreDuration"/>
                </td>
                </tr>
                <tr>
                
                <td class='header' :style="getDefaultColorStyle()">From-To</td><td> <InputDatePick :auto="false" v-model="dataRecord.fromDate"/></td>
                <td> <InputDatePick :auto="false" v-model="dataRecord.untilDate"/></td>
                <td class='header' :style="getDefaultColorStyle()">Distribution</td>
                <td :colspan="3"> 
                   <div :class="dataRecord.distribution===di?'radioSelected':'radioSelect'" 
                        @click="dataRecord.distribution=di; $forceUpdate()"
                        style='padding-left: 5pt; display: inline-flex;' v-for="(dis, di) in metadata.columns.find(p=>p.column=='distribution').values" :key="'d'+di">
                   	{{ dis }}
                   </div>
                </td>
                </tr>
                </table>
          
	            <div style='float: left;  width: 35%; height: 100%; border: .5px solid #cce;'>
	                
	                <div style='font-size: 9pt; font-weight: bold;'>Available copies</div>
	                <div style='font-size: 9pt; font-weight: bold;'>
	                    <span style='width: 30%; padding-right: 5pt;'>Search:</span> 
	                    <input :style="getAppStyle()+'width: 70%; border: 1pt inset #aaa;'" :placeholder="'search for material...'"
	                    v-model="searchMaterial"/></div>
	                <div style='width: 100%; height: calc(53vH); overflow-y: scroll;'>
		            	<div class="copy2Select" v-for="(c,ci) in materialData.data.filter(p=>JSON.stringify([p.name,p.houseNumber,p.shortname,p.duration,p.received]).toLowerCase().includes(searchMaterial.toLowerCase())).filter(p=>dataRecord.ignoreDuration || !duration || p.duration==duration)" :key="'c'+ci">
			            	<div :class="isDisabled(c)?'disabled':''">
				            	<div style='font-family: monospace; font-size: 10pt; float: left; overflow-x: hidden; text-overflow: ellipsis; white-space: nowrap; width: 90% !important;' 
				            	    @click="!isDisabled(c)?select(c):none()">
				            	{{c.houseNumber}}  {{c.received}} {{c.duration}} {{c.name}} {{c.shortname}} 
				            	</div>
				            	<div class='actionBN' style='cursor: pointer;' @click="$refs.editor.open( 'Copy', c.id)">
				            	    <mdicon size='16'  name='pencil'/>
				            	</div>
			            	</div>
		            	</div>
	            	</div>
	            </div>
	            <div style='float: left; width: 65%; height: 100%; border: .5px solid #cce;'>
	                <div style='width: 100%; height: calc(58vH); overflow-y: scroll;'>
	                <div v-if="metadata.columns.find(p=>p.column=='distribution').values[dataRecord.distribution]==='Sequence'">
		           		<div style='width: 100%; padding-bottom: 12pt; border-bottom: 1px solid grey;'>Sequence: {{getSequence()}}</div> 
		           		<div class='copy2Select' :style="'height: 20pt; width: 100%;'" draggable v-for="(c,ci) in selected" :key="'s'+ci" 
		           		      @dragstart.exact="dragStartCopy($event, c.id, ci)"
					          @dragend.exact="dragEndCopy($event, c.id, ci)" 
					          @dragover.prevent
							  @dragenter.prevent
							  @contextmenu.prevent="ctxMenu($event, c, ci)"
					          @drop.exact="dropCopy($event, ci)">
					        <div >
					        <div style='font-family: monospace; float: left; display: inline-block; white-space: nowrap; width: 60% !important; overflow-x: hidden;'>
		           			{{c.houseNumber}}  {{c.received}} {{c.duration}} {{c.name}} {{c.shortname}} 
		           			</div>
		           			
		           			<div style='float:right;' @click="removeCopy(ci, true)">
		           			     <mdicon size='18' name='close'/>
		           			</div>
		           			<div v-if="hasFilter(c, ci)" :title='selectedMap[ci].filter.title' style='float:right;' @click="setFilter(c, ci, true)">
		           			     <mdicon size='18' name='movie-filter'/>
		           			</div> 
		           			<div v-else style='float:right;' @click="setFilter(c, ci, true)">
		           			     <mdicon size='18' name='movie-filter-outline'/>
		           			</div> 
		           			<div v-if="hasFilter(c, ci)" @click="setFilter(c, ci, true)" :title='selectedMap[ci].filter.title' style='float: right; display: inline-block; text-overflow: ellipsis; white-space: nowrap; width: 35%; overflow-x: hidden;'>
		           			({{selectedMap[ci]&&selectedMap[ci].filter?selectedMap[ci].filter.title:""}})
		           			</div>
		           			</div>
		           			
		           			
		            	</div>
		            	
	            	</div>
	            	<div v-else>
	            	    <div style='width: 100%; padding-bottom: 12pt; border-bottom: 1px solid grey;'>Selected: {{selected.filter((v,i,arr)=>( arr.indexOf(v)==i)).length}} Copies</div> 
	            	    <!--  filter unique :  (v,i,arr)=>( arr.indexOf(v)==i) -->
	            	    <div class='copy2Select noBreak' style='height: 28pt;'  
	            	         v-for="(c,ci) in selected.filter((v,i,arr)=>( arr.indexOf(v)==i))" :key="'s'+ci" 
	            	         @contextmenu.prevent="ctxMenu($event, c, ci)"
	            	         >
		           			<div style='font-family: monospace; float: left; display: inline-block; white-space: nowrap; width: 50% !important; overflow-x: hidden;'>
		           			{{c.houseNumber}}  {{c.received}} {{c.duration}} {{c.name}} {{c.shortname}} 
		           			</div>
		           			<div style='display: inline-flex' ><InputInt :style="'font-size: 8pt; width: 100pt;'" v-model="c.percentage"/></div>
		            		<div v-if="hasFilter(c, ci)" :title='selectedMap[ci].filter.title' style='float:right;' @click="setFilter(c, ci, true)">
		           			     <mdicon size='18' name='movie-filter'/>
		           			</div> 
		           			<div v-else style='float:right;' @click="setFilter(c, ci, true)">
		           			     <mdicon size='18' name='movie-filter-outline'/>
		           			</div> 
		           			<div v-if="hasFilter(c, ci)" @click="setFilter(c, ci, true)" :title='selectedMap[ci].filter.title' style='float: right; display: inline-block; text-overflow: ellipsis; white-space: nowrap; width: 30%; overflow-x: hidden;'>
		           			({{selectedMap[ci]&&selectedMap[ci].filter?selectedMap[ci].filter.title:""}})
		           			</div>
		            	</div>
	            	</div>
	            	</div>
	            </div>
            
            
            </div>

               <div class="modal-footer" style='display: inline; width: 100%; height: 80pt; padding-top: 20pt; padding-bottom: 20pt;'>
               <button :style="getAppStyle()" type="button" class="button btn-secondary" @click="newCopy()" data-dismiss="modal">new {{tr('Copy')}}</button>
               <div style='display: inline-flex; float: right;'>

                <button :style="getAppStyle()" type="button" class="button btn-secondary" @click="close()" data-dismiss="modal">Close</button>
                <button :style="getAppStyle()" type="button" class="button btn-secondary" @click="reload(data.id)" data-dismiss="modal"><mdicon size='18' name='reload'/></button>
                <button :style="getAppStyle()" type="button" class="button btn-primary btn-save"   @click.prevent="update()">Save</button>
               </div>
              
               <div style='display: inline-flex; float: right; padding-top: 4pt; padding-right: 20pt;'>
                
                <button :style="getAppStyle()" type="button" class="button btn-nav" :disabled='visualIndex == 0'                  @click="first()">|&lt;</button>
                 <button :style="getAppStyle()" type="button" class="button btn-nav" :disabled='visualIndex <= 0'                  @click="left()" >&lt;</button>
                <button :style="getAppStyle()" type="button" class="button btn-nav" :disabled='visualIndex+1 >= visualMapping.length' @click="right()">&gt;</button>
                <button :style="getAppStyle()" type="button" class="button btn-nav" :disabled='visualIndex+1 == visualMapping.length' @click="last()" >&gt;|</button>
                
               </div>
               <div style='display: inline-flex; float: right; padding-right: 20pt;'>

               </div>
             </div>
             
            
         </slot>
      </div>
    </div>
    
  </div>    
 
  <InfoPopUp :time=infoTime :infoType=asText :info=showAsText></InfoPopUp>
  <GFWEOpenEditor ref='editor' @update='reload(dataId)' @insert='reload(dataId)' ></GFWEOpenEditor>
  <GFWEditorInventory ref='editorInv' @inventory='setInventory' ></GFWEditorInventory>
   <ErrorPopUp :errorType="errorTypePU" :error="errorPU"></ErrorPopUp>
   <ContextMenu ref="menu">
      <template v-if="contextData"  slot-scope="{ contextData }">
        <ContextMenuLabel v-if="contextData && contextData.copy">
        {{contextData.copy.name}}
        </ContextMenuLabel>
        <ContextMenuItem v-if="!contextData.sequence"  @clicked="$refs.menu.close(); adjustPercentage();">
        	set equal distribution to all
        </ContextMenuItem>
        <ContextMenuItem v-if="!contextData.sequence"  @clicked="$refs.menu.close(); adjustPercentage(contextData.copy)">
        	adjust other percentages
        </ContextMenuItem>
        
         <ContextMenuItem v-if="contextData"  @clicked="$refs.menu.close(); removeCopy( contextData.pos, contextData.sequence, contextData.copy  )">
        	remove {{contextData.copy.name}}
        </ContextMenuItem>
        </template>
  </ContextMenu>
  </div>
</template>

<script>
import GLink from '@/components/GLink';
import GSelect from '@/components/GSelect';
import GSelectEntity from '@/components/GSelectEntity';
import GSelectModel from '@/components/GSelectModel';
import GSelectMSimple from '@/components/GSelectSimple';
import InputInventoryFilter from '@/components/inputElements/InputInventoryFilter';
import GFWEditorInventory from '@/components/GFWEditorInventoryInline';
import InputText from '@/components/inputElements/InputText';
import InputWeekdays from '@/components/inputElements/InputWeekdays';
import InputTime from '@/components/inputElements/InputTime2';
import GImage from '@/components/GImage';
import InputSecret from '@/components/inputElements/InputSecret';
import InputTimezone from '@/components/inputElements/InputTimezone';
import InputInt from '@/components/inputElements/InputInt';
import InfoPopUp from '@/components/InfoPopUp';
import InputDouble from '@/components/inputElements/InputDouble';
import InputDateTime from '@/components/inputElements/InputDateTime';
import InputDatePick from '@/components/inputElements/InputDatePick3';
import InventoryPanel from '@/components/GFWEditorInventoryPanel';
import Switch from '@/components/Switch'
import ErrorPopUp from '@/components/ErrorPopUp';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuLabel from '@/components/ContextMenuLabel';
import ContextMenuSep from '@/components/ContextMenuSep';
import {HTTP, fwAPI, rotationAPI, invAPI, showError, dashboardInfoAPI } from '@/variables.js'
import { getAppStyle, setDarkMode, initAppMode, getFGColor, getDefaultColorStyle } from '@/AppStyle.js'
import { tr } from '@/translate.js';
import { Tabs, TabPane } from 'vue-bulma-tabs'

var timers = [];
export default {
  name: "GFWEditorRotation",
   components : {
    ErrorPopUp, InfoPopUp, InputText, InputDatePick, InputInt, 'app-switch': Switch, //, GSelectMSimple
    ContextMenu, ContextMenuItem, ContextMenuLabel, GFWEditorInventory
  },
  props: {
    metadata: Object,
    dataRecord: Object,
    parentMeta: Object,
    parentId: { type: Number, default: 0 },
    parentRecord: Object,
    time: { type: Number, default: 0 },
    zIndex: { type: Number, default: 90 },
    dataArray: { type: Array, default: () => [] },
    entityMap: { type: Object, default: () => {} },
    detailEntityMap: { type: Object, default: () => {} },
    mapping: { type: Array, default: () => [] },
    selectedIndex: { type: Number, default: 0 },
    dataId: { type: Number, default: 0 },
    path: { type: Array, default: () => [] },
    display: Boolean
  },
  data(){
    return {
        success: false,
        index: this.selectedIndex,
        selected: [],
        selectedMap: [],
        selectedMapIndex: null,
        duration: 0,
        searchMaterial: "",
        productId: 0,
        asText: "",
        showAsText: "",
        infoTime: 0,
        selectedMaterial: [],
        data: this.copy(this.dataRecord),
        suggestedName4Id: 0,
        selectedTab: 0,
        visualMapping: Array,
        visualIndex: 0,
        numberOfRows: 0,
        lastUser: {},
        newCopyDuration: 10,
        width: 75,
        height: 90,
        mainHeight: 70,
        allDetailIDs: [],
        selectedDetail: {},
        metadataDetail: {},
        showEditorDetail: false,
        msgClass: "msgDefault",
        
        materialData: { data: [] },
        valueArr: [],
        valueStruct: [],
       
        message: "",
        errorPU: {},
        errorTypePU: "",
        updateCount: 0,
		currentDataRecord: this.data,
        currentParentId: 0,
        currentParentMeta: {},
        loaded: false,
        tr,
        getAppStyle, getFGColor, getDefaultColorStyle
     }
    },
  methods: {
        name() { return "FWViewer"+this.zIndex; },
        none() {},
        tooltipFilterAdd(txt) { return "add filter: " + txt; },
        tooltipFilterAdded(txt) { return "filter: " + txt + " already used"; },
        close() {this.selectedTab = 0; this.$emit('close')},
        copy(r) { let aCopy = {}; Object.assign( aCopy, r); return aCopy; },
        getContentStyle()  { return "width: "+ this.width+"% !important;height: "+ this.height+"% !important;"; },
        getMainContentStyle()  { return "height: "+ this.mainHeight+"% !important;"; },
        incWidth() { if ( this.width < 90) { this.width *= 1.1; this.height*=1.1;} },
        decWidth() { if ( this.width > 20) { this.width *= 0.9; this.height*=0.9;} },
        maximize() { if ( this.width == 100) { this.width = 70; this.mainHeight=80, this.height=90; } else { this.width=100; this.mainHeight=70, this.height=100; }},
		isDisabled( copy)
		{
        	if ( copy.lock )
        	{
        		return true;
        	}
        	let seq = this.metadata.columns.find(p=>p.column=='distribution').values[this.dataRecord.distribution]==='Sequence';
        	if ( seq)
        	{
        		return false;
        	}
        	if (this.selected.find( p=>p.id===copy.id))
        	{
        		return true;
        	}
        	return false;
        		
		},
		hasFilter(copy, index)
		{
			let map = this.selectedMap[ index];
			if (!map)
			{
				alert(index)
			}
			this.selectedMapIndex = index;
			//alert( JSON.stringify(map) + copy.id )
			//if ( map.copyId !== copy.id )
			//{
			//	showError( this.toast, null, "internal error: wrong copy")
			//}
			return map.filter && map.filter.title;
		},
		setFilter(copy, index)
		{
			let map = this.selectedMap[ index];
			this.selectedMapIndex = index;
			//alert( JSON.stringify(map) + copy.id )
			//if ( map.copyId !== copy.id )
			//{
			//	showError( this.toast, null, "internal error: wrong copy")
			//}
			this.$refs.editorInv.open( map.filter, map, 'filter', true);
		},
		setInventory(newMap)
		{
			this.selectedMap[ this.selectedMapIndex] = newMap;
			this.$forceUpdate();
		},
        select( copy)
        {
        	this.selected.push( copy )
        	let newMap = {rotationId: this.dataRecord.id, copyId: copy.id, filter: {...copy.inventory}}
        	this.selectedMap.push( newMap);
        	//alert( JSON.stringify(newMap)+ "/" +copy.id)
        	let p = Math.round(100.0 / ( this.selected.filter((v,i,arr)=>( arr.indexOf(v)==i)).length));
        	
        	for ( let el in this.selected )
        	{
        		this.selected[el].percentage = p;
        	}
        	
        	this.$set( this, "selected", this.selected)
        	
        },
        adjustPercentage(copy)
        {
        	if ( !copy )
        	{
	        	let p = Math.round(100.0 / ( this.selected.filter((v,i,arr)=>( arr.indexOf(v)==i)).length));
	        	
	        	for ( let el in this.selected )
	        	{
	        		this.selected[el].percentage = p;
	        	}
	        	
	        	this.$set( this, "selected", this.selected)
        	}
        	else
        	{
        		let elementsInList = this.selected.filter((v,i,arr)=>( arr.indexOf(v)==i)).length;
        		
        		copy.percentage = parseInt( copy.percentage)
        		
        		if ( elementsInList > 1 && copy.percentage >= 0 && copy.percentage <= 100)
        		{
        			
					let p = Math.round((100.0-copy.percentage) / ( elementsInList-1));
					
		        	for ( let el in this.selected )
		        	{
		        		if ( this.selected[ el].id != copy.id)
		        		{
		        			this.selected[el].percentage = p;
		        		}
		        	}
        		}
	        	this.$set( this, "selected", this.selected)
        	}
        },
        newCopy()
        {
			let that = this;
			this.$iosPrompt({
	      text: 'Enter duration',
	      placeholder: this.duration
	    }).then( r => {
	        that.newCopyDuration = r;
	        that.$refs.editor.open( 'Copy', 0, that.createNewCopy)
	    })        	
        },
        createNewCopy( copy)
        {
			copy.name = "Dummy for "+this.dataRecord.name;
			copy.shortname = "Dummy"+this.newCopyDuration;
			copy.code = "Dummy"+this.newCopyDuration;
			copy.duration = this.newCopyDuration;
			copy.durationCounting = this.newCopyDuration;
			copy.durationFinancial = this.newCopyDuration;
        	copy.productIds = [this.productId];
        	return copy;
        },
        getSequence()
        {
        	let uniqueCopies = [...this.selected.filter((v,i,arr)=>( arr.indexOf(v)==i))];
        	
        	for ( let c in uniqueCopies )
        	{
        		let copy = this.selected[ c];
        		copy.identifier=0;
        	}
        	let seqStr = ''
        	let ident = 'A'.charCodeAt(0);
        	for ( let c in this.selected )
        	{
        		let copy = this.selected[ c];
        		let getIdent = uniqueCopies.find( p=>p.id===copy.id);
        		if ( !getIdent.identifier )
        		{
        			getIdent.identifier = String.fromCharCode( ident++);
        		}
        		seqStr += getIdent.identifier +' ';
        	}
        	return seqStr
        },
        dragStartCopy(evt, copyId, iRow)
	    {
	    	evt.dataTransfer.dropEffect = 'move'
	        evt.dataTransfer.effectAllowed = 'move'
	        evt.dataTransfer.setData('copyId', copyId)
	        evt.dataTransfer.setData('iRow', iRow)
	    },
	    dragEndCopy(evt, widgetName, iRow)
	    {
	    },
	    removeCopy( no, sequence, copy )
	    {
	    	if ( sequence === null || sequence === true )
	    	{
		    	this.selected.splice( no, 1); // remove old
		    	this.selectedMap.splice( no, 1); // remove old
		    	this.$set(this, "selected", [... this.selected]);
	    	}
	    	else
	    	{
	    		this.selected = this.selected.filter( p=>p.id !== copy.id);
	    		//this.selectedMap = this.selectedMap.filter( p=>p.id !== copy.id);
	    		this.$set(this, "selected", [... this.selected]);
	    	}

	    },
	    dropCopy(evt, iRow)
	    {
	    	let selectedCopy = [... this.selected];
	    	
	    	let copyId = evt.dataTransfer.getData('copyId');
	    	let fieldRow = evt.dataTransfer.getData('iRow');
	    	//alert( copyId)
	    	//alert( JSON.stringify( this.selected))
	    	let insert = this.selected.find(p=>p.id==copyId)
	    	let insertMap = this.selectedMap[fieldRow]
	    	
	    	selectedCopy.splice( fieldRow, 1); // remove old
	    	this.selectedMap.splice( fieldRow, 1); // remove old

	    	selectedCopy.splice( iRow, 0, insert);
	    	this.selectedMap.splice( iRow, 0, insertMap);
	    	this.$set(this, "selected", selectedCopy);
	    	
	    },
	    ctxMenu(event, item, pos) 
        { 
	  	
	  	  event.preventDefault();
          let seq = this.metadata.columns.find(p=>p.column=='distribution').values[this.dataRecord.distribution]==='Sequence';
	  	  this.$refs.menu.open( event, { copy: item, sequence: seq, pos: pos });
          
        },

        visible(column) { return column && column != null && column != 'null';},
        getModalStyle() { return "'z-index: " + (2+this.zIndex)+";'"; },
        incZIndex() { return parseInt(this.zIndex)+1; },
        clearMsg()
        {
        	this.message = "";
        },
        printMsg(status, msg)
        {
        	if ( parseInt(status) != 200 )
        	{
        		this.msgClass = "msgError";
        	}
        	else
        	{
        		this.msgClass = "msgDefault";
        	}
        	timers.push( setTimeout(this.hideSuccess, 4000));
        	this.message = msg;
        },
        findColumn(column) { 
           //console.log( column );
           var colObject=this.metadata.columns.find(p => p.column === column); 
           //console.log( colObject );
           if ( colObject ) { 
              return colObject; 
           } 
           else { 
              colObject=this.metadata.links.find(p => p.name === column); 
              if ( colObject ) { 
              	return colObject; 
              } 
              else {
                return {};
              }
           }
        },
        addPath()
        {
        	var p = JSON.parse(JSON.stringify(this.path));
        	p.push({name: this.data.visualDisplay, entity: this.metadata.name, position: p.length});
        	return p;
        },
        
        
        closeUntilPosition(position)
        {
        	//alert( position +"/" + this.path.length);
        	if ( position < this.path.length )
        	{
        		this.close();
        	}
        	this.$emit("closeUntilPosition", position)
        },
        closeEditorDetail()
	    {
	        this.showEditorDetail = false;
	    },
        getImgData(val) { return 'data:image/png;base64,' + val; },
        hideSuccess() { this.success = false; this.clearMsg(); clearInterval( timers.pop()); },
        getData(id)
        {
        	
        	this.percentage = [];
        	
        	
        	//alert( "getData");
        	//console.log( fwAPI+"/"+this.metadata.name+"/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+id);
            //alert( rotationAPI+"/load/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+id)
        	HTTP.post( rotationAPI+"/load/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+id)
                    .then( response => 
                    {

                    	this.data = response.data.rotation;
                     	this.getUser(this.data.lUserId); 
                     	this.materialData = { };
                     	this.materialData.data = response.data.copies;
                     	this.dataRecord = response.data.rotation;
                   	    this.productId = this.dataRecord.productId;
                     	//alert( JSON.stringify( response.data.mapList))
                     	this.selected = [];
                     	this.selectedMap = [];
                     	if ( response.data.mapList)
                     	{
	                     	for ( let x in response.data.mapList )
	                     	{
	                     		let map = response.data.mapList[x];
								//alert( JSON.stringify( map))
	                     		let copy = this.materialData.data.find( p=>p.id === map.copyId);
	                     		if ( !copy.percentage )
	                     		{
	                     			copy.percentage = map.percentage;
	                     		
	                     		}
	                     		this.selectedMap.push( map )
	                     		//alert( JSON.stringify( map))
	                     		this.selected.push( copy )
	                     		
	                     		//alert( JSON.stringify( this.selected))
	                     	}
                     	}
                        
                    }).catch(e => {
                    	showError( this.$toast, e, "loading rotation data "+ id)
                       
                    });
        },
        left() {
            if (this.visualIndex > 0 )
            {
                this.visualIndex--;
                let id = this.visualMapping[ this.visualIndex ].dataId;
                this.getData(id);
                
            }
        },
        right() {
            if (this.visualIndex+1 < this.visualMapping.length )
            {
                this.visualIndex++;
                let id = this.visualMapping[ this.visualIndex ].dataId;
                this.getData(id);
                
            }
        },
        last() {
            if (this.visualIndex+1 < this.visualMapping.length )
            {
                this.visualIndex = this.visualMapping.length-1;
                let id = this.visualMapping[ this.visualIndex ].dataId;
                this.getData(id);
            }
        },
        first() {
            if (this.visualIndex > 0 )
            {
                this.visualIndex = 0;
                let id = this.visualMapping[ this.visualIndex ].dataId;
                this.getData(id);
            }
        },
        folderColumnsIndex(tabColumns) {
            var intArr = [];
            for ( var i=0; i < tabColumns.length; i++ )
            {
                intArr.push(i);
            }
            return intArr;
        },
        
        getLabelClass( tab, colIdx) 
        {
        	 let columnName = tab.columns[colIdx];
             let column = this.findColumn(columnName)
        	 if ( column.mandatory )
        	 {
        	 	return "elementLabel required";
        	 }
        	return "elementLabel";
        },
        getStyle( tab, colIdx) 
        { 
            let column = tab.columns[colIdx];
            let columnNext = colIdx + 1 < tab.columns.length ? tab.columns[colIdx+1]: column;
            if (this.visible(column)) {
                if ( this.visible(columnNext) ) { 
                    return "width: " + Math.round(100.0/tab.cells)+"%;";
                }
                else 
                {
                    //console.log("column " + column+ " " + "width: " + Math.round(200.0/tab.cells)+"%;");
                    return "width: " + Math.round(200.0/tab.cells)+"%;";
                }
            } else {
                return "display: none;";
            } 
        },  
        
        passUpdate(entity, record) {
            this.updateCount++;
        	this.$emit( "update", entity, record);
        },
        passInsert(entity, record) {
            this.updateCount++;
        	this.$emit( "insert", entity, record);
        },
        
        loadMaterials( productId)
        {
        	this.materialData = [];
        	this.selected = [];
        	
        	if ( productId )
        	{
        		this.productId = productId;
        	    //alert(dashboardInfoAPI+"/getMaterials/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.parentRecord.id+"/"+0);
        		HTTP.post( rotationAPI+"/getMaterials/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+productId+"/"+0, this.dataRecord)
        		 .then( response => 
                 {     
                    this.materialData = response.data;
                    this.materialData.options = this.materialData.data.map(r => {return {id: r.id, label: r.name, visible: true}});
                    this.$set( this.materialData, "data", [...this.materialData.data])
                    this.$forceUpdate();
                 }).catch(e => {
                        showError( this.$toast, e, "get materials")
                    });
        	}
        	else
        	{
        		this.this.materialData = [];
        	}
        },
        update() {
        	
           let percentages = [];
           let list = [];
           let mapList = [];
           if ( this.dataRecord.distribution )
           {
        	   list = this.selected.filter((v,i,arr)=>( arr.indexOf(v)==i));
        	   mapList = this.selectedMap.filter((v,i,arr)=>( arr.indexOf(v)==i));
        	   percentages = list.map( p=>p.percentage);   
        	   mapList = [...mapList.map(p=> p.filter)]
           }
           else
           {
        	   list = [...this.selected];
        	   mapList = [...this.selectedMap.map(p=> p.filter)]
           }
           for ( let x in list )
    	   {
    		   delete list[x].percentage;
    	   }
           if (!percentages)
           {
        	   percentages = [];   
           }
           while ( percentages.length < list.length)
           {
        	   percentages.push(1);
           }
           let data = { rotation: this.dataRecord, copies: list,  filterList: mapList, percentages: percentages};
           //console.log( JSON.stringify( data))
           this.dataRecord.id = (this.dataRecord.id)?this.dataRecord.id:null;
           HTTP.put( rotationAPI+"/save/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, data)
           .then( response => 
           {
                this.dataRecord = response.data.rotation;
                this.data = response.data.rotation;
             	this.getUser(this.data.lUserId); 
             	//this.materialData = { };
             	//this.materialData.data = response.data.copies;
           
             	//alert( JSON.stringify( response.data.mapList))
             	this.selected = [];
             	this.selectedMap = [];
             	if ( response.data.mapList)
             	{
                 	for ( let x in response.data.mapList )
                 	{
                 		let map = response.data.mapList[x];
                 		let copy = this.materialData.data.find( p=>p.id === map.copyId);
                 		if ( !copy.percentage )
                 		{
                 			copy.percentage = map.percentage;
                 		}
                 		this.selected.push( copy )
                 		this.selectedMap.push( map )
                 		//alert( JSON.stringify( this.selected))
                 	}
             	}
                this.$toast.success( this.dataRecord.name+" saved", 'Ok', { timeout: 1000, position: "topRight" });
               
           }).catch(e => {
               showError( this.$toast, e, "update()")
           }); 
        },
        getUser(id)
        {
            this.lastUser = {};
            HTTP.get( fwAPI+"/findUser/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+id)
                    .then( response => 
                    {
                         this.lastUser = response.data;
                        
                    }).catch(e => {
                        this.error("getUser("+id+") failed", e)
                    });
        },
        selectTab(tab)
        {
        	var firstTab = (tab === this.metadata.folder[0]);
        	//console.log("selectTab() --> " + firstTab+ " :"+  JSON.stringify(tab));
        	return firstTab;
        },
        tabChange(index)
        {
        	this.selectedTab = index;
        	//console.log("tabChange() --> " +index);
        },
        reload(id)
        {
        	this.readDataRecord(id);
        },
        readDataRecord(id)
        {
        	if ( id > 0 )
        	{
        		this.getData( id);
        		this.dataId = id;
        	}
        	this.index = this.selectedIndex; 
            this.selectedTab = 0;
            this.numberOfRows = 0;
            //console.log( JSON.stringify(this.mapping));
            this.visualMapping = [];
            this.visualIndex = 0;
            for ( var x in this.mapping )
            {
            	//console.log( JSON.stringify(this.mapping[x]));
            	if ( this.mapping[x].visible )
            	{
            		this.numberOfRows++;
            		
            		if ( this.data.id == this.mapping[x].dataId )
            		{
            			this.visualIndex = this.visualMapping.length;
            		}
            		this.visualMapping.push( this.mapping[x] );
            	}
            }
            this.$set( this, "data", this.data);
            

        },
        
  },
   updated() {
     if ( !this.loaded && this.dataId)
     {
     	this.loaded = true;
     	this.reload(this.dataId);
     }
     if ( this.dataRecord && !this.dataRecord.id && this.suggestedName4Id != this.parentRecord.id)
 	 {
 		this.dataRecord.name = "Rotation for "+ this.parentRecord.name; 
 		this.suggestedName4Id = this.parentRecord.id;
 	 }
     
     if ( this.dataRecord && this.dataRecord.id && this.dataId !== this.dataRecord.id )
 	 {
        this.dataId = this.dataRecord.id;
 		this.reload(this.dataRecord.id );
 	 }  
     else if ( this.dataRecord.id===null && this.parentRecord &&  this.parentRecord.id )
     {
    	 this.dataRecord.id = 0;
    	 this.loadMaterials( this.parentRecord.id);
    	 
     }
     this.$nextTick(function () {
 	    try
 	    {
 	    	this.$refs.menu.open(event, {spot: {}, name: "" });
 	    } catch(e) { /* */ }
 	  	this.$refs.menu.close();
 		});
  },
  computed: {
	msgDisplayClass: function () { return this.msgClass; }
  },
  
  created() {
	  
	  if ( this.dataRecord && this.dataRecord.id && this.dataId !== this.dataRecord.id )
	 	 {
	        this.dataId = this.dataRecord.id;
	 		this.reload(this.dataRecord.id );
	 	 }  
	     else if ( this.dataRecord.id===null && this.parentRecord &&  this.parentRecord.id )
	     {
	    	 this.dataRecord.id = 0;
	    	 this.loadMaterials( this.parentRecord.id);
	    	 
	     }   
	     else if ( this.dataRecord.id===null && this.dataRecord.productId )
	     {
	    	 this.dataRecord.id = 0;
	    	 this.loadMaterials( this.dataRecord.productId);
	    	 
	     }   
  		
  },
  mounted() {
	  
		
 	    	
},
   watch:
   {
	   counter:  function() { alert(this.counter)},
	   dataRecord : function()  {
		  
	       	if ( this.dataRecord && this.dataRecord.id && this.dataId !== this.dataRecord.id )
	    	{
	       		this.dataId = this.dataRecord.id;
	    		this.reload(this.dataRecord.id );
	    	}    	
        },
        parentRecord: function()  { 
        	
        	if ( this.parentRecord && this.parentRecord.id)
        	{
        		this.loadMaterials( this.parentRecord.id);
        	}
        	if ( this.dataRecord && !this.dataRecord.id && this.suggestedName4Id != this.parentRecord.id)
        	{
        		this.dataRecord.name = "Rotation for "+ this.parentRecord.name; 
        		this.suggestedName4Id = this.parentRecord.id;
        	}
        	
        }
   }
}
</script>
<style scoped>
.editorElement {
     display: inline-flex; 
     padding-bottom: 8pt;
     padding-top: 2pt;
     padding-right: 8pt;
 }
.elementLabel {
    display: inline-flex;
 }
.required:after {
    content:" *";
    color: red;
 }
.elementField {
    display: inline-flex;
     //border: 1pt solid yellow;
     width: 100%;
 }
.lUpdateInfo {
    font-size: 11pt;
    color: #888;
}
.lUpdateInfoSuccess {
    vertical-align: middle;
    
}
.mainContent {
    height: 100%;
    transition: all 0.4s ease;
    //border: 1pt solid blue;
}
.appHeadLine { 
    width: 100%; 
    border-bottom: 1pt solid #aaa; 
    text-align: right;
    margin-bottom: 2pt; 
    font-weight: bold;
    padding: 10pt 10pt 1pt;
}
    
.appCounter {
	display: inline-grid;
	font-size: 11pt;
	color: #888;
}    
.appLeft {
	display:  inline-table; 
	text-align: left;
	width: 70%; 
} 
.appRight {
	display: inline-table; 
	text-align: right;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
	width: 30%; 
}
.bottom {
  position: absolute;
  bottom: 0;
  left: 0;
}
.header {
  font-weight: bold;
  color: #aaa;
  border: none;
  font-size: 14px;
  
  padding-left: 4px;
  padding-top: 2px;
}
.appHead { 
    width: 100%;  
    text-align: right;
    background-color: #eef;
    padding: 0pt 0pt 0pt;}
    
.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  //height: 60%;
  width: 60%;
  margin: 0px auto;
  padding-left: 0pt;
  padding-bottom: 20pt;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.4s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
  padding: 5pt;
  //border: 1pt solid blue;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.btn-nav {
    font-size: 9pt;
    vertical-align: middle;
    valign: middle;
}
.btn-save {
	background-color: #ded;
}
.iButton {
    background-color: transparent;
    border-color: #dbdbdb;
    border-width: 0px;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding-bottom: 2pt;;
    padding-left: 2pt;
    padding-right: 2pt;
    padding-top: 4pt;
    height: 20pt;
    text-align: center;
    white-space: nowrap;
}

.msgDefault {
	font-size: 9pt;
}
.msgError {
	font-size: 9pt;
	font-weight: bold;
	color: #bb0000;
}
.actionBN { float: left; border: 1pt solid #ccc; border-radius: 3px; border-bottom: 0; text-align: center; width: 10%;}
.radioSelect { border: 0.5px solid grey; width: 80pt; font-size: 9pt; cursor: pointer;}
.radioSelected { border: 0.5px solid grey; width: 80pt; margin-top: 4px; font-size: 9pt; background-color: #bbf; color: #000; }
.radioSelect:hover { background-color: #ddf; color: #000;}
.copy2Select { border-bottom: 0.5px solid #aaa; height: 16pt; width: 100%; font-size: 9pt; cursor: pointer;}
.disabled { cursor: not-allowed important; background-color: #00 !important; color: #aaa !important;}
.copy2Select:hover { background-color: #ddf; color: #000;}
*:disabled { background-color: #fdd !important; color: #aaa !important; cursor: not-allowed;}
.on { background-color: #dfd; font-weight: bold; font-size: 8pt;}
.off { background-color: #000; color: #fcc; font-weight: bold; font-size: 8pt;}
.noBreak {
    
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
