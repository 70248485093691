<template>
 <div style='width: 100%; height: 100%; position: relative;'>
   
     <div class='transition' :style='getControlStyle()'>
    	<!-- CONTROLS -->
    	    
		    <div style='position: relative; display: flex; width: 100%; height: calc(100vh - 190px);'>
		        <div style='float: top;'>
			        <div style='vertical-align: middle; text-align: center; font-size: 14pt; font-weight: bold;width: 100%;'>Deal Analyser/Creator 1.0</div>
			        <br/>
			        
			        <div style='display: inline-flex; width: 45%;'>
				    <InputDatePick :auto="false" 
	                            @change="dateChanged" v-model="fromDate"/>  
	                            </div>
	                            -
	                <div style='display: inline-flex; width: 45%;'>
	                <InputDatePick :auto="false" @change="dateChanged" v
	                            v-model="untilDate"/>     
	                 </div>  
	                <br/>
	                <hr/>
			         <InputSearch v-model="searchTerm" topic='deal & campaigns' @search="doSearch(true)" usage='dashboard'/>
			        <br/>  
	                <button v-tooltip='"reload screen"' class='button myButton' :style="getAppStyle()" @click="getData()"><mdicon :size="15" name="reload" /></button> 
					<button v-tooltip='"update budget data (no discount change)"' class='button myButton' :style="getAppStyle()" @click="updateData()"><mdicon :size="15" name="update" /></button> 
					<!--
					<button v-tooltip='"show all details ("+tr("Placement")+"s)"' class='button myButton' :style="getAppStyle()" @click="findData()"><mdicon :size="15" name="tab-search" /></button> 
					-->
					
				</div>
		    	<div style='position:absolute; bottom:50px;'>
		    	<span class='SVcontrolLabel'>Show Controls: </span>
		    	&nbsp;
		    	<app-switch v-model="controlsRight" :checked="controlsRight"/>
		    	</div>
		    	

		    	
		    </div> <!-- CONTROLS -->
    </div>
    
    <!-- MAIN -->
	<div class='transition' style='float: left; width: 78%;'> 
	    <!-- HEADER -->
	    
	    <!-- TABLE -->
	    <div v-if="ready" style='width: calc(100%); height: calc(100vh - 110px);'  >

	    	<div style='float: top; width: 100%; height: 28pt; overflow-y: scroll;'>
			    <div style='float: top;'>
				    <div v-for="(col,idx) in selectedColumns" :key="'ColH_'+idx" class='floatLeft tableHeader' :style='getColumnStyle(col, idx)'
						@click='setOrderBy(col)'
						>
				       <div class='header higher' 
				            :style="'background-color: #3b60c4; color: #fff;'">
				       		<div class='ltd' :title="col.name"> {{col.name}}<sub>{{getOrderBy(col)}}</sub></div>
				       	</div>
				    </div>
				   
			    </div>  <!-- float: top -->   
			</div> <!-- HEADER --> 
	    <!-- TABLE -->
	    <div style='width: 100%; height: calc(80vH) !important; overflow-y: scroll;' >
			    	<div   style='float: top; width: 100%;height: 100%;' >

			    	    <div v-for="(line,idx) in limit(data)" 
			    	         class='reqLine' :key="'DAT_'+idx" :ref="'DAT_'+idx" :id="'DAT_'+idx"
							 @contextmenu="ctxMenu($event, line, idx)"
			    	         @mouseenter="hoverLine('DAT_'+idx)"
			    	         @mouseleave="leaveLine('DAT_'+idx)"
							 @click="open( line, line)"
			    	        >

			    	        <div v-for="(col,idx2) in selectedColumns" :key="'CS_'+idx+'_'+idx2" :class="'floatLeft'"  :style='getColumnStyle(col, idx2)'>
			    	            <div :class="'reqDetail dontWrap '+ col.class" :style='getColumnDetailStyle(col, line)' 
			    	                
						             >
						            <div v-if="col.type==='icon'" :title="col.tooltip" style='width: 100%; height: 100%;'>
						                <div v-if="testColumn4Line( col, line)" :class="'reqDetailNB dontWrap '+col.class" @click="performClick4Line(col, line)" style='width: 100%; height: 100%;'>
						       			<mdicon :size="18" :name="col.show" />
						       			</div>
						       			<div v-else  style='width: 100%; height: 100%;'> 
						       			
						       			</div>
						       		</div>
						       		<div v-else-if="col.show === 'PERFBT'"  class='reqDetailNB dontWrap' style='padding-left: 2pt; padding-top: 4pt;'>
					    	           <GProgress :treshold="80" :percentage="getPercentBT(line)"/>
								    </div>
									<div v-else-if="col.show === 'PERFBD'" class='reqDetailNB dontWrap' style='padding-left: 2pt; padding-top: 4pt;'>
					    	           <GProgress :treshold="80" :percentage="getPercentBD(line)"/>
									</div>
						       		<div v-else :title="line.display[col.id]" >
						       			{{line.display[col.id]}}
						       		</div>
						       	</div>
						     </div> 

				    	        
			    	    </div>
			    		
			    	</div> 
		    	</div>  <!-- TABLE -->
    	</div>  <!-- TABLE -->
    </div>  <!-- MAIN -->
    <ContextMenu ref="menu" :offsetY="-100">
      <template v-if="contextData && contextData.data"  slot-scope="{ contextData }">
        
		<ContextMenuItem v-if="contextData.data.campaignIds && contextData.data.campaignIds.length == 1" @clicked="$refs.menu.close(); openModule('Campaign', contextData.data.campaignIds[0]); ">
        	Edit {{tr('Campaign')}}
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.data.dealId" @clicked="$refs.menu.close(); openModule('Deal', contextData.data.dealId); ">
        	Edit {{tr('Deal')}}
        </ContextMenuItem>
		<ContextMenuItem v-if="contextData.data.possibleDealId" @clicked="$refs.menu.close(); openModule('Deal', contextData.data.possibleDealId); ">
        	Edit Virtual-{{tr('Deal')}}
        </ContextMenuItem>
		<ContextMenuItem v-if="contextData.data.possibleDealId" @clicked="$refs.menu.close(); assignDeal( [contextData.data]); ">
		    Assign Virtual-{{tr('Deal')}}
        </ContextMenuItem>
		<ContextMenuItem v-else @clicked="$refs.menu.close(); createDeal(contextData.data);">
        	Create {{tr('Deal')}}
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.data.placementId" @clicked="$refs.menu.close(); $refs.editor.open('Placement', contextData.data.placementId); ">
        	Edit {{tr('Placement')}}
        </ContextMenuItem>
 		<ContextMenuSep/>
        <ContextMenuItem v-if="contextData.data.type === 'CAMPAIGN' || contextData.data.type === 'DEAL'" @clicked="$refs.menu.close(); getDetailData( contextData.data) ">
        	View details
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.data.type === 'CAMPAIGN' || contextData.data.type === 'PLACEMENT'" @clicked="$refs.menu.close(); getDetailMediaData( contextData.data) ">
        	View media/details
        </ContextMenuItem>
        <ContextMenuSep/>
        
		<ContextMenuItem v-if="contextData.data.dealId" @clicked="$refs.menu.close(); $refs.dealView.open(contextData.data); ">
		        	View monthly budgets
		        </ContextMenuItem>
        
        <ContextMenuItem v-if="dataSave && dataSave.length" @clicked="$refs.menu.close(); goBack(); ">
        	Back
        </ContextMenuItem>

        
      </template>    
	</ContextMenu>
    <GConfirm ref='confirm'/>
    <PDFViewer name='pdfView' ref='pdfView' />
    <GFWEOpenEditor ref='editor'></GFWEOpenEditor>
	<ProgressBar v-if="showProgressBar" :generalInfo=pbTitle :action=pbAction @action=action></ProgressBar>
	<DealView ref='dealView' 
        @close="closeBreakView()">
    </DealView>
	<DealWizard ref='dealWizzard'></DealWizard>
 </div>
</template>

<script>
import {HTTP, fwAPI, invAPI, reportAPI, dealReportAPI, clientReportAPI, opsAPI, invoiceAPI, setReload, myLocale, showError, formatNumber} from '@/variables.js';
import { setGoBack } from '@/breadCrumb.js';
import { tr } from '@/translate.js';
import { getAppStyle, isDarkMode, initAppMode, getFG, getBG, getBGColor } from '@/AppStyle.js';
import {store, restore} from '@/DealScreen.js';
import InputTime from '@/components/inputElements/InputTime2';
import InputText from '@/components/inputElements/InputText';
import Switch from '@/components/Switch';
import DealView from '@/components/DealView';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';
import ContextMenuLabel from '@/components/ContextMenuLabel';
import InputTimezone from '@/components/inputElements/InputTimezone';
import GridBreakView from '@/components/GridBreakView';
import InputDatePick from '@/components/inputElements/InputDatePick2'; 
import GSelectMSimple from '@/components/GSelectMSimple'; 
import ProgressBar from '@/components/ProgressBar';
import PDFViewer from '@/components/PDFViewer';
import GConfirm from '@/components/GConfirm';
import DealWizard from '@/components/Deal/DealWizard';
import GProgress from '@/components/misc/GProgress';
import InputSearch from '@/components/inputElements/InputSearch';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import { format } from '@/Numbers.js';
var momentTZ = require('moment-timezone');

var timers = [];
var lastMarked = "-";
import JQuery from "jquery";
let $ = JQuery;
export default {
  name: 'NEXT_REPORTER_View',
  components : {
   'app-switch': Switch, InputDatePick, ProgressBar, InputSearch, GConfirm, ContextMenu, ContextMenuItem, ContextMenuSep, GProgress, DealView
   , DealWizard
  },
  data () {
    return {
      data: [],
      fromDate: new Date(),
      untilDate: new Date(),
      showProgressBar: false,
      searchTerm: "",
      search: "",
      dealId: 0,
      dealData: {},
      dataSave: [],
      pbTitle: "",
      pbAction: "",
      controlsRight: false,
      ready: false,
      fontSize: 11,
      widthCalculated: 800,
      tr, getAppStyle, getBGColor, format,
      orderBy: [],
      p: 1,
      availableColumns: [
	        {id: 10, name: tr("Deal"), show: "deal", type: 'txt', disabled: true, class:'left', w: 4},
			{id: 11, name: tr("VirtualDeal"), show: "possibleDeal", type: 'txt', disabled: true, class:'left', w: 4},
	        {id: 20, name: tr("Campaign"), show: "campaign", type: 'txt', disabled: true, class:'left', w: 5},
	        {id: 21, name: tr("Placement"), show: "placement", type: 'txt', disabled: true, class:'left', w: 5},
	        {id: 22, name: tr("Media"), show: "media", type: 'txt', disabled: true, class:'centered', w: 3},
	        {id: 25, name: "Details", show: "chevron-down", type: 'icon', tooltip: "Show details", click: 'showDetails', disabled: false, class:'centered', w: 1},
	        
	        {id: 30, name: ("#"+tr("Campaign")+"s"), show: "campaignIds", type: 'txt', disabled: true, class:'centered', w: 1},
	        {id: 40, name: ("#"+tr("Placement")+"s"), show: "placementIds", type: 'txt', disabled: true, class:'centered', w: 1},
	        {id: 50, name: "Advertiser", show: "client", type: 'txt', disabled: true, class:'centered', w: 5},
	        {id: 60, name: "Until", show: "to", type: 'txt', disabled: true, class:'centered', w: 4},
	        {id: 70, name: "Agency", show: "agency", type: 'txt', disabled: true, class:'centered', w: 5},
	        {id: 80, name: "Budget", show: "budget", type: 'mny', disabled: true, class:'centered', w: 2},
	        {id: 90, name: "BudgetDetail", show: "budgetDetails", type: 'mny', disabled: true, class:'centered', w: 2},
	        {id: 100, name: "Booked", show: "bookedBudget", type: 'mny', disabled: true, class:'centered', w: 2},
	        {id: 101, name: "Total", show: "bookedBudgetTotal", type: 'mny', disabled: true, class:'centered', w: 2},
	        {id: 102, name: "Performance Budget", type: 'progress', show: "PERFBT", w: 3},
	        {id: 103, name: "Performance Budget", type: 'progress', show: "PERFBD", w: 3},
	        
	        {id: 105, name: "Discounting", show: "bookedBudgetDiscounting", type: 'mny', disabled: true, class:'centered', w: 2},
	        {id: 110, name: "⌀ CashDisc.", show: "cashDiscount", type: 'perc', disabled: true, class:'centered', w: 2},
	        {id: 111, name: "CashDisc.", show: "cashDiscount", type: 'perc', disabled: true, class:'centered', w: 2},
	        {id: 112, name: "CD Scale", show: "activeScale.cashDiscount", type: 'perc', disabled: true, class:'centered', w: 2},
	        {id: 120, name: "FreeDisc.", show: "incentiveDiscount", type: 'perc', disabled: true, class:'centered', w: 2},
	        
	        {id: 199, name: "Update", show: "refresh-auto", type: 'icon', test: "autoUpdate", tooltip: "Automated discount update", disabled: false, class:'centered', w: 1},
	        {id: 200, name: "Active.", show: "activeScale.name", type: 'txt', disabled: true, class:'left', w: 4},
	        {id: 250, name: "Assign", show: "update", type: 'icon', test: "canBeAssigned", tooltip: "Update active scale", click: 'assignVirtual', disabled: false, class:'centered', w: 1},
	        {id: 300, name: "Virtual.", show: "virtualScale.name", type: 'txt', disabled: true, class:'left', w: 4},

	        
      ],
      selectedIN: "",
      selectedDefault: [50,70,30,10,11,80],
      selectedSave: [10,20,25,30,40,50,60,70,80,90,100,110],
      selectedDefaultD: [30,50,70,10,11,80],
      selectedDefaultP: [21,22,50,70,10,11,80],
      selectedDefaultPM: [21,22,50,70,30,10,11,80],
      selected: [],
      selectedColumns: [
	        
      ]
    }
  },
  methods: {
	dateChanged()
	{
	    	this.getData();
	},
    action( what, myId, api)
    {
   		this[what](myId, api)
    },
    showDetails( line)
    {
		
		if ( line.type === 'DEAL')
		{
			this.getDetailData( line)
		}
		else
		{
			this.getDetailMediaData( line)
		}
	},
	openModule(what, id)
	{
		this.$refs.editor.open( what, id);
	},
	openDealWizzard( line ) 
	{
		if ( line && (line.dealId || line.possibleDealId) )
		{
	    	this.$refs.dealWizzard.open(line);
		}
	},
    testColumn4Line( col, line)
    {
    	if ( !col.test )
    	{
    		return true;
    	}
    	return this[col.test]( line);
    },
    openDeliveryScreen( data)
    {
		if ( data.salesRequestIds && data.salesRequestIds.length === 1 )
		{
    	    let viewName = this.$router.matcher.getRoutes().find(p=>p.path==='/workflowOperationsTrafficDelivery').name;
	    	if ( !viewName )
	    	{
	    		return;
	    	}
	    	setGoBack( this.$router.currentRoute.path, this.$router.currentRoute.name);
	    	this.$router.replace({name: viewName, params: { id: data.salesRequestIds[0], from: data.from, until: data.to}});
		}       
    },
    open( col, line)
    {
		this.openDealWizzard( line );
    },
    performClick4Line( col, line)
    {
    	if ( !col.click )
    	{
    		return;
    	}
    	this[col.click]( line);
    },
    getPercentBT( line)
    {
    	let i1 = line.budgetHC;
    	let i2 = line.bookedBudgetTotal;
    	//alert( 100*i2/i1); 
    	if ( i1 === 0 )
    	{
    		return Math.round(100);
    	}
    	return Math.round(100*i2/i1);
    },
    getPercentBD( line)
    {
    	let i1 = line.budgetHC;
    	let i2 = line.budgetDetailsHC;
    	//alert( 100*i2/i1); 
    	if ( i1 === 0 )
    	{
    		return Math.round(100);
    	}
    	return Math.round(100*i2/i1);
    },
    getPercentF( line)
    {
    	let i1 = line.incentivesBudgetClaim;
    	let i2 = line.bookedBudgetIncentives;
    	//alert( 100*i2/i1); 
    	if ( i1 === 0 )
    	{
    		return Math.round(100);
    	}
    	return Math.round(100*i2/i1);
    },
    canBeAssigned( line)
    {
    	let id1 = line.activeScale && line.activeScale.id ? line.activeScale.id : 0;
    	let id2 = line.virtualScale && line.virtualScale.id ? line.virtualScale.id : 0;
    	return id1 !== id2;
    },
    autoUpdate( line)
    {
    	return line.autoUpdate;
    },
    isAuto( line)
    {
    	return line.autoUpdate;
    },
    changeAuto( line)
    {
    	let that=this;
    	let name = line.deal?line.deal:line.campaign;
    	if ( line.dealId && line.campaignId)
    	{
    		this.$toast.info("Autmated update must be assigned on deal level, not on campaign level", 'Info', { position: "topRight" });
    		return;	
    	}
    	this.$refs.confirm.confirm( {text: name, title: "Auto update", button1: "Cancel", button2: !line.autoUpdate ? "Enable":"Disable"}).then(x => {
    		
	    	line.autoUpdate = !line.autoUpdate;
	    	that.assignVirtualInternal( line, true);
    	});
    },
    assignVirtual( line, dontUpdateScale)
    {
    	let name = line.deal?line.deal:line.campaign;
    	let that=this;
    	if ( !line.mediaId || !line.placementId)
    	{
    		this.$toast.info("Scale must be assigned on placement/media level", 'Info', { position: "topRight" });
    		return;	
    	}
    	if ( line.activeScale && line.virtualScale && line.virtualScale.id == line.activeScale.id)
    	{
    		this.$toast.info("Scale is already activated", 'Info', { position: "topRight" });
    		return;	
    	}
    	this.$refs.confirm.confirm( {text: name, title: "Set active scale", button1: "Cancel", button2: "Set"}).then(x => {

    		that.assignVirtualInternal( line, false);
    	});
    },
    assignVirtualInternal( line, dontUpdateScale)
    {
    	let copy = {...line};
    	
   		let that=this;
    	
    	delete copy.display;
    	delete copy.discountConsumer;
    	delete copy.index;
    	if ( ! dontUpdateScale)
    	{
    		copy.activeScale = copy.virtualScale;
    	}
		let dataSave = new Promise(function (resolve, reject) {
			
       		HTTP.put( dealReportAPI+"/updateDealCampaignData/"+sessionStorage.tenantId
       				+"/"+sessionStorage.accessPointId
       				+"/"+sessionStorage.unitId
       				+"/"+sessionStorage.userId,
       				copy
       				)

        		 .then( response => 
                 {
                 	let newLine = response.data;
                 	newLine.index = line.index;
                 	that.data = that.data.filter(p=>p != line);
                 	that.data.push( newLine);
                 	
                    resolve (true);     
                    
                 }).catch(e => {
                	    showError( that.$toast, "update deal/campaign data", e);
                        reject ();
                    });
	      });
    },
    createDeal( line)
    {
    	let that=this;
    	let copy = {...line};
    	delete copy.display;
    	delete copy.discountConsumer;
    	delete copy.index;
		copy.from = momentTZ(this.fromDate).utcOffset(0, true).format().split('T')[0];
		let dataSave = new Promise(function (resolve, reject) {
			
       		HTTP.put( dealReportAPI+"/createDeal/"+sessionStorage.tenantId
       				+"/"+sessionStorage.accessPointId
       				+"/"+sessionStorage.unitId
       				+"/"+sessionStorage.userId
					+"/deal"
       				,copy
       				)

        		 .then( response => 
                 {
                	that.$toast.success("deal " +response.data.name +" created", 'Ok', { position: "topRight" });

                    resolve (true);     
                    
                 }).catch(e => {
                	    showError( that.$toast, "createDeal", e);
                        reject ();
                    });
	      }).then(p=>{ that.getData( ); });
    },
	assignDeal( lines)
    {
    	let that=this;
		let arr = [];
		for ( let i in lines)
		{
			let line = lines[i];
    		let copy = {...line};
	    	delete copy.display;
	    	delete copy.discountConsumer;
	    	delete copy.index;
			copy.from = momentTZ(this.fromDate).utcOffset(0, true).format().split('T')[0];
			arr.push( copy);
		}
		let dataSave = new Promise(function (resolve, reject) {
			
       		HTTP.put( dealReportAPI+"/assignDeal/"+sessionStorage.tenantId
       				+"/"+sessionStorage.accessPointId
       				+"/"+sessionStorage.unitId
       				+"/"+sessionStorage.userId
					+"/deal"
       				,arr
       				)

        		 .then( response => 
                 {
                	that.$toast.success("deal assigned" +response.data.name +" created", 'Ok', { position: "topRight" });

                    resolve (true);     
                    
                 }).catch(e => {
                	    showError( that.$toast, "createDeal", e);
                        reject ();
                    });
	      }).then(p=>{ that.getData( ); });
    },
    show( line)
    {
    	alert( JSON.stringify( line.placementIds))
    },
    ctxMenu(event, data, idx) 
    { 
       event.preventDefault();
       this.$refs.menu.open(event, {data: data, row: idx} )
    },
	getControlStyle() {
    	if ( this.controlsRight )
    	{
    		return "float: right; width: 21%; height: calc(100vh - 120px); padding: 2ex;"
    	}
    	return "float: left; width: 21%; height: calc(100vh - 120px); padding: 2ex;"
    },
    getColumnStyle(col, idx)
    {
    	let wTotal = this.widthCalculated;
        return "width: " + (parseInt(col.w)/wTotal*100) +"%; background-color: "+getBG()+";color: "+getFG();
    },
    getColumnDetailStyle(col, line)
    {
     	return "font-size:"+this.fontSize+"px;";
    },
    doOrderBy(data)
    {
       let that = this;
       
    	data.sort(function(a,b) {
	     	for ( var i in that.orderBy)
	     	{
	     		let iCol = that.orderBy[i];
	     		let v1 = a[ iCol.show ];
	     		let v2 = b[ iCol.show ];
	     		
	     		if ( ! v2 || v1 > v2 )
	     		{
	     			return iCol.sorting;
	     		}
	     		if ( ! v1 || v1 < v2 )
	     		{
	     			return -iCol.sorting;
	     		}
	     	}
	     	return a.index - b.index;
	     });
		return data;
    },
    doSearch( force) {
	  	if ( force || (this.searchTerm))
		{
	    	this.search = this.searchTerm ? this.searchTerm.toLowerCase(): "*";
	    	this.limit( this.data);
		}
		
	  },
	  applySearch( data)
	    {
	    	let filtered = [];
	    	for (let di in data)
	    	{
	    		let line = data[di];
	    		line.display = [];
	    		let match = !this.searchTerm || this.searchTerm==="*";
	    		for (let ci in this.selectedColumns)
	    		{
	    			let col = this.selectedColumns[ci];
	    			let displayValue = this.print( line, col);
	    			line.display[ col.id ] = displayValue;
	    			if ( displayValue )
	    			{
		    			match = (match || displayValue.toString().toLowerCase().includes(this.searchTerm));
	    			}
	    		}
	    		if ( match )
	    		{
	    			filtered.push( line);
	    		}
	    	}
	    	return filtered;
	    },
    limit( data)
    {
        //let tmp = [...data];
        //let tmp = this.doFilter( data);
        let tmp = this.applySearch( data);
    	let array = this.doOrderBy( tmp);
    	if ( this.newLine )
    	{
    		array.push( this.newLine);
    	}
    	
    	return array;
    },
    hoverLine(line)
    {
    	
    	if ( isDarkMode())
    	{
    		$('#'+line).children().addClass('reqLineBGDM');
    	}
    	else
    	{
    		$('#'+line).children().addClass('reqLineBG');
    	}
    },
    leaveLine(line)
    {
    	if ( isDarkMode())
    	{
    		$('#'+line).children().removeClass('reqLineBGDM');
    	}
    	else
    	{
    		$('#'+line).children().removeClass('reqLineBG');
    	}
    },
    print( line, col)
    {
       //return spot[ col.show ];
       let xLine = line;
       let val = null;
       if ( col.type==='icon' )
       {
   	     return "";
   	   }
   	   if ( col.show.endsWith("Ids"))
       {
		   val = xLine[ col.show ].length;
    	   return val;
       } 
       if ( col.show==='budget')
       {
		   if ( xLine.type==="DEAL" )
		   {
		   	val = xLine.budgetDealHC;
		   }
		   else if ( xLine.type==="CAMPAIGN" )
		   {
			   val = xLine.budgetCampaignsHC;
		   }
		   else 
		   {
			   val = xLine.budgetPlacementsHC;
		   }
		   return format( val);
	   }
	   if ( col.show==='budgetDetails')
       {
		   val = xLine.budgetDetailsHC;
		   return format( val);
	   }
       if ( col.show.includes("."))
       {
    	   let path = col.show.split('.');
    	   let tmp = xLine[ path[0] ];
    	   if ( tmp )
    	   {
    	   		val = tmp[ path[1]];
       	   }
       } 
       else
       {
    	   val = xLine[ col.show ];
       }
       if ( col.type==='mny' )
    	{
    	   //return (Math.round( 100 * val/1000)/100)+'k';
    	   return format( val);
    	}
    	
       if ( col.type==='perc' )
       {
   	     return (Math.round( 100 * val)/100)+'%';
   	   }

    	if ( ! val )
    	{
    		return "";
    	}
    	
    	if ( col.boolean )
    	{
    		if ( val )
    		{
    			return "X";
    		}
    		return "";
    	}
    	if ( isNaN( val) )
    	{
    		return val?val:"";
    	}
    	try
    	{
    		
    		return val ? formatNumber( val ) : val;
    	}
    	catch(e) {
    		//
    	}
    },
    getData( ) {
    	this.pbAction = "getDataInternal";
    	this.pbInfo = "Loading data (deals + campaigns)";
    	this.showProgressBar = true;
    },
    getDataInternal( myId, api ) {
        let that = this;
		
		let req = {	 
					fromDateISO: momentTZ(this.fromDate).utcOffset(0, true).format().split('T')[0], 
					untilDateISO: momentTZ(this.untilDate).utcOffset(0, true).format().split('T')[0] 
		};
		// alert( JSON.stringify(req)+"/"+this.fromDate)
		let dataLoad = new Promise(function (resolve, reject) {
            
       		api.post( dealReportAPI+"/getClientData/"+sessionStorage.tenantId
       				+"/"+sessionStorage.accessPointId
       				+"/"+sessionStorage.unitId
       				+"/"+sessionStorage.userId
       				+"/"+req.fromDateISO
       				+"/"+req.untilDateISO
       				+"/"+myId
       				)

        		 .then( response => 
                 {
                 	that.data = response.data;
                 	that.dataSave = [];
                 	//alert( JSON.stringify( that.data))
                 	for ( let i in that.data)
                 	{
                 		that.data[i].index = i;
                 	}
                 	that.selectedDefault = that.selectedDefaultD;
                 	that.showProgressBar = false;
                 	that.selected = null;
                 	that.arrangeColumns();
                 	that.ready = true;
                    resolve (true);     
                    
                 }).catch(e => {
                	    showError( that.$toast, "get client data", e);
                	    that.showProgressBar = false;
                        reject ();
                    });
	      });
    },
    findData( ) {
    	this.pbAction = "findDataInternal";
    	this.pbInfo = "Searching placements & details";
    	this.showProgressBar = true;
    },
    findDataInternal( myId, api ) {
        let that = this;
		
		let req = {	 
					fromDateISO: momentTZ(this.fromDate).utcOffset(0, true).format().split('T')[0], 
					untilDateISO: momentTZ(this.untilDate).utcOffset(0, true).format().split('T')[0] 
		};
		
		let dataLoad = new Promise(function (resolve, reject) {
            
       		api.post( dealReportAPI+"/findPlacementMediaData/"+sessionStorage.tenantId
       				+"/"+sessionStorage.accessPointId
       				+"/"+sessionStorage.unitId
       				+"/"+sessionStorage.userId
       				+"/"+req.fromDateISO
       				+"/"+req.untilDateISO
       				+"/"+myId
       				)

        		 .then( response => 
                 {
                 	that.data = response.data;
                 	that.dataSave = [];
                 	//	alert( JSON.stringify( that.data[x].campaignIds))
                 	for ( let i in that.data)
                 	{
                 		that.data[i].index = i;
                 	}
                 	that.selectedDefault = that.selectedDefaultPM;
                 	that.showProgressBar = false;
                 	that.selected = null;
                 	that.arrangeColumns();
                 	that.ready = true;
                    resolve (true);     
                    
                 }).catch(e => {
                	    showError( that.$toast, "get deal data", e);
                	    that.showProgressBar = false;
                        reject ();
                    });
	      });
    },
    updateData( ) {
    	this.pbAction = "updateDataInternal";
    	this.pbInfo = "Updating budgeta (deals + campaigns)";
    	this.showProgressBar = true;
    },
    updateDataInternal( myId, api ) {
        let that = this;
		
		let req = {	 
					fromDateISO: momentTZ(this.fromDate).utcOffset(0, true).format().split('T')[0], 
					untilDateISO: momentTZ(this.untilDate).utcOffset(0, true).format().split('T')[0] 
		};
		// alert( JSON.stringify(req)+"/"+this.fromDate)
		let dataLoad = new Promise(function (resolve, reject) {
            
       		api.post( dealReportAPI+"/updateDealCampaignData/"+sessionStorage.tenantId
       				+"/"+sessionStorage.accessPointId
       				+"/"+sessionStorage.unitId
       				+"/"+sessionStorage.userId
       				+"/"+req.fromDateISO
       				+"/"+req.untilDateISO
       				+"/"+myId
       				)

        		 .then( response => 
                 {
                 	that.showProgressBar = false;
                 	that.getData();

                 	that.ready = true;
                    resolve (true);     
                    
                 }).catch(e => {
                	    showError( that.$toast, "update budget data", e);
                	    that.showProgressBar = false;
                        reject ();
                    });
	      });
    },
    goBack()
    {
    	this.data = [];
    	this.selectedDefault = [...this.selectedSave];
    	this.selected = null;
        this.arrangeColumns();
    	for ( let i in this.dataSave)
    	{
    		this.data.push( this.dataSave[i]);
    	}

    },
   
    getDetailData( dealData ) {
    	this.dealData = dealData;
    	this.dataSave = [...this.data];
    	this.selectedSave = [...this.selectedDefault];
    	this.pbAction = "getCampaignDataInternal";
    	this.pbInfo = "Loading campaigns";
    	this.showProgressBar = true;
    },
    getDetailMediaData( dealData ) {
    	this.dealData = dealData;
    	this.dataSave = [...this.data];
    	this.selectedSave = [...this.selectedDefault];
    	this.pbAction = "getCampaignMediaDataInternal";
    	this.pbInfo = "Loading campaigns";
    	this.showProgressBar = true;
    },
    getCampaignDataInternal( myId, api ) {
        let that = this;
		
		// alert( JSON.stringify(req)+"/"+this.fromDate)
		let dataLoad = new Promise(function (resolve, reject) {
            delete that.dealData.index;
            delete that.dealData.display;
       		api.post( dealReportAPI+"/getCampaignDataDetails/"+sessionStorage.tenantId
       				+"/"+sessionStorage.accessPointId
       				+"/"+sessionStorage.unitId
       				+"/"+sessionStorage.userId
       				+"/"+false
       				+"/"+myId, 
       				that.dealData
       				)

        		 .then( response => 
                 {
                	
                 	that.data = response.data;
                 	that.selectedDefault = that.selectedDefaultD;
                 	//alert( that.dealData.type)
					if ( that.dealData.type === 'CAMPAIGN')
					{
						that.selectedDefault = that.selectedDefaultP;
					}
					if ( that.dealData.type === 'CAMPAIGN')
					{
						that.selectedDefault = that.selectedDefaultP;
					}
					if ( that.dealData.type === 'DEAL')
					{
						that.selectedDefault = that.selectedDefaultD;
					}
					
                 	for ( let i in that.data)
                 	{
                 		that.data[i].index = i;
                 		
                 	}
                 	that.selected = null;
                 	that.arrangeColumns();
                 	that.showProgressBar = false;
                 	that.ready = true;
                    resolve (true);     
                    
                 }).catch(e => {
                	    showError( that.$toast, "get INVOICE Input data", e);
                	    that.showProgressBar = false;
                        reject ();
                    });
	      });
    },
    getCampaignMediaDataInternal( myId, api ) {
        let that = this;
		
		// alert( JSON.stringify(req)+"/"+this.fromDate)
		let dataLoad = new Promise(function (resolve, reject) {
            delete that.dealData.index;
            delete that.dealData.display;
       		api.post( dealReportAPI+"/getCampaignDataDetails/"+sessionStorage.tenantId
       				+"/"+sessionStorage.accessPointId
       				+"/"+sessionStorage.unitId
       				+"/"+sessionStorage.userId
       				+"/"+true
       				+"/"+myId, 
       				that.dealData
       				)

        		 .then( response => 
                 {
                	
                 	that.data = response.data;
                 	that.selectedDefault = that.selectedDefaultD;
					//alert( that.dealData.type)
					if ( that.dealData.type === 'CAMPAIGN')
					{
						that.selectedDefault = that.selectedDefaultPM;
					}
					
                 	for ( let i in that.data)
                 	{
                 		that.data[i].index = i;
                 		
                 		//alert( JSON.stringify( that.data[i].activeScale))
                 	}
                 	that.selected = null;
                 	that.arrangeColumns();
                 	that.showProgressBar = false;
                 	that.ready = true;
                    resolve (true);     
                    
                 }).catch(e => {
                	    showError( that.$toast, "get INVOICE Input data", e);
                	    that.showProgressBar = false;
                        reject ();
                    });
	      });
    },
    
    getOrderBy( col )
    {
    	for ( var i in this.orderBy)
    	{
    		let iCol = this.orderBy[i];
    		if ( iCol.show === col.show )
    		{
    		    if ( iCol.sorting > 0 )
    		    {
    				return "A"+(parseInt(i)+1);
    			}
    			return "D"+(parseInt(i)+1);
    		}
    	}
    	return "";
    },
   setOrderBy( col )
    {
       if ( col.type === 'icon' || col.type === 'progress')
       {
    	   return;
       }
       var add = true;
    	for ( var i in this.orderBy)
    	{
    		let iCol = this.orderBy[i];
    		if ( iCol.show === col.show )
    		{
    		    if ( col.sorting === 1 )
    		    {
    		    	col.sorting = -1;
    		    }
    		    else
    		    {
    				this.orderBy.splice( i, 1);
    				col.sorting = 0;
    			}
    			add = false;
    			break;
    		}
    	}
    	if ( add )
    	{
    		this.orderBy.push( col );
    		col.sorting = 1;
    	}
    	//this.doOrderBy();
    	this.$forceUpdate();
    	
    },
	arrangeColumns()
	{
		this.availableColumns = this.availableColumns.sort((a,b)=>a.name.localeCompare(b.name));
	    this.selectedColumns = new Array();
	    //alert( JSON.stringify( this.selectedIN))
	    if ( this.selectedIN)
	    {
	    	try
	    	{
	    		this.selected = this.selectedIN.split(',');
	    		//alert( this.selected)
	        } catch (e)
	        {
	        	//alert(JSON.stringify(e))
	        	this.selected = [];
	        }
	    	
	    }
	    //alert( JSON.stringify( this.selected))
	    if ( ! this.selected || ! this.selected.length )
	    {
	    	this.selected = [];
	    	
	    	for ( let i in this.selectedDefault )
			{
				let col = this.availableColumns.find( p=> p.id===this.selectedDefault[i])
				this.selected.push( col.id);
			}
	    }
	    //console.log(JSON.stringify(this.selected))
		for ( let i in this.selected )
		{
			let id = parseInt(this.selected[i]);
			for ( var j in this.availableColumns )
			{
				let column = this.availableColumns[j];
				if ( column.id === id )
				{
					this.selectedColumns.push( column );
					break;
				}
			}
	    }
	    this.calcWidths( false);
	},
	calcWidths( withSaving)
	{
	    let wTot = 0;
		var columns = "";
		for ( var i in this.selectedColumns )
     	{
     		wTot += this.selectedColumns[ i].w;
     		if ( columns )
     		{
     			columns += "," + this.selectedColumns[ i].id;
     		}
     		else
     		{
     			columns = this.selectedColumns[ i].id;
     		}
     	}
		this.widthCalculated = wTot;
		if ( withSaving )
     	{
	     	let saveUser = 0;
	     	
	     	if ( this.user && ! this.user.columnsOPSD)
	     	{
	     		saveUser = 1;
	     		this.user.columnsOPSD=columns;
	     	}
	     	if ( this.user && this.user.columnsOPSD != columns)
	     	{
	     		saveUser = 1;
	     		this.user.columnsOPSD=columns;
	     	}
	     	//alert( saveUser);
	     	if ( saveUser ) 
	     	{
	     		this.userUpdated( this.user);
			}
	     	else
	     	{
	     		this.$toast.info("column settings not changed", 'Ok', { timeout: 2000, position: "topRight" });
	     	}
		}
	},
    storeSettings() 
    {
		let obj = restore();
		if ( !obj )
		{
			obj = {};
		}
		obj.data = this.data;
		obj.dataSave = this.dataSave;
		obj.selectedColumns = this.selectedColumns;
		obj.selectedDefault = this.selectedDefault;
		obj.fromDate = this.fromDate;
		obj.untilDate = this.untilDate;
		obj.searchTerm = this.searchTerm;
		store( obj);
    },

  },
  	
  beforeDestroy() {
	 this.storeSettings();

  },
  created() {
	  	let restoreView = restore();
	  	if ( restoreView && restoreView.data )
	  	{
			this.data = restoreView.data;
			this.dataSave = restoreView.dataSave;
			this.selectedColumns = restoreView.selectedColumns;
			this.selectedDefault = restoreView.selectedDefault;
			this.fromDate = restoreView.fromDate;
			this.untilDate = restoreView.untilDate;
			this.searchTerm = restoreView.searchTerm;
			this.ready = true;
			this.arrangeColumns();
			this.calcWidths();
			
			this.setOrderBy( {show: 'client'});
		}
		else
		{
			let d = new Date();
			let inc = d.getMonth() > 10 ? 1: 0;
			this.fromDate = new Date(d.getFullYear()+inc, 0, 1);
			this.untilDate = new Date(d.getFullYear()+inc, 11, 31);
			this.arrangeColumns();
			this.calcWidths();
			
			this.setOrderBy( {show: 'client'});
			this.getData();
		}	  
		initAppMode();
	    

  },
  watch: {
  	fromDate: function() { this.untilDate = new Date(this.fromDate.getFullYear(), 11, 31); },
  	untilDate: function() {  },
  	
  },
  updated() {
  }
}
</script>
<style lang="scss" scoped>
 .transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }
  
.transitionFast {
 	transition: all .4s;
   -webkit-transition: all .4s;
 }
.header { 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 7pt; 
  font-weight: bold; 
  width: 100% !important; 
  height: 28pt; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
  background-color: #ff9800; 
  }
.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  overflow: hidden; 
  text-overflow: ellipsis;
}
.centered {
  justify-content: center;
}
.right {
  justify-content: right;
}
.left {
  justify-content: left;
}
.floatLeft {
    display: inline-flex;
    float: left;
}
.reqLineBG {
  background-color: #e8f0e8 !important; 
}
.reqLineBGDM {
  background-color: #888 !important; 
  color: #eef;
}
.ltd {
  padding-left: 1pt;
  padding-right: 1pt;
  overflow-x: hidden;
  font-size: 9px;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
}
.reqDetail { 
  display: flex;
  align-items: center;
  font-size: 8pt;  
  width: 100% !important; 
  height: 20pt; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
  overflow: hidden;
  cursor:pointer;
  //background-color: #aaa; 
}
.reqDetailNB { 
  display: flex;
  align-items: center;
  font-size: 8pt;  
  width: 100% !important; 
  height: 20pt; 
  overflow: hidden;
  cursor:pointer;
  //background-color: #aaa; 
}

</style>
