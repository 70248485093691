import {HTTP, fwAPI, rightsAPI, searchAPI, dashboardInfoAPI, showError} from '@/variables.js';
import { goBack } from '@/breadCrumb.js';
import { setTrTitle } from '@/translate.js';
export function defer() {
var res, rej;

	var promise = new Promise((resolve, reject) => {
		res = resolve;
		rej = reject;
	});

	promise.resolve = res;
	promise.reject = rej;

	return promise;
}
export function getDataByName( module, name )
{
	let promDefer = defer();
    if ( module && name )
    {
	    
		//alert( fwAPI+"/getByName/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+module+"/"+name)
    	HTTP.post( fwAPI+"/getByName/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+module+"/"+name)
		 .then( response => 
         {
         	let data = response.data
            

            if ( data.extraData )
            {
            	for ( let i in data.extraData )
            	{
            		data[i] = data.extraData[i];
            	}
            }
            //console.log(fwAPI+"/getByName/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+module+"/"+name)
            //console.log(JSON.stringify(data))
			promDefer.resolve( data);
   			
                  
         }).catch(e => {
                try {
                	promDefer.reject();
                	console.log(e)
                } catch (e2)
                {
					console.log(e2)
				}
            });   
	}
	else
	{
		promDefer.reject();	
	}
	return promDefer;
}

export function getData( module, id )
{
	let promDefer = defer();
    if ( module && id )
    {
	    
		HTTP.get( fwAPI+"/entityById/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+module+"/"+id)
		 .then( response => 
         {
         	let data = response.data

            if ( data.extraData )
            {
            	for ( let i in data.extraData )
            	{
            		data[i] = data.extraData[i];
            	}
            }
			promDefer.resolve( data);
   			
                  
         }).catch(e => {
                try {
                	promDefer.reject();
                	console.log(e)
                } catch (e2)
                {
					console.log(e2)
				}
            });   
	}
	else
	{
		promDefer.reject();	
	}
	return promDefer;
}



export function getPlacements( requestObj )
{
	let promDefer = defer();
    if ( requestObj )
    {
	    
		//alert( fwAPI+"/entityById/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+module+"/"+id)
    	HTTP.post( dashboardInfoAPI+"/getPlacements/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, requestObj)
		 .then( response => 
         {
         	let data = response.data
			
            if ( data.extraData )
            {
            	for ( let i in data.extraData )
            	{
            		data[i] = data.extraData[i];
            	}
            }
            //console.log(fwAPI+"/getByName/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+module+"/"+name)
            //console.log(JSON.stringify(data))
			promDefer.resolve( data);
   			
                  
         }).catch(e => {
                try {
                	promDefer.reject();
                	console.log(e)
                } catch (e2)
                {
					console.log(e2)
				}
            });   
	}
	else
	{
		promDefer.reject();	
	}
	return promDefer;
}

export function openView( router, toast, view, list, title  )
{
	//alert( "openView("+ view+"/"+title)
	HTTP.post( rightsAPI+"/viewAllowed/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, {view: view, title: title})
		 .then( response => 
         {
			if ( response.data.allowed )
			{ 
				try
				{
					if ( title )
					{
						setTrTitle( view, title)
					}
					router.replace(view).then( x => 
			        { 
			            // routine went well
			            
			        }).catch(e => {
						  // if route is already active...
						  router.replace("/home")
						  router.replace(view)
			
			        });
					
				}
				catch (e)
				{
					alert( JSON.stringify(e));
				}        				
            }
            else
            {
				if ( list && list.length)
			    {
					list.pop();
				}
				else
				{
					goBack();
				}
				toast.error("right '"+response.data.right.name+"' is not granted for "+ response.data.user, 'Error', { timeout: 2000, position: "topRight" });
			}  
         }).catch(e => {
			    
				if ( list && list.length)
			    {
					list.pop();	
				}
				else
				{
					goBack();
				}
                toast.error("Oops", 'Error', { position: "topRight" });
            });   
}
export async function allowedView( router, toast, view, list, title  )
{
	await HTTP.post( rightsAPI+"/viewAllowed/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, {view: view, title: title})
		 .then( response => 
         {
			if ( response.data.allowed )
			{ 
				return true;		
            }
            else
            {
				
				toast.error("right '"+response.data.right.name+"' is not granted for "+ response.data.user, 'Error', { timeout: 2000, position: "topRight" });
				return false;
			}  
         }).catch(e => {
			    
				toast.error("Oops", 'Error', { position: "topRight" });
				return false;
            });   
}

export function getCopyright()
{
	let year = new Date().getFullYear();
	if ( year > 2023)
	{
		return   "© 2023-"+year+" nextadsystem.com"
	}
	
	return  "© "+year+" nextadsystem.com"
}
