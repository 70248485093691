<template>
  <div id='body'  style='height: calc(100vH - 90px);'>
  
    <div v-if='entityList.length'>
	    <div v-for='(entity, idx) in entityList' :key="'e_'+idx" 
	        :style="'float: left; width: calc('+99/entityList.length+'%); padding-left: 5pt; padding-right: 2pt; border-right: 0.5pt solid #eee;'"> 
	      
			<template v-if="!entity.module.startsWith('/')">
	  			<GFWE v-if="allowedView( $router, $toast, entity.module, entityList, entity.title)" :entity='entity.module' :xtitle='entity.title' @close="removeModule" @clearAll="clearAll()"/>
			</template>
	  		<div v-else-if='! entity.module.startsWith("/fw/")'>
	  			{{openView( $router, $toast, entity.module, entityList, entity.title)}}
	  		</div>
	  	</div>
	  	
  	</div>
  	<div v-else style='padding-left: 1pt;height: calc(100vH - 110px); !important;' @contextmenu.prevent="ctxMenu($event)">
  	   
  	    <div class='appHeadLine' style='width: 100% !important;' @click='getUser(true)'>
  	     <div style='position: relative; float: right; width: 100% !important; border-bottom: 1px solid #ccc;'>
	  	     <div style='position: relative; float: left;'>
	  	      <div class='breadcrumbItemCurrent transition' :style="getAppStyle()">
	  		   Desktop for {{user.login}} 
	  		  </div>
  		    		  
  		  	 </div>
	  		 <div  style='float: right'>   
	  		    <WindowCloseBN @click="openView( $router, $toast, '/dataMiner', [], 'Data-Miner')" tooltip='Switch to Data-Miner'/>
			  </div>
  		   </div>
  		</div>
		
  		<div style='float: top; '>
  		<!--
  		<div class='shadowLight' style='margin-right: 5pt; display: block; float: left; width: 6%; font-size: 10pt; height: calc(100vH - 116px); padding: 5pt;'>

  		</div>
  		-->
  		<div style='float: left; width: 70%;'>
	  		<table id='wbTable' class='transition mytable' >
	  			<tr v-if="showHeader" >
	  				<td v-for='iCell in activeCells()' @click='edit=iCell' class='transition' width="150" :style="'width: 150pt; white-space: nowrap;'" :key="'th_'+iCell">
	  				    <InputText v-model='titles[iCell]' @changed="change('header')" @enter="edit=''" @blur="edit=''"  @escape="edit=''" v-if="edit === iCell"/>
	  					<div v-else class='transition' :style="'font-size: '+fontSize+'px; font-weight: bold; text-align: center; margin-bottom: 4pt; margin-top: 8pt; color: #aaa'">
	  					{{titles[iCell]}}
	  					</div>
	  				</td>
	  			</tr>
		  		<tr v-for='(line, iRow) in transform()' style="" :key="'e_'+iRow" >
		  		 <td v-for='(item, iCell) in filter(line)' 
		  		     class='transition' width="150"
		  		     style='width: 150pt; white-space: nowrap;' :key="'t_'+iRow+'_'+iCell">
		         <div v-if="item.module" draggable class='module' 
		                     :style="'border-radius: '+rounding+'px; box-shadow: '+shadow1+'px '+shadow1+'px '+shadow2+'px 0 '+(shadowColorOver['#'+iRow+'.'+iCell]?shadowColorOver['#'+iRow+'.'+iCell]:shadowColor)+'; display: inline-flex; width: 100%; color:'+item.fcolor+';background-color:'+item.color+';'"
		              @dragstart.exact="dragStart($event, item.no-1, iRow)"
			          @dragend.exact="dragEnd($event, item.no-1, iRow)" 
			          @dragover.prevent
					  @dragenter.prevent
			          @drop.exact="dropField($event, item.no-1, iRow)" 
			          @mouseenter="setModule(item); $set(shadowColorOver, '#'+iRow+'.'+iCell, '#444')"
			          @mouseleave="setModule({}); $set(shadowColorOver, '#'+iRow+'.'+iCell, false)"
		              @click='if (edit !== item.module) push( item.module, item.title)'>
		         <InputText v-model='item.title' :clipboard='false' :fontSize='fontSize' @changed="change('name')" @enter="edit=''" @blur="edit=''"  @escape="edit=''" v-if="edit === item.module"/>
		         <div v-else class='transition' :style="'font-size: '+fontSize+'px; font-weight: bold;'">
		         {{item.title?item.title:item.module}}
		         <span v-if='itemNotify[item.module]' class='w3-badge w3-blue'>{{itemNotify[item.module]}}</span>
		         <span v-if='itemWarn[item.module]' class='w3-badge w3-red'>{{itemWarn[item.module]}}</span>
		         </div>
		         </div>
		         <div v-else>
		         
		         </div>
		         
				  </td> 
				  <td>
				  	<div class='transition' style='display: flex; width: 20pt !important; height: 36pt !important;' 
			          @dragover.prevent
					  @dragenter.prevent
			          @drop.exact="dropField($event, activeCells().length, 0)">
			      </div>
		          </td>
		        </tr>
	        </table>
        </div>
        
        <div v-if='user && user.id' class='transition' style='width: 20%; height: calc(100vH - 150px); margin-top: 32pt; float: right;' >
        	<div v-if='showSettingsWidget' class='transition'
	            :style="getAppStyle()+'float: top; border: 1pt solid #ccc; box-shadow: '+shadow1+'px '+shadow1+'px '+shadow2+'px 0 '+shadowColor+';font-size: 10pt; float: top; width: 95%; padding-left: 5pt; margin-top: 10pt; margin-right: 10pt;'" 
	           >
		       <div class='white' style='width: 90%; float: left;'>
			       	<b>Settings</b>
			       </div> 
			       <div class='white iButton' :style="getAppStyle()+'float: right;'" @click='showSettingsWidget=false;'>
			       		<span aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16">
							  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
							  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
							</svg>
						  </span>
				   </div>
				<table class='settings' width="100%">
				<tr>
				<td width='68%'>
		        multiple views <app-switch v-model="multipleTables" :checked="multipleTables" @change="change('view')"/>
		        </td>
		        <td rowspan=3>
		        <button title='save all the changes done on this screen' class='buttonSmall' @click="userUpdated()">Save</button>
  					<span v-show='Object.keys(changes).length' class='w3-badge w3-blue'>{{Object.keys(changes).length}}</span>
  				</td>
  				</tr>
  				<tr>
  				<td>
		  		add/collect <app-switch v-model="autoAdd" :checked="autoAdd"/><br/>
		  		</td>
		  		</tr>
		  		<tr>
  				<td>
		  		dark/night mode <app-switch v-model="darkMode" :checked="darkMode"/><br/>
		  		</td>
		  		</tr>
		  		</table>
		  		<br/>
		        <span class='dontWrap smallText bold'>Font-Size:</span>
		        <div style='width: 75%;padding-left: 5pt;'>
				<vue-slider @change='val => change("font")' :marks="false" min=8 max=20 v-model="fontSize"></vue-slider>	
				</div>
				<br/>
				<span class='dontWrap smallText bold'>Shadow/Rounding:</span>
		        <div style='width: 75%;padding-left: 5pt;'>
				<vue-slider title='Shadow' @change="change('shadow1')" :marks="false" min=0 max=36 v-model="shadow1"></vue-slider>	
				<vue-slider title='Shadow (blur-radius)' @change="change('shadow2')" :marks="false" min=0 max=36 v-model="shadow2"></vue-slider>
				<vue-slider title='Rounding' @change="change('rounding')" :marks="false" min=0 max=36 v-model="rounding"></vue-slider>
				</div>
				
				<br/>
					
	        </div>
	        
        	<WIDGETS :class="showSettingsWidget?'invisibleItem':''"
	                 :widgets='widgets' :shadow1="shadow1" :shadow2="shadow2" :shadowColor="shadowColor"
	                 :widgetsSetup='widgetsSetup'
	                 :fontSize="Math.min(fontSize,11)" :user="user"
	                 @widgets="changeWidgets" 
	                 @widgetsSetup="changeWidgetsSetup" 
	                 @reload="getUser(true)"
	                 @save="userUpdated"
	                 @add="add" @open="open"
	                 @showSettings='showSettingsWidget=true'>
	        </WIDGETS>
	       
	    
        </div>
      </div>
       
  	</div>
  	
  	<GFWEOpenEditor ref='editor' ></GFWEOpenEditor>
  	
  	<ContextMenu ref="menu">
      <template v-if="contextData"  slot-scope="{ contextData }">
        <ContextMenuItem v-if="contextData.module"  @clicked="$refs.menu.close(); entityList.push( { module: contextData.module, title: contextData.title}); ">
        	open '{{contextData.title?contextData.title:contextData.module}}'
        </ContextMenuItem>
        <ContextMenuSep v-if="contextData.module"/>
        <ContextMenuItem v-if="contextData.module" @clicked="$refs.menu.close(); edit=contextData.module;">
        	edit '{{contextData.title?contextData.title:contextData.module}}'
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.module" @clicked="removeFromList( contextData);">
        	remove '{{contextData.title?contextData.title:contextData.module}}'
        </ContextMenuItem>
        <ContextMenuSep v-if="contextData.module"/>

       
        <ContextMenuItem v-if="!darkMode" @clicked="darkMode = !darkMode; setDarkMode( true)">
        	<div>switch to dark mode</div>
        </ContextMenuItem>
        <ContextMenuItem v-else @clicked="darkMode = !darkMode; setDarkMode( false)">
        	<div>switch to normal mode</div>
        </ContextMenuItem>
         <ContextMenuItem @clicked="showColorPickerDM($event, contextData);">
        	<div>set background color for dark mode</div>
        </ContextMenuItem>
         <ContextMenuSep v-if="contextData.module"/>
        <ContextMenuItem v-if="contextData.module" @clicked="showColorPicker($event, contextData);">
        	<div>set background color for {{contextData.title}}</div>
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.module" @clicked="copyColor($event, contextData);">
        	<div>set this background color for the column</div>
        </ContextMenuItem>
       <ContextMenuItem v-if="contextData.module" @clicked="showColorPickerSHD($event);">
        	<div>set color for shadow</div>
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.module" @clicked="switchColor( contextData ); $refs.menu.close();">
        	<div>switch color for {{contextData.title}}</div>
        </ContextMenuItem>
       
        <ContextMenuSep v-if="contextData.module"/>
        
        
        <ContextMenuItem  @clicked="showSettingsWidget=true; $refs.menu.close();">
          Show Settings Widget
        </ContextMenuItem>
        <ContextMenuSep v-if="contextData.module"/>
        <ContextMenuItem  @clicked="showHeader=!showHeader; $refs.menu.close();">
          {{(showHeader)?"Hide":"Show"}} table header
        </ContextMenuItem>
         <ContextMenuItem  @clicked="getUser(true); $refs.menu.close();">
          Reload
        </ContextMenuItem>
        <ContextMenuItem  @clicked="userUpdated(); $refs.menu.close();">
          Save
        </ContextMenuItem>

       </template>
    </ContextMenu>
    
    <vue-modal-2 :darkMode="false" 
       :headerOptions="{title: 'set color for ' + selectedItem.title}" 
       :footerOptions="{ btn1: 'Cancel', btn2: 'Set', btn1OnClick:closeColorPicker, btn2OnClick:closeColorPicker, disableBtn2:false}"
       name="modal-selectColumns"  @on-close="closeColorPicker">
		<div style='padding-left: 10pt; height: calc(50vH)!important; overflow-y: auto; '>
        <color-panel v-model="selectedItem.color" @change='changes["color"]=1' ></color-panel>
		</div>
    </vue-modal-2>
   <vue-modal-2 :darkMode="false" 
       :headerOptions="{title: 'set color for shadow'}" 
       :footerOptions="{ btn1: 'Cancel', btn2: 'Set', btn1OnClick:closeColorPicker, btn2OnClick:closeColorPicker, disableBtn2:false}"
       name="modal-selectColumnsSHD"  @on-close="closeColorPicker">
		<div style='padding-left: 10pt; height: calc(50vH)!important; overflow-y: auto; '>
        <color-panel v-model="shadowColor" @change='changes["shadowColor"]=1' ></color-panel>
		</div>
    </vue-modal-2>
    <vue-modal-2 :darkMode="false" 
       :headerOptions="{title: 'set color for shadow'}" 
       :footerOptions="{ btn1: 'Cancel', btn2: 'Set', btn1OnClick:closeColorPicker, btn2OnClick:closeColorPicker, disableBtn2:false}"
       name="modal-selectColumnsDM"  @on-close="closeColorPicker">
		<div style='padding-left: 10pt; height: calc(50vH)!important; overflow-y: auto; '>
        <color-panel v-model="darkModeBGColor" ></color-panel>
		</div>
    </vue-modal-2>
   
    
  </div>   
</template>

<script>
import {HTTP, userAPI, fwAPI, opsAPI, bngAPI, setReload, unregisterReload, setListener, unregisterListener, showError} from '@/variables.js';
import { initGoBack, setGoBack } from '@/breadCrumb.js';
import { getAppStyle, setDarkMode, isDarkMode, setDarkModeBGColor, getDefaultBG, isDark } from '@/AppStyle.js';
import { setTrTitle } from '@/translate.js';
import { openView, allowedView } from '@/utils.js';
import GFWE from '@/views/GFWE';
import WIDGETS from '@/components/desktopWidgets/WorkbenchWidgets';
import ContextMenu from '@/components/ContextMenu';
import App from '@/App';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';
import InputText from '@/components/inputElements/InputText';
import Switch from '@/components/Switch';
import WindowCloseBN from '@/components/misc/WindowCloseBN';
import JQuery from "jquery";
import 'w3-css/w3.css';
let $ = JQuery;
export default {
  name: "FW",
  components : {
   'app-switch': Switch, WIDGETS, WindowCloseBN,
   GFWE, ContextMenu, ContextMenuItem, ContextMenuSep, InputText
  },
  data() {
      return {
         titles: ["Settings", "Ad Sales", "Setup","Reports","Finance","Schedule","Controls","",""],
         entityList: [],
         recentlyOriginal: [],
         user: {},
         tasks: [],
         edit: "",
         widgets: ["task", "noty", "logs", "errorlogs", "search", "login"],
         widgetsSetup: {},
         multipleTables: true,
         autoAdd: false,
         darkMode: false,
         activeModule: {},
         getAppStyle,
         showSettingsWidget: false,
         showHeader: true,
         fontSize: 10,
		 changes: [],
		 shadow1: 0,
		 shadow2: 0,
		 rounding: 0,
         shadowColor: '#000',
         shadowColorOver: [],
         selectedItem: {},
         itemNotify: [],
         itemWarn: [],
         darkModeBGColor: getDefaultBG(),
         initGoBack,
         getDefaultBG,
         setDarkMode,
         openView, allowedView
      }
  },
  methods: {
	  	removeModule( event, entity)
	  	{
	  		this.entityList = this.entityList.map(p=>p.module).filter(p => p != entity);
	  	},
	  	changeWidgets( newOnes )
        {
        	this.widgets = newOnes;
        },
        changeWidgetsSetup( newOnes )
        {
        	this.widgetsSetup = newOnes;
        },
	    closeSettingsWidget()
	    {
	    	this.showSettingsWidget = false;
	    },
	    change(what) { this.$set( this.changes, what, true); },
	    push(module, title)
	    {
	  		if ( module.startsWith("/fw/"))
	  		{
	  			module = module.substring(4);
	  		}
	  		initGoBack( this.$router.currentRoute.path, "Desktop for "+this.user.login);
	    	this.entityList.push({module: module, title: title});
	    	
	    },
	    copyColor( event, cell )
	    {
	        let col = this.recentlyOriginal[ cell.no-1 ];
	    	for ( let r in col )
	    	{
	    		col[r].color=cell.color;
	    		col[r].fcolor=cell.fcolor;
	    	}
	    	this.change('color')
	    },
	    open( menu)
	    {
	    	this.push( menu.entity)
	    },
	    add( menuEntity, menuTitle)
	    {
	    	if ( ! this.recentlyOriginal || ! this.recentlyOriginal[0] )
    		{
    		    this.recentlyOriginal = [];
    			this.recentlyOriginal[0] = [];
    			this.titles = [];
    			this.titles.push(menuEntity);
    			this.change('moduleAdd')
    		}
    		this.recentlyOriginal[0].push({ no: 1, color: '#ccc', fcolor: '#000', title: menuTitle, module: menuEntity});
	    },
	    addModule(m, t) 
	    {
	    	//alert( m);
	    	if ( this.autoAdd )
	    	{
	    		this.add(m,t);
	    		return null;
	    	}
	    	return m;
	    },
	    showColorPicker( event, item)
	    {
	        this.selectedItem = item;
	        this.$refs.menu.close();
	    	this.$vm2.open('modal-selectColumns');
	    	
	    },
	    showColorPickerSHD( event)
	    {
	        this.$refs.menu.close();
	    	this.$vm2.open('modal-selectColumnsSHD');
	    	
	    },
	    showColorPickerDM( event)
	    {
	        this.$refs.menu.close();
	    	this.$vm2.open('modal-selectColumnsDM');
	    	
	    },
	    closeColorPicker()
	    {
	    	this.$vm2.close('modal-selectColumns');
	    	this.$vm2.close('modal-selectColumnsDM');
	    	this.$vm2.close('modal-selectColumnsSHD');
	    },
	    switchColor( item)
	    {
	    	this.$set( item, 'fcolor', (item.fcolor === '#000') ? '#fff' : '#000');
	    	this.change('color')
	    	this.$forceUpdate();
	    },
	  	removeFromList( obj)
	  	{
	  	    this.$refs.menu.close();
			for ( let x in this.recentlyOriginal)
			{
			    for ( let y in this.recentlyOriginal[x])
				{
    				if ( this.recentlyOriginal[x][y].module === obj.module )
    				{
						this.recentlyOriginal[x].splice(y, 1);
						break;
					}
				}
    		}
			this.change('moduleAdd')
	  	},
	  	filter( line)
	  	{
	  		let tmp = [];
	  		for ( let x = 0; x <= 10; x++)
	  		{
	  			
	  			if ( this.isActive(x) )
	  			{
	  				if ( line[x] )
	  				{
	  					tmp.push( line[x])
	  				}
	  				else
	  				{
	  					tmp.push( {})
	  				}

	  			}
	  		}
	  		//alert( JSON.stringify(tmp))
	  		return tmp;
	  	},
	  	activeCells()
	  	{
	  		let x = [];
	  		for ( let i = 0; i <= 10; i++)
	  		{
	  			if ( this.isActive(i))
	  			{
	  				x.push(i);
	  			}
	  			
	  		}
	  		return x;
	  	},
	  	isActive( lineNo)
	  	{
	  		return this.recentlyOriginal[lineNo] && this.recentlyOriginal[lineNo].length
	  	},
	  	ctxMenu(event, item) 
        { 
          event.preventDefault();
          this.$refs.menu.open(event, this.activeModule );
        },
        setModule(item) 
        { 
          this.activeModule = item;
        },
	  	dragStart(evt, iCell, iRow)
	    {
	    	$("#wbTable").prop("border", 1);
	        evt.dataTransfer.dropEffect = 'move'
	        evt.dataTransfer.effectAllowed = 'move'
	        evt.dataTransfer.setData('iCell', iCell)
	        evt.dataTransfer.setData('iRow', iRow)
	    },
	    dragEnd(evt, iCell, iRow)
	    {
	    	 $("#wbTable").removeProp("border");
	    	 $("#wbTable").removeAttr("border");
	    },
	    
	    dropField(evt, iCell, iRow)
	    {
	       event.stopPropagation();
	       
	       $("#wbTable").removeProp("border");
	       $("#wbTable").removeAttr("border");
	       let fieldCell = evt.dataTransfer.getData('iCell');
	       let fieldRow = evt.dataTransfer.getData('iRow');
	       //alert( fieldCell+"/"+fieldRow + "--->" + iCell +"/" +iRow);
	       if ( parseInt(fieldCell) !== parseInt(iCell) )
	       {
		       let arraySRC = this.recentlyOriginal[parseInt(fieldCell)];
		     
		       let array = this.recentlyOriginal[parseInt(iCell)];
		       let field = arraySRC[fieldRow];
		       if ( !array )
		       {
		       		array = [];
		       		this.titles.push(field.title);
		       }
		       
		       field.no = parseInt(iCell)+1;
		       array.splice(parseInt(iRow), 0, field);
		       //alert( JSON.stringify( array));
		       arraySRC.splice(parseInt(fieldRow), 1);
		       //alert( JSON.stringify( arraySRC));
	       	   this.recentlyOriginal[parseInt(iCell)] = array;
	       	   this.recentlyOriginal[parseInt(fieldCell)] = arraySRC;
	       	}
	       	else
	       	{
		        let array = this.recentlyOriginal[parseInt(fieldCell)];
		        if ( iRow < fieldRow)
	            {
	                //alert( iRow +"/"+ fieldRow);
	       			let field = array[fieldRow];
	       			field.no = parseInt(fieldCell)+1;
		       		array.splice(iRow, 0, field);
		       		array.splice(parseInt(fieldRow)+1, 1);
	       		}
	       		else
	       		{
	       			let field = array[fieldRow];
	       			field.no = parseInt(fieldCell)+1;
		       		array.splice(parseInt(iRow)+1, 0, field);
		       		array.splice(parseInt(fieldRow), 1);
	       		} 
	       		this.recentlyOriginal[parseInt(fieldCell)] = array;
	       	}
       		//alert( JSON.stringify(array));
	       	//this.$forceUpdate();
	       	//
	       	this.recentlyOriginal = this.beautify(this.recentlyOriginal);
	       	this.change('moduleMove')
	    },
	    clearAll()
	    {
	    	this.entityList=[];
	    	setReload(null);
	    },
	    beautify( recentlyOriginal)
	    {
	    	let titleArr = [];
	    	let tmp = [];
	    	
    		for ( let l = 0; l <= 10; l++ )
    		{
    			let lineArr = recentlyOriginal[ l];
    			if ( lineArr && lineArr.length )
    			{
    				titleArr.push(  this.titles[tmp.length]);
    				for ( let r in lineArr )
    				{
    					let elem = lineArr[ r];
    					elem.no = tmp.length + 1;
    				}
    				tmp.push( lineArr);
    			}
    			
    		}
    		this.titles = titleArr;
    		return tmp;
	    },
	  	userUpdated() { 
	    		let that = this;
				
				

	    		let bgSave = this.user.favorites.darkModeBGColor;
	    		this.recentlyOriginal = this.beautify(this.recentlyOriginal);
	    		this.user.favorites = { 
	    		    fontSize: that.fontSize,
	    			entities: that.recentlyOriginal, 
	    			titles: this.titles,
	    			shadowColor: this.shadowColor,
	    			rounding: this.rounding,
	    			shadow1: this.shadow1,
	    			shadow2: this.shadow2,
	    			multipleTables: this.multipleTables,
	    			darkMode: this.darkMode,
         			widgets: this.widgets,
         			widgetsSetup: this.widgetsSetup,
         			showHeader: this.showHeader,
         			darkModeBGColor: that.darkModeBGColor,
					darkModeBGColorSave: bgSave,
					editorStyle: sessionStorage.editorStyle?sessionStorage.editorStyle:'classic',
					tabLayoutIDX: sessionStorage.tabLayoutIDX?sessionStorage.tabLayoutIDX:0
	    		};
				//alert(userAPI+"/setUserFavorites/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId);
	    		//alert(JSON.stringify(this.user.favorites))
				HTTP.put( userAPI+"/setUserFavorites/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, this.user)
	            .then( response => 
	            { 
	 				that.user = response.data;
	                if ( !that.user.favorites )
	 				{
	 					that.recentlyOriginal = [];
	 				}
	 				else
	 				{
						let favs = that.user.favorites.entities;
		 				let tmp = [];
						for ( let i in favs)
						{
						    let fav = favs[i];
						   
						    if ( fav && Array.isArray(fav) )
						    {
						        for ( let ii in fav )
						        {
						            let f = fav[ii];
						            
									if ( ! tmp[f.no-1] )
									{
										tmp[f.no-1] = [];
									}
									tmp[f.no-1].push( f);
								}
							}
							else if ( fav)
							{
								if ( ! tmp[fav.no-1] )
								{
									tmp[fav.no-1] = [];
								}
								tmp[fav.no-1].push( fav );
							}
						}
						that.recentlyOriginal = tmp;
						that.changes = [];
		 				if ( !that.recentlyOriginal )
		 				{
		 					that.recentlyOriginal = [];
		 				}
		 				that.$toast.success("User "+that.user.login+" updated", 'Ok', { timeout: 1000, position: "topRight" });
		 			}
	 				
	            }).catch(e => {
	                   if ( e.response)
                        {
                        	that.$toast.error("saving user: " + e.response.data, 'Error', { position: "topRight" });
                     	}
                     	else
                     	{
                     		that.$toast.error("saving user: " + e, 'Error', { position: "topRight" });
                     	}
	
	            });
	    },
	    transform()
	    {
	    	let that = this;
	    	let tmp = that.recentlyOriginal;
	    	let transposed = [];
	    	
			for ( let line in tmp )
			{
				let row = tmp[line];
				for ( let col in row )
				{
					let td = row[col];
					if ( ! transposed[col] )
					{
						transposed[col] = [];
					}
					transposed[col][line] = td;
				}
				//transposed[row.length][line] = transposed[row.length-1][line];
			}
			
			return transposed;
	    },
	    getUser(force) { 
	    	
	    	if ( force || ! this.user || ! this.user.id)
	    	{
	    		
	    		let that = this;
	    		
	    		HTTP.post( userAPI+"/getById/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.login+"/"+sessionStorage.userId)
	            .then( response => 
	            { 
	                that.user = response.data;
	                	                
	                if ( !that.user.favorites )
	 				{
	 					that.recentlyOriginal = [];
						that.darkModeBGColor = null;
	 				}
	 				else
	 				{
	 					if ( that.user.favorites.titles )
	 					{
	 						that.titles = that.user.favorites.titles;
	 					}
	 					if ( that.user.favorites.darkModeBGColor && isDark(that.user.favorites.darkModeBGColor) )
	 					{
	 						that.darkModeBGColor = that.user.favorites.darkModeBGColor;
	 					}
						else
						{
							that.darkModeBGColor = null;
						}
						sessionStorage.editorStyle = that.user.favorites.editorStyle?that.user.favorites.editorStyle:'classic';
						sessionStorage.tabLayoutIDX = that.user.favorites.tabLayoutIDX?that.user.favorites.tabLayoutIDX:0;
	 					that.multipleTables = that.user.favorites.multipleTables;
	 					that.darkMode = that.user.favorites.darkMode;
	 					setDarkMode( that.darkMode);
	 					//alert( that.darkMode+"/"+that.user.favorites.darkModeBGColor)
	 					that.widgets = that.user.favorites.widgets;
	 					that.widgetsSetup = that.user.favorites.widgetsSetup;
	 					if (!that.widgets )
	 					{
	 						that.widgets= [];
	 					}
	 					if (!that.widgetsSetup )
	 					{
	 						that.widgetsSetup= {};
	 					}
	 					
	 					that.showHeader = that.user.favorites.showHeader;
						if ( !that.user.favorites.shadow1)
						{
							that.user.favorites.shadow1 = 1;
						}
						that.$set( that, "fontSize", that.user.favorites.fontSize);
						that.$set( that, "shadowColor", that.user.favorites.shadowColor);
						that.$set( that, "shadow2", that.user.favorites.shadow2);
						that.$set( that, "shadow1", that.user.favorites.shadow1);
						that.$set( that, "rounding", that.user.favorites.rounding);
						
						if ( ! that.fontSize )
						{
							that.fontSize = 9;
						}
		 				let favs = that.user.favorites.entities;
		 				let tmp = [];
		 				
		 				let SalesInputSceen = null; 
		 				let TrafficAssignSceen = null; 
		 				let TrafficDeliverySceen = null; 
						
						//let routes = that.$router.getRoutes();
						
						//console.log( JSON.stringify( favs[0]))
						for ( let i in favs)
						{
						    let fav = favs[i];

						    if ( fav && Array.isArray(fav) )
						    {
						        for ( let ii in fav )
						        {
						            let f = fav[ii];
									setTrTitle( f.module, f.title );
						            if ( f.module === '/workflowOperationsTrafficAssign' )
						            {
						            	TrafficAssignSceen = f;
						            }
						            else if ( f.module === '/workflowOperationsTrafficDelivery' )
						            {
						            	TrafficDeliverySceen = f;
						            }
						            else if ( f.module === '/workflowOperationsSalesInput' )
						            {
						            	SalesInputSceen = f;
						            }
		
							        if ( f && ! f.fcolor )
								    {
								    	f.fcolor='#000';
								   	}
									if ( ! tmp[f.no-1] )
									{
										tmp[f.no-1] = [];
									}
									tmp[f.no-1].push( f);
								}
							}
							else if ( fav)
							{
							    if ( fav.module === '/workflowOperationsTrafficAssign' )
						        {
						            	TrafficAssignSceen = fav;
						        }
						        else if ( fav.module === '/workflowOperationsTrafficDelivery' )
						        {
						            	TrafficDeliverySceen = fav;
						        }
						        else if ( fav.module === '/workflowOperationsSalesInput' )
						        {
						            	SalesInputSceen = fav;
						        }
						        
								if ( ! tmp[fav.no-1] )
								{
									tmp[fav.no-1] = [];
								}
								tmp[fav.no-1].push( fav );
							}
						}
						
						that.recentlyOriginal = that.beautify(tmp);
		 				if ( !that.recentlyOriginal )
		 				{
		 					that.recentlyOriginal = [];
		 				}
		 				that.changes =[];
						
			            //alert(TrafficAssignSceen.module+"/"+sessionStorage.unitId)
			            if ( TrafficAssignSceen && TrafficAssignSceen.module  )
			            {
				            HTTP.post( opsAPI+"/countSalesRequests/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/1/toTraffic")	
				    		 .then( response => 
				             {
				                that.$set( that.itemNotify, TrafficAssignSceen.module, response.data.todo);
				                that.$set( that.itemWarn, TrafficAssignSceen.module, response.data.issues);
				             }).catch(e => {
				           		    
				                    showError( that.$toast, "loading sales-requests", e);
				                });   
				        }
				        if ( TrafficDeliverySceen && TrafficDeliverySceen.module  )
			            {
				            HTTP.post( opsAPI+"/countSalesRequests/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/2/ReadyForDelivery")	
				    		 .then( response => 
				             {
				                that.$set( that.itemNotify, TrafficDeliverySceen.module, response.data.todo);
				                that.$set( that.itemWarn, TrafficDeliverySceen.module, response.data.issues);
				             }).catch(e => {
				           		    
				                    showError( that.$toast, "loading sales-requests", e);
				                });   
				        }
				        if ( SalesInputSceen && SalesInputSceen.module  )
			            {
				            HTTP.post( opsAPI+"/countSalesRequests/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/0/Issues")	
				    		 .then( response => 
				             {
				                that.$set( that.itemNotify, SalesInputSceen.module, response.data.todo);
				                that.$set( that.itemWarn, SalesInputSceen.module, response.data.issues);
				             }).catch(e => {
				           		    
				                    showError( that.$toast, "loading sales-requests", e);
				                });   
				        }
			            
		 			}
					
		 			if ( ! force )
		 			{
			 			if ( that.$route.params.module && that.$route.params.module !== 'myDashBoard' )
						{
						    if ( !that.entityList.map(p=>p.module).includes( that.$route.params.module) )
						    {
						   	 	that.entityList.push( { module: that.$route.params.module, xtitle: "XXX"});
						   	}
						 }
				     }
	            }).catch(e => {
	                   showError( that.$toast,  "loading user(workbench)", e);
	
	            });
	       }
	       else
	       {
       			if ( this.$route.params.module && this.$route.params.module !== 'myDashBoard' )
				{
				    if ( !this.entityList.map(p=>p.module).includes(this.$route.params.module) )
				    {
						//console.log("push("+this.$route.params.module+")")
				   	 	this.entityList.push( { module: this.$route.params.module, xtitle: "XXX"});
				   	}
				 }
	       }
	    },
	    toggleDarkMode(dm)
	    {
	    	if (!dm)
	    	{
				this.user.favorites.darkModeBGColorSave = this.darkModeBGColor;
				this.darkModeBGColor = "#fff";
				setDarkMode( false);
			}
			else
			{
				setDarkMode( true);
				if ( this.user.favorites.darkModeBGColorSave && isDark(this.user.favorites.darkModeBGColorSave))
				{
					this.darkModeBGColor = this.user.favorites.darkModeBGColorSave;
				}
				else if ( this.user.favorites.darkModeBGColor && isDark(this.user.favorites.darkModeBGColor))
				{
					this.darkModeBGColor = this.user.favorites.darkModeBGColor;
				}
				else
				{
					this.darkModeBGColor = "#111";
				}
			}
	    }
   },
  updated() {
	 // alert("U> "+this.$route.params.module)
  },
  created() {
	// alert(this.$route.params.module)
    if ( ! this.multipleTables )
	{
		this.entityList = [];
	}
	setReload(this.getUser);
	setListener( this.addModule );
	this.getUser();
	
  },
  destroyed() {
  	unregisterReload( this.getUser );
  	unregisterListener( this.addModule );
  },
  watch: {
	    darkModeBGColor: function() { setDarkModeBGColor( this.darkModeBGColor); },
	    darkMode: function(){ this.toggleDarkMode( this.darkMode);},
        '$route' () {
            if ( ! this.multipleTables )
			{
				this.entityList = [];
			}
			
			this.getUser();
		 }
    },
};
</script>
<style lang="scss" scoped>
th div { padding: 5pt; width: 100%; height: 100%; border-bottom: 2pt solid grey; }
th div:hover { 
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
    //border: 1pt outset grey;
    cursor: pointer;
    background-color: #e8f0e8; }
th { padding: 0pt; }
tr[marked=true] { 
    //text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
font-weight: bold; }
.module { cursor: pointer; margin: 4pt; margin-bottom: 2pt; margin-right: 0pt; margin-left: 0pt; display: flex; background-color: #eef; 
          border: 1pt solid #ccc; width: 150px; height: 32pt; align-items: center; justify-content: center; 
           
          }
.task { cursor: pointer; margin: 4pt; margin-bottom: 2pt; margin-right: 0pt; margin-left: 0pt; display: block; background-color: #eef; 
          border: 1pt solid #ccc; width: 90%; height: 38pt; align-items: center; justify-content: center; 
          box-shadow: 5px 5px 8px 0 #444; 
          }
.task:hover { background-color: #888; color: #fff; text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7); font-weight: bold; }
//.module:hover { text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1); font-weight: bold; }
.module:hover { background-color: #444 !important; color: #fff !important; 
   font-weight: bold; 
}
.withPaddingL { padding-left: 20pt;  padding-right: 5pt; }
.withPaddingR { padding-right: 20pt;  padding-left: 5pt; }
.reportItem {
	transition: all .4s;
   -webkit-transition: all .4s;
   font-size: 12pt;
   height: 12pt;
   cursor: pointer;
}
.shadow {
	transition: all .4s;
   -webkit-transition: all .4s;
   box-shadow: 5px 5px 8px 0 #444;
}
.shadowLight {
	transition: all .4s;
   -webkit-transition: all .4s;
   box-shadow: 2px 2px 4px 0 #666;
}
.transition {
 	transition: all .8s;
   -webkit-transition: all .8s;
 }
.reportItem:hover {
	background-color: #eef;
	
}
.breadcrumbItemCurrent {
}
.breadcrumbItemCurrent:hover {
	background-color: #888 !important;
	color: #eee !important;
	box-shadow: 4px 4px 5px 0 #66a;
}
.invisibleItem { 
    visibility: hidden;
    opacity: 0;
    width: 0pt !important;
    height: 0pt !important;
    -webkit-transform: scale(0.0);
        -ms-transform: scale(0.0);
        transform: scale(0.0);
    transition: all .5s;
   -webkit-transition: all .5s;

}
.buttonSmall{
	//width: 18%;
    padding: 6px 8px;
    outline: none;
    border-radius: 3px;
    height: 24pt;
    font-size: 10pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
   
    margin-right: 4pt;
}
.buttonSmall:hover{
	//width: 18%;
    padding: 6px 8px;
    outline: none;
    border-radius: 3px;
    height: 24pt;
    font-size: 10pt;
    background-color: #ccc;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
   
    margin-right: 4pt;
}
.mytable
{
    table-layout: fixed;
}

table.settings td {
	padding-bottom: 4pt;
} 


</style>