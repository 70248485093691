<template>
	<div :style="getAppStyle()" class='BMadbreak1'  >
		<div style="font-size: 8pt; color: #999; font-weight: normal;" >
					
			{{show( dayNo, daySlots )}}
		</div>
   </div>
</template>
<script>
import { getAppStyle, isDarkMode, getFG,getBGColor } from '@/AppStyle.js';
import GRate from '@/components/GFill';
import { formatPercentInt, format } from '@/Numbers.js';
export default {
  props: {
	  daySlots: { type: Array },
	  dayNo: { type: Number, default: 0 },
	 
	  display: { type: String, default: 'spots' },
	  forDuration: { type: Number, default: 10 },
  },
 components : {
	 
  },
  data () {
    return {
    	getAppStyle,getFG, getBGColor
    }
  },
 methods: {
   getClass()
   {
	
	if ( this.daySlot.available < 0)
	{
		return "hatchMinus"+((isDarkMode())?"DM":"");
	}
	if ( this.forDuration > this.daySlot.available )
	{
		return "hatchOver"+((isDarkMode())?"DM":"");
	}
	return "";
   },	
   show( dayNo, slots)
   {
		let result = 0;
		let total = 0;
		let min = 99999;
		let max = 0;
		for ( let s in slots)
		{
			let gslots = slots[s]
			//console.log( JSON.stringify( gslots ))
			let gridSlots = gslots.slots.filter(p=>p.dayNo==dayNo);
			
			for ( let d in gridSlots)
			{
				let daySlot = gridSlots[d];
				//console.log( JSON.stringify( daySlot ))
				if ( this.display == 'spots')
				{
					result += daySlot.spotCount.totalSpots;
				}
				if ( this.display == 'free')
				{
					result += daySlot.available;
				}
				if ( this.display == 'booked')
				{
					result +=  daySlot.capacity - daySlot.available;
				}
				if ( this.display == 'budget')
				{
					result += daySlot.spotCount.totalBudget;
				}
				if ( this.display == 'avail. ratecard')
				{
					result += daySlot.available * daySlot.pricePerSec;
				}
				if ( this.display == 'avail. ratecard (1xdur)')
				{
					if ( daySlot.available >= this.forDuration)
					{
						result += this.forDuration * daySlot.pricePerSec;
					}
					
				}
				if ( this.display == 'capacity')
				{
					result += daySlot.capacity;
				}
				if ( this.display == 'price')
				{
					min = Math.min( min, daySlot.price);
					max = Math.max( max, daySlot.price);
				}
				if ( this.display == 'pricegroup')
				{
					min = Math.min( min, daySlot.pricegroup);
					max = Math.max( max, daySlot.pricegroup);
				}
				if ( this.display == 'free ratio')
				{
					result += daySlot.available;
					total += daySlot.capacity;
				}
				if ( this.display == 'booked ratio')
				{
					result += daySlot.capacity - daySlot.available;
					total += daySlot.capacity;
				}
			}
		}
		if ( this.display == 'price' || this.display == 'pricegroup')
		{
			if ( max == 0 )
			{
				return "-"
			}
			return min+"-"+max;
		}
		if ( (this.display == 'budget') || (this.display ==  'avail. ratecard') || ( this.display == 'avail. ratecard (1xdur)'))
		{
			return format(result);
		}
		if ( this.display == 'free ratio' || this.display == 'booked ratio' )
		{
			if ( total == 0 )
			{
				return "-"
			}
			return formatPercentInt(result / total);
		}
		if ( this.display == 'avg price')
		{
			return "-"
		}
		return result;			
		
   },
   getTitle()
   {
		let title = "";
		title += this.daySlot.program+", Ratecard: "+this.daySlot.price+ ", Available: "+ this.daySlot.available+"sec"
		return title;
   }
 }
} 
</script>
<style lang="scss" scoped>
.BMadbreak1 {
	display: inline-flex; 
	width: 100%;
	max-height: 12pt;
	overflow-x: hidden;
    overflow-y: hidden;
	white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis; 
    vertical-align: top; 
    padding-top: 0pt;
    height: 100%;

}


.hatchOver
{
	background: repeating-linear-gradient( 180deg, #f99, #f99 1px, white 1px, white 5px);
}
.hatchMinus
{
	background: repeating-linear-gradient( 180deg, #f99, #f99 1px, white 1px, white 3px);
}


.hatchOverDM
{
	background: repeating-linear-gradient( 180deg, #f44, #f44 1px, black 1px, black 5px);
}
.hatchMinusDM
{
	background: repeating-linear-gradient( 180deg, #f44, #f44 1px, black 1px, black 3px);
}

</style>