<template>
<div class='inputWrapper' :style="getAppStyle()+'width:'+width+'% !important;'">
   <span v-if='clipboard'>
	    <span class='span25' :title="valueAsDate">
		    <input v-if="border" class="inputBorder" :style='getStyle()' :disabled=disabled ref='inp'
		    	@change="changed" 
		    	@keypress.enter="enterPressed()" 
		    	@keyup.esc="escapePressed()"
		    	@blur="$emit('blur')" 
		    	@focus="$event.target.select()" v-model="internalValue"/>
		    <input v-else class="inputText" :style='getStyle()' :disabled=disabled ref='inp'
		    	@change="changed" 
		    	@keypress.enter="enterPressed()" 
		    	@keyup.esc="escapePressed()"
		    	@blur="$emit('blur')" 
		    	@focus="$event.target.select()" v-model="internalValue"/>
	    </span>
	    <button @click="help" class="button" :style="getAppStyle()+'padding: 0pt; width: 20pt; height: 22pt;'"> 
	    <mdicon size="14" name="help"></mdicon>
	    </button >
	    <button @click="showDate" class="button" :style="getAppStyle()+'padding: 0pt; width: 20pt; height: 22pt;'"> 
	    <mdicon size="22" name="calendar-check-outline"></mdicon>
	    </button >
	    
        <span v-if="clipboard" class='span75'>
          <clipboard v-model="internalValue"><icon-write /></clipboard>
        </span>
   </span>    
   
   <span v-else>
	    <span class='span100'>
	     
		<input v-if="border" ref='inp' class="inputBorder" :style='getStyle()' :disabled=disabled 
	    	@change="changed" 
	    	@keypress.enter="enterPressed()" 
	    	@keyup.esc="escapePressed()"
	    	@blur="$emit('blur')" 
	    	@keydown.tab="$emit('tab')"
	    	@focus="$event.target.select()" v-model="internalValue"/>
	    <input v-else ref='inp' class="inputTextNBNC" :style='getStyle()' :disabled=disabled 
	    	@change="changed" 
	    	@keypress.enter="enterPressed()" 
	    	@keyup.esc="escapePressed()"
	    	@keydown.tab="$emit('tab')"
	    	@blur="$emit('blur')" 
	    	@focus="$event.target.select()" v-model="internalValue"/>

	    </span>
	    
    </span>
    <GConfirm ref='confirm'/>
    <DisplayHelp :ref="'displayHelp1'" />
</div>
</template>
<script>
import clipboard from '@/components/Clipboard';
import {HTTP, fwAPI, showError} from '@/variables.js';
import { printTime2WithSec} from '@/basicTimeFN.js';

import GConfirm from '@/components/GConfirm';
import DisplayHelp from '@/components/inputElements/DisplayHelp';
import { getAppStyle, setDarkMode, initAppMode } from '@/AppStyle.js';
export default {
  props: {
    value: String,
    fontSize: { type: Number, default: 14},
	dataId: { type: Number, default: 0},
    disabled: Boolean,
    width:  { type: Number, default: 100 },
    mark: { type: Boolean, default: false},
    search: { type: Boolean, default: false},
    clipboard: { type: Boolean, default: true},
    border: { type: Boolean, default: false}
  },
  data() {
      return {
      	copy: null,
      	init: true,
      	valueAsDate: null,
      	doSearch: false,
      	getAppStyle
      }
  }, 
  components : {
    clipboard, GConfirm, DisplayHelp
  },
  methods: {
      open() { this.init = true; this.$refs.inp.focus(); this.$refs.inp.select();},
      getStyle() { 
      	if ( this.mark )
      	{
      		return "font-size:" +this.fontSize+"px; background-color: #fdd;";
      	}
      	return "font-size:" +this.fontSize+"px;"+getAppStyle();

      },
      save()  {
      	if ( this.init ) { this.copy = this.value; this.init = false }
      },
      showDate( )
      {
    	  this.getDate( this.value);
    	  this.$toast.info(this.valueAsDate, 'Current duration: ', { timeout: 1500, position: "topRight" });
      },
      help()
      {
    	  let text=["P is the duration designator (referred to as 'period'), and is always placed at the beginning of the duration.",
			"Y is the year designator that follows the value for the number of years.",
			"M is the month designator that follows the value for the number of months.",
			"W is the week designator that follows the value for the number of weeks.",
			"D is the day designator that follows the value for the number of days.",
			"T is the time designator that precedes the time components.",
			"H is the hour designator that follows the value for the number of hours.",
			"M is the minute designator that follows the value for the number of minutes.",
			"S is the second designator that follows the value for the number of seconds.",
			"<b>PT1M10s = 00:01:10, PT30S = 00:00:30, PT1H = 01:00:00"] 
    	  this.$refs['displayHelp1'].open( "ISO Duration", text );
      },
      getDate( value)
      {
    	  HTTP.get( fwAPI+"/isoDur2Sec/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+value)
          .then( response => 
          {
          	this.valueAsDate = printTime2WithSec(response.data);
          	
	      }).catch(e => {
	    	  this.valueAsDate = JSON.stringify(e);
          });
      },
      fire()
      {
    	  if ( this.search)
    	  {
    		this.$emit('search');
    	  }
    	  else
    	  {
    		  this.$emit('enter');
    	   }
      },
      enterPressed() { this.fire();   },
      escapePressed() { this.$emit("input", this.copy); this.$emit("escape"); },
      changed() { this.$emit("changed", this.value); }
  },
  created() {
    //this.copy = ""+this.value; 
    this.getDate( this.value);
    
  },
  mounted() {
    //this.copy = ""+this.value; 
    this.open();
    
  },
  computed: {
    internalValue: {
      get() {
        this.save();
        this.getDate( this.value); 
        return this.value
      },
      set(v) {
    	//this.getDate( v);
        this.$emit("input", v)
      }
    }
    }
 }
</script>
<style lang="scss" scoped>
input { border: none; width: 100%}
input:disabled { font-weight: bold; font-size: 12pt; border: none; width: 100%; color: #888; }
input:focus { outline: 1pt dotted grey;}

.inputText { text-align: left; height: 100%; margin: 0; padding: 0; border: none; }
.inputTextNBNC { text-align: left; height: 20pt; margin: 0; padding: 0; border: none; }
.inputBorder { padding: 2pt; text-align: left; border: 1pt solid #ccc;  }
.span100 { display: inline-block; width: 100%; height: 100%;}
.span25 { display: inline-flex; width: 75%; height: 100% !important; }
.spanSX { display: inline-flex; width: 80%; height: 100% !important; }
.dist5 { margin-left: 4pt; }
.span75 {  width: 5%; text-align: right; float: right; }
.inputWrapper { 
    display: inline-block;
    border: 1pt solid #ccc;
    border-radius: 6px;
    padding: 2pt;
    background-color: transparent !important;
}

.inputButton { 
    border: 1pt solid grey;
}

</style>