<template>
  <div style='margin: 0pt; height: 100%; width:100%;' @contextmenu="mouseRClicked($event)">
 
  <div style='width: 100%;' :style='getAppStyle()'>
     <div style='width: 100%;'>  	  
        <div>
          <slot name="body">
            <div style='float: top; width: 100%;' >
             <div class='transition' :style='getControlStyle()'>
	    		<!-- CONTROLS -->
			    <div v-if="stations && stations.length" style='float: top; width: 100%; vertical-align: middle;' class='smaller'>
					
					<span class='SVcontrolLabel'>Channel/Station:</span><br/>
						<GSelect style='width: 100%' :options="stations" v-model="selectedStation" :multiple="false" @input="getInventory">  
								    	 
						</GSelect>

					    <br/>
					    <div>
					    <div style='display: inline-flex; width: 45%;'>
					    <InputDatePick  :auto="false" 
		                            @enter="getInventory()" v-model="fromDate"/>  
		                            </div>
		                            -
		                <div style='display: inline-flex; width: 45%;'>
		                <InputDatePick  :auto="false" 
		                            @enter="getInventory()" v-model="untilDate"/>     
		                 </div>           

						</div>
						
						
						<span class='dontWrap smallText bold'>Product:</span>
						<InputTextWithSearch withDelete="true" search='true' @id="p=>productId=p" v-model="product" :clipboard='false' searchImmed='99' entity="Product"/>
						<br/>
						
						
						
						<span class='dontWrap smallText bold'>Display:</span>
							<GSelect :options="allDisplays" v-model="display"  >  
						    </GSelect>
						  <span class='dontWrap smallText bold'>Duration:</span>
						  <InputInt v-model="duration"/> 
						  
						  <span v-if="setup.rcFactor" class='dontWrap smallText bold'>RCFactor:</span>
						  <InputDouble v-if="setup.rcFactor" v-model="rcFactor"/> 

						<br/>
						<div  class="dontWrapIB" style='height: 21pt; width: 100%;'>
							<span v-if="gridViewFin" class='smallText bold' style='width: 50%;'>Financial day</span>
							<span v-else class='smallText bold' style='width: 50%;'>TX day</span>
							<div class="dontWrapIB" style='float: right; padding-top: 3pt; width: 40%; display: inline-block;'>
								<app-switch v-model="gridViewFin" :checked="gridViewFin"/>
							</div>
						</div>	
						<br/>				
			        <!-- FILTERS -->    
				    <div class="BMSection" :style="getBGStyle2()+'width: 100%; !important'">
			              <span class='BMSectionDetail dontWrap90 smallText'><b>FILTER:</b></span>
			        </div>

				    <span class='dontWrap smallText bold'>Pricegroups:</span>
					<GSelect :options="allPG" v-model="selectedPG" :multiple="true" >  
				    </GSelect>
				    
				    <span class='dontWrap smallText bold'>Timeslots:</span>
					<GSelect :options="allTimeslots" v-model="selectedTimeslots" :multiple="true">  
				    </GSelect>
				    
				    <span class='dontWrap smallText bold'>Grids/Programs:</span>
					<GSelect :options="allGrids" v-model="selectedGrids" :multiple="true" >  
				    </GSelect>

				    <button @click='clearFilter()' :style="getAppStyle()+'font-size: 8pt;'" class='button myButtonSmall minRound'>Remove filter</button>
					<button @click='getInventory()' :style="getAppStyle()+'font-size: 8pt;'" class='button myButtonSmall minRound'><mdicon size="16" name='reload'/></button>
					<button :title='"create detailed excel report"' class='button myButtonSmall minRound' :style="getAppStyle()+'float: right;'" @click="createExcel(2)"><mdicon :size="18" name="microsoft-excel" /><mdicon :size="18" name="plus" /></button> 
					<button :title='"create summary excel report for all channels"' class='button myButtonSmall minRound' :style="getAppStyle()+'float: right;'" @click="createExcel(3)"><mdicon :size="18" name="microsoft-excel" /><mdicon :size="18" name="sigma" /></button> 
					<button :title='"create excel report"' class='button myButtonSmall minRound' :style="getAppStyle()+'float: right;'" @click="createExcel(1)"><mdicon :size="18" name="microsoft-excel" /></button> 
										
					
			    </div> <!-- CONTROLS -->
    		</div>
    		</div>
    
    		<!-- MAIN -->
			
            <div class='transition' v-if="inventoryPlacementResult && inventoryPlacementResult.days" style='float: left; width: 80%; height: calc(100vH - 120px); overflow-y: auto; position: relative;'> 
				
				<div v-for="(result,ri) in inventoryPlacementResult.gridPeriodResult" :key="'r'+ri" style='width: 100%; float: left; position: relative;' >
					   <!-- LEFT TABLE-->
					   <div class='BMHeadActions' :style="'background-color: '+getBG()+' !important; height: 21pt; margin-top: 0;'">
						   <img @click="$refs.editor.open('Media', result.channel.id);" 
						        :style="'background-color: '+getBGColor('#fff','#444') +';float:left; height: 100%;'" :src="getImageData(result.channel.logo)"/>
				           <div class="channelTitle" >{{result.channel.name}}  
						   <span style="float: right; width: 40%;">	
							<vue-slider style='z-index: 0;' :min="0" :max="lastDay-14" v-model="firstDay"></vue-slider>
							</span>
						   </div>
					   </div>
			           <div style='float: top; width: 100%; position: relative;'>
				           <div style='float: left; display: flex;'>
					            <div>
								    <div :style="'background-color: '+getBG()+';float: top left; overflow-y: hidden; cursor: pointer;'" class='BMHeadActions22'
								         @click="markAll(result)" title='mark *ALL* visible breaks'>
								        <div class="BMday transition" style="width: 40pt !important;">
									        <div class='BMheader BMheadLine' :style="getStyle2('channel')">
									       
										    </div>
										</div>
										<div class="BMday transition" style="width: 40pt !important;">
									        <div class='BMheader BMheadLine' :style="getStyle2('break')">
									         
										    </div>
										</div>
								   </div>
								   <div style='float: top left;'>
		   						   		<div v-for="(grid,gi) in filterGrid(result.grids)" :key="'g'+gi">
											
		   							        <div style='float: top left; overflow-y: hidden;' class="BMday transition " >
		   								        <div class='BMheader BMtime' :style="getStyleFor('channel', result.channel, grid)">
		   									    	{{printTime2(grid.time)}}
		   									    </div>
		   									     
		   									</div>
		   									<div style='display: table-cell;'>
			   									<div class='BMheader' :style="getStyleFor('full', result.channel, grid)">
			   										<div style='display: block;' class="day transition" >
			   											<div class="BMday transition">
			   										        <div class=" BMheader BMprogram" 
			   										              :style="getStyleFor('break', result.channel)">
			   											    	{{grid.gridName}}
			   											    </div>
															
														    <div style='display: table-cell;'>
			   											        <div class='BMheaderInv BMinfo'  :style="getStyleFor('break')+';color: transparent;width: 100%;'"></div>
	
				   												<div class='BMheader BMX' :style="getStyleFor('break', result.channel, grid)">
				   													<div style='display: block;' class="BMday transition" 
																	     v-for="(slot,si) in filterSlot( grid.slots)" :key="'s'+si">
				   														<div class="BMday transition" :style="'display: block; '+getStyleFor('break')" >
																		    <div>
				   															    <div class='BMbreak BMLabel BMheader' :style="getStyleFor('break')">
				   															       {{ slot.label }}
				   															    </div>	
				   														    </div>
				   														    
				   														</div>    
				   													</div>
			   										
			   												</div>
			   											</div>
													</div>
			   										    
			   									</div>
			   							
			   								</div>
		   								</div>
		   							        
		   							 </div>
		   						 </div>	  
								   
							   </div>  
						   </div>
			        	</div>
						<!-- LEFT TABLE-->
						
						<!-- DAILY GRID -->
						
					       <div  :style='getStyleForDayTable()'>
					            <div :style="'display: block; background-color: '+getBGColor('#f8f8f8','#080808')+' !important;'">
									<div  :style="'background-color: '+getBG()+';display: block'" class="BMHeadActions22">
									    <div >
					
										    <div class="BMday transition" v-for="(day,idx) in filterDays(inventoryPlacementResult.days)" :key="'d_'+idx">
											    <div  class='BMheaderDay BMheadLine' :style="'font-weight: normal;'+getStyleFor('day')">
											    <b>{{day.weekday}} {{ day.name}}</b>
											    </div>	
										    </div>
									   </div>
									   <div  >
									   		
			   							    <div class="BMday transition" v-for="(day,idx) in filterDays(result.days)" :key="'i_'+idx" >
			   								    <div class='BMheaderDay BMbreak BMCounter' title='mark all visible Breaks on this day' @click="markAllDay(day, null, result)" :style="getStyleFor('day')">
													<BookingDay :forDuration="duration" :dayNo="day.no" :daySlots="filterGrid(result.grids)" :display="display.label"/>
			   								    </div>	
			   							    </div>
			   						   </div>
								  </div>
								 
								   <div style='display: block; float: top left;  overflow-y: hidden;'>
			   					   		<div v-for="(grid,gi) in filterGrid(result.grids)" :key="'gx'+gi">
											
											<div style='float: top left; overflow-y: hidden;'>
																			   		
				   							    <div class="BMday transition" v-for="(day,idx) in filterDays(inventoryPlacementResult.days)" :key="'i_'+idx" >
				   								    <div class='BMheaderDay BMbreak BMprogramDate'  :style="getStyleFor('day')+';background-color:'+getBGColor('#ccc','#444')">
				   								    {{day.weekday}} {{ day.name}}
				   								    </div>	
				   							    </div>
				   						   </div>
										   
										   <div style='float: top left; overflow-y: hidden;'>																	   		
   				   							    <div class="BMday transition" v-for="(day,idx) in filterDays(inventoryPlacementResult.days)" :key="'i_'+idx" >
   				   								    <div class='BMheaderDay BMbreak BMCounter' :style="getStyleFor('day')">
													
   				   								    <BookingGrid :forDuration="duration" :dayNo="day.no" :daySlots="filterSlot(grid.slots)" :display="display.label"/>
   				   								    </div>	
   				   							    </div>
   				   						   </div>
										   
										   <div  v-for="(slot,si) in filterSlot(grid.slots)" :key="'sx'+si"  style='float: top left; overflow-y: hidden;'>																	   		
  				   							    <div class="BMday transition" v-for="(day,idx) in filterDays(inventoryPlacementResult.days)" :key="'i_'+idx" >
													<div v-if="filterDaySlot(slot.slots.find(p=>p.dayNo==day.no))">
  				   								        <div v-for="(daySlot,dsi) in [filterDaySlot(slot.slots.find(p=>p.dayNo==day.no))]" :key="'ds'+dsi" 
															   class='BMheaderDay BMadbreak' 
															   :style="getStyleFor('day')">
															<div :style="getAppStyle()" >
																
															<BookingGridBreak :forDuration="duration" :exclusion="daySlot.exclusion" :clashCode="daySlot.clashCode" :daySlot="daySlot" :display="display.label"/>
															
															</div>
														</div>
														
													 </div>
													 <div v-else class='BMheaderDay notAvail' 
														  :style="getStyleFor('day')+'; background-color: '+(user.bookSettings?user.bookSettings.unavailColor:'#eee')+';'">
  				   								  		
  				   								    </div>	
  				   							    </div>
  				   						   </div>
										 
										</div>
									</div>
							</div>
						
						<!-- DAILY GRID -->
					</div>
				</div>
			</div> <!-- mainContent -->
			
	      </slot>
	    </div> <!-- modal-body -->
	  </div> <!-- modal-container -->  	  
	  
	 </div> <!-- modal-wrapper -->  
   
    <ContextMenu ref="menu">
      <template v-if="contextData"  slot-scope="{ contextData }">
		
		<ContextMenuItem v-if="markOnlyAvailable"  @clicked="markOnlyAvailable = false; $refs.menu.close();">
          Mark all breaks (for cancellation)
        </ContextMenuItem>
		<ContextMenuItem v-else @clicked="markOnlyAvailable = true; $refs.menu.close();">
          Mark only available breaks (for booking)
        </ContextMenuItem>
		<ContextMenuSep v-if="contextData.channel"/>
        <ContextMenuItem v-if="contextData.key" @clicked="openBreak($event, contextData.key, contextData.slot)">
          Open Break {{contextData.day.name}} / {{contextData.slot.code}}
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.day" @clicked="openDetailPlan( contextData.channel, contextData.day.date)">
          Open RunDown Screen {{contextData.day.name}}
        </ContextMenuItem>
       
       </template>
    </ContextMenu>
	<ProgressBar v-if="showProgressBar" :generalInfo=pbTitle :action=pbAction @action=action></ProgressBar>
    <BreakView ref='breakView' :reference=selectedRef :time="new Date().getTime()" 
        :options=options
        @saveUser=saveUser
        @deleteSpot="deleteSpot"
        @setTypeForSpots="setTypeForSpots"
        @close="closeBreakView()">
    </BreakView>
	<GFWEOpenEditor ref='editor' @update='doReload'></GFWEOpenEditor>

   
 </div>
</template>

<script>
import GFWTreeView from '@/components/GFWTreeView';
import InputWeekdays from '@/components/inputElements/InputWeekdays';
import InputTime from '@/components/inputElements/InputTime2';
import InputInt from '@/components/inputElements/InputInt';
import InputDouble from '@/components/inputElements/InputDouble';
import GProgress from '@/components/misc/GProgress';
import ProgressBar from '@/components/ProgressBar';
import BreakView from '@/components/BreakViewNew';
import BookingGridBreak from '@/components/booking/BookingGridBreakAvail';
import BookingGrid from '@/components/booking/BookingGridAvail';
import BookingDay from '@/components/booking/BookingDayAvail';
import InputDatePick from '@/components/inputElements/InputDatePick3';
import InputTextWithSearch from '@/components/inputElements/InputTextWithSearch';
import GSelect from '@/components/misc/GSelect'
import Switch from '@/components/Switch';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';
import {HTTP, fwAPI, invAPI, bngAPI, placementServiceAPI, reportAPI, setReload, formatNumber, showError} from '@/variables.js';
import { getAppStyle, setDarkMode, initAppMode, getBGStyle2, getBG, getBGColor, isDarkMode } from '@/AppStyle.js';
import {fmtTimePart, printTimeHHMM, printTime2 } from '@/basicTimeFN.js';
import { formatPercent, format } from '@/Numbers.js';
import { getDataByName}  from '@/utils.js';
import { forceFileDownload} from '@/fileUtil.js';
import { getRawKey, getRawKeyById } from '@/bookMan.js';
import { setGoBack } from '@/breadCrumb.js';
import { openView } from '@/utils.js';
import { isoDate } from '@/basicTimeFN.js';
import ApexCharts from 'apexcharts'
var momentTZ = require('moment-timezone');
var numeral = require('numeral');
var timers = [];
export default {
  name: 'GFW BookingGrid',
  components : {
    ContextMenu, ContextMenuItem, ContextMenuSep, BreakView, GSelect, BookingGrid, BookingDay, InputTextWithSearch, InputDouble
	, BookingGridBreak, InputDatePick, ProgressBar, InputInt, 'app-switch': Switch
  },
  props: {
  
  	targetType: Object,
    options: Object,
    targetGroup: Object,
    markBreaks: Array,
    spotsUnplacedBreak: Array,
    spotsBreak: Array,
    spotsBreakTotal: Array,
    invalidSpots: Array,
    invalidSpotsChanged: {type: Number, defaultValue: 0},
    allBookingStats: Array,
    placementInfo: Object,
    inputBreakTypeIds: Array,
    time: Number
  },
  data () {
    return {
		inventoryPlacementResult: null,
		productId: 0,
		product: "",
		rcFactor: 1.0,
		allDisplays: [],
		duration: 20,
		gridViewFin: false,
		display: { id: 1, label: 'free', visible: true},
		inputWeekdays: 127,
		stationId: 0,
        station: {},
		user: null,
        selectedStation: {},
        currency: {},
        stations: [],
        data: [],
	    reports: [],
        fromDate: {},
        untilDate: {},
        controlsRight: false,
	    showProgressBar: false,
	    pbAction: "",
        pbInfo: "",
        pbReport: null,
			  		
      placement: {},
      placementFrom: null,
      dayHasBreak: null,
      placementTo: null,
      openThisModule: String,
      inventory: {},
      gridData: [],
      mediaSet: {},
      channels: {},
      channelDiffs: [],
      timeSlots: {},
	  graphToShow: 'weekPaid',
	  markOnlyAvailable: true,
	  detailLevel: 1,
      
      durationData: [],
      breakTypeData: [],
      plcmBreakTypes: [],
      
      showEditor: false,
      entityMap: {},
      spotListTime: 0,
      mapping: [],
      metadata: {},
      dataId: 0,
	  firstDay: 0,
	  lastDay: 0,
	  chart: null,
	  chartOptions: null,
      //
      allMedia: [],
      selectedMedia: [],
      allTimeslots: [],
      selectedTimeslots: [],
      allPG: [],
      selectedPG: [],
      allGrids: [],
      selectedGrids: [],
      allCats: [],
      selectedCats: [],
      //
      selectedRecord: {},
      selectedIndex: 0,
      allEntities: [],
      widths: [],
      weekdayLabel: [],
      weekdays: [],
      weekdayBITS: [],
      marked: [],
      dates: [],
      isoDates: [],
      fontSize: {},
      limitDays: 60,
      spotsPlacement: [],
	  setup: {},
      
      spots: 0,
      markedSpots: 0,
      markedGRP: 0,
      shown: false,
      showBreakView: false,
      
      selectedRef: "",
      selectedGrid: {},
      loader: {},
      format,getBG,
      getAppStyle,
      getBGStyle2,
	  getBGColor,
      loadingActive: false
    }
  },
  beforeUpdate: function () {
  	this.getOpenThisModule();
  },

  methods: {
    getOpenThisModule() 
    {
    	this.openThisModule = this.$route.query.toOpen;
    	return this.openThisModule;
    },
	action( what, myId, api)
	{
		this[what](myId, api)
	},
	createExcel( detailLevel) 
	{
		this.detailLevel = detailLevel;
    	this.pbAction = "createExcelInternal";
    	this.pbInfo = "Creating excel"
    	this.showProgressBar = true;
    },
	getRcFactor( productId)
	{
		let that = this;
		if ( productId && this.setup.rcFactor )
		{
			let date = new Date().toISOString().split('T')[0];
			HTTP.post( placementServiceAPI+"/getRcFactor/"+sessionStorage.tenantId
					  				  +"/"+sessionStorage.accessPointId
									  +"/"+sessionStorage.unitId
									  +"/"+sessionStorage.userId
									  +"/"+date
									  +"/"+productId)
			.then( response => 
			{
				if ( response.data) 
				{ 
					that.rcFactor = response.data;
				}
			})
		}
		else
		{
			that.rcFactor = 1.0;
		}
	},
	
	getSetup( )
	{
		let that = this;
		HTTP.post( invAPI+"/getSetupAvailReport/"+sessionStorage.tenantId
							  				  +"/"+sessionStorage.accessPointId
											  +"/"+sessionStorage.unitId
											  +"/"+sessionStorage.userId)
		.then( response => 
		{
			if ( response.data) 
			{ 
				that.setup = response.data;
				//alert( JSON.stringify(that.setup))
			}
		})
	},
	createExcelInternal(myId, api)
	{
	   let that = this;
       let dateFrom = new Date(this.fromDate).toISOString().split('T')[0];
       let dateTo = new Date(this.untilDate).toISOString().split('T')[0];
       that.gridValid = [];
       // GET INVENTORY 
       // GET GRIDS
       let filterStructure = {};
       filterStructure.placement = null;
	   if ( this.productId )
	   {
		filterStructure.product = {id: this.productId, name: this.product};
	   }
	   filterStructure.duration = this.duration;
	   //alert( JSON.stringify( this.selectedStation))
	   filterStructure.mediaIds = [ this.selectedStation.id];
	   //alert( JSON.stringify(filterStructure))
	   let view = this.gridViewFin ? "Financial": "TX";
	  
	   api.post( invAPI+"/createExcel4AvailsReport/"+sessionStorage.tenantId
		  				  +"/"+sessionStorage.accessPointId
						  +"/"+sessionStorage.unitId
						  +"/"+sessionStorage.userId
						  +"/"+dateFrom
						  +"/"+dateTo
						  +"/"+sessionStorage.timezoneId
						  +"/null/"+view
						  +"/"+that.rcFactor
						  +"/"+that.detailLevel
						  +"/"+myId, filterStructure )
		 .then( response => 
         {
			let downloadResponse = response.data;
            forceFileDownload( downloadResponse.file, downloadResponse.filename);
			that.showProgressBar = false;
         	//
            
         }).catch(e => {
			    console.log( e)
        	    showError( that.$toast, "get excel data", e);
        	    that.showProgressBar = false;
            });
	},
	printDate( date)
	{
		let fmt = sessionStorage.dateFormat;
	    let time = date;
        try
        {
            time = momentTZ(new Date(date)).tz(sessionStorage.timezone).format(fmt);
        }
		catch (e)
		{
			//
		}
		return time;
	},
	getImageData(val) { return val?'data:image/png;base64,' + val: 'data:image/png'; },
	filterDays( days )
	{
		if ( days && days.length)
		{
			if ( this.firstDay  )
			{
				try {
					return days.filter( p => p && p.no >= parseInt(this.firstDay) && (this.inputWeekdays & p.weekdayBit));
				} catch ( e )
				{
					return days.filter( p => p && (this.inputWeekdays & p.weekdayBit));
				}
			}
		}
		return days.filter( p => p && (this.inputWeekdays & p.weekdayBit));

	},
	available( slot)
	{
		if ( slot.exclusion )
		{
			return false;
		}
		if ( slot.clashCode)
		{
			return false;
		}
		if ( slot.clashCode )
		{
			return false;
		}
		if ( this.duration > slot.available )
		{
			return false;
		}
		return true;
	},	
    getControlStyle() {
    	if ( this.controlsRight )
    	{
    		return "float: right; width: 18%; height: calc(100vH - 234px); padding: 1ex;"
    	}
    	return "float: left; width: 18%; height: calc(100vH - 200px); padding: 1ex;"
    },
    limit(dates)
     {
     	let maxLen = this.limitDays;
        if ( dates.length > maxLen )
        {
        	return dates.slice().splice( 0, maxLen );
        }
        return dates;
     },
     getPercent( spots, targetType, target)
    {
    	return target==0? 100: Math.round(10000*spots/target)/100;
    }, 
    getMainContentStyle()  { return "height: "+ this.mainHeight+"% !important;"; },
    fmt( val)
    {
       return formatNumber( val );
    },
    openDetailPlan(station, isoDay)
    {
    	setGoBack( this.$router.currentRoute.path, this.$router.currentRoute.name);
    	openView( this.$router, this.$toast, 'detailPlanning?stationId='+station.id+'&day='+ isoDay);
    },
    adSpace(iGrid)
    {
    	
    	if ( iGrid.replacements )
    	{
    		let r = iGrid.replacements.find( p=>p.date !== null);
    		if ( r)
    		{
    			//alert( JSON.stringify( iGrid.adspace))
    			return iGrid.adspace.concat( iGrid.adspace);
    		}
    	}
    	return iGrid.adspace;
    },
    saveUser( user)
    {
    	this.$emit("saveUser", user);
    },
    deleteSpot( toDelete)
    {
		//alert( JSON.stringify( toDelete))
    	this.$emit("deleteSpot", toDelete);
    },
    setTypeForSpots( mySpots, type)
    {
    	this.$emit("setTypeForSpots", mySpots, type);
    },
    closeDetails() {
       this.treeHeight=90.0;
       this.placements = [];
       this.gridValid = [];
       this.inventory={};
       this.placement={};
       this.selectedGrid={}
       this.showPlacementGrid = false;
       this.showPlacementSpots = false;
    },
    
    getWidth() 
    {
    	let max = window.innerWidth;
    	
    	let avail = (max * 0.8) - 152;

    	return avail;
    },
    getHeight() 
    {
    	let max = window.innerHeight;
    	
    	let avail = (max)-120;

    	return avail;
    },
    
    nodeRClicked(event, channel, day, daySlot)
    {
		let key = getRawKeyById( daySlot.breakGridId, day.date);
		this.$refs.breakView.close();
        this.$refs.menu.open(event, { key: key, channel: channel, day: day, slot: daySlot} )
    },
	mouseRClicked(event)
    {
        event.preventDefault();
        //this.$refs.menu.open(event, { } )
    },
    openBreak( event, ref, slot ) {
    	this.selectedRef = ref;
    	this.$refs.menu.close();
    	this.$refs.breakView.open(ref, slot, this.duration);
    },
    closeBreakView() {
    	this.$refs.breakView.close();
    },
    getMainStyle()
    {
    	return "padding: 10pt 10pt 10pt 10pt; float: left; width: 75%; height: "+this.getHeight()+"px; overflow-y: scroll;";
    },
    filter( breakTypes )
    {
    	var copy = new Array;
    	
    	for ( var bi in breakTypes )
    	{
    		let b = breakTypes[bi];
    		if ( this.breakTypeVisible( b.id))
    		{
    			copy.push(b);
    		}
    	}
    	return copy;
    }, 
    breakTypeVisible( typeId )
    {
    	return this.inputBreakTypeIds.includes(typeId);
    },
    getKey(channel, grid, adspace, breaktype, day)
    {
		if ( adspace && adspace.id )
		{
			return getRawKeyById( adspace.id, this.isoDates[ day]);
		}
		if ( adspace && adspace.no )
		{
    		return getRawKey( channel, grid.detail.id, adspace.no, breaktype.id, this.isoDates[ day]);
		}
		return "-";
    },
    getStyleForDayTable()
    {
    	return "float: top left; display: flex; width: "+this.getWidth()+"px !important; position: relative; overflow-x: clip;"
    },
	getStyleFor(name, channel, grid, slot)
    {
        var w = 50;
        var h = 1;
        
		if ( channel && grid && grid.slots && name != 'day')
		{
		    h = this.filterSlot(grid.slots).length;   
			if ( name != 'full' && name != 'break' )
        	{ 
        		h += 2;
        	} 		
		}
		else if ( channel && (grid) )
        {
            h = 2*this.filterSlot(grid.slots).length;
        	
        }
		if ( name === 'program' )
        {
        	w=50;
    	}
		if ( name === 'channel' )
        {
        	w=40;
    	}
		if ( name === 'break' )
	    {
	    	w=50;
		}
		if ( name === 'day' )
	    {
	    	w=40;
		}
    	if ( name == 'full' )
    	{
    		return "height: "+(14*(h))+"pt; width: 100% !important;";
    	}
    	return "position: relative; height: "+(14*h)+"pt; width: "+w+"pt !important; max-width: "+w+"pt !important; min-width: "+w+"pt !important;";
    },
    getStyle2(name, channel, slot, grid)
    {
        var w = 30;
        var h = 1;
     
        if ( name === 'channel' )
        {
        	w=40;
    	}
    	if ( name === 'break' )
        {
        	w=50;
    	}
    	if ( name == 'full' )
    	{
    		return "height: "+(28*h)+"pt; width: 100% !important";
    	}
    	return "height: "+(28*h)+"pt; width: "+w+"pt !important; valign: bottom !important";
    },

    dayStyle() {
    	return "float: left; width: 4%; ";
    },
    
    closeEditor()
    {
         this.showEditor = false;
    },
    
	weekdaySet(weekdays, date)
	{
		let wd = this.weekdays[date];
    	return ( weekdays & wd )
	},
	
    printTimeslot( elem)
    {
    	return printTime2(elem.time) + " - " + printTime2(elem.time+elem.duration);
    },
	getStations() {
        let that = this;
		this.startLoader()
    	let stationLoad = new Promise(function (resolve, reject) {
            HTTP.post( reportAPI+"/getSpecialReports/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId)
        		 .then( response => 
                 {
                 	var allStations = response.data.stations;
					that.user = response.data.user;
					
					if ( that.user.bookSettings && that.user.bookSettings.gridViewFin )
			        {
			        	that.gridViewFin = that.user.bookSettings.gridViewFin;
			        }
					that.stations = allStations.map(a => {return {id: a.id, label: a.name, visible: true}});
                    that.reports = response.data.reports;
                    that.selectedStation =  that.stations[0];
					that.allDisplays = [];
					that.allDisplays.push({ id: 1, label: 'free', visible: true});
					that.allDisplays.push({ id: 2, label: 'booked', visible: true});
					that.allDisplays.push({ id: 3, label: 'free ratio', visible: true});
					that.allDisplays.push({ id: 4, label: 'booked ratio', visible: true});
					that.allDisplays.push({ id: 5, label: 'spots', visible: true});
					that.allDisplays.push({ id: 6, label: 'price', visible: true});
					that.allDisplays.push({ id: 7, label: 'pricegroup', visible: true});
					that.allDisplays.push({ id: 8, label: 'budget', visible: true});
					that.allDisplays.push({ id: 9, label: 'avg price', visible: true});
					that.allDisplays.push({ id: 10, label: 'avail. ratecard', visible: true});
					that.allDisplays.push({ id: 11, label: 'avail. ratecard (1xdur)', visible: true});
					that.stopLoader();
					
                    resolve (that.stationId);                    
                 }).catch(e => {
					    that.stopLoader();
                        this.$toast.error("loading data for media/stations: " + e.response.data, 'Error', { position: "topRight" });
                        reject ();
                    });
	      });
    },
	filterGrid( grids)
    {
		let tmp = [... grids];
		if ( this.selectedGrids && this.selectedGrids.length > 0 )
     	{
     		tmp =  tmp.filter(g => this.selectedGrids.includes(g.gridName) );
     	}
		if ( this.selectedTimeslots && this.selectedTimeslots.length > 0 )
     	{
     		tmp =  tmp.filter(g => this.selectedTimeslots.includes( this.printTimeslot(g)) );
     	}
    	return tmp;
    },
	getPG( slot)
	{
		if ( slot.pricegroup < 10 )
		{
			return "P-0"+slot.pricegroup+" (" + slot.price+")";
		}
		return "P-"+slot.pricegroup+" (" + slot.price+")";
	},
	filterSlot( slots)
    {
		
		try
		{
			
			if ( this.selectedPG && this.selectedPG.length > 0 )
	     	{
				let tmp = [];
				for ( let si in slots)
				{
	     			let newSlots = slots[si].slots.filter(g => this.selectedPG.includes( this.getPG(g) ));
					if ( newSlots && newSlots.length )
					{
						tmp.push( slots[si]);
					}
				}
				return tmp;
	     	}
			
	    	return slots;
		} catch (e)
		{
			return slots;
		}

    },
	filterDaySlot( daySlot)
	{
		try
		{
			if ( this.selectedPG && this.selectedPG.length > 0 )
	     	{
	     		if ( this.selectedPG.includes( this.getPG( daySlot) ))
				{
					return daySlot;
				}
				return null;
	     	}
	    	return daySlot;
		} catch (e)
		{
			return daySlot;
		}
	},
    getGridData( tmp, keepFilters)
    {
        this.timeSlots = new Array();
        if (!keepFilters.includes("Grid"))
        {
        	this.allGrids = new Array();
        }
        if (!keepFilters.includes("PG"))
        {
        	this.allPG = new Array();
        }
        if (!keepFilters.includes("Timeslot"))
        {
        	this.allTimeslots = new Array();
        }
		for (let ri in tmp.gridPeriodResult)
		{
			let r = tmp.gridPeriodResult[ri];	
			if ( this.selectedGrids && this.selectedGrids.length > 0 )
	     	{
	     		r.grids =  r.grids.filter(g => this.selectedGrids.includes(g.gridName) );
	     		keepFilters.push('Grid');
	     	}
			if ( this.selectedTimeslots && this.selectedTimeslots.length > 0 )
	     	{
	     		r.grids =  r.grids.filter(g => this.selectedTimeslots.includes( this.printTimeslot(g)) );
	     		keepFilters.push('Timeslot');
	     	}
			for (let gi in r.grids)
			{
				let grid = r.grids[ gi];
				if (!keepFilters.includes("Grid"))
	        	{
		            if ( !this.allGrids.includes( grid.gridName))
		            {
		            	this.allGrids.push( grid.gridName);
		            }
		        }
				if (!keepFilters.includes("Timeslot"))
	        	{
	        		let ts = this.printTimeslot( grid);
		            if ( !this.allTimeslots.includes(ts))
		            {
		            	this.allTimeslots.push(ts);
		            }
		        }
				for (let sli in grid.slots)
				{
					let slot = grid.slots[ sli];
					for (let ssli in slot.slots)
					{
						let dayslot = slot.slots[ ssli];
						if (!keepFilters.includes("PG"))
			        	{
			            	if ( !this.allPG.includes( this.getPG( dayslot)))
			            	{
			            		this.allPG.push( this.getPG( dayslot));
			            	}
			            }
					}
				}
			}
		}

        this.allPG.sort( (a, b) => a.localeCompare(b));
        
    },
    clearFilter() { 
       this.startLoader();
       this.selectedMedia = new Array();
       this.selectedGrids = new Array();
       this.selectedPG = new Array();
       this.selectedTimeslots = new Array();
       this.selectedCats = new Array();
       this.selectedSpotReasons = new Array(); 
    },
	getAdBreaksNew() { 
    	this.pbAction = "getAdBreaksInternalNew";
    	this.pbTitle = "Reading grid structure";
  	    this.showProgressBar = true;
    },
    getAdBreaksInternalNew(myId, api) {
	   let that = this;	
       let dateFrom = new Date(this.fromDate).toISOString().split('T')[0];
       let dateTo = new Date(this.untilDate).toISOString().split('T')[0];
       //alert( dateFrom+" - "+ dateTo)
       that.gridValid = [];
       // GET INVENTORY 
       // GET GRIDS
	   let dFrom = new Date(dateFrom);
       let dTo = new Date(dateTo);
       let time = new Date().getTime();
       let filterStructure = {};
       filterStructure.placement = null;
	   if ( this.productId )
	   {
		filterStructure.product = {id: this.productId, name: this.product};
	   }
	   filterStructure.duration = this.duration;
	   //alert( JSON.stringify( this.selectedStation))
	   filterStructure.mediaIds = [ this.selectedStation.id];
	   //alert( JSON.stringify(filterStructure))
       let startTime = new Date().getTime();
	   let view = this.gridViewFin ? "Financial": "TX";
	   //alert( JSON.stringify(that.filterStructure))
	       let grd = new Promise(function (resolve, reject) {
		   api.post( invAPI+"/findInventorySlotsNew/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+dateFrom+"/"+dateTo+"/"+sessionStorage.timezoneId+"/null/"+view+"/"+myId, filterStructure )
		         		 .then( response => 
		                  {
							//alert( JSON.stringify(response.data))
							that.showProgressBar = false;
							that.inventoryPlacementResult = response.data;
							//
							
							
						  }).catch(e => {
	                        showError( that.$toast, "getting ad grid", e);
							that.showProgressBar = false;
	                    });
			
  
    	})
	  },
   printTime(time)
   {
       		return printTimeHHMM(time);
   },
   printTime2(time)
      {
        return printTime2(time);
   },
   getInventory()
   	{
   		this.getAdBreaksNew();
   	},
   startLoader()
    {
    	if ( !this.loadingActive)
      	{
	      	this.loadingActive = true;
	      	this.loader = this.$loading.show({
	                    // Optional parameters
	                    container: this.$refs.formContainer,
	                    canCancel: true,
	                    programmatic: false,
	                    onCancel: this.onCancel,
	                    color: '#000000',
					    loader: 'dots',
					    width: 64,
					    height: 64,
					    active: true,
					    backgroundColor: '#ffffff',
					    opacity: 0.5,
					    zIndex: 999,
	                });
	    }
    },
	stopLoader()
	{
		clearInterval( timers.pop());
		this.loadingActive = false;
		this.loader.hide();
	},
	
  },
  computed: {
  	spotsSelected: { 
  	    get() {
	        var spots = new Array()
	    	for ( var x in this.marked )
	    	{
	    		if ( this.marked[x] )
	    		{
	    			spots.push( x );
	    		}
	    	}
	    	return spots;
      }
  	}
  },
  watch: {
	
	inventoryPlacementResult: 	function() {
        if ( this.inventoryPlacementResult )
        {
			this.lastDay = 10;
			if ( this.inventoryPlacementResult && this.inventoryPlacementResult.days && this.inventoryPlacementResult.days.length)
			{
				this.lastDay = this.inventoryPlacementResult.days[this.inventoryPlacementResult.days.length-1].no;
				this.getGridData( this.inventoryPlacementResult, []);
			}
			
    	}
    },
	productId: function() {
		this.getRcFactor( this.productId);
	},
    user: function() {
    	if ( this.user.fontSize )
        {
        	this.fontSize = this.user.fontSize;
        }
		
    }
  },
  created() {
	let d = new Date();
	let inc = d.getMonth() > 10 ? 1: 0;
	let mn = d.getMonth() > 10 ? 1 : d.getMonth() +1;
	let from = isoDate( d.getFullYear()+inc, mn, 1);
	this.getSetup( );
	this.fromDate = new Date( from);
	this.untilDate = new Date( );
	this.untilDate.setFullYear( d.getFullYear()+inc, mn, 0)
  	this.getStations();
	this.firstDay = 1;
  },
  updated() {
  //console.log("update...");
  this.$nextTick(function () {
    
    if (this.loadingActive)
    {
        //console.log("stop loader: " + timers.length)
		
	    timers.push( setTimeout( this.stopLoader, 100));
    }
  })
  }
}
</script>
<style scoped>
ul {
  list-style: square inside none;
}
.bold {
  font-weight: bold;
}
.xtransition {
 	transition: all .2s;
   -webkit-transition: all .2s;
 }
 .appLeft {
	display:  inline-table; 
	text-align: left;
	width: 70%; 
}  
.xtransitionFast {
 	transition: all .4s;
   -webkit-transition: all .4s;
 }
.BMday {
  display: table-cell;
  max-width: 50pt !important;
  overflow-x: hidden;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
}
.BMheaderDay { 
  font-size: 7pt; 
  display: table-cell;
  text-align: center;
  font-weight: bold;
  vertical-align: middle;
  align-items: center;
  justify-content: center;  
  border-left: 1px solid #aaa; 
  border-bottom: 1px solid #ccc; 
  cursor: pointer;
  overflow-x: hidden;

  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
 }
 .right
 {
	float: right !important;
	
	align-items: right !important;;
	justify-content: right !important;; 
	text-align: right !important;
 }
.BMSection {
    margin-top: 0pt;
	height:20pt; 
	width: 100%;
	border: 1pt ridge #ccc;
	vertical-align: middle; 
	text-align: center;
	display: inline-flex; 
	color: #666; 
	background-color: #eee; 
	font-weight: bold; 
	font-size: 10pt;
}
.BMSectionDetail {
    padding-top:  3pt; 
    padding-left: 20pt;
    text-align: center;
	width: 100%;
}
.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  width: 100%;
  display: flex;
}
.dontWrap90 {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  width: 50%;
  display: flex;
}
.smallText {
  font-size: 9pt;
  height: 12pt;
}

.BMnoadbreak {
  border-left: 1px solid #aaa; 
  border-bottom: 1px solid #ccc; 
  color: #bbb;
  background-color: #eee;
  text-align: center;
  vertical-align: bottom;
  align-items: center;
  justify-content: center;  
  overflow-x: hidden;
  overflow-y: hidden;
  font-size: 7pt;
}
.channelTitle {
	height: 22px;
	display: block;
	padding-left: 5pt;
	padding-top: 4px;
	vertical-align: middle;
	align-items: center;
	justify-content: center;  
	overflow-x: hidden;
	overflow-y: hidden;
	font-size: 10pt;
	font-weight: bold;
}
.BMnoadbreakExcl {
  border-left: 1px solid #aaa; 
  border-bottom: 1px solid #ccc; 
  color: #bbb;
  background-color: #fcc;
  text-align: center;
  vertical-align: bottom;
  align-items: center;
  justify-content: center;  
  overflow-x: hidden;
  overflow-y: hidden;
  font-size: 7pt;
}
.BMadbreak1 {
	display: inline-block; 
	width: 100%;
	overflow-x: hidden;
    overflow-y: hidden;
	white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis; 
    vertical-align: top; 
    padding-top: 0pt;
    border: none;
    margin: 0pt;
    height: 100%;
    margin: 0pt;
}
.BMadbreak {
	
}
.BMadbreak2 {
	display: inline-block; 
	width: 28% !important; 
	overflow-x: hidden;
    overflow-y: hidden;
	white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis; 
	height: 100%;
	margin: 0pt;
    padding: 0pt;
}
.BMadbreakMarked {
  border-left: 1px solid #aaa; 
  border-bottom: 1px solid #ccc; 
  color: #f0c;
  background-color: #bcc; 
  text-align: center;
  vertical-align: bottom;
  align-items: center;
  justify-content: center;  
  font-size: 7pt;
}
.myButton {
	//width: 18%;
    padding: 6px 8px;
    outline: none;
    border-radius: 3px;
    height: 24pt;
    font-size: 10pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 4pt;
    margin-right: 4pt;
    margin-bottom: 4pt;
}
.BMbreak:hover {
  cursor: pointer;
}
.BMadbreak:hover {
	color: #000 !important;
	background-color: #ccf !important; 
	border-left: 2px solid blue !important;
	border-right: 2px solid blue !important;
}
.BMadbreakMarked:hover {
  color: #000 !important;
  background-color: #ccf !important; 
}
.BMheader { 
  display: block;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  font-size: 8pt; 
  font-weight: bold;
  white-space: nowrap; /* Don't forget this one */ 
 }
.BMheaderInv {
	color: transparent;
}

.BMprogram {
	
	border-bottom: 1px solid grey; 
	//border: 1px solid #fcc; 
	position:relative;
	z-index: 0;
}
.BMLabel {
	font-size: 10px;
}
.Date {
	border-bottom: 1px solid #888 !important; 
}
.BMprogram:hover {
	color: #fff !important; 
    background-color: #888 !important; 
	cursor: pointer;
	
}
.puInfo { padding-left: 2em; z-index: 999; }
.BMtime {
	font-weight: normal;
	padding-left: 8pt;
	cursor: pointer;
}
.BMuserTime {
	color: #888;
	padding-left: 2pt;
}
.BMinfo {
	//background-color: #e0f5fd; 
}
.BMbreak {
	display: table-cell;
	//background-color: #e0f5fd;
	border-bottom: 1px solid #ccc; 
	font-weight: normal !important;
}
.notAvail {
	display: table-cell;
	border-bottom: 1px solid #ccc; 
	cursor: not-allowed  ;
}
.BMheadLine {
  //color: #fff !important;
  //background-color: #e0f5fd !important; 
}
.todrag {

}

.BMHeadActions {
	position: sticky !important;
	top: -.5px !important;
	margin-top: 0 !important;
	height: 21pt !important;
	z-index: 99;
 }
.BMHeadActions22 {
	 position: sticky !important;
	 top: 21pt !important;
	 z-index: 99;
 }

 .BMHeadActions:hover {
 	cursor: pointer;
 }
 .placementName {
	display: inline-flex;
	width: 40% !important;
	overflow-x: hidden;
    overflow-y: hidden;
	white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis; 
 }
 .BMCounter {
	//background-color: #f2f2f2 !important; 
 } 
 .BMappHead { 
    width: 100%;  
    text-align: right;
    padding: 0pt 0pt 0pt;
 }
 .colored {
  background-color: #eef; 
 }
 .myButtonSmall {
 	//width: 18%;
     padding: 3px 4px;
     outline: none;
     border-radius: 4px 12px 0 0;
     height: 20pt;
     font-size: 7pt;
     background-color: #eef;
     border: 1px outset #aaa;
     color: rgb(0, 0, 0);
     margin-top: 4pt;
     margin-right: 0pt;
     margin-bottom: 4pt;
 } 
 .minRound {
	border-radius: 4px 4px 4px 4px;
 }
 .myButtonSmall:hover {
 	background-color: #bbb !important;
 }
 .myButtonSmall:where([active]) {
 	background-color: #bbf;
 	
 }
 .iButton {
    background-color: transparent;
    border: none;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding-bottom: 2pt;;
    padding-left: 2pt;
    padding-right: 2pt;
    padding-top: 4pt;
    height: 20pt;
    text-align: center;
    white-space: nowrap;
}  
.iButton:hover {
    color: #fff !important;
  	background-color: rgb(88, 218, 218);; 
}  
img {
    max-width:80pt;
    height:auto;
} 
</style>
