<template>
<div>   
	  <XModal v-if="copyEntry" v-show="visible" :name="name" :resizable="true" :width="width+'%'" :height="height+'%'" :draggable="false" >
	  <div :style="getAppStyle()+'height: 100%;border: 2pt inset grey;'">
	  <div class='appHead'>
             <div class="dontWrap" style='color: #000; width: 80%; overflow-x: clip; font-size: 10pt; display: inline-flex; float: left; font-weight: bold; padding-left: 5pt; padding-top: 2pt; margin-right: 15pt;'>
              <div v-if="copyEntry && copyEntry.name" style='padding-top: 4pt; '>{{copyEntry.name}}</div><div v-else style='padding-top: 4pt; '>Copy Viewer</div>
             </div>
            
              <button id='closePopup2' type="button" class="iButton" @click='close' data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16">
				  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
				  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
				</svg>
			  </span>
              </button>
            </div> <!-- appHead -->
       <div class="modal-header">
          <slot name="header">
            <div class='appHeadLine'>
            	
            </div> <!-- appHeadLine -->
            
           </slot>
        </div> <!-- modal-header -->  	  
        <div class="modal-body" style='height: 100% !important;' id='main-body'>
	  
	      <div  style=''>
          <slot name="body">
           
            <div v-if="copy" class='mainContent' >
              
				<table  width="100%">
					<tr>
						<td width="15%">{{tr('Copy')}}</td>
						<td width="84%"><div class="opener small" @click="$refs.editor.open('Copy', copy.id)">{{copy.houseNumber}}/{{copy.name}}/{{copy.duration}}"</div></td>
					</tr>
					
					<tr>
						<td>Date From/To</td><td><div class="small" style="display: inline-flex;width:90pt">
							    {{fmtDate(copy.fromDate)}}
								</div>-
						    <div class="small" style="display: inline-flex;width:90pt">
								{{fmtDate(copy.untilDate)}}</div></td>
					</tr>			
					<tr>
						<td width="15%">In Rotations</td>
						<td width="84%">
							<div v-for="(p,pi) in rotations.filter( p=>inRotations.includes( p.id))" :key="'pl'+pi" 
								class="opener small" style="display: inline-flex; margin-right: 5pt; border: 1px dotted grey; font-size: 10pt;" @click="$refs.editor.open('Rotation', p.id)">
								{{p.label}}
							</div>	
						</td>
					</tr>	
					
					
					<tr v-if="client">
						<td width="15%">{{tr("Client")}}</td><td width="84%"><div class="opener small" @click="$refs.editor.open('Client', client.id)">{{client.name}}</div></td>
					</tr>
					<tr v-if="product">
						<td width="15%">{{tr("Product")}}</td><td width="84%"><div class="opener small" @click="$refs.editor.open('Product', product.id)">{{product.name}}</div></td>
					</tr>
					<tr>
						<td colspan=2 style="border-bottom: 1px outset #448; padding-top: 5pt;"></td>
					</tr>
					<tr v-if="placements && placements.length">
						<td colspan=2>
							<div  style='width: 100%; height: calc(20vH) !important; overflow-y: scroll;'>
								<table border=0 width="100%">
								<tr :style="getAppStyle()+'position: sticky; top: 0; z-index: 9;'">
									<td >{{tr("Placement")}}</td>
									<td >{{tr("Media")}}</td>
									<td >From</td>
									<td >Until</td>
									<td >Rotation</td>
									<td ></td>
									<td ></td>

								</tr>
								<tr v-for="(p,pi) in placements" :key="'pl'+pi" class="small" >
									<td ><div class="opener small" @click="$refs.editor.open('Placement', p.id)">{{p.name}}</div></td>
									<td >{{media.find(m=>p.media.includes(m.id)).name}}</td>
									<td >{{p.fromDate}}</td>
									<td >{{p.untilDate}}</td>
									<td width="30%"><GSelectSimple :options="rotations" v-model="p.rotationId"/></td>
									<td v-if="inRotations.includes( p.rotationId)" title="copy is already assigned to this rotation" :style="isDarkMode()?'color: #0f0;':'color: green;'"> <mdicon   name="check"/></td>
									<td v-else-if="p.rotationId" title="copy is not part of this rotation">
								    <Button title="assign to rotation" style='float:right;' class='smallBN' @click="addToRotation( p)">
										<mdicon  :size='14' name="plus-circle-outline"/>
									</Button>
									</td>
									<td v-else><Button v-if="!p.rotationId" title="create and assign rotation" style='float:right;' class='smallBN' @click="createRotation(p)"><mdicon :size='14' name='screen-rotation'/></button></td>
									<td  ><Button v-if="p.rotationId"  style='float:right;' title="open/edit rotation" class='smallBN' @click="$refs.editor.open('Rotation', p.rotationId)"><mdicon :size='14' name='pencil'/></button>
								    <Button v-if="p.rotationId && isDifferent(p)"  style='float:right;' title="open/edit rotation" class='smallBN' @click="savePlacement( p)"><mdicon :size='14' name='content-save-outline'/></button>
									</td>
								</tr>
								</table>	
							</div>
						</td>
					</tr>
				</table>	
               
				
			   
			</div>
			
	      </slot>
	      </div>
	      </div>
		  <div style='position: sticky; width: 100%; bottom: 20pt !important; height: 30pt; padding-right: 15pt;'>
				<Button v-if="copy.id" title="reload data" style='float:right;' class='button' @click="loadData"><mdicon size="18" name="reload"/></button>	
				<Button title="create rotation" style='float:right;' class='button' @click="createRotation()"><mdicon :size='18' name='screen-rotation'/></button>               
				<Button title="close popup" style='float:right;' class='button' @click="close"><mdicon :size='18' name='close'/></button>
		</div>
	  </div>		
	  </XModal>
	  
	<GFWEOpenEditor ref='editor' @opened="hideMe" @closed="openMeAgain"></GFWEOpenEditor>
	<GConfirm ref='confirm'/>
	<GPrompt ref='prompt'/>
	
</div>
</template>


<script>
import {HTTP, fwAPI, copyWorkflowAPI, invAPI, setReload, showError} from '@/variables.js';
import { getAppStyle, setDarkMode, isDarkMode, initAppMode, getFGColor } from '@/AppStyle.js';
import { getData, defer } from '@/utils.js';
import { format } from '@/Numbers.js';
import { tr } from '@/translate.js';
import { parseTime, fmtDate } from '@/basicTimeFN.js';
import InputDatePick2 from '@/components/inputElements/InputDatePick3';
import InputDateTime from '@/components/inputElements/InputDateTime';
import InputMoney from '@/components/inputElements/InputMoneyNC';
import InputDouble from '@/components/inputElements/InputDouble';
import Switch from '@/components/Switch';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import InputText from '@/components/inputElements/InputText';
import GSelectSimple from '@/components/GSelectSimple';
import Checked from '@/components/Ops/Checked';
import GConfirm from '@/components/GConfirm';
import GPrompt from '@/components/GPrompt';
import CopyCreateWizard from '@/components/misc/CopyCreateWizard';
import ClickOutside from 'vue-click-outside'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import JQuery from "jquery";
let $ = JQuery;
var momentTZ = require('moment-timezone');
export default {
  name: 'GOPS_CopyEntryWizzard',
  components : {
      GConfirm, 
	  //InputText, 
	  //InputDatePick2, 
	  GSelectSimple, GPrompt
	  //InputDouble, 'app-switch': Switch
	  //,InputMoney
  },
  props: {
    name: {
      type: String,
      default: 'CopyViewer'
    },
    height: {
      type: Number,
      default: 60
    }
  },
  data () {
    return {
		width: 75,
		adjust: true,
		copyEntry: null,
		copy: null,
		product: null,
		client: null,
		placements: [],
		placementsDB: [],
		rotations: [],
		inRotations: [],
		media: [],
		rotationName: "",
		visible: false,
		getAppStyle, format, tr,fmtDate, isDarkMode
    }
  },
  directives:{
    ClickOutside,
  },
  
  methods: {
     close() {  this.$modal.hide(this.name) },
     getWidth() { return this.width+"%";},
     
     title( name )
     {
    	return name;
     },
	 bg4Scale( fld, scale)
	 {
		if ( fld.activeScaleId===scale.id)
		{
			return "color: #fff; background-color: #000;";
		}
	 },
	
	 openEdit( entity, id )
	 {
		this.$refs.editor.open( entity, id);
	 },
	 openMeAgain()
	 {
		this.visible = true;
	 },
	 hideMe()
	 {
	 	this.visible = false;
	 },
	 doReload( p )
	 {
		//alert( JSON.stringify(p))
		if ( p )
		{
			this.copy=this.asCopy(p); 
			this.$emit('reload');
		}
	 },
	
	 open( copyEntry ) {
		this.copyEntry = copyEntry;
		let that = this;
		
		this.loadData().then( p=>{
			that.visible = true;
			that.$modal.show(that.name);
			that.$forceUpdate();
		});
		
	 },
	 isDifferent( placement)
	 {
		let db = this.placementsDB.find( p=>p.id==placement.id);
		return ( db != null && db.rotationId != placement.rotationId)
	 },
	 createRotation( placement)
	 {
		let that = this;
		let inputStruct = { name: this.rotationName};
		if ( placement )
		{
			inputStruct.name = placement.name;
			inputStruct.placementId = placement.id;
		}	
		
		this.$refs.prompt.confirm({textField: inputStruct.name, text: 'Name', title: "Create rotation", button1: "Cancel", button2: "Ok"})
		.then(r=>{
			
			if ( r )
			{
				inputStruct.name = r;
				HTTP.put( copyWorkflowAPI+"/createRotation/"+sessionStorage.tenantId
					+"/"+sessionStorage.accessPointId
					+"/"+sessionStorage.unitId
					+"/"+sessionStorage.userId
					+"/"+that.copyEntry.copyId,
					inputStruct
					)
		
				 .then( response => 
		         {
					that.copy = response.data.copy;
					that.product = response.data.product;
					that.client = response.data.client;
					that.placements = response.data.placements;
					that.placementsDB = response.data.placements.map( p=> { return {id: p.id, rotationId: p.rotationId}});
					that.rotations = response.data.rotations;
					that.rotations = that.rotations.map( p=> { return {id: p.id, label: p.name, visible: true}});
					that.rotations.unshift( {id: 0, label: "no rotation", visible: true} )
					
					that.media = response.data.media;
					that.inRotations = response.data.inRotations;
					that.rotationName = response.data.name;
			   		
				}).catch(e => {
		    	    showError( that.$toast, "createRotation", e);
					
		        });
			}
		})

		    

	 },
	 addToRotation( placement)
 	 {
 		let that = this;
 		if ( !placement || !placement.rotationId)
 		{
 			return;
 		}	

		HTTP.put( copyWorkflowAPI+"/addToRotation/"+sessionStorage.tenantId
			+"/"+sessionStorage.accessPointId
			+"/"+sessionStorage.unitId
			+"/"+sessionStorage.userId
			+"/"+placement.id
			+"/"+placement.rotationId
			+"/"+that.copyEntry.copyId
			)

		 .then( response => 
         {
			that.copy = response.data.copy;
			that.product = response.data.product;
			that.client = response.data.client;
			that.placements = response.data.placements;
			that.placementsDB = response.data.placements.map( p=> { return {id: p.id, rotationId: p.rotationId}});
			that.rotations = response.data.rotations;
			that.rotations = that.rotations.map( p=> { return {id: p.id, label: p.name, visible: true}});
			that.rotations.unshift( {id: 0, label: "no rotation", visible: true} )
			
			that.media = response.data.media;
			that.inRotations = response.data.inRotations;
			that.rotationName = response.data.name;
			
	   		
		}).catch(e => {
    	    showError( that.$toast, "createRotation", e);
			
        });


 	 },
	 savePlacement( placement)
  	 {
  		let that = this;
  		if ( !placement || !placement.rotationId)
  		{
  			return;
  		}	

 		HTTP.put( copyWorkflowAPI+"/savePlacement/"+sessionStorage.tenantId
 			+"/"+sessionStorage.accessPointId
 			+"/"+sessionStorage.unitId
 			+"/"+sessionStorage.userId
 			+"/"+placement.id
 			+"/"+placement.rotationId
 			+"/"+that.copyEntry.copyId
 			)

 		 .then( response => 
          {
 			that.copy = response.data.copy;
 			that.product = response.data.product;
 			that.client = response.data.client;
 			that.placements = response.data.placements;
			that.placementsDB = response.data.placements.map( p=> { return {id: p.id, rotationId: p.rotationId}});
 			that.rotations = response.data.rotations;
 			that.rotations = that.rotations.map( p=> { return {id: p.id, label: p.name, visible: true}});
 			that.rotations.unshift( {id: 0, label: "no rotation", visible: true} )
 			
 			that.media = response.data.media;
 			that.inRotations = response.data.inRotations;
 			that.rotationName = response.data.name;
 			
 	   		
 		}).catch(e => {
     	    showError( that.$toast, "createRotation", e);
 			
         });


  	 },
	 loadData()
	 {
		let that = this;
		
		let dataLoad = new Promise(function (resolve, reject) {
			HTTP.post( copyWorkflowAPI+"/getCopyDetails/"+sessionStorage.tenantId
				+"/"+sessionStorage.accessPointId
				+"/"+sessionStorage.unitId
				+"/"+sessionStorage.userId
				+"/"+that.copyEntry.copyId
				)
	
			 .then( response => 
	         {
				that.copy = response.data.copy;
				that.product = response.data.product;
				that.client = response.data.client;
				that.placements = response.data.placements;
				that.placementsDB = response.data.placements.map( p=> { return {id: p.id, rotationId: p.rotationId}});
				that.rotations = response.data.rotations;
				that.rotations = that.rotations.map( p=> { return {id: p.id, label: p.name, visible: true}});
				that.rotations.unshift( {id: 0, label: "no rotation", visible: true} )
				
				that.media = response.data.media;
				that.inRotations = response.data.inRotations;
				that.rotationName = response.data.name;
				
				resolve( that.copy);
		   		
			}).catch(e => {
	    	    showError( that.$toast, "reload", e);
				reject();
	        });
		});
		return dataLoad;
	 },
     getTableStyle() {
        
     	//return "overflow-y: scroll; height: calc(60vH - 104px)";
     	return "overflow-y: auto; height: calc("+this.height+"vH - 180pt) !important;";
     },
	 
	 save( )
 	 {
		let that = this;
		this.copy.duration = parseTime( this.copy.duration);
		
		this.getLogName().then( p=> {
			let copy = {...this.copy}
			copy.received = momentTZ(copy.received).utcOffset(0, true).format().split('T')[0];
			
			HTTP.post( copyWorkflowAPI+"/saveCopyEntry/"+sessionStorage.tenantId
				+"/"+sessionStorage.accessPointId
				+"/"+sessionStorage.unitId
				+"/"+sessionStorage.userId
				,
				copy
				)
	
			 .then( response => 
	         {
				that.copy = that.asCopy( response.data);
				that.$emit("reload");
		   		
			}).catch(e => {
	    	    showError( that.$toast, "update discount scale", e);
	        });
		});
 	 },
	 
  },
  watch: {

  },
  created() {

  }
}
</script>
<style scoped >

 .transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }
.hTable {
  table-layout: fixed;
  width: 100%;;
}

.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  overflow-x: hidden;
  overflow-y: hidden;
}
.tdSmall {
  overflow: hidden;
  width: 5%;
}
.tdLarge {
  width: 25%;
  overflow: hidden;
}
.tdXLarge {
  width: 40%;
  overflow: hidden;
}
.appLeft {
	display:  inline-table; 
	text-align: right;
	width: 100%; 
} 
.appRight {
	display: inline-table; 
	text-align: right;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
	width: 50%; 
}
.appHead { 
    width: 100%;  
    text-align: right;
    background-color: #eef;
    padding: 0pt 0pt 0pt;
 }
.history {
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 400 !important;
}
.mainContent {
  position: relative;
  height: 80% !important;
  
  float: top;
} 
.selectable {
	cursor: pointer; 
	transition: all 0.4s ease;
}
.bold {
	font-weight: bold;
}
.indent1 {
	margin-left: 10pt;
}
.indent2 {
	margin-left: 20pt;
}
.border {
	border: 1px solid #ccc;
}
.selectable:hover {
	background-color: #ccc !important;
	color: #000 !important;
}
.small {
	font-size: 10pt;
}
.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: table;
}
.btn-small {
	height: 14pt;
}
.btn-save {
	background-color: #080 !important;
    color: #fff !important;
}
.opener {
	cursor: pointer;
}
.opener:hover {
	background-color: #ccc !important;
	color: #000 !important;
	border-radius: 4px;
}
.button {
    background-color: white;
    border-color: #dbdbdb;
    border-width: 1px;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding-bottom: calc(0.7em - 1px);
    padding-left: .7em;
    padding-right: .7em;
    padding-top: calc(0.7em - 1px);
    text-align: center;
    white-space: nowrap;
    font-size: 10pt;
}
.smallBN {
	//width: 18%;
    outline: none;
    border-radius: 3px;
    height: 20pt;
    width: 24pt;
    font-size: 9pt;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
	margin-left: 5pt;
	padding-top: 0pt;
	margin-top: 0pt;
} 
.smallBN:hover {
    background-color: #aaf;
    border: 1px outset #aaf;
} 
.smallBN2 {
	//width: 18%;
    outline: none;
    border-radius: 3px;
    height: 20pt;
    width: 20pt;
    font-size: 9pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
	margin-left: 5pt;
	padding-top: 2pt;
	margin-top: 2pt;
} 
.smallStep {
	
    outline: none;
    border-radius: 3px;
    height: 14pt;
    width: 18pt;
    font-size: 9pt;
    background-color: #cfc;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
	margin-left: 5pt;
	padding-top: 0pt;
	margin-top: 2pt;
}  
.button:hover {
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
    border: 1pt outset grey;
    background-color: #e8f0e8;
}
.modal-wrapper {

  vertical-align: middle;
}
td { 
    padding-right: 8px;
}
.modal-container {

  margin: 0px auto;
  padding-left: 0pt;
  padding-bottom: 20pt;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.4s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}
.steps {
	margin-left: 5pt;
}
.modal-body {
  margin: 20px 0;
  padding: 5pt;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.scInp {
	display: inline-flex;
	width: 12% !important;	
}
</style>
